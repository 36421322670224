import { DateTime } from "luxon";

import { connection as contactConnection } from "@/store/modules/contacts";
import { manageAPIError } from "@/js/api/builder/api-error";

const output = signature => {
	signature.signedAt = DateTime.fromISO(signature.signed_at, { zone: 'utc' });
	delete signature.signed_at;

	if (signature.entity_system) {
		signature.entitySystem = {
			principal: signature.entity_system.principal,
			componentName: signature.entity_system.component_name
		};
		delete signature.entity_system;
	}

	if (signature.entity_individual) {
		signature.entityIndividual = {
			principal: signature.entity_individual.principal
		};
		delete signature.entity_individual;
	}

	return signature;
};

const findSignatures = id => contactConnection.axios
	.get(`/${id}/signature`)
	.then(({ data }) => data.signatures.map(output))
	.catch(manageAPIError);

const createSignature = (id, signature) => contactConnection.axios
	.post(`/${id}/signature`, signature, { refetchId: id })
	.then(({ data }) => data.signatures.map(output))
	.catch(manageAPIError);

const findExtendedSignatures = id => contactConnection.axios
	.get(`/${id}/signatures`)
	.then(({ data }) => data)
	.catch(manageAPIError);

const createHumanOverride = (id, signature) => contactConnection.axios
	.post(`/${id}/signatures/submissions/human_override`, signature, { refetchId: id })
	.then(({ data }) => data)
	.catch(manageAPIError);

const socket = contactConnection.socket;

export {
	findSignatures,
	createSignature,
	findExtendedSignatures,
	createHumanOverride,
	socket
};
