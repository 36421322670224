<template lang="pug">
ExamplePicker(v-model="example")

SchemaView.example(
	:object="object"
	:schema="schema"
)

VJSON(
	title="object"
	:data="object"
)

VJSON(
	title="schema"
	:data="schema"
)

</template>

<script>

import styleguideComponents from '../components';

import ExamplePicker from './components/ExamplePicker.vue';
import { View as SchemaView } from '@/components/base/JsonSchema/index';

import examples from './examples';

export default {
	name: 'StyleguideJsonSchemaView',
	components: {
		...styleguideComponents,
		ExamplePicker,
		SchemaView
	},
	data() {
		return {
			example: 'basic'
		};
	},
	computed: {
		schema() {
			return examples[this.example].schema;
		},
		object() {
			return examples[this.example].object;
		}
	}
};

</script>

<style lang="scss">
.example {
	margin-bottom: 50px;
}
</style>
