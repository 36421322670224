// This is pretty basic and does not work everything.
// Should be enough for api responses though
export const camelize = (string, uppercaseFirstLetter = false) => {
	// Do nothing with id pairs: (ID, ID)
	// This format is used in reservation window change responses
	if (/^\([^)]+\)$/.test(string)) {
		return string;
	}

	return string
		.split(/[_-\s]+/g)
		.map((substring, i) => {
			const firstLetter = substring[0] ?? '';
			const rest = substring.slice(1).toLowerCase();

			if (i > 0 || uppercaseFirstLetter) {
				return firstLetter.toUpperCase() + rest;
			}

			return firstLetter.toLowerCase() + rest;
		})
		.join('');
};

export const camelizeKeys = (object) => {
	if (Array.isArray(object)) {
		return object.map(camelizeKeys);
	} else if (object === null || typeof object !== 'object') {
		return object;
	}

	return Object.entries(object).reduce((object, [key, value]) => {
		return {
			...object,
			[camelize(key)]: camelizeKeys(value)
		};
	}, {});
};

export const underscore = (string) => {
	if (typeof string !== 'string') {
		return string;
	}

	const expression = /(?:([0-9a-z])([A-Z])|([a-zA-Z])([0-9]))/g;
	return string.replace(expression, '$1$3 $2$4')
		.replace(/[ -]+/g, '_')
		.toLowerCase();
};

export const underscoreKeys = (object) => {
	if (Array.isArray(object)) {
		return object.map(underscoreKeys);
	} else if (object === null || typeof object !== 'object') {
		return object;
	}

	return Object.entries(object).reduce((object, [key, value]) => {
		return {
			...object,
			[underscore(key)]: underscoreKeys(value)
		};
	}, {});
};

export const dasherize = (string) => {
	if (typeof string !== 'string') {
		return string;
	}

	return underscore(string).replace(/_/g, '-');
};

export const dasherizeKeys = (object) => {
	if (Array.isArray(object)) {
		return object.map(dasherizeKeys);
	} else if (object === null || typeof object !== 'object') {
		return object;
	}

	return Object.entries(object).reduce((object, [key, value]) => {
		return {
			...object,
			[dasherize(key)]: dasherizeKeys(value)
		};
	}, {});
};
