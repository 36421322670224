<template lang="pug">
.travel-range {{label}}
</template>

<script>

export default {
	name: 'StringValue',
	props: {
		value: {
			type: Object,
			required: true
		}
	},
	computed: {
		label() {
			if (this.isContinuous) {
				return 'Continuous';
			}

			return `${this.from} - ${this.to} ${this.unit}`;
		},
		isContinuous() {
			return this.value['is_continuous'] === true;
		},
		from() {
			return this.value.from?.value;
		},
		to() {
			return this.value.to?.value;
		},
		unit() {
			return this.value.from?.unit;
		}
	}
};

</script>
