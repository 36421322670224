<template lang="pug">
teleport(to="body")
	.modal-overlay(role="dialog")
		.modal(:class="[size, align]")
			header.modal-header
				slot(name="header")
				button.close-button(
					type="button"
					@click.stop="close"
				)
					VIcon(name="cross")

			.modal-body
				slot

			footer.modal-footer(v-if="hasFooter")
				slot(name="footer")

				template(v-if="deleteButton")
					VButton.alert.invert(@click.stop="remove") {{ deleteButton }}
					.divider

				VButton.invert(
					v-if="confirmButton"
					@click.stop="confirm"
					:class="{ disabled: disableConfirmButton }"
					:disabled="disableConfirmButton"
					:tooltip="confirmButtonTooltip"
				) {{ confirmButton }}
				VButton.secondary.invert(
					v-if="showCancelButton"
					@click.stop="close"
				) {{ cancelButtonText }}
</template>

<script>
export default {
	name: 'VModal',
	props: {
		size: {
			type: String,
			required: false,
			default: ''
		},
		deleteButton: {
			type: String,
			required: false,
			default: ''
		},
		confirmButton: {
			type: String,
			required: false,
			default: ''
		},
		disableConfirmButton: {
			type: Boolean,
			required: false,
			default: false
		},
		hideCancelButton: {
			type: Boolean,
			required: false,
			default: false
		},
		cancelButtonText: {
			type: String,
			required: false,
			default: 'Cancel'
		},
		confirmButtonTooltip: {
			type: String,
			required: false,
			default: null
		},
		align: {
			type: String,
			required: false,
			default: null
		},
		closeOnConfirm: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	emits: ['close', 'remove', 'confirm'],
	computed: {
		showCancelButton() {
			return this.hideCancelButton === false;
		},
		hasFooter() {
			return this.deleteButton ||
				this.confirmButton ||
				this.showCancelButton ||
				this.$slots.footer;
		}
	},
	mounted() {
		document.body.addEventListener('keyup', this.closeOnEscape);
	},
	created() {
		document.body.style.overflow = 'hidden';
	},
	beforeUnmount() {
		document.body.removeEventListener('keyup', this.closeOnEscape);
		this.close();
	},
	methods: {
		close() {
			document.body.style.overflow = 'auto';
			this.$emit('close');
		},
		remove() {
			this.$emit('remove');
			this.close();// Here we'd like to add some wait function ( with loading spinner) while waiting for API response.
		},
		confirm() {
			this.$emit('confirm');
			if (this.closeOnConfirm) {
				this.close();// Here we'd like to add some wait function ( with loading spinner) while waiting for API response.
			}
		},
		closeOnEscape(event) {
			if (event.key == 'Escape') {
				event.stopPropagation();
				this.close();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.modal-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-y: auto;
	background: hsl(0 0% 0% / 40%);
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: rem-calc(40 20);

	@include scrollbars(0.5rem, hsl(var(--white20)));
}

.modal {
	width: rem-calc(600);
	background: hsl(var(--dark-purple110));
	border: 1px solid hsl(var(--white20));
	border-radius: var(--global-radius);
	box-shadow: inset 0 0 9px 0 hsl(0 0% 100% / 10%);
	display: flex;
	flex-flow: column;
	margin: auto;

	&.top {
		align-self: start;
		margin-top: 0;
	}

	&.small {
		max-width: rem-calc(400);
	}

	&.medium {
		width: rem-calc(660);
	}

	&.large {
		width: 90vw;
		max-width: rem-calc(960);
	}

	&.full {
		align-self: flex-start;
		width: 90vw;
		max-width: rem-calc(960);

		.modal-footer {
			margin-top: auto;
		}
	}
}

.modal-header {
	position: relative;
	padding: var(--box-padding);
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: rem-calc(24);
	flex-shrink: 0;
	font-weight: var(--font-bolder);

	#{v-deep('h1')},
	#{v-deep('h2')},
	#{v-deep('h3')},
	#{v-deep('h4')},
	#{v-deep('h5')},
	#{v-deep('h6')} {
		margin: 0;
	}
}

.close-button {
	position: absolute;
	top: rem-calc(10);
	right: rem-calc(10);
	appearance: none;
	outline: none;
	background: transparent;
	color: var(--body-color);
	border: 0;
	cursor: pointer;
	font-size: inherit;
	opacity: 0.6;

	&:hover {
		opacity: 1;
	}
}

.modal-body {
	padding: var(--box-padding);

	#{v-deep('> *:first-child')} {
		margin-top: 0;
	}

	#{v-deep('> *:last-child')} {
		margin-bottom: 0;
	}
}

.modal-footer {
	padding: var(--box-padding);
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;

	#{v-deep('.identifier')} {
		margin-right: rem-calc(10);
	}

	> *:last-child {
		margin-right: 0 !important;
	}

	.button {
		margin: rem-calc(0 10 0 0);
	}

	.divider {
		width: rem-calc(1);
		height: rem-calc(30);
		background-color: hsl(var(--white20));
		margin-right: rem-calc(10);
	}
}
</style>
