import store from '@/store/store';

const listStoreModulesWithMethod = (type, method) => {
	const MODULE_NAME = 'MODULE_NAME';
	const regex = new RegExp(`^(?<${MODULE_NAME}>.+)/${method}$`);

	return Object.keys(store[type]).map(key => key.match(regex)?.groups[MODULE_NAME])
		.filter(match => !!match);
};

export { listStoreModulesWithMethod };
