import { isString, defaults } from 'lodash';

const truncateString = (original, maxLength, separator) => {
	if (original.length <= maxLength) {
		return original;
	}

	separator = separator || '...';

	const sepLength = separator.length;
	const charsToShow = maxLength - sepLength;
	const frontChars = Math.ceil(charsToShow / 2);
	const backChars = Math.floor(charsToShow / 2);

	return original.substr(0, frontChars) +
		separator +
		original.substr(original.length - backChars);
};

const fullSpacecraftName = (name) => {
	return name
		.replace(/[-_]/g, ' ')
		.split(' ')
		.map((segment, i, segments) => {
			if (segments.every(s => !/(^| )[A-Za-z]+[0-9]*($| )/.test(s))) {
				return segment;
			}

			return segment[0].toUpperCase() + segment.substr(1).toLowerCase();
		})
		.join(' ');
};

const spacecraftName = (name) => {
	const fullName = fullSpacecraftName(name);
	const maxLength = 12;

	if (fullName.length <= maxLength) {
		return fullName;
	}

	return `${fullName.substr(0, maxLength)}…`;
};

const compactSpacecraftName = (name) => {
	const fullName = fullSpacecraftName(name);

	if (/^[a-zA-Z]{3,} [a-zA-Z0-9]{1,3}$/.test(fullName)) {
		return `${fullName.substr(0, 3)} ${fullName.replace(/^.+ /, '')}`;
	} else if (fullName.length > 4) {
		return `${fullName.substr(0, 3)}…`;
	}

	return fullName;
};

const capitalize = (word, options = {}) => {
	const {
		preserveCase = true
	} = options;

	return word[0].toUpperCase() + (preserveCase ? word.slice(1) : word.slice(1).toLowerCase());
};

const capitalizeWords = (string, options = ' ') => {
	if (isString(options)) {
		return capitalizeWords(string, { joinOn: options });
	}

	options = defaults(options, {
		joinOn: ' ',
		preserveCase: true
	});

	return string
		.split(/[ _-]/g)
		.map((word) => {
			return capitalize(word, options);
		})
		.join(options.joinOn);
};

const toSentenceCase = (string, options = {}) => {
	const {
		preserveCase = true
	} = options;

	if (typeof string !== 'string') {
		return '';
	}

	return string
		.split(/[ _-]/g)
		.map((word, index) => {
			if (index === 0) {
				return capitalize(word, options);
			} else if (preserveCase) {
				return word;
			}

			return word.toLowerCase();
		})
		.join(' ');
};

const toKebabCase = (string) => {
	if (typeof string !== 'string') {
		return '';
	}

	return string
		.replace(/([a-z])([A-Z])/g, "$1-$2")
		.replace(/[\s_]+/g, '-')
		.toLowerCase();
};

const pluralize = (count, singular, plural = null) => {
	if (plural === null) {
		plural = `${singular}s`;
	}

	const word = count === 1 ? singular : plural;
	return `${count} ${word}`;
};

const percentage = (value, { maxDecimals = 2 } = {}) => {
	if (!Number.isFinite(value)) {
		return '∞%';
	} else if (typeof maxDecimals === 'number') {
		return `${parseInt(value * Math.pow(10, maxDecimals)) / Math.pow(10, maxDecimals)}%`;
	}

	return `${value}%`;
};

const toSentence = (values, options = {}) => {
	if (!Array.isArray(values)) {
		return toSentence([], options);
	}

	const connector = options.connector ?? ', ';
	const twoConnector = options.twoConnector ?? ` and `;
	const lastConnector = options.connector ?? `, and `;

	switch (values.length) {
		case 0:
			return '';
		case 1:
			return values[0];
		case 2:
			return values[0] + twoConnector + values[1];
		default:
			return values.slice(0, -1).join(connector) + lastConnector + values[values.length - 1];
	}
};

const number = (number) => {
	const thousandsSeparator = ',';

	return String(number)
		.split('').reverse().join('') // eslint-disable-line newline-per-chained-call
		.replace(
			/([0-9]{3})/g,
			`$1${thousandsSeparator}`
		)
		.split('').reverse().join('') // eslint-disable-line newline-per-chained-call
		.replace(
			new RegExp(`^${thousandsSeparator}`),
			''
		);
};


export const $text = {
	truncateString,
	fullSpacecraftName,
	spacecraftName,
	compactSpacecraftName,
	capitalize,
	capitalizeWords,
	toSentenceCase,
	pluralize,
	percentage,
	toSentence,
	number,
	toKebabCase
};

export default {
	install: app => {
		app.config.globalProperties.$text = $text;
	}
};
