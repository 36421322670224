import antenna from "./antenna.json";
import antennaSchema from "./antenna.schema.json";
import basic from "./basic.json";
import basicSchema from "./basic.schema.json";
import spacecraft from "./spacecraft.json";
import spacecraftSchema from "./spacecraft.schema.json";

const files = new Map<string, unknown>([
	['antenna.json', antenna],
	['antenna.schema.json', antennaSchema],
	['basic.json', basic],
	['basic.schema.json', basicSchema],
	['spacecraft.json', spacecraft],
	['spacecraft.schema.json', spacecraftSchema]
]);

const _: {
    [key: string]: {
        schema: object,
        object: object
    }
} = Array.from(files.keys())
	.reduce((examples, name) => {
		const base = name
			.replace(/^[^a-zA-Z0-9]+/, '')
			.replace(/(\.schema){0,1}\.json$/, '');
		const type = /(\.schema)\.json$/.test(name) ? 'schema' : 'object';

		if (!(base in examples)) {
			examples[base] = {
				schema: {},
				object: {}
			};
		}

		examples[base][type] = files.get(name);
		return examples;
	}, {} as any);

export default _;
