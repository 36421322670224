<template lang="pug">
VModal(
	v-if="isOpen"
	size="full"
	hideCancelButton
	@close="close"
)
	template(v-if="isLoading")
		VLoadingView(inline)
	template(v-else)
		ComponentStack(
			ref="stack"
		)
			SignModalDetails(
				:contact="contact"
				:signature="signature"
			)

		OverrideSubmissionModal(
			ref="overrideSubmissionModal"
			:signature="signature"
			:contact="contact"
		)
</template>

<script>
import { computed } from 'vue';

import { UPDATE_RESOURCE } from '@/env';
import {
	findExtendedSignatures as findSignature,
	socket
} from '@/js/api/contact-signatures';
import {
	getContactSignatureProfileId,
	setContactSignatureProfileId
} from '@/js/api/signature-profiles';
import { camelizeKeys } from '@/js/utils/case-conversion';
import delegate from '@/js/utils/delegate';

import ComponentStack from './ComponentStack.vue';
import SignModalDetails from './SignModalDetails.vue';
import OverrideSubmissionModal from './OverrideSubmissionModal.vue';

const updateSignatureInterval = 20 * 1000;

export default {
	components: {
		ComponentStack,
		SignModalDetails,
		OverrideSubmissionModal
	},
	provide() {
		return {
			$signContactModal: computed(() => {
				return {
					signature: this.signature,
					signatureComponents: this.signatureComponents,
					signatureProfiles: this.signatureProfiles,
					signatureProfileId: this.signatureProfileId,
					setSignatureProfileId: this.setSignatureProfileId,
					openOverrideSubmissionModal: this.openOverrideSubmissionModal
				};
			})
		};
	},
	data() {
		return {
			isOpen: false,
			isLoading: false,
			contact: null,
			loadedSignature: null,
			signatureProfileId: null,
			interval: null
		};
	},
	computed: {
		...delegate('signature', 'signatureComponents', 'signatureProfiles', { to: 'data' }),
		hasContact() {
			return this.contact !== null;
		},
		submissions() {
			return this.signature?.submissions ?? [];
		},
		data() {
			/* eslint-disable-next-line */
			const custom = null;
			const dump = false;

			if (custom !== null) {
				return custom;
			}

			if (dump) {
				/* eslint-disable */
				const object = {
					signature: window._.cloneDeep(this.loadedSignature),
					signatureComponents: window._.cloneDeep(this.$store.getters['signatureComponents/all']),
					signatureProfiles: window._.cloneDeep(this.$store.getters['signatureProfiles/all'])
				}
				console.log(`const custom = JSON.parse('${JSON.stringify(object)}');`);
				/* eslint-enable */
			}

			return {
				signature: this.loadedSignature,
				signatureComponents: this.$store.getters['signatureComponents/all'],
				signatureProfiles: this.$store.getters['signatureProfiles/all']
			};
		}
	},
	watch: {
		isOpen(value, previousValue) {
			if (value && !previousValue) {
				this.isLoading = true;
				Promise.all([
					this.fetchSignature(),
					this.getSignatureProfile(),
					this.getSignatureComponents()
				])
					.finally(() => {
						this.isLoading = false;
					});

				this.interval = setInterval(this.fetchSignature, updateSignatureInterval);
				socket.on(UPDATE_RESOURCE, this.socketUpdate);
			} else if (!value && previousValue) {
				socket.off(UPDATE_RESOURCE, this.socketUpdate);
				clearInterval(this.interval);
				this.interval = null;
			}
		}
	},
	beforeUnmount() {
		this.isOpen = false;
	},
	methods: {
		open({ contact }) {
			this.contact = contact;
			this.isOpen = true;
		},
		close() {
			this.contact = null;
			this.isOpen = false;
		},
		openOverrideSubmissionModal(options = {}) {
			this.$refs.overrideSubmissionModal.open({
				...options,
				contactId: this.contact.id
			}).then(() => {
				this.fetchSignature();
			});
		},
		fetchSignature() {
			if (!this.hasContact) {
				return Promise.resolve();
			}

			return findSignature(this.contact.id)
				.then((signature) => {
					this.loadedSignature = camelizeKeys(signature);

					return this.loadedSignature;
				});
		},
		getSignatureProfile() {
			return Promise.all([
				getContactSignatureProfileId(this.contact.id),
				this.$store.dispatch('signatureProfiles/ensure')
			]).then(([id]) => {
				this.signatureProfileId = id;
			});
		},
		setSignatureProfileId(id) {
			return setContactSignatureProfileId(this.contact.id, id)
				.then(() => {
					this.$notify.event('The signature profile was successfully changed', 'success');
					this.signatureProfileId = id;

					return id;
				});
		},
		getSignatureComponents() {
			return this.$store.dispatch('signatureComponents/ensure');
		},
		socketUpdate(data) {
			if (!this.hasContact) {
				return;
			}

			if (data.id === this.contact.id) {
				this.fetchSignature();
			}
		}
	}
};
</script>
