import _ from 'lodash';

export default stateName => {
	return {
		initiate(state) {
			state.initiated = true;
		},
		startInitiation(state) {
			state.initiating = true;
		},
		endInitiation(state) {
			state.initiating = false;
		},
		set(state, records) {
			state[stateName] = records;
		},
		overwrite(state, records) {
			// we want to avoid replacing the whole list on ie. a refetch. Using this mutation only updates the
			// records that have changed in comparison with the existing records and make the update smoother.
			const idsToDelete = [];

			state[stateName].forEach((record, index) => {
				const updatedRecord = records.find(({ id }) => record.id === id);
				if (updatedRecord) {
					if (!_.isEqual(updatedRecord, record)) {
						// update records that was different in the new list
						state[stateName][index] = updatedRecord;
					}
				} else {
					idsToDelete.push(record.id);
				}
			});

			// remove records that is not in the new list
			idsToDelete.forEach(id => {
				const index = state[stateName].indexOf(record => record.id === id);
				if (index >= 0) {
					state[stateName].splice(index, 1);
				}
			});

			// add new records to the list that was not already in the existing list
			records.forEach(record => {
				if (!state[stateName].find(({ id }) => id === record.id)) {
					state[stateName].push(record);
				}
			});
		},
		add(state, record) {
			state[stateName].push(record);
		},
		update(state, records) {
			if (!Array.isArray(records)) {
				records = [records];
			}

			records.forEach(record => {
				let existingRecordIndex = state[stateName].findIndex(found => found.id === record.id);
				if (existingRecordIndex >= 0) {
					state[stateName][existingRecordIndex] = record;
				} else {
					state[stateName].push(record);
				}
			});
		},
		remove(state, id) {
			const index = state[stateName].indexOf(resource => resource.id === id);
			if (index >= 0) {
				state[stateName].splice(index, 1);
			}
		},
		setEditorUserList(state, userList) {
			state.editorUserList = userList;
		}
	};
};
