import { merge } from 'lodash';

import dig from '@/js/utils/dig';

const KEY = 'preferences';
const FALLBACK = {};

const read = () => {
	return JSON.parse(localStorage.getItem(KEY)) ?? FALLBACK;
};

const write = (preferences) => {
	localStorage.setItem(
		KEY,
		JSON.stringify(preferences)
	);

	return read();
};

const state = {
	preferences: read()
};

const toObject = (key, value) => {
	return key
		.split('/')
		.reverse()
		.reduce((object, key, i) => {
			if (i === 0) {
				return {
					[key]: value
				};
			}

			return {
				[key]: object
			};
		}, {});
};

const mutations = {
	set(state, { key, value }) {
		const next = merge(
			state.preferences,
			toObject(key, value)
		);

		state.preferences = write(next);
	}
};

const actions = {
};

const getters = {
	get({ preferences }) {
		return (key, defaultValue = null) => {
			return dig(preferences, key.split('/')) ?? defaultValue;
		};
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
