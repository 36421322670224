<template lang="pug">
VModal(
	v-if="isOpen"
	ref="modal"
	size="full"
	hideCancelButton
	@close="close"
)
	template(#header) {{ title }}
	VLoadingView(
		v-if="isLoading"
		inline
	)

	template(v-else)
		h3 Short Description
		.inputs
			VInput(
				placeholder=""
				v-model="shortDescription"
			)
			VTextArea(
				:required="true"
				v-model="description"
			) Full description
			VButton(
				:disabled="!shortDescription || !description"
				@click="create"
			) Create
		template(v-if="hasChildren")
			h3 {{ parentTitle }}
			ol.timeline
				TimelineDay(
					v-for="{ date, items } in parentGroup"
					:key="groupKey(date)"
					:date="date"
					:items="items"
				)
		template(v-if="hasChildren")
			h3(v-if="hasChildren") Child incidents
			ol.timeline
				TimelineDay(
					v-for="{ date, items } in childrenGroup"
					:key="groupKey(date)"
					:date="date"
					:items="items"
				)
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { createFilter } from '@/js/utils/filter';
import { camelizeKeys } from '@/js/utils/case-conversion';

import { findExtendedSignatures as findSignature } from '@/js/api/contact-signatures';
import { getContactSignatureProfileId } from '@/js/api/signature-profiles';

import { groupByDate } from './TheSignMultipleModal/lib/contacts.js';
import TimelineDay from './TheSignMultipleModal/TimelineDay.vue';

export default {
	name: 'TheCreateServiceNowIncidentsModal',
	components: {
		TimelineDay
	},
	provide() {
		return {
			selectContact: this.selectContact,
			getSignatures: this.getSignatures
		};
	},
	emits: ['close'],
	data() {
		return {
			isOpen: false,
			isLoading: false,
			shortDescription: '',
			description: '',
			contacts: null,
			childrenGroup: [],
			parentGroup: [],
			items: []
		};
	},
	computed: {
		hasChildren(){
			return this.contacts.length > 1;
		},
		title() {
			return this.hasChildren ? 'Create ServiceNow Incidents' : 'Create ServiceNow Incident';
		},
		contactIds() {
			return this.contacts.map(({ id }) => id);
		},
		parentTitle() {
			return this.hasChildren ? 'Parent contact' : 'Contact';
		},
		...mapGetters({
			user: 'users/me'
		})
	},
	watch: {
		isOpen(isOpen, wasOpen) {
			if (isOpen && !wasOpen) {
				this.load();
			} else if (!isOpen && wasOpen) {
				this.reset();
			}
		}
	},
	beforeUnmount() {
		this.isOpen = false;
	},
	methods: {
		open(selectedContacts) {
			this.items = selectedContacts.map((contact) => {
				return {
					contact,
					signature: null,
					signatureProfileId: null,
					isSelected: true
				};
			});
			this.contacts = selectedContacts;
			this.isOpen = true;
		},
		close() {
			this.contacts = null;
			this.isOpen = false;
			this.reset();
		},
		async load() {
			this.isLoading = true;
			await Promise.all([
				this.getSignatures(),
				this.getSignatureProfiles(),
				this.getSignatureComponents()
			]);
			this.createGroups();
			this.isLoading = false;
		},
		reset() {
			this.isLoading = false;
			this.shortDescription = '';
			this.description = '';
			this.contacts = null;
			this.items = [];
		},
		createGroups() {
			this.parentGroup = groupByDate([this.items[0]]);
			if (this.hasChildren) {
				this.childrenGroup = groupByDate(this.items.slice(1));
			} else {
				this.childrenGroup = [];
			}
		},
		groupKey(date) {
			return date.toISODate();
		},
		assignItemProperties(contactId, properties) {
			const index = this.getItemIndex(contactId);

			this.items.splice(index, 1, cloneDeep({
				...this.items[index],
				...properties
			}));
		},
		getItemIndex(contactId) {
			return this.items.findIndex(createFilter({
				'contact.id': contactId
			}));
		},
		create() {
			this.isLoading = true;
			if (this.hasChildren) {
				this.createTicket({
					contact: this.contacts[0],
					shortDesc: this.shortDescription,
					desc: this.description,
					tenantName: this.$store.getters['tenants/find'](this.contacts[0].missionProfile.tenant).name
				})
					.then((response) => {
						this.contacts.slice(1).forEach(contact => this.createTicket({
							contact: contact,
							shortDesc: this.shortDescription,
							desc: this.description,
							tenantName: this.$store.getters['tenants/find'](contact.missionProfile.tenant).name,
							parentId: response.id
						}));
					})
					.then(() => {
						this.$notify.event('Successfully created incident(s)', 'success');
					})
					.catch(error => this.$notify.event(`Failed to create incident(s). \n${error.message}`, 'error'))
					.finally(() => {
						document.body.style.overflow = 'auto';
						this.close();
					});

			} else {
				this.createTicket({
					contact: this.contacts[0],
					shortDesc: this.shortDescription,
					desc: this.description,
					tenantName: this.$store.getters['tenants/find'](this.contacts[0].missionProfile.tenant).name
				})
					.then(() => this.$notify.event('Successfully created incident(s)', 'success'))
					.catch(error => this.$notify.event(`Failed to create incident(s). \n${error.message}`, 'error'))
					.finally(() => {
						document.body.style.overflow = 'auto';
						this.close();
					});
			}
		},
		getSignatures(contactIds = null) {
			contactIds = contactIds ?? this.contactIds;
			return Promise.all(
				contactIds.map((id) => {
					return findSignature(id)
						.then(camelizeKeys)
						.then((signature) => {
							return this.assignItemProperties(id, {
								signature
							});
						});
				})
			).then((signatures) => {
				this.signatures = signatures;
				return this.signatures;
			});
		},
		getSignatureProfiles() {
			return Promise.all([
				this.$store.dispatch('signatureProfiles/ensure'),
				...this.contacts.map(({ id }) => getContactSignatureProfileId(id)
					.then((signatureProfileId) => {
						return this.assignItemProperties(id, { signatureProfileId });
					})
				)
			]);
		},
		getSignatureComponents() {
			return this.$store.dispatch('signatureComponents/ensure');
		},
		...mapActions({
			createTicket: 'serviceNow/createTicket'
		})
	}
};
</script>


<style lang="scss" scoped>
.timeline {
	list-style: none;
	margin-bottom: rem-calc(20);
	margin-top: rem-calc(20);

}
</style>
