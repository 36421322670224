import { camelizeKeys, underscoreKeys } from '@/js/utils/case-conversion';
import baseAPIBuilder from "@/js/api/builder/api-builder";
import { getScope } from '@/js/auth/auth-config';
import { Uuid } from './types';
import { UnwrapAsync, mapKeys } from '../utils/safe-conversions';
import { SignatureProfile } from './types/stores/signatures-profiles';


export type SignatureProfileSystemDefault = {
	system_id: Uuid,
	signature_profile_id: Uuid
};

export type SignatureProfileMissionProfileDefault = {
	mission_profile_id: Uuid,
	signature_profile_id: Uuid
};

export type SignatureProfileSystemMissionProfileDefault = {
	system_id: Uuid,
	mission_profile_id: Uuid,
	signature_profile_id: Uuid
};


export type WireResponseSignatureProfileDefaults = {
	global_default: Uuid,
	system_defaults: SignatureProfileSystemDefault[],
	mission_profile_defaults: SignatureProfileMissionProfileDefault[],
	system_mission_profile_defaults: SignatureProfileSystemMissionProfileDefault[]
}

const mapping = {
	global_default: "globalDefault",
	system_defaults: "systemDefaults",
	mission_profile_defaults: "missionProfileDefaults",
	system_mission_profile_defaults: "systemMissionProfileDefaults",
	mission_profile_id: "missionProfileId",
	system_id: "systemId",
	signature_profile_id: "signatureProfileId"
} as const;

const meta = {
	basePath: "/v1.0",
	mapping,
	scope: await getScope('kogs')
};

const builder = new baseAPIBuilder(meta as any);
const kogs = builder.connection!.axios;

const signatureProfilesPath = '/signature_profiles';

const contactSignaturesPath = (contactId: Uuid) => {
	return `/contacts/${contactId}/signatures`;
};

const setContactSignaturePath = (contactId: Uuid) => {
	return `/contacts/${contactId}/signatures_profiles`;
};

export const getSignatureProfiles = () => {
	return kogs.get(signatureProfilesPath)
		.then((response) => {
			return {
				...response,
				data: camelizeKeys(response.data) as SignatureProfile[]
			};
		});
};

export const getContactSignatureProfileId = (contactId: Uuid) => {
	return kogs.get(contactSignaturesPath(contactId))
		.then(({ data }) => {
			return camelizeKeys(data).signatureProfileId;
		});
};

export const setContactSignatureProfileId = (contactId: Uuid, signatureProfileId: Uuid) => {
	const payload = underscoreKeys({
		signatureProfileId
	});

	return kogs.put(setContactSignaturePath(contactId), payload);
};

export const updateSignatureProfileGlobal = (signatureProfileId: Uuid) => {
	// @ts-expect-error - refetchId is not a valid parameter
	return kogs.patch("/signature_profiles/defaults/global", { signature_profile_id: signatureProfileId }, { refetchId: "00000000-0000-0000-0000-000000000000" });
};

export const listSignatureProfileDefaults = async () => {
	return mapKeys(mapping, (await kogs.get("/signature_profiles/defaults"))["data"] as WireResponseSignatureProfileDefaults);
};

export type SignatureProfileDefaults = UnwrapAsync<typeof listSignatureProfileDefaults>

export type SystemDefaults = SignatureProfileDefaults["systemDefaults"];

export const getSignatureProfileDefault = async (systemId: Uuid | null, missionProfileId: Uuid | null): Promise<Uuid | null> => {
	const query = (systemId ? `system_id=${systemId}` : "") + (missionProfileId ? `&mission_profile_id=${missionProfileId}` : "");
	return ((await kogs.get(`/signature_profiles/defaults/overrides?${query}`).catch(() => {
		return { data: { signature_profile_id: null } };
	}))["data"] as { "signature_profile_id": Uuid | null })["signature_profile_id"];
};

export const updateSignatureProfileDefault = (systemId: Uuid | null, missionProfileId: Uuid | null, signatureProfileId: Uuid) => {
	const query = (systemId ? `system_id=${systemId}` : "") + (missionProfileId ? `&mission_profile_id=${missionProfileId}` : "");
	// @ts-expect-error - refetchId is not a valid parameter
	return kogs.patch(`/signature_profiles/defaults/overrides?${query}`, { signature_profile_id: signatureProfileId }, { refetchId: "00000000-0000-0000-0000-000000000000" });
};

export const deleteSignatureProfileDefault = (systemId: Uuid | null, missionProfileId: Uuid | null) => {
	const query = (systemId ? `system_id=${systemId}` : "") + (missionProfileId ? `&mission_profile_id=${missionProfileId}` : "");
	// @ts-expect-error - refetchId is not a valid parameter
	return kogs.delete(`/signature_profiles/defaults/overrides?${query}`, { refetchId: "00000000-0000-0000-0000-000000000000" });
};

