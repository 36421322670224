<template lang="pug">
label
	slot
	span.optional(v-if="!required && $slots.default") (optional)
	.textarea
		textarea(
			:value="modelValue"
			:placeholder="placeholder"
			:disabled="disabled"
			:readonly="readOnly"
			:required="required"
			@input="updateTextarea"
		)
		VIcon.disable-icon(
			v-if="disabled"
			name="lock"
		)

	VCallout(
		v-if="validation"
		type="warning"
	) {{ validation }}
</template>

<script>
export default {
	name: 'VTextArea',
	props: {
		modelValue: {
			type: [String, Number],
			required: true
		},
		placeholder: {
			type: String,
			required: false,
			default: ''
		},
		required: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		readOnly: {
			type: Boolean,
			required: false,
			default: false
		},
		validation: {
			type: String,
			required: false,
			default: ''
		}
	},
	emits: ['update:modelValue'],
	methods: {
		updateTextarea(event) {
			this.$emit('update:modelValue', event.target.value);
		}
	}
};
</script>

<style lang="scss" scoped>
.textarea {
	display: flex;
	position: relative;
	margin: rem-calc(0 0 10);

	[disabled] {
		pointer-events: auto;
		cursor: not-allowed;
	}

	.disable-icon {
		position: absolute;
		top: rem-calc(10);
		right: rem-calc(10);

		&#{v-deep('.stroke-primary')} {
			stroke: hsl(var(--white40));
		}

		&#{v-deep('.fill-primary')} {
			fill: hsl(var(--white40));
		}
	}

	textarea {
		margin: 0;
	}
}
</style>
