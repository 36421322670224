import { useToast, POSITION } from 'vue-toastification';
import NotifyInternalError from './NotifyInternalError.vue';

const toast = useToast() as any;

export const event = (message: string, type = 'info', options = {}) => {
	const { notifyDevops } = { notifyDevops: false, ...options };
	if (notifyDevops) {
		message = message + '\nPlease notify DEVOPS.';
		// TODO: Notify devops
		// Send error to endpoint to webserver and let that handle the further notification?
	}
	toast[type](message);
};

const internalError = (message: any) => {
	const content = {
		component: NotifyInternalError,
		props: {
			message: message
		}
	};
	toast.error(content, { timeout: 10000, position: POSITION.BOTTOM_RIGHT });
};

export default {
	// eslint-disable-next-line no-unused-vars
	install: (app: { config: { globalProperties: { $notify: { event: (message: string, type?: string, options?: object) => void; internalError: (message: unknown) => void; }; }; }; }) => {
		app.config.globalProperties.$notify = {
			event,
			internalError,
			...[
				'error',
				'info',
				'success',
				'warning',
			].reduce((types, type) => {
				return {
					...types,
					[type](message: any, options: object | undefined) {
						event(message, type, options);
					}
				};
			}, {})
		};
	}
};
