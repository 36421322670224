<template lang="pug">
span.environment(v-if="!isProductionEnvironment") {{ environment }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "TheEnvironmentIndicator",
	computed: {
		...mapGetters({
			environment: "environment/environment",
			isProductionEnvironment: "environment/isProductionEnvironment"
		})
	}
};
</script>

<style lang="scss" scoped>
.environment {
	display: flex;
	position: fixed;
	top: 50%;
	transform: rotate(-90deg);
	transform-origin: 0 0;
	border-radius: 0 0 rem-calc(5) rem-calc(5);
	font-size: rem-calc(15);
	font-weight: var(--font-boldest);
	line-height: 1.3;
	color: hsl(var(--dark-purple));
	padding: rem-calc(2 12);
	pointer-events: none;

	.development &,
	.dev & {
		background-color: hsl(var(--dev-color));
	}

	.test & {
		background-color: hsl(var(--test-color));
	}

	.staging &,
	.preprod & {
		background-color: hsl(var(--staging-color));
	}
}
</style>
