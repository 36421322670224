import { isString } from 'lodash';

/* eslint-disable comma-dangle */

const SPECIAL = {
	'doppler': 'Doppler',
	'hpa': 'hPa',
	'if_frequency_range': 'IF frequency range',
	'reed_solomon_codes': 'Reed-Solomon codes',
};

const ACRONYMS = [
	'id',
	'acu',
	'eirp',
	'hdr',
	'itu',
	'ldpc',
	'rf',
	'rs',
	'ttc',
	'vat',
];

/* eslint-enable comma-dangle */

const isAcronym = (word) => {
	return ACRONYMS.includes(word);
};

const formatSpecial = (id) => {
	return SPECIAL[normalize(id)] ?? null;
};

const isSpecial = (id) => {
	return formatSpecial(id) !== null;
};

const normalize = (id) => {
	return id
		// Make sure words are separated by a single _
		.replace(/[-_]+/g, '_')
		// Split camelCased words:
		// - fooBar -> foo_Bar
		// - 10Items -> 10_Items
		// - over9000 -> over_9000
		.replace(/([a-z])([A-Z])/g, '$1_$2')
		.replace(/([0-9])([a-zA-Z])/g, '$1_$2')
		.replace(/([a-zA-Z])([0-9])/g, '$1_$2')
		// Lowercase everything
		.toLowerCase();
};

export const prettyPropertyName = (schema, id = null) => {
	if (id === null) {
		id = schema;
		schema = null;
	}

	if (isString(schema?.title) && schema.title.length > 0) {
		return schema.title;
	} else if (isSpecial(id)) {
		return formatSpecial(id);
	}


	return normalize(id)
		.split('_')
		.map((word, i) => {
			if (isAcronym(word)) {
				return word.toUpperCase();
			} else if (isSpecial(word)) {
				return formatSpecial(word);
			} else if (i === 0) {
				return word.slice(0, 1).toUpperCase() + word.slice(1);
			}

			return word;
		})
		.join(' ');
};
