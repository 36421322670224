import { axiosUser } from '@/js/network/axios';

import { event } from '@/js/plugins/notify/notify';
import store from '@/store/store';

const saveableFilter = filter => {
	const { stations, systems, spacecrafts, tenants, states, signature_evaluation_outcomes } = filter;
	return { stations, systems, spacecrafts, tenants, states, signature_evaluation_outcomes };
};

export default {
	namespaced: true,
	state: () => ({
		filters: [],
		initiated: false
	}),
	mutations: {
		initiate(state) {
			state.initiated = true;
		},
		set: (state, filters) => state.filters = filters.map(filter => {
			const brokenFilters = {};
			Object.keys(filter.filter).forEach(item => {
				const getter = store.getters[`${item}/find`];
				if (getter) {
					const brokenIds = filter.filter[item].filter(id => !getter(id, { notify: false }));
					if (brokenIds.length) {
						brokenFilters[item] = brokenIds;
						filter.filter[item] = filter.filter[item].filter(id => getter(id, { notify: false }));
					}
				}
			});

			if (Object.keys(brokenFilters).length) {
				let message = `Broken ids in custom filter "${filter.name}"`;
				Object.keys(brokenFilters).forEach(item => {
					message += `\n${item}: ${brokenFilters[item].join(',')}`;
				});
				event(message, 'warning');
			}

			return filter;
		}),
		update: (state, filter) => state.filters = [...state.filters.filter(({ id }) => id !== filter.id), filter],
		remove: (state, id) => state.filters = state.filters.filter(filter => filter.id !== id)
	},
	getters: {
		all: state => state.filters
	},
	actions: {
		ensure({ commit, dispatch }) {
			return dispatch('initiate').then(() => commit('initiate'));
		},
		async initiate({ commit, dispatch }) {
			// initiate modules to check for broken ids
			await Promise.all(['stations', 'systems', 'spacecrafts', 'tenants'].map(module => dispatch(`${module}/ensure`, {}, { root: true })));
			try {
				const { data } = await axiosUser.get('/me/filters');
				commit('set', data);
			} catch (error) {
				event(`Failed to load filters\n${error}`, 'error');
			}
		},
		async create({ commit }, { filter, name }) {
			const { data } = await axiosUser.post('/me/filters', { filter: saveableFilter(filter), name });
			commit('update', data);
		},
		async update({ commit }, { filter, name, id, order }) {
			const { data } = await axiosUser.put(`/me/filters/${id}`, { filter: saveableFilter(filter), name, order });
			commit('update', data);
		},
		async remove({ commit }, id) {
			await axiosUser.delete(`/me/filters/${id}`);
			commit('remove', id);
		}
	}
};

export { saveableFilter };
