import { state, mutations, actions, getters } from '@/store/build-module/common';
import baseAPIBuilder from '@/js/api/builder/api-builder';
import { getScope } from '@/js/auth/auth-config';

const module = 'problematics';

const meta = {
	module,
	basePath: '/v1.0/problematics/contacts/overlapping_conditions',
	mapping: {
		contact_id: 'contactId',
		system_id: 'systemId',
		condition_kind: 'conditionKind',
		condition_id: 'conditionId',
		contact_start: 'contactStart',
		contact_end: 'contactEnd',
		condition_start: 'conditionStart',
		condition_end: 'conditionEnd'
	},
	dateFields: ['condition_end', 'condition_start', 'contact_start', 'contact_end'],
	parser: data => data.tenant || data.data || data,
	inputWrapper: input => ({ data: input }),
	scope: await getScope('kogs')
};
const builder = new baseAPIBuilder(meta);
const api = {
	...builder.build(['list', 'add', 'update', 'find'])
};

export default {
	namespaced: true,
	state: () => ({
		...state(module)
	}),
	getters: {
		findContactOverlaps(state) {
			return contactId => {
				return state.problematics.filter(overlap => overlap.contactId === contactId);
			};
		},
		...getters(module)
	},
	mutations: {
		...mutations(module)
	},
	actions: {
		...actions(api),
		async fetchForSystem({ commit }, filter) {
			const systemProblematics = await api.list(filter);
			commit('update', systemProblematics);
			return systemProblematics;
		}
	}
};
