<template lang="pug">
ol.issue-categories(v-if="hasCategories")
	li.issue-category(
		v-for="{ name } in categories"
	) {{name}}
</template>

<script>

import { createFilter } from '@/js/utils/filter';
import { issueCategories } from '@/js/utils/data-definitions';

export default {
	props: {
		ids: {
			type: Array,
			required: false,
			default() {
				return [];
			}
		}
	},
	computed: {
		categories() {
			return this.ids.map((id) => {
				return issueCategories.find(createFilter({ id }));
			});
		},
		hasCategories() {
			return this.categories.length > 0;
		}
	}
};

</script>

<style lang="scss" scoped>
.issue-categories {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: rem-calc(-5 0);
}

.issue-category {
	margin: rem-calc(5) 0;
	border: rem-calc(1) solid hsl(var(--white20));
	padding: rem-calc(6 10 5);
	border-radius: 1000px;
	font-size: rem-calc(13);
	font-weight: 600;
	line-height: 1;
	text-transform: uppercase;
	color: var(--issue-categories-text-color, hsl(var(--white)));

	&:not(:last-child) {
		margin-right: rem-calc(8);
	}

}
</style>
