<template lang="pug">
.container
	header
		.title(v-if="hasTitle") {{title}}
		.actions
			VButton.small.invert.secondary(@click="didClickCopy") Copy

	pre {{ formattedData }}
</template>

<script>

import { copyToClipboard } from '@/js/utils/copy';

export default {
	props: {
		data: {
			required: false,
			default: null,
			validator() {
				return true;
			}
		},
		title: {
			type: String,
			required: false,
			default: null
		}
	},
	computed: {
		formattedData() {
			if (typeof this.data === 'undefined') {
				return 'undefined';
			} else if (this.data === null) {
				return 'null';
			}

			return this.data;
		},
		hasTitle() {
			return typeof this.title === 'string' && this.title.length > 0;
		}
	},
	methods: {
		didClickCopy() {
			let data = this.formattedData;
			if (Array.isArray(data) || typeof data === 'object') {
				data = JSON.stringify(data, null, 2);
			}
			if (this.hasTitle) {
				data = `${this.title}:\n${data}`;
			}
			copyToClipboard(data);
			this.$notify.event('Json data copied to clipboard!', 'success');
		}
	}
};

</script>

<style lang="scss" scoped>
.container {
	background: #000;
	color: #cbcbcb;
	border-radius: 8px;
	border: 1px solid rgba(#fff, 0.25);
	margin: 15px 0;
	position: relative;
	z-index: 0;
	min-height: 58px;
	display: flex;
	align-items: center;

	header {
		display: flex;
		gap: 10px;
		justify-content: flex-end;
		align-items: center;
		padding: 10px;
		position: absolute;
		top: 0;
		right: 0;
		background: rgba(#000, 0.15);
		border-bottom-left-radius: 8px;
		z-index: 1;

		.title {
			flex: 0 0 auto;
			font-family: Monaco, 'Bitstream Vera Sans Mono', Consolas, Courier, monospace;
			font-size: 12px;
			font-weight: 600;
		}

		.actions {
			flex: 0 0 auto;

			.button {
				margin: 0;
			}
		}
	}

	pre {
		padding: 15px;
		font-family: Monaco, 'Bitstream Vera Sans Mono', Consolas, Courier, monospace;
		font-size: 12px;
		line-height: 1.2;
		position: relative;
		width: 100%;
		overflow-x: auto;
		word-wrap: break-word;
		white-space: pre-wrap;
		word-break: break-all;
		scrollbar-color: hsl(var(--white20)) transparent;
		@include scrollbars(0.5rem, hsl(var(--white20)));
	}
}

</style>
