import { cloneDeep, isObject } from 'lodash';

import { $text } from "@/js/plugins/text";
import { createFilter } from '@/js/utils/filter';
import { compareVersionedStrings } from '@/js/utils/compare';
import {
	getJsonSchema,
	getJsonSchemas
} from '@/js/api/json-schemas';

const state = {
	schemas: []
};

const mutations = {
	set(state, schema) {
		let index = state.schemas.findIndex(createFilter({
			id: schema.id
		}));
		if (index === -1) {
			index = state.schemas.length;
		}

		state.schemas.splice(index, 1, schema);
	}
};

const actions = {
	ensure({ getters, dispatch }, id) {
		if (getters.has(id)) {
			return Promise.resolve(getters.find(id));
		}

		return dispatch('get', id);
	},
	get({ commit, getters }, id) {
		return getJsonSchema(id).then((response) => {
			commit('set', response.data);

			return getters.find(id);
		});
	},
	getAll({ commit, getters }) {
		return getJsonSchemas().then((response) => {
			response.data.data.forEach((schema) => {
				commit('set', schema);
			});

			return getters.all;
		});
	}
};

const getters = {
	all(state, { schemaName }) {
		return cloneDeep(state.schemas).sort((a, b) => {
			return compareVersionedStrings(
				schemaName(a),
				schemaName(b)
			);
		});
	},
	find(_, getters) {
		return (idOrFilter) => {
			return getters.where(idOrFilter).at(0) ?? null;
		};
	},
	findSchema(_, getters) {
		return (id) => {
			return getters.find(id)?.jsonSchema ?? null;
		};
	},
	schemaName(_, { find, has }) {
		return (idOrSchema) => {
			if (!isObject(idOrSchema) && !has(idOrSchema)) {
				return null;
			} else if (!isObject(idOrSchema)) {
				idOrSchema = find(idOrSchema);
			}

			const {
				id,
				resourceType,
				jsonSchema
			} = idOrSchema;

			return [
				jsonSchema?.title,
				`${$text.capitalize(resourceType, { preserveCase: false })} Schema(${id.slice(0, 7)})`
			].find((name) => {
				return typeof name === 'string' && name.length > 0;
			});
		};
	},
	findResourceType(_, getters) {
		return (id) => {
			return getters.find(id)?.resourceType ?? null;
		};
	},
	where(_, { all }) {
		return (idOrFilter) => {
			if (typeof idOrFilter === 'string') {
				idOrFilter = {
					id: idOrFilter
				};
			}

			return all.filter(createFilter(idOrFilter));
		};
	},
	antennaSchemas(_, getters) {
		return getters.where({
			resourceType: 'ANTENNA'
		});
	},
	has(_, getters) {
		return (id) => {
			return getters.find(id) !== null;
		};
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
