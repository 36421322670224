import { DateTime } from 'luxon';
import { isNil, isString } from 'lodash';

export const destringifyValue = (type, value) => {
	if (isNil(value)) {
		return null;
	}

	switch (type) {
		case 'integer':
			return destringifyInteger(value);
		case 'date':
			return destringifyDate(value);
		case 'date-time':
			return destringifyDateTime(value);
		case 'number':
			return destringifyNumber(value);
		default:
			return value;
	}
};

const destringifyInteger = (value) => {
	if (isString(value)) {
		value = value.replace(/[^0-9]+/g, '');
		value = parseInt(value);
	}
	value = isNaN(value) ? defaultValue('integer') : value;

	return isNaN(value) ? defaultValue('integer') : value;
};

const destringifyNumber = (value) => {
	if (isString(value)) {
		value = value.replace(/[^0-9.,]+/g, '');
		value = value.replace(',', '.');
		value = parseFloat(value);
	}

	return isNaN(value) ? defaultValue('number') : value;
};

const destringifyDate = (value) => {
	return destringifyDateTime(value).startOf('day');
};

const destringifyDateTime = (value) => {
	if (!isString(value) || value.length === 0) {
		return defaultValue('date-time');
	}

	return DateTime.fromISO(value);
};


export const stringifyValue = (type, value) => {
	if (isNil(value)) {
		return '';
	}

	switch (type) {
		case 'date':
			return stringifyDate(value);
		case 'date-time':
			return stringifyDateTime(value);
		default:
			return String(value);
	}
};

export const stringifyDateTime = (value) => {
	return value.toISO();
};

export const stringifyDate = (value) => {
	return value.toISODate();
};

export const defaultValue = (schema) => {
	if (isString(schema)) {
		schema = { type: schema };
	}
	if (schema?.default != null) {
		return schema.default;
	}

	switch (schema.type) {
		case 'array':
			return [];
		case 'boolean':
			return false;
		case 'date':
			return DateTime.utc().startOf('day');
		case 'date-time':
			return DateTime.utc();
		case 'integer':
		case 'number':
			return null;
		case 'string':
			return defaultStringValue(schema);
		default:
			return '';
	}
};

const defaultStringValue = (schema) => {
	if (Array.isArray(schema.enum) && schema.enum.length === 1) {
		return schema.enum[0];
	}

	return '';
};
