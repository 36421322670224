import { axiosUser } from '@/js/network/axios';
import { DateTime } from 'luxon';
import { event } from '@/js/plugins/notify/notify';

import store from '@/store/store';

import { activitySocket } from '@/js/network/socket';

import {
	EMIT_ACTIVITY_DISCONNECT,
	EMIT_ACTIVITY_ACTIVE_USERS
} from '@/env';

activitySocket.on(EMIT_ACTIVITY_DISCONNECT, id => store.commit('principals/disconnectUser', id));
activitySocket.on(EMIT_ACTIVITY_ACTIVE_USERS, activeUsers => store.commit('principals/setActiveUsers', activeUsers));

export default {
	namespaced: true,
	state: () => ({
		principals: [],
		activeUsers: {},
		loadingIds: []
	}),
	getters: {
		find: (state, getters) => id => {
			const principal = state.principals.find(principal => principal.id === id) || { id, isUnknown: true };
			const onlineLastActivity = state.activeUsers[id];
			// if user is online the lastActivity should be in state.activeUsers. Else fetch it from the principal (stored in user database).
			const lastActive = onlineLastActivity ?? principal.lastActive;

			const isLoading = state.loadingIds.includes(id);

			return {
				...principal,
				lastActive,
				isOnline: !!onlineLastActivity,
				isYou: getters.isYou(id),
				isLoading
			};
		},
		isYou: (state, getters, rootState, rootGetters) => id => rootGetters['user/user']?.id === id
	},
	mutations: {
		update: (state, principals) => {
			if (!Array.isArray(principals)) {
				principals = [principals];
			}

			principals.forEach(principal => {
				const index = state.principals.findIndex(found => found.id === principal.id);
				if (index >= 0) {
					state.principals[index] = principal;
				} else {
					state.principals.push(principal);
				}
			});
		},
		setActiveUsers: (state, activeUsers) => {
			Object.keys(activeUsers).forEach(key => activeUsers[key] = DateTime.fromISO(activeUsers[key], { zone: 'utc' }));
			state.activeUsers = activeUsers;
		},
		disconnectUser: (state, id) => {
			state.activeUsers[id] = null;
		},
		setLoadingIds: (state, idList) => state.loadingIds = [...state.loadingIds, ...idList],
		removeLoadingIds: (state, idList) => state.loadingIds = state.loadingIds.filter(id => !idList.includes(id))
	},
	actions: {
		ensure: async ({ commit, state, rootGetters }, idList) => {
			try {
				if (!Array.isArray(idList)) {
					idList = [idList];
				}

				const loadingIds = idList
					.filter(id => !state.principals.find(principal => principal.id === id))
					.filter(id => !state.loadingIds.includes(id));

				if (!loadingIds.length) {
					return;
				}

				commit('setLoadingIds', loadingIds);

				const ids = loadingIds.join(',');

				const principals = await axiosUser.get('/principals', { params: { ids } })
					.then(({ data }) => data.map(principal => ({
						...principal,
						...principal.owner ?
							{ // Is for only organizations. Other owner kinds use already attached labels or tags in the owner object.
								owner: {
									...principal.owner,
									...principal.owner.kind === 'organization' ? { name: rootGetters['organizations/find'](principal.owner.id)?.name ?? 'Unknown org' } : null
								}
							} :
							null,
						lastActive: data.lastActive ? DateTime.fromISO(data.lastActive, { zone: 'utc' }) : null
					})))
					.catch(() => []);
				commit('update', principals);

				commit('removeLoadingIds', loadingIds);
			} catch (error) {
				event(`Failed to ensure list of user names.\n${error.message}`, 'error');
			}
		}
	}
};
