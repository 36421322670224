<template lang="pug">
template(v-if="isPresent")
	.string {{formattedValue}}
template(v-else)
	BlankPropertyValue
</template>

<script>

import { DateTime } from 'luxon';

import { camelize } from '@/js/utils/case-conversion';

import BlankPropertyValue from './Blank.vue';

export default {
	name: 'StringValue',
	components: {
		BlankPropertyValue
	},
	props: {
		value: {
			type: String,
			required: false,
			default: null
		},
		format: {
			type: String,
			required: false,
			default: null
		}
	},
	computed: {
		formattedValue() {
			if (this.isDateTime) {
				return this.$time.formatDateTime(this.dateTime);
			}
			return this.value;
		},
		dateTime() {
			return DateTime.fromISO(this.value, { zone: 'utc' });
		},
		isPresent() {
			return typeof this.value === 'string' && this.value.length > 0;
		},
		...[
			'date-time'
		].reduce((computed, format) => {
			return {
				...computed,
				[camelize(`is-${format}`)]() {
					return this.format === format;
				}
			};
		}, {})
	}
};

</script>
