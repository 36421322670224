import store from '@/store/store';
import { DateTime } from 'luxon';

const loadingModule = {
	state: {
		loading: false
	},

	getters: {
		loading: state => state.loading
	},
	mutations: {
		startLoading: state => state.loading = true,
		endLoading: state => state.loading = false
	}
};

const errorModule = {
	state: {
		error: null
	},
	getters: {
		error: state => state.error
	},
	mutations: {
		setError: (state, error) => state.error = error,
		clearError: state => state.error = null
	}
};

const initiateModule = {
	state: {
		initiated: false
	},
	getters: {
		initiated: state => state.initiated
	},
	mutations: {
		initiate: state => state.initiated = true,
		uninitiate: state => state.initiated = false
	}
};

const lastFetchedModule = {
	state: {
		lastFetched: DateTime.utc()
	},
	getters: {
		lastFetched: state => state.initiated
	},
	mutations: {
		setLastFetched: state => state.lastFetched = DateTime.utc()
	}
};

// This module is designed to register a function to react on store changes and to initate a refetch interval on create.
// It is designed to clean this up on unmount.
// It is required that the parent module have a refetch action registered
const mountModule = storeSubscription => ({
	state: {
		unsubscribeFunction: null,
		refetchInterval: null
	},
	mutations: {
		create: state => {
			const { moduleName, refetchIntervalTime } = state;
			state.refetchInterval = setInterval(() => store.dispatch(`${moduleName}/refetch`), refetchIntervalTime * 1000);
			state.unsubscribeFunction = store.subscribe(storeSubscription(moduleName));
		},
		unmount: state => {
			const { moduleName, refetchInterval } = state;
			clearInterval(refetchInterval);
			state.unsubscribeFunction();
			store.unregisterModule(moduleName);
		}
	}
});

export {
	loadingModule,
	errorModule,
	mountModule,
	lastFetchedModule,
	initiateModule
};
