<template lang="pug">
label
	slot
	span.optional(v-if="!required && $slots.default") (optional)
	.select(:class="[{ focus: selectFocused }, { disabled: disabled }]")
		VIcon(
			v-if="icon"
			:name="icon"
		)
		select(
			:value="modelValue"
			:disabled="disabled"
			:required="required"
			@focus="selectFocused = true"
			@blur="selectFocused = false"
			@input="updateSelect"
		)
			option(
				disabled
				value=""
			) {{ placeholder }}
			option(
				v-for="item in formattedArray"
				:key="item"
				:value="item.id"
			) {{ item.name }}

		VIcon.disable-icon(
			v-if="disabled"
			name="lock"
		)
</template>

<script>
export default {
	name: 'VSelect',
	props: {
		items: {
			type: [Array, Object],
			required: true
		},
		modelValue: {
			type: [String, Number, Boolean],
			required: false,
			default: ''
		},
		placeholder: {
			type: String,
			required: false,
			default: 'Select from list'
		},
		required: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		icon: {
			type: String,
			required: false,
			default: ''
		}
	},
	emits: ['update:modelValue'],
	data() {
		return {
			selectFocused: false
		};
	},
	computed: {
		formattedArray() {
			return typeof this.items[0] === 'object' ?
				this.items :
				this.items.map(item => ({ id: item, name: item }));
		}
	},
	methods: {
		updateSelect(event) {
			this.$emit('update:modelValue', event.target.value);
		}
	}
};
</script>

<style lang="scss" scoped>
.select {
	display: flex;
	align-items: center;
	margin: rem-calc(0 0 10);
	border: 1px solid hsl(var(--white20));
	border-radius: var(--global-radius);
	user-select: none;

	&:hover {
		border-color: hsl(var(--white40));
	}

	&.disabled {
		border-color: hsl(var(--white20));
		cursor: not-allowed;
	}

	&.focus {
		border-color: hsl(var(--white40));
		box-shadow: 0 0 0 3px hsl(var(--white) / 10%);
	}

	.icon {
		margin-left: rem-calc(10);

		&.disable-icon {
			margin: rem-calc(0 10);
		}

		&#{v-deep('.stroke-primary')} {
			stroke: hsl(var(--white40));
		}

		&#{v-deep('.fill-primary')} {
			fill: hsl(var(--white40));
		}
	}

	select {
		border: 0;
		border-radius: 0;
		margin: 0;
		outline: 0;

		&[disabled] {
			background-image: none;
			padding-right: rem-calc(10);
			pointer-events: none;
			color: hsl(var(--white70));
		}

		&:focus {
			box-shadow: none;
		}
	}
}
</style>
