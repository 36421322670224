<template lang="pug">
li.day
	h2
		span.date {{formattedDate}}
		| {{' '}}
		span.day {{formattedDay}}

	ol.contacts
		TimelineContact(
			v-for="item in items"
			:key="item.contact.id"
			v-bind="item"
		)
</template>

<script>

import TimelineContact from './TimelineContact/TimelineContact.vue';

export default {
	components: {
		TimelineContact
	},
	props: {
		date: {
			type: Object,
			required: true
		},
		items: {
			type: Array,
			required: true
		}
	},
	computed: {
		formattedDate() {
			return this.$time.formatDate(this.date);
		},
		formattedDay() {
			return this.$time.toWeekday(this.date, { today: true });
		}
	}
};

</script>

<style lang="scss" scoped>
h2 {
	display: block;
	font-size: var(--font-medium-size);
	font-weight: var(--font-normal);
	border-bottom: 1px solid hsl(var(--white20));
	padding: 0 0 rem-calc(10);
	margin: 0;

	.day {
		color: hsl(var(--white70));
		margin-left: rem-calc(20);
	}
}

.contacts {
	list-style: none;
}
</style>
