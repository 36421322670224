<template lang="pug">
.item
	router-link(:to="path") {{name}}
</template>

<script>

export default {
	name: 'NavigationItem',
	props: {
		item: {
			type: Object,
			required: true
		},
		parentPath: {
			type: String,
			required: true
		}
	},
	computed: {
		name() {
			return this.item.name;
		},
		path() {
			return [this.parentPath, this.item.path]
				.join('/')
				.replace(/\/+/g, '/');
		}
	}
};

</script>

<style lang="scss" scoped>
.item {
	color: hsl(var(--white60));
	font-size: rem-calc(16);

	&:not(:last-child) {
		margin-bottom: rem-calc(10);
	}

	a {
		text-decoration: none;
		color: inherit;
	}
}
</style>
