<template lang="pug">
.select-all(
	:class="modifierClasses"
	@click="toggleSelectAll"
)
	VIcon(:name="iconName")
	| {{ selectionText }}
</template>

<script>
export default {
	name: 'VSelectAll',
	props: {
		nrTotal: {
			type: Number,
			required: true
		},
		nrSelected: {
			type: Number,
			required: true
		},
		loading: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['toggleSelectAll'],
	computed: {
		anySelected() {
			return this.nrSelected > 0;
		},
		allSelected() {
			return this.nrTotal > 0 && this.nrSelected === this.nrTotal;
		},
		selectionText() {
			return this.anySelected ? `${this.nrSelected}/${this.nrTotal} selected` : `Select all (${this.nrTotal})`;
		},
		iconName() {
			if (this.loading) {
				return 'inSetupStatus';
			} else if (!this.anySelected) {
				return '20/form/checkbox';
			} else if (this.allSelected) {
				return '20/form/checkbox.checked';
			}

			return '20/form/checkbox.partial';
		},
		modifierClasses() {
			return {
				'is-loading': this.loading
			};
		}
	},
	methods: {
		toggleSelectAll() {
			this.$emit('toggleSelectAll');
		}
	}
};
</script>

<style lang="scss" scoped>
.select-all {
	display: flex;
	align-items: center;
	font-weight: var(--font-bold);
	cursor: pointer;

	.icon {
		--icon-size: 16px;

		margin-right: rem-calc(10);

		&.icon-20-form-checkbox {
			opacity: 0.4;
		}

		&.icon-in-setup-status {
			--icon-color-primary: hsl(var(--white40));
		}
	}

	&.is-loading {
		cursor: default;

		.icon {
			animation:
				select-all-spin-animation
				1s
				linear
				infinite;
		}

		@keyframes select-all-spin-animation {
			0% {
				transform: rotateZ(0);
			}

			50% {
				transform: rotateZ(180deg);
			}

			100% {
				transform: rotateZ(360deg);
			}
		}
	}
}
</style>
