import _ from 'lodash';

import store from '@/store/store';
import {
	loadingModule,
	errorModule,
	mountModule,
	lastFetchedModule,
	initiateModule
} from './submodules/submodules';

import { errorMessage } from '@/js/api/builder/api-error';
import { event } from '@/js/plugins/notify/notify';
import ContactState from '@/js/classes/contact-state';
import { contactSignatureOutcomes } from '@/js/utils/data-definitions';

const extendContact = contact => {
	const spacecraft = store.getters['spacecrafts/find'](contact.spacecraft);
	const system = store.getters['systems/find'](contact.system);
	const missionProfile = store.getters['missionProfiles/find'](contact.missionProfile);
	const station = store.getters['stations/find'](system.station);
	const tenant = store.getters['tenants/find'](missionProfile.tenant);
	const organization = store.getters['organizations/find'](tenant?.organizationId);
	const overlappingConditions = store.getters['problematics/findContactOverlaps'](contact.id);
	const ticket = store.getters['serviceNow/findTicketByContactId'](contact.id);

	const teardownDuration = Math.max(missionProfile.teardownDuration, system.teardownDuration);
	const setupDuration = Math.max(missionProfile.setupDuration, system.setupDuration);

	const { startTime, endTime } = contact;
	const duration = endTime.diff(startTime, 'seconds').seconds;
	const durationPadded = duration + teardownDuration + setupDuration;
	const relevantSigning = contact?.signature?.outcome == contactSignatureOutcomes.waiting.id.toUpperCase() ? null : contact?.signature?.outcome;
	const extendedState = contact.state ? new ContactState(relevantSigning ?? contact.newState ?? contact.state) : null;

	return {
		...contact,
		spacecraft,
		system,
		missionProfile,
		station,
		tenant,
		organization,
		ticket,
		overlappingConditions,
		setupDuration,
		teardownDuration,
		duration,
		durationPadded,
		extendedState,
		startTimePadded: startTime.minus({ seconds: setupDuration }),
		endTimePadded: endTime.plus({ seconds: teardownDuration }),
		isExtended: true
	};
};

// For batch operations to limit amount of store invokations.
// There probably is a sweet spot on when it's better to use singular, simple testing yielded 50 as a decent number
const extendContacts = contacts => {
	if (contacts.length < 50) {
		return contacts.map(extendContact);
	}

	const spacecrafts = store.getters['spacecrafts/allForce'];
	const systems = store.getters['systems/allForce'];
	const missionProfiles = store.getters['missionProfiles/allForce'];
	const stations = store.getters['stations/allForce'];
	const tenants = store.getters['tenants/allForce'];
	const organizations = store.getters['organizations/allForce'];
	const allOverlappingConditions = store.getters['problematics/all'];
	const allTickets = store.getters['serviceNow/all'];

	return contacts.map(contact => {
		const spacecraft = spacecrafts.find(({ id }) => contact.spacecraft === id);
		const system = systems.find(({ id }) => contact.system === id);
		const missionProfile = missionProfiles.find(({ id }) => contact.missionProfile === id);
		const station = stations.find(({ id }) => system.station === id);
		const tenant = tenants.find(({ id }) => missionProfile.tenant === id);
		const organization = organizations.find(({ id }) => tenant?.organizationId === id);
		const overlappingConditions = allOverlappingConditions.filter(({ contactId }) => contact.id === contactId);
		const ticket = allTickets.find(({ contactId }) => contact.id === contactId);

		const teardownDuration = Math.max(missionProfile.teardownDuration, system.teardownDuration);
		const setupDuration = Math.max(missionProfile.setupDuration, system.setupDuration);

		const { startTime, endTime } = contact;
		const duration = endTime.diff(startTime, 'seconds').seconds;

		const durationPadded = duration + teardownDuration + setupDuration;
		const relevantSigning = contact?.signature?.outcome == contactSignatureOutcomes.waiting.id.toUpperCase() ? null : contact?.signature?.outcome;
		const extendedState = contact.state ? new ContactState(relevantSigning ?? contact.newState ?? contact.state) : null;

		return {
			...contact,
			spacecraft,
			system,
			missionProfile,
			station,
			tenant,
			organization,
			ticket,
			overlappingConditions,
			setupDuration,
			teardownDuration,
			duration,
			durationPadded,
			extendedState,
			startTimePadded: startTime.minus({ seconds: setupDuration }),
			endTimePadded: endTime.plus({ seconds: teardownDuration }),
			isExtended: true
		};
	});
};

// TODO: removed none api fields
const toApiContact = contact => {
	if (contact.isExtended) {
		const hasParameterSchema = !!contact.missionProfile.parameterSchemaId;
		const properties = {
			...hasParameterSchema ? { parameters: contact.properties?.parameters, parameter_schema_id: contact.missionProfile.parameterSchemaId } : {},
			is_internal: contact.isInternal ?? contact.properties?.is_internal ?? false,
			is_test: contact.properties?.is_test ?? false,
			...contact.properties?.cfes ? { cfes: contact.properties.cfes } : { cfes: [] }
		};
		return {
			missionProfile: contact.missionProfile.id,
			spacecraft: contact.spacecraft.id,
			system: contact.system.id,
			startTime: contact.startTime,
			endTime: contact.endTime,
			externalRef: contact.externalRef,
			id: contact.id,
			properties
		};
	}
	return contact;
};


const contactUpdateSubscription = moduleName => ({ type, payload }) => {
	const updatedContact = payload;
	const id = store.state[moduleName].id;

	if (type === 'contacts/update') {
		if (updatedContact.id === id) {
			store.commit(`${moduleName}/setContact`, updatedContact);
		}
	}
};

const submodules = [
	loadingModule,
	errorModule,
	mountModule(contactUpdateSubscription),
	lastFetchedModule,
	initiateModule
];

const contactModule = (moduleName, id) => {
	const mainModule = {
		namespaced: true,
		state: {
			moduleName,
			id,
			contact: null,
			refetchIntervalTime: 60 * 2 // refetch contact every 2 minute
		},
		getters: {
			contact: (state) => {
				const contact = state.contact;
				if (!contact) {
					return null;
				}

				if (contact.isExtended) {
					return contact;
				}

				return {
					...extendContact(contact)
				};
			}
		},
		mutations: {
			setContact: (state, contact) => {
				if (!_.isEqual(contact, state.contact)) {
					state.contact = contact;
				}
			}
		},
		actions: {
			fetchContact: async ({ state, commit, dispatch }) => {
				commit('startLoading');
				commit('clearError');

				try {
					const contact = await dispatch('contacts/fetchOne', state.id, { root: true });
					commit('setContact', contact);
					commit('initiate');
					commit('setLastFetched');
				} catch (error) {
					commit('uninitiate');
					commit('setContact', null);
					commit('setError', errorMessage(error));
				}

				commit('endLoading');
			},

			refetch: async ({ state, dispatch, commit }) => {
				if (!state.initiated) {
					return;
				}

				try {
					await dispatch('contacts/fetchOne', state.id, { root: true });
					commit('setLastFetched');
				} catch {
					event(`Failed to update information about contact ${state.id}`, 'warning');
				}
			}
		}
	};

	// Register submodules before returning
	submodules.forEach(module => ['state', 'getters', 'mutations', 'actions'].forEach(object => Object.assign(mainModule[object], module[object])));

	return mainModule;
};

// this module assumes that 'contacts/ensure' have been dispatched
const createContactModule = async id => {
	const moduleName = `contact_${id}`;

	const module = contactModule(moduleName, id);

	store.registerModule(moduleName, module);

	store.commit(`${moduleName}/create`);

	await store.dispatch(`${moduleName}/fetchContact`, id);

	return {
		unmountFunction: () => store.commit(`${moduleName}/unmount`),
		moduleName
	};
};

export default createContactModule;

export { extendContact, extendContacts, toApiContact };
