import { DateTime } from "luxon";

export default {
	namespaced: true,
	state: () => ({
		now: DateTime.utc(),
		mockNow: DateTime.utc() // Debug tool for setting your own time in KOGNI
	}),
	getters: {
		now(state) {
			return DateTime.fromSeconds(state.now.toSeconds()).toUTC();
		},
		mockNow(state) {
			return state.mockNow;
		}
	},
	mutations: {
		updateTime(state) {
			state.now = DateTime.utc();
		},
		updateMockTime(state, time) {
			state.mockNow = time;
		}
	},
	actions: {
		start({ commit }) {
			setInterval(() => commit('updateTime'), 1000);
		},
		setMockTime({ commit }, time) {
			commit('updateMockTime', time);
		}
	}
};
