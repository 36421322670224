<template lang="pug">
.pill-item
	.pill-icon(v-if="icon")
		VIcon(:name="icon")
	.pill-content
		slot
</template>

<script>
export default {
	name: 'SimplePillItem',
	props: {
		icon: {
			type: String,
			required: false,
			default: ''
		}
	}
};
</script>

<style lang="scss" scoped>
.pill-item {
	display: inline-flex;
	align-items: center;
	font-size: var(--font-table-size);

	& + .pill-item {
		margin-left: rem-calc(10);
	}
}

.pill-icon {
	opacity: 0.4;
	margin-right: rem-calc(6);
	display: inline-flex;
	align-items: center;
	flex-shrink: 0;

	.icon {
		margin: 0;
	}
}

.pill-content {
	line-height: 1.2;
	font-weight: var(--font-normal);

	.status & {
		font-size: var(--font-small-size);
		font-weight: var(--font-bolder);
	}
}
</style>
