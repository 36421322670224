import { state, mutations, actions, getters } from '@/store/build-module/common';
import changelogActions from '@/store/build-module/changelog-actions';
import baseAPIBuilder from '@/js/api/builder/api-builder';
import { getScope } from '@/js/auth/auth-config';

const module = 'stations';

const meta = {
	module,
	basePath: '/v1.0/stations',
	mapping: {
		lifecycle_state: 'lifecycleState'
	},
	inputWrapper: input => ({ data: input }),
	scope: await getScope('kogs')
};

export const builder = new baseAPIBuilder(meta);

const api = {
	...builder.build(['list', 'add', 'update', 'find'])
};

export default {
	namespaced: true,
	state: () => ({
		...state(module)
	}),
	getters: {
		...getters(module)
	},
	mutations: {
		...mutations(module)
	},
	actions: {
		...actions(api),
		...changelogActions({ connection: api.connection, objectType: module })
	}
};
