import _ from 'lodash';

const REQUIRED_SEARCH_STRING_LENGTH_TO_MATCH_ON_ID = 5;
const ALWAYS_IGNORED_KEYS = ['icon', 'url'];
const ALWAYS_IGNORED_NESTED_OBJECTS = ['location'];

const matches = (list, searchString, allowedKeys = null) => {
	return list.filter(item => isMatch(item, searchString, allowedKeys));
};

const isMatch = (object, searchString, allowedKeys = null) => {
	searchString = searchString.toUpperCase();
	const searchLength = searchString.length;

	const values = getValuesFromNestedObject(object, searchLength, allowedKeys);

	return _.chain(values)
		.compact()
		.map(value => value.toString().toUpperCase())
		.some(value => value.includes(searchString))
		.value();
};

const getValuesFromNestedObject = (object, searchLength, allowedKeys = null, returnList = []) => {

	let values = returnList;

	_.forIn(object, (value, key) => {
		// Ignore objects where the value is an object and object key is in ignored list.
		const ignoredNestedObject =  ALWAYS_IGNORED_NESTED_OBJECTS.includes(key);
		if (ignoredNestedObject) {
			return;
		}

		if (_.isPlainObject(value)) {
			getValuesFromNestedObject(value, searchLength, allowedKeys, values);
		}

		// Ignore keys in always ignored list or not in the allowkeys list, if that list is provided when calling the function.
		const keyIgnored =  ALWAYS_IGNORED_KEYS.includes(key) || (allowedKeys && !allowedKeys.includes(key));
		if (keyIgnored) {
			return;
		}

		switch (typeof value) {
			case 'number':
				value = value.toString();
				break;
			case 'string': {
				const isUUID = value.match(/([0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12})/);
				const isNotIdKey = key.toUpperCase() !== 'ID';

				if (isUUID && (isNotIdKey || searchLength < REQUIRED_SEARCH_STRING_LENGTH_TO_MATCH_ON_ID)) {
					value = null;
				}
				break;
			}
			case 'boolean':
				// if the boolean is true it means that the object has the key and hence it is added as the value
				if (value) {
					value = key;
				}
				break;
			default:
				return null;
		}
		values.push(value);
	});

	return values;
};

export default {
	install: app => {
		app.config.globalProperties.$search = {
			matches,
			isMatch
		};
	}
};
