import { createFilter } from '@/js/utils/filter';
import { createHumanOverride } from '@/js/api/contact-signatures';
import { toDateTime } from '@/js/utils/filter/types';
import { underscoreKeys } from '@/js/utils/case-conversion';
import { issueCategories } from '@/js/utils/data-definitions';

import dig from '@/js/utils/dig';

const validIssueCategories = issueCategories.map(({ id }) => {
	return id;
});
const validOutcomes = [
	'SUCCESS',
	'ANOMALY',
	'REMARK'
];

//
// Attest
//

export const attest = () => {
	return Promise.reject('Not yet implemented');
};

//
// Elaborate
//

export const elaborate = (contactId, signature, options = {}) => {
	if (!isValidElaborate(signature, options)) {
		return Promise.reject(getElaborateValidationErrors(signature, options));
	}

	return createHumanOverride(
		contactId,
		underscoreKeys({
			dryRun: false,
			payload: buildElaboratePayload(signature, options)
		})
	);
};

export const buildElaboratePayload = (signature, options = {}) => {
	const {
		identityReference = null,
		comment = null
	} = options;

	return {
		kind: 'ACKNOWLEDGE_LIST_V1',
		acknowledgeList: {
			comment: getAcknowledgeListComment(signature),
			acknowledge: buildAcknowledgeListEntries(signature, {
				identityReference,
				kind: 'ELABORATE',
				elaborate: {
					comment
				}
			})
		}
	};
};

export const isValidElaborate = (...args) => {
	return getElaborateValidationErrors(...args).length === 0;
};

export const getElaborateValidationErrors = (signature, options = {}) => {
	return [
		...identityReferenceValdationErrors(options.identityReference),
		...getCommentValidationErrors(options.comment)
	];
};

//
// Elaborate signature
//

export const elaborateSignature = (contactId, signature, options = {}) => {
	if (!isValidElaborateSignature(signature, options)) {
		return Promise.reject(getElaborateSignatureValidationErrors(signature, options));
	}

	return createHumanOverride(
		contactId,
		underscoreKeys({
			dryRun: false,
			payload: buildElaborateSignaturePayload(signature, options)
		})
	);
};

export const buildElaborateSignaturePayload = (signature, options = {}) => {
	const {
		comment = null
	} = options;

	return {
		kind: 'ACKNOWLEDGE_LIST_V1',
		acknowledgeList: {
			comment,
			acknowledge: buildAcknowledgeListEntries(signature, null)
		}
	};
};

export const isValidElaborateSignature = (...args) => {
	return getElaborateSignatureValidationErrors(...args).length === 0;
};

export const getElaborateSignatureValidationErrors = (signature, options = {}) => {
	return [
		...getCommentValidationErrors(options.comment)
	];
};

//
// Force outcome
//

export const forceOutcome = (contactId, signature, options = {}) => {
	if (!isValidForceOutcome(signature, options)) {
		return Promise.reject(getForceOutcomeValidationErrors(signature, options));
	}

	return createHumanOverride(
		contactId,
		underscoreKeys({
			dryRun: false,
			payload: buildForceOutcomePayload(signature, options)
		})
	);
};

export const buildForceOutcomePayload = (signature, options = {}) => {
	const {
		issueCategories = null,
		comment = null,
		outcome = null
	} = options;

	return {
		kind: 'FORCE',
		force: {
			outcome,
			issueCategories,
			comment
		}
	};
};

export const isValidForceOutcome = (...args) => {
	return getForceOutcomeValidationErrors(...args).length === 0;
};

export const getForceOutcomeValidationErrors = (signature, options = {}) => {
	return [
		...getIssueCategoriesValidationErrors(options.outcome, options.issueCategories),
		...getCommentValidationErrors(options.comment),
		...getOutcomeValidationErrors(options.outcome)
	];
};

//
// Override
//

export const override = (contactId, signature, options = {}) => {
	if (!isValidOverride(signature, options)) {
		return Promise.reject(getOverrideValidationErrors(signature, options));
	}

	return createHumanOverride(
		contactId,
		underscoreKeys({
			dryRun: false,
			payload: buildOverridePayload(signature, options)
		})
	);
};

export const buildOverridePayload = (signature, options = {}) => {
	const {
		identityReference = null,
		issueCategories = null,
		comment = null,
		outcome = null
	} = options;

	return {
		kind: 'ACKNOWLEDGE_LIST_V1',
		acknowledgeList: {
			acknowledge: buildAcknowledgeListEntries(signature, {
				identityReference,
				kind: 'OVERRIDE',
				override: {
					issueCategories,
					comment,
					outcome
				}
			})
		}
	};
};

export const isValidOverride = (...args) => {
	return getOverrideValidationErrors(...args).length === 0;
};

export const getOverrideValidationErrors = (signature, options = {}) => {
	return [
		...identityReferenceValdationErrors(options.identityReference),
		...getIssueCategoriesValidationErrors(options.outcome, options.issueCategories),
		...getOverrdideCommentValidationErrors(options.outcome, options.comment),
		...getOutcomeValidationErrors(options.outcome)
	];
};

//
// Helpers
//

/**
 * Valid issue categories must be present in validIssueCategories, except for
 * when the outcome is SUCCESS when no categories are allowed
 *
 * @param {string} outcome
 * @param {array} issueCategories
 *
 * @return {array} errors
 */
const getIssueCategoriesValidationErrors = (outcome, issueCategories) => {
	if (!Array.isArray(issueCategories)) {
		return ['issueCategories must be an array'];
	} else if (outcome === 'SUCCESS' && issueCategories.length === 0) {
		return [];
	} else if (outcome === 'SUCCESS') {
		return ['issueCategories must be empty when outcome is SUCCESS'];
	} else if (issueCategories.length === 0) {
		return ['issueCategories can not be empty'];
	}

	const invalid = issueCategories.reduce((invalid, issueCategory) => {
		if (!validIssueCategories.includes(issueCategory)) {
			invalid.push(issueCategory);
		}

		return invalid;
	}, []);

	if (invalid.length === 0) {
		return [];
	}

	return [`issueCategories contain invalid options: ${invalid.join(', ')}`];
};

/**
 * Valid outcomes must be strings present in validOutcomes
 *
 * @param {string} outcome
 *
 * @return {array} errors
 */
const getOutcomeValidationErrors = (outcome) => {
	if (typeof outcome !== 'string') {
		return ['outcome must be a string'];
	} else if (outcome.length === 0) {
		return ['outcome can not be empty'];
	}

	if (validOutcomes.includes(outcome)) {
		return [];
	}

	return [`outcome must be one of ${validOutcomes.join(', ')}, not ${outcome}`];
};

/**
 * Validate comment using getCommentValidationErrors except for when outcome is
 * success where the comment is optional.
 *
 * @param {string} outcome
 * @param {string} comment
 *
 * @return {array} errors
 */
const getOverrdideCommentValidationErrors = (outcome, comment) => {
	if (outcome === 'SUCCESS') {
		return [];
	}

	return getCommentValidationErrors(comment);
};

/**
 * Valid identity references must:
 * - be objects
 * - contain either attemptId or componentName
 *
 * @param {object} identityReference
 *
 * @return {array} errors
 */
const identityReferenceValdationErrors = (identityReference) => {
	if (identityReference === null) {
		return [`identityReference is null, must be an object`];
	} else if (typeof identityReference !== 'object') {
		return [`identityReference is ${typeof identityReference}, must be an object`];
	}

	const hasAttemptId = typeof identityReference.attemptId === 'string' && identityReference.attemptId.length > 0;
	const hasComponentName = typeof identityReference.componentName === 'string' && identityReference.componentName.length > 0;

	if (hasAttemptId) {
		return [];
	} else if (hasComponentName) {
		return [];
	}

	return ['identityReference does not contain attemptId or componentName'];
};

/**
 * Valid comments:
 * - must be strings
 * - can not be empty
 *
 * @param {string} comment
 *
 * @return {array} errors
 */
export const getCommentValidationErrors = (comment) => {
	if (typeof comment !== 'string') {
		return ['comment must be a string'];
	} else if (comment.length === 0) {
		return ['comment can not be empty'];
	}

	return [];
};

/**
 * @param {object} signature - The entire signature api response
 * @param {object} newEntry
 *
 * @return {array} newEntry, followed by entries with attemptId's different from newSignature's
 */
const buildAcknowledgeListEntries = (signature, newEntry) => {
	const acknowledgeEntries = [
		...signature.submissions
			.filter(createFilter({ 'input.componentName': 'HUMAN_OVERRIDE' }))
			.sort((a, b) => {
				return toDateTime(b.submittedAt).toMillis() - toDateTime(a.submittedAt).toMillis();
			})
			.flatMap((submission) => {
				return dig(submission, ['input', 'payload', 'acknowledgeList', 'acknowledge']);
			})
			.filter((entry, i, entries) => {
				const attemptId = dig(entry, ['identityReference','attemptId']);
				if (attemptId === null) {
					return true;
				}

				return i === entries.findIndex(createFilter({
					'identityReference.attemptId': attemptId
				}));
			})
	];

	if (!newEntry) {
		return acknowledgeEntries;
	}

	return [
		newEntry,
		...acknowledgeEntries
			.filter(createFilter({
				'identityReference.attemptId': {
					not: dig(newEntry, ['identityReference','attemptId'])
				}
			}))
			.filter((entry) => {
				return entry !== null && typeof entry !== 'undefined';
			})
	];
};

/**
 * Find the first acknowledge list comment in the signature
 *
 * @param {object} signature - The entire signature api response
 *
 * @return {string | undefined}
 */
const getAcknowledgeListComment = (signature) => {
	return signature.submissions
		.sort((a, b) => {
			return toDateTime(b.submittedAt).toMillis() - toDateTime(a.submittedAt).toMillis();
		})
		.map((submission) => {
			return dig(submission, ['input', 'payload', 'acknowledgeList', 'comment']);
		})
		.find((comment) => {
			return typeof comment === 'string';
		});
};
