import _ from 'lodash';
import { $time } from '@/js/plugins/time';
import { DateTime } from 'luxon';

import store from '@/store/store';
import icons from '@/assets/icons';

const dateTime = timeString => {
	const dateTime = DateTime.fromISO(timeString, { zone: 'utc' });

	if (dateTime.invalid) {
		return null;
	}

	return $time.formatDateTime(dateTime);
};

const millisecondToSeconds = milliseconds => {
	if (milliseconds == null) {
		return null;
	}
	const seconds = milliseconds / 1000;
	return `${seconds} second${seconds === 1 ? '' : 's'}`;
};

const meters = meters => {
	if (meters == null) {
		return null;
	}
	return `${meters} meter${meters === 1 ? '' : 's'}`;
};

const degrees = degrees => {
	return degrees == null ? null : `${degrees}°`;
};

const location = ({ latitude, longitude, altitude }) => {
	const location = {};

	if (latitude != null) {
		location.latitude = degrees(latitude);
	}

	if (longitude != null) {
		location.longitude = degrees(longitude);
	}

	if (altitude != null) {
		location.altitude = meters(altitude);
	}

	return location;
};

const profilePercentChange = (profile, otherValue) => {
	const { oldValue, newValue } = otherValue;

	if (newValue) {
		return '';
	}

	const changedDegrees = profile
		.map((value, index) => ({
			directionDegree: index,
			oldValue: oldValue[index] || 0,
			newValue: value
		}))
		.filter(({ newValue, oldValue }) => newValue !== oldValue);

	return {
		representationComponent: 'HorizonChange',
		props: { changedDegrees }
	};
};

const singleResourceWindowOverride = (windows, type) => {
	if (!['spacecraft', 'system'].includes(type)) {
		throw new Error('type did not match "spacecraft" or "system"');
	}
	const ruleObject = {};
	windows = Array.isArray(windows) ? windows[0] : windows;

	Object.entries(windows).forEach(([uuid, window]) => {
		const match = uuid.match(/(?<type>[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12})/);

		const customRule = `Custom rule ${$time.formatDuration(window / 1000)}`;

		if (!match) {
			ruleObject['[unknown override]'] = customRule;
		}
		const object = store.getters[`${type}s/find`](match.groups.type);

		ruleObject[`${object?.name}` || `unknown ${type}`] = customRule;
	});

	return ruleObject;
};

const customMapping = {
	contacts: {
		fieldNames: {
			end: 'end time',
			start: 'start time'
		},
		representationFunctions: {
			end: dateTime,
			start: dateTime
		}
	},
	missionProfiles: {
		fieldNames: {
			spacecraftIds: 'selected spacecrafts',
			systemIds: 'compatible systems'
		},
		representationFunctions: {
			bandServices: bands => bands.map(band => ({
				representationComponent: 'BandTagsItem',
				props: { band, bandTypeName: 'band' },
				representationValue: `'${band.direction} ${band.polarization ?? ''}' for ${band.band}-band`
			}))
		}
	},
	stations: {
		representationFunctions: {
			location
		}
	},
	systems: {
		representationFunctions: {
			diameter: meters,
			setupDuration: millisecondToSeconds,
			teardownDuration: millisecondToSeconds,
			// bandTypeName should be 'type' here as the system objects, but for some reason the changelog uses 'band'
			bands: bands => bands.map(band => ({
				representationComponent: 'BandTagsItem',
				props: { band, bandTypeName: 'band' },
				representationValue: `'${band.direction} ${band.polarization ?? ''}' for ${band.band}-band`
			})),
			location,
			horizon: profilePercentChange,
			uplink: profilePercentChange
		},
		extractFields: {
			masks: {
				newFields: ['horizon', 'uplink'], fallback: []
			}
		},
		disableChangeEqualMerging: {
			horizon: true,
			uplink: true
		}
	},
	tenants: {
		fieldNames: {
			spacecraftIds: 'active spacecrafts',
			systemIds: 'allowed systems'
		}
	},
	reservationWindows: {
		fieldNames: {
			stations: 'reservation window',
			spacecrafts: 'custom rules',
			systems: 'custom rules',
			overrides: 'custom rules',
			default: 'default reservation window'
		},
		representationFunctions: {
			stations: windows => Object.entries(windows).map(([stationId, window]) => {
				const match = stationId.match(/(?<uuid>[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12})/);

				if (!match) {
					return {};
				}

				const station = store.getters['stations/find'](match.groups.uuid);

				if (!station) {
					return {
						representationComponent: 'TierMarker',
						props: { station: { name: 'Unknown', location: { latitude: 0, longitude: 0, altitude: 0 }, window: window / 1000, size: 165 } }
					};
				}

				return {
					representationComponent: 'TierMarker',
					props: { station, defaultWindow: window / 1000, size: 165 },
					representationValue: `${$time.formatDuration(window / 1000)} for ${station.name}`
				};
			}),
			default: window => ({
				representationComponent: 'TierMarker',
				props: { isDefault: true, defaultWindow: window / 1000, size: 165 },
				representationValue: $time.formatDuration(window / 1000)
			}),
			systems: windows => singleResourceWindowOverride(windows, 'system'),
			spacecrafts: windows => singleResourceWindowOverride(windows, 'spacecraft'),
			overrides: windows => {
				const ruleObject = {};

				Object.entries(windows).forEach(([uuids, window]) => {
					const match = uuids.match(/(?<spacecraftUuid>[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}), (?<systemUuid>[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12})/);

					const customRule = `Custom rule ${$time.formatDuration(window / 1000)}`;

					if (!match) {
						ruleObject['[unknown override]'] = customRule;
					}

					const spacecraft = store.getters['spacecrafts/find'](match.groups.spacecraftUuid);
					const system = store.getters['systems/find'](match.groups.systemUuid);

					ruleObject[`${system?.name || '[unknown system]'} - ${spacecraft?.name || '[unknown spacecraft]'}`] = customRule;
				});

				return ruleObject;
			}
		}
	},
	horizonProfile: {
		fieldNames: {
			horizon: 'horizon profile',
			uplink: 'uplink profile'
		},
		representationFunctions: {
			horizon: profilePercentChange,
			uplink: profilePercentChange
		},
		disableChangeEqualMerging: {
			horizon: true,
			uplink: true
		}
	},
	dedicatedSystems: {
		fieldNames: {
			systemIds: 'dedicated systems'
		}
	}
};

const routerPillProps = (type, item) => {
	const iconName = type;
	if (typeof icons[iconName] !== 'string') {
		return null;
	}

	// convert ie. missionProfile to mission_profile
	const snakeType = _.snakeCase(type);

	return { iconName, item, url: `/${snakeType}s/` };
};

export { customMapping, routerPillProps };
