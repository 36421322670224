<template lang="pug">
.number {{value}}
</template>

<script>

export default {
	name: 'NumberValue',
	props: {
		value: {
			type: Number,
			required: true
		}
	}
};

</script>
