import dig from '@/js/utils/dig';

import Styleguide from '@/views/Styleguide/Styleguide.vue';

import Components from '@/views/Styleguide/ComponentsPage.vue';
import ButtonGroup from '@/views/Styleguide/Layout/ButtonGroup.vue';
import DropdownSelect from '@/views/Styleguide/Forms/DropdownSelect.vue';
import * as JsonSchema from '@/views/Styleguide/JsonSchema/index';

import pages from './pages';
import { mapPaths, mapNames } from '../lib/map-attributes';

const paths = mapPaths(pages);
const names = mapNames(pages);

const route = (property, component, options = {}) => {
	const {
		path = dig(paths, property.split('.')),
		name = dig(names, property.split('.'))
	} = options;

	return {
		path,
		component,
		meta: {
			name
		}
	};
};

export default [
	{
		path: '/styleguide',
		component: Styleguide,
		children: [
			{
				path: '',
				redirect: `/styleguide/${paths.components}`
			},
			route('components', Components),
			route('layout.buttonGroup', ButtonGroup),
			route('forms.dropdownSelect', DropdownSelect),
			route('jsonSchema.view', JsonSchema.View),
			route('jsonSchema.form', JsonSchema.Form),
		]
	}
];
