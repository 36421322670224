import { axiosUser } from '@/js/network/axios';
import { event } from '@/js/plugins/notify/notify';

import defaultSettings from '@/store/helpers/store-settings';

export default {
	namespaced: true,
	state: () => ({
		settings: {}
	}),
	mutations: {
		set: (state, { setting, value }) => Object.assign(state.settings, { [setting]: value }),
		setAll: (state, settings) => state.settings = settings
	},
	getters: {
		find: state => setting => state.settings[setting]
	},
	actions: {
		async save({ commit }, { setting, value }) {
			try {
				commit('set', { setting, value });
				await axiosUser.put('/me/settings', { [setting]: value });
			} catch (error) {
				event(`Failed to persist settings on server.\n${error.message}`, 'warning');
			}
		},
		async initiate({ commit }) {
			try {
				const response = await axiosUser.get('/me/settings');
				const remoteSettings = response.data;

				const settings = {};
				Object.keys(defaultSettings).forEach(key => settings[key] = defaultSettings[key].defaultValue);

				commit('setAll', { ...settings, ...remoteSettings });
			} catch (error) {
				event(`Failed to load settings from server. Using defaults.\n${error.message}`, 'warning');
			}
		}
	}
};
