<template lang="pug">
li.submissions-timeline-entry
	.outcome
		.icon-container
			OutcomeIcon(
				:outcome="outcome"
				:isManual="isManualOutcome"
				:isFaded="evaluationForced"
			)

	.content
		SubmissionDetails(:items="details")
		.comment(v-if="hasComment")
			p {{comment}}
		IssueCategories(:ids="issueCategories")
	.actions
			DropdownList
				DropdownListItem(
					icon="textBlock"
					@click.prevent="didClickShowPayload"
				)
					| Show payload
</template>

<script>

import delegate from '@/js/utils/delegate';
import { toDateTime } from '@/js/utils/filter/types';

import submissionPropsMixin from '../lib/mixins/submission-props';
import dig from '@/js/utils/dig';

import Inspector from '../Inspector.vue';
import IssueCategories from '../IssueCategories.vue';
import OutcomeIcon from '../OutcomeIcon.vue';
import { DropdownList, DropdownListItem } from '@/components/base/DropdownList/index';
import { SubmissionDetails }  from '../SubmissionDetails/index';

export default {
	name: 'SubmissionTimelineEntry',
	components: {
		DropdownList,
		DropdownListItem,
		IssueCategories,
		OutcomeIcon,
		SubmissionDetails
	},
	mixins: [
		submissionPropsMixin()
	],
	inject: [
		'$stack',
		'title',
		'evaluationIndex'
	],
	props: {
		submission: {
			type: Object,
			required: true
		},
		humanOverride: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		...delegate('evaluation', 'payload', { to: 'submission' }),
		humanOverrides() {
			if (this.humanOverride === null) {
				return [];
			}

			return [this.humanOverride];
		},
		acknowledgeListEntries() {
			return dig(this.humanOverride, ['input', 'payload', 'acknowledgeList', 'acknowledge']) ?? [];
		},
		submittedAt() {
			if (this.humanOverride) {
				return toDateTime(this.humanOverride.submittedAt);
			}

			return toDateTime(this.submission.submittedAt);
		}
	},
	methods: {
		didClickShowPayload() {
			this.$stack.push({
				component: Inspector,
				props: {
					back: 'Back to signatures',
					title: this.title,
					object: this.submission?.payload,
					details: this.details.filter(entry => entry.props.icon != 'burger')
				}
			});
		}
	}
};

</script>

<style lang="scss" scoped>
.submissions-timeline-entry {
	display: flex;

	.outcome {
		--icon-size: #{rem-calc(20)};

		position: relative;
		flex: 0 0 var(--icon-size);

		.icon-container {
			position: relative;
			z-index: 2;
			background: hsl(var(--dark-purple110));
			box-shadow: 0 0 0 rem-calc(8) hsl(var(--dark-purple110));

			.state-icon {
				display: block;
			}
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			background: hsl(var(--white30));
			width: rem-calc(1);
			left: 50%;
			transform: translateX(-50%);
			z-index: 1;
		}
	}

	.content {
		flex: 0 1 100%;
		margin-left: rem-calc(20);
		padding-bottom: rem-calc(30);
	}

	.comment {
		color: var(--component-submission-text-color);
	}

	&:last-child {
		.outcome:after {
			display: none;
		}

		.content {
			padding-bottom: 0;
		}
	}

	#{v-deep('.details')} {
		&:not(:last-child) {
			margin-bottom: rem-calc(5);
		}
	}
}
</style>
