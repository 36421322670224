import store from '@/store/store';

const validate = (dependencies, name) => {
	dependencies = dependencies.length ? dependencies : [dependencies];

	const isArray = Array.isArray(dependencies);
	const containsObjects = dependencies.every(dependency => typeof dependency === 'object');

	if (!isArray || !containsObjects) {
		console.error(`Error in component '${name}': dependencies-option should be an object or an array of objects`);
		return;
	}

	const storeActions = Object.keys(store._actions);
	dependencies.forEach(dependency => {
		const action = dependency.action;

		if (!storeActions.includes(action)) {
			console.error(`Error in component '${name}': action '${action}' does not exist in the Vuex-store`);
		}
	});
};

export const validateDependencies = component => {
	const dependencies = component.dependencies;
	const name = component.name;

	validate(dependencies, name);
};
