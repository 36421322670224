<template lang="pug">
h2 Buttons
div
	VButton Default!
	VButton(disabled) Disabled!
	VButton(
		disabled
		tooltip="Disabled button"
	) With tooltip!
	VButton.secondary Secondary!
	VButton.secondary(disabled) Disabled!
div
	VButton.invert Default!
	VButton.invert(disabled) Disabled!
	VButton.invert.secondary Secondary!
	VButton.invert.secondary(disabled) Disabled!
div
	VButton(icon="leop") Default!
	VButton(
		icon="leop"
		disabled
	) Disabled!
	VButton.secondary(icon="leop") Secondary!
	VButton.secondary(
		icon="leop"
		disabled
	) Disabled!
div
	VButton.invert(icon="leop") Default!
	VButton.invert(
		icon="leop"
		disabled
	) Disabled!
	VButton.invert.secondary(icon="leop") Secondary!
	VButton.invert.secondary(
		icon="leop"
		disabled
	) Disabled!
div
	VButton.small Small!
	VButton.small(disabled) Disabled!
	VButton.small.secondary Small!
	VButton.small.secondary(disabled) Disabled!
div
	VButton.small.invert Small!
	VButton.small.invert(disabled) Disabled!
	VButton.small.invert.secondary Small!
	VButton.small.invert.secondary(disabled) Disabled!

h2 Forms
VInput(
	:modelValue="inputText"
	placeholder="And this is the input"
	required
) This is the label!
VSelect(
	placeholder="Select something"
	:items="['A choice', 'Another choice']"
	icon="system"
	required
) Here comes a select box
VInput(
	:modelValue="inputText"
	placeholder="This one is disabled!"
	disabled
	required
) This is the label
VTextArea(
	:modelValue="inputText"
	placeholder="Whatever"
) This is an textarea
VCheckbox(v-model:checked="isChecked") And a checkbox!

h2 Modal
VButton(@click="modalShown = true") Test modal
VModal(
	v-if="modalShown"
	@close="closeModal"
	@confirm="closeModal"
	:confirm-button="'Test modal!'"
)
	template(#header) Test modal!
	VInput(
		:modelValue="inputText"
		placeholder="And this is the input"
	) This is the label!
	VSelect(
		placeholder="Select something"
		:items="['A choice', 'Another choice']"
	) Here comes a select box
	VInput(
		:modelValue="inputText"
		placeholder="This one is disabled!"
		disabled
	) This is the label
	VTextArea(
		:modelValue="inputText"
		placeholder="Whatever"
	) This is an textarea

h2 Toast notifications
div
	VButton(@click="$notify.event('Default toast!')") Default!
	VButton.success(@click="$notify.event('Success toast!', 'success')") Success!
	VButton.alert(@click="$notify.event('Alert toast!', 'error')") Alert!
	VButton.warning(@click="$notify.event('Warning toast!', 'warning')") Warning!

h3 Internal error
div
	VButton.alert(@click="$notify.internalError('Unable to retrieve systems')") Error!

h2 Callouts
VCallout This is the default callout!
VCallout(type="success") This is an success callout!
VCallout(type="alert") This is an alert callout!
VCallout(type="warning") This is an warning callout!

h2 Accordion
ul.collapsible
	AccordionItem(name="Accordion item 1")
		p Accordion content!
	AccordionItem(name="Accordion item 2")
		p Accordion content!
	AccordionItem(name="Accordion item 3")
		p Accordion content!

h2 Pills
SimplePill(:items="pillItems")
SimplePill(icon="tenant") Tropic

h2 Status pills
SimplePill(status="error") Not OK
SimplePill(status="warning") Partial
SimplePill(status="ok") Test

h2 Tooltips
.row
	.col
		Popper(content="Pop it!")
			VButton
				VIcon(name="leop")
				| Hover me!
	.col
		VButton
			VTooltip(
				text="Tooltip at bottom"
				position="bottom"
			)
				VIcon(name="leop")
				| Hover me!
	.col
		VButton
			VTooltip(
				text="Tooltip to the right"
				position="right"
			)
				VIcon(name="leop")
				| Hover me!
	.col
		VButton
			VTooltip(
				text="Tooltip to the left"
				position="left"
			)
				VIcon(name="leop")
				| Hover me!

h2 Date and time picker
.row
	.col
		DateTimePicker(
			v-model="date"
			@update:modelValue="time => date = time"
			uid="dp-start"
			required
		) Pick your date
	.col
		DateTimePicker(
			v-model="date"
			@update:modelValue="time => date = time"
			uid="dp-stop"
			required
		) Pick your date

h2 Dialogs
div
	VButton(@click="$dialog.confirm") Confirm
	VButton(@click="$dialog.alert") Alert
	VButton(@click="$dialog.prompt") Prompt
</template>

<script>
import { DateTime } from 'luxon';
import Popper from 'vue3-popper';

import AccordionItem from '@/components/base/AccordionItem.vue';
import DateTimePicker from '@/components/base/DateTimePicker.vue';
import SimplePill from '@/components/base/SimplePill/SimplePill.vue';
import styleguideComponents from './components';

export default {
	name: 'StyleGuide',
	components: {
		...styleguideComponents,
		AccordionItem,
		DateTimePicker,
		Popper,
		SimplePill
	},
	data() {
		return {
			modalShown: false,
			pillItems: [
				{
					icon: 'tenant',
					text: 'Tropic'
				},
				{
					icon: 'spacecraft',
					text: '1'
				}
			],
			inputText: '',
			selectInput: '',
			isChecked: false,
			date: DateTime.now().toUTC()
		};
	},
	methods: {
		closeModal() {
			this.modalShown = false;
		},
		clicked() {
			alert('Clicked!');
		}
	}
};
</script>
