<template lang="pug">
.date-time-picker
	label
		slot
		span.optional(v-if="!required && $slots.default") (optional)
	.input(:class="{ disabled }")
		VIcon(v-if="disabled" name="lock")
		VIcon(v-else name="calendar")
		.date(v-click-outside="closeDatePicker")
			input(
				type="text"
				:value="labelText"
				@input="updateByInputField"
				@click="openDatePicker"
			)
			.date-picker(:class="{ show: showDatePicker }")
				Datepicker(
					:uid="uid"
					:modelValue="datetime"
					:autoPosition="true"
					:clearable="false"
					:minDate="minimumDate"
					:maxDate="maximumDate"
					:minTime="minimumTime"
					:maxTime="maximumTime"
					:disabledDates="disabledDates"
					placeholder="Select date"
					inline
					hideInputIcon
					dark
					autoApply
					enableSeconds
					@update:modelValue="updateByPicker"
				)
</template>

<script>
import { DateTime } from 'luxon';
import { default as Datepicker } from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
	name: 'DateTimePicker',
	components: {
		Datepicker
	},
	props: {
		modelValue: {
			type: DateTime,
			required: true
		},
		uid: {
			type: String,
			required: true
		},
		placeholder: {
			type: String,
			required: false,
			default: ''
		},
		required: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		minDate: {
			type: DateTime,
			required: false,
			default: null
		},
		maxDate: {
			type: DateTime,
			required: false,
			default: null
		},
		useMinMaxTime: {
			type: Boolean,
			required: false,
			default: false
		},
		label: {
			type: String,
			required: false,
			default: null
		}
	},
	emits: ['update:modelValue', 'close', 'showDatePicker'],
	data() {
		return {
			inputFocused: false,
			showDatePicker: false,
			datetime: '',
			minimumDate: null,
			maximumDate: null,
			minimumTime: null,
			maximumTime: null,
			format: 'LLL dd, yyyy - HH:mm:ss'
		};
	},
	computed: {
		formattedDateTime() {
			const iso = new Date(this.datetime).toISOString();
			return DateTime.fromISO(iso).toFormat(this.format);
		},
		disabledDates() {
			return this.maxDate ? [this.utcHackIn(this.maxDate.plus({ days: 1 }))] : [];
		},
		labelText() {
			return this.label ?? this.formattedDateTime;
		}
	},
	watch: {
		modelValue() {
			if (this.useMinMaxTime) {
				this.updateMinTime();
				this.updateMaxTime();
			}

			this.datetime = this.utcHackIn(this.modelValue);
		},
		minDate() {
			this.minimumDate = this.toDate(this.minDate);

			if (this.modelValue < this.minDate) {
				this.updateByMinDate();
			}
		},
		maxDate() {
			this.maximumDate = this.toDate(this.maxDate);
		},
		showDatePicker(value) {
			this.$emit('showDatePicker', value);
		}
	},
	created() {
		this.datetime = this.utcHackIn(this.modelValue);

		this.minimumDate = this.toDate(this.minDate);
		this.maximumDate = this.toDate(this.maxDate ? this.maxDate.plus({ days: 1 }) : this.maxDate);

		if (this.useMinMaxTime) {
			this.updateMinTime();
			this.updateMaxTime();
		}
	},
	methods: {
		toTime(datetime) {
			return {
				hours: datetime.c.hour,
				minutes: datetime.c.minute,
				seconds: datetime.c.second
			};
		},
		toDate(datetime) {
			return datetime ? datetime.toISODate() : null;
		},
		utcHackIn({ year, month, day, hour, minute, second }) {
			return DateTime.local(year, month, day, hour, minute, second).toString();
		},
		utcHackOut({ year, month, day, hour, minute, second }) {
			return DateTime.utc(year, month, day, hour, minute, second);
		},
		updateMinTime() {
			if (this.modelValue.c.day === this.minDate.c.day && this.modelValue.c.month === this.minDate.c.month && this.modelValue.c.year === this.minDate.c.year) {
				this.minimumTime = this.toTime(this.minDate);
			} else {
				this.minimumTime = null;
			}
		},
		updateMaxTime() {
			if (this.modelValue.c.day === this.maxDate.c.day && this.modelValue.c.month === this.maxDate.c.month && this.modelValue.c.year === this.maxDate.c.year) {
				this.maximumTime = this.toTime(this.maxDate);
			} else {
				this.maximumTime = null;
			}
		},
		updateByPicker(newDatetime) {
			const datetime = DateTime.fromISO(new Date(newDatetime).toISOString());
			const hack = this.utcHackOut(datetime);
			this.update(hack);
		},
		updateByInputField(input) {
			let datetime = null;
			datetime = DateTime.fromFormat(input.target.value, this.format);
			if (!datetime.isValid) {
				datetime = DateTime.fromISO(input.target.value);
			}
			const hack = this.utcHackOut(datetime);
			this.update(hack);
		},
		updateByMinDate() {
			const selected = this.modelValue;
			const datetime = this.minDate.set({
				hour: selected.hour,
				minute: selected.minute,
				second: selected.second
			});
			this.update(datetime);
		},
		update(datetime) {
			if (datetime.isValid) {
				this.$emit('update:modelValue', datetime);
			}
		},
		openDatePicker() {
			this.showDatePicker = true;
		},
		closeDatePicker() {
			if (this.showDatePicker) {
				this.$emit('close');
			}
			this.showDatePicker = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.input {
	display: flex;
	align-items: center;
	margin: rem-calc(0 0 10);
	border: 1px solid hsl(var(--white20));
	border-radius: var(--global-radius);
	background-color: var(--body-background);
	color: var(--body-color);
	height: var(--input-height);
	padding: rem-calc(0 0 0 10);
	font-family: inherit;
	font-size: 1rem;
	line-height: 1.5;
	min-width: 0;

	.icon {
		flex-shrink: 0;
		margin-right: rem-calc(5);

		&.disable-icon {
			margin: rem-calc(0 0 0 10);
		}

		&#{v-deep('.stroke-primary')} {
			stroke: hsl(var(--white40));
		}

		&#{v-deep('.fill-primary')} {
			fill: hsl(var(--white40));
		}
	}

	&:hover {
		border-color: hsl(var(--white40));
	}

	&.focus {
		border-color: hsl(var(--white40));
		box-shadow: 0 0 0 3px hsl(var(--white) / 10%);
	}

	&.disabled {
		cursor: not-allowed;

		input {
			color: hsl(var(--white40));
			pointer-events: none;
		}
	}

	> div:not(.icon) {
		flex-grow: 1;
	}

	input {
		margin: 0;
		padding: 0;
		border: 0;

		@include input-placeholder {
			color: hsl(var(--white40));
		}

		&:focus {
			box-shadow: none;
		}
	}
}

.date {
	position: relative;

	.date-picker {
		left: 50%;
		transform: translateX(-50%);
	}
}

.time {
	display: flex;
	flex-grow: 0 !important;
	padding: rem-calc(0 0 0 10);
	position: relative;

	#{v-deep('.text')} {
		margin: 0;
		border: 0;

		&.focus {
			box-shadow: none;
		}
	}

	.input-wrap {
		display: flex;
		align-items: center;
	}
}

.date-picker,
.time-picker {
	position: absolute;
	top: 100%;
	left: rem-calc(10);
	background-color: hsl(var(--dark-purple110));
	border: 1px solid hsl(var(--white40));
	border-radius: var(--global-radius);
	margin: rem-calc(10 0 0);
	display: none;
	opacity: 0;
	z-index: 30;

	&.show {
		display: block;
		opacity: 1;
	}

	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translateX(-50%);
		width: 0;
		height: 0;
		border-style: solid;
		border-color: transparent;
	}

	&:before {
		border-width: rem-calc(10);
		border-bottom-color: hsl(var(--white40));
	}

	&:after {
		border-width: rem-calc(8);
		border-bottom-color: hsl(var(--dark-purple110));
	}
}
</style>
