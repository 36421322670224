<template lang="pug">
li.list-item(:class="[{ clickable: hasClick, pills: hasPills, 'nav-button': hasViewButton }]")
	component(
		:is="navigationLink ? 'router-link' : 'div'"
		:to="navigationLink"
		:class="['content', item.lifecycleState]"
	)
		VIcon(
			v-if="icon"
			:name="icon"
		)
		.text
			slot
		.lifecycle(v-if="item.lifecycleState")
			VTooltip(
				:text="stateName"
				:position="tooltipPosition"
			)
				VIcon(:name="stateIcon")
		.pills(v-if="hasPills")
			slot(name="pills")
	router-link.button.small.secondary(
		v-if="hasViewButton"
		:to="navigationLink"
	) View
</template>

<script>
import validate, { joi } from '@pv';

export default {
	name: 'SimpleListItem',
	props: {
		item: {
			type: Object,
			required: false,
			validator: validate(joi.object({
				lifecycleState: joi.lifecycleState().allow('')
			})),
			default: () => ({ lifecycleState: '' })
		},
		icon: {
			type: String,
			required: false,
			default: ''
		},
		hasViewButton: {
			type: Boolean,
			required: false,
			default: false
		},
		tooltipPosition: {
			type: String,
			required: false,
			default: 'top'
		},
		navigationLink: {
			type: String,
			required: false,
			default: null
		}
	},
	computed: {
		hasClick() {
			return !!this.$attrs.onClick;
		},
		hasPills() {
			return !!this.$slots.pills;
		},
		stateName() {
			const state = this.item.lifecycleState.toLowerCase();
			return this.$lifecycle.name(state);
		},
		stateIcon() {
			const state = this.item.lifecycleState;
			return this.$lifecycle.icon(state);
		}
	}
};
</script>

<style lang="scss" scoped>
.list-item {
	list-style: none;
	padding: rem-calc(10 0);

	.collapsible-content & {
		&:first-of-type {
			padding-top: rem-calc(0);
		}

		&:last-of-type {
			padding-bottom: rem-calc(0);
		}
	}

	& + .list-item {
		border-top: 1px solid hsl(var(--white10));
	}

	&.pills {
		.content {
			.text {
				order: 1;
				flex-grow: 0;
			}

			.pills {
				order: 2;
				margin-left: rem-calc(10);
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: rem-calc(10);

				#{v-deep('.pill')} {
					margin: 0;
				}
			}

			.lifecycle {
				order: 3;
				margin-left: auto;
			}
		}
	}

	&.nav-button {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.content {
			flex-flow: column;
			align-items: flex-start;
			flex-grow: 1;
			margin-right: rem-calc(12);

			.pills {
				margin: rem-calc(12 0 0 0);
			}
		}
	}

	.content {
		display: flex;
		align-items: center;
		font-size: rem-calc(18);
		font-weight: var(--font-bolder);
		padding: rem-calc(10 0);
		color: inherit;
		text-decoration: none;

		&.deprecated {
			.icon,
			.text,
			.pills {
				opacity: var(--deprecated-opacity);
			}
		}

		.text {
			flex-grow: 1;
		}
	}

	.icon,
	.small-icon {
		margin-right: rem-calc(10);
	}

	> .button {
		margin: 0;
	}

	&.clickable {
		cursor: pointer;
		user-select: none;

		&:hover .content {
			opacity: 0.75;
		}
	}
}
</style>
