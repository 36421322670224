import { DateTime, Duration } from 'luxon';

const formatDate = time => time.toFormat('LLL dd, yyyy');
const formatDateTime = time => time.toFormat('LLL dd, yyyy - HH:mm:ss');
const formatTime = time => time.toFormat('HH:mm:ss');
const formatTimeHoursMinutes = time => time.toFormat('HH:mm');
const formatMonthDay = time => time.toFormat('LLL dd');
const epoch = time => time.toMillis();
const formatSimpleDateTime = time => time.toFormat('dd.MM HH:mm');

const formatDuration = (window, options = {}) => {
	if (!window || isNaN(window)) {
		return window;
	}

	const durationInstance = Duration.isDuration(window) ?
		window :
		Duration.fromObject({ seconds: Math.round(window) });

	let duration = durationInstance
		.shiftTo('days', 'hours', 'minutes', 'seconds')
		.toObject();

	let time = '';
	if (duration.days) {
		time += duration.days + ` day${duration.days > 1 ? 's' : ''} `;
		if (options.onlyLargest) {
			return time;
		}
	}
	if (duration.hours) {
		time += duration.hours + ` hour${duration.hours > 1 ? 's' : ''} `;
		if (options.onlyLargest) {
			return time;
		}
	}
	if (duration.minutes) {
		time += duration.minutes + ' min ';
		if (options.onlyLargest) {
			return time;
		}
	}
	if (duration.seconds) {
		time += duration.seconds + ' sec';
		if (options.onlyLargest) {
			return time;
		}
	}

	return time;
};

const formatWrittenDay = date => {
	const textDate = date.toRelativeCalendar();
	if (textDate === 'yesterday' || textDate === 'today' || textDate === 'tomorrow') {
		return textDate.charAt(0).toUpperCase() + textDate.slice(1);
	}
	return date.toFormat('LLL dd, yyyy');
};

const formatTimeLabel = (startTime, endTime, humanReadableDate = false, showSeconds = true) => {
	const sameDay = startTime.hasSame(endTime, 'day');

	if (sameDay) {
		const date = humanReadableDate ? $time.formatWrittenDay(startTime) : $time.formatDate(startTime);
		const start = $time.formatTime(startTime);
		const end = $time.formatTime(endTime);

		return `${date} - ${start} -> ${end}`;
	}

	const start = showSeconds ? startTime.toFormat('LLL dd - HH:mm:ss') : startTime.toFormat('LLL dd - HH:mm');
	const end = showSeconds ? endTime.toFormat('LLL dd - HH:mm:ss') : endTime.toFormat('LLL dd - HH:mm');

	return `${start} -> ${end}`;
};

const toWeekday = (date, options = {}) => {
	const {
		today = false
	} = options;

	if (today && DateTime.utc().hasSame(date, 'day')) {
		return 'Today';
	}

	return date.toFormat('EEEE');
};

const tryFormatStringToISO = (time) => {
	const format = whichTimeFormat(time);
	if (!format) {
		return undefined;
	}
	return DateTime.fromFormat(time, format).toFormat('yyyy-MM-dd\'T\'HH:mm:ss') + 'Z';
};

function whichTimeFormat(time) {
	if (/^[A-Za-z]+\s[0-9]+,\s[0-9]+$/.test(time)) {
		return 'LLL dd, yyyy';
	} else if (/^[A-Za-z]+\s[0-9]+,\s[0-9]+\s-\s[0-9]+:[0-9]+:[0-9]+$/.test(time)) {
		return 'LLL dd, yyyy - HH:mm:ss';
	} else if (/^[0-9]+\.[0-9]+\s[0-9]+:[0-9]+$/.test(time)) {
		return 'dd.MM HH:mm';
	}
	return undefined;
}

export const $time = {
	formatDateTime,
	formatDate,
	formatTime,
	epoch,
	formatDuration,
	formatMonthDay,
	formatTimeHoursMinutes,
	formatWrittenDay,
	formatTimeLabel,
	toWeekday,
	formatSimpleDateTime,
	tryFormatStringToISO
};

export default {
	install: app => {
		app.config.globalProperties.$time = $time;
	}
};
