<template lang="pug">
li.list-item(
	:class="{ checked: isChecked, disabled: isDisabled }"
	@click="isDisabled ? null : toggleCheck()"
	:title="isDisabled ? title : ''"
)
	.text-container
		span.name(:title="title") {{ itemName }}
		span.secondary-name(v-if="item.secondaryName") {{ item.secondaryName }}
	VTooltip.lifecycle(
		v-if="indicateNonOperational && $lifecycle.isStaged(item)"
		:text="$lifecycle.name(item.lifecycleState)"
		position="left"
	)
		VIcon(:name="$lifecycle.icon(item.lifecycleState)")
	.color-icon(
		v-for="(icon, index) in item.additionalIcons"
		:key="index"
		:title="icon.tooltip"
	)
		VIcon(:name="icon.icon")
	VIcon(
		v-if="displayCheck"
		:name="icon"
	)
</template>

<script>
import validate, { joi } from '@pv';

export default {
	name: 'DropdownItem',
	props: {
		item: {
			type: Object,
			required: true,
			validator: validate(joi.object({
				name: joi.string(),
				lifcycleState: joi.lifecycleState(), // Can be used for visualizing lifecyle in the menu
				id: joi.string(), // Not necessarily a guid
				isDisabled: joi.boolean() // Can be used to disable an item in the dropdown menu
			}))
		},
		displayCheck: {
			type: Boolean,
			required: false,
			default: true
		},
		multiSelect: {
			type: Boolean,
			required: false,
			default: false
		},
		indicateNonOperational: {
			type: Boolean,
			required: false,
			default: false
		},
		disabledReason: {
			type: String,
			required: false,
			default: null
		},
		truncateName: {
			type: Boolean,
			required: false,
			default: false
		},
		additionalIcons: {
			type: Array,
			required: false,
			default: () => [],
			validator: validate(joi.array().items(joi.object().keys({
				icon: joi.icon(),
				tooltip: joi.string()
			})))
		}
	},
	emits: ['toggleCheck'],
	data() {
		return {
			isChecked: false
		};
	},
	computed: {
		itemName() {
			if (this.truncateName) {
				return this.$text.truncateString(this.item.name, 19);
			}
			return this.item.name;
		},
		checkedIcon() {
			return this.multiSelect ? '20/form/checkbox.checked' : '20/form/radio.selected';
		},
		uncheckedIcon() {
			return this.multiSelect ? '20/form/checkbox' : '20/form/radio';
		},
		icon() {
			return this.isChecked ? this.checkedIcon : this.uncheckedIcon;
		},
		isDisabled() {
			return !!this.item.isDisabled;
		},
		title() {
			if (this.isDisabled && this.disabledReason) {
				return this.disabledReason;
			}
			return this.item.name;
		}
	},
	watch: {
		item: {
			deep: true,
			handler() {
				this.isChecked = this.item.isChecked;
			}
		},
		displayCheck() {
			if (!this.displayCheck) {
				this.isChecked = false;
			}
		}
	},
	created() {
		if (this.item.isChecked) {
			this.isChecked = true;
		}
	},
	methods: {
		toggleCheck() {
			this.isChecked = this.multiSelect ? !this.isChecked : true;

			this.$emit('toggleCheck', {
				item: this.item,
				isChecked: this.isChecked
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.list-item {
	display: flex;
	align-items: center;
	height: var(--list-item-height);
	cursor: pointer;

	+ .list-item {
		border-top: 1px solid hsl(var(--white15));
	}

	.lifecycle {
		flex-shrink: 0;
		margin-left: rem-calc(10);

		.icon {
			margin-left: 0;
		}
	}

	.icon {
		flex-shrink: 0;
		opacity: 0.4;
		margin-left: rem-calc(10);

		#{v-deep('.stroke-white-100')} {
			stroke: var(--body-background);
		}
	}

	&:hover .icon,
	&.checked .icon {
		opacity: 1;
	}

	&.disabled {
		color: hsl(var(--white40));
		cursor: not-allowed;

		&:hover {
			.icon-20-form-radio {
				opacity: 0.4;
			}
		}

		#{v-deep('.tooltip')} {
			width: 100%;
		}
	}
}
</style>
