<template lang="pug">
router-link(
	:class="modifierClasses"
	:to="url"
	active-class=""
	exact-active-class=""
	@click="onClick"
) {{ item.title }}
</template>

<script>
import validate, { joi } from '@pv';

export default {
	name: 'NavbarItem',
	props: {
		item: {
			type: Object,
			required: true,
			validator: validate(joi.object({
				url: joi.string().required(),
				title: joi.string().required()
			}))
		},
		hasNotification: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['click'],
	computed: {
		url() {
			return this.item.url;
		},
		modifierClasses() {
			return {
				'is-active': this.isActive,
				'indicator': this.hasNotification
			};
		},
		isActive() {
			return this.activeUrls.some(url => {
				if (typeof url === 'string') {
					return url === this.$route.path;
				}

				return url.test(this.$route.path);
			});
		},
		activeUrls() {
			return [this.url, ...this.item.activeUrls || []];
		}
	},
	methods: {
		onClick() {
			this.$emit('click');
		}
	}
};
</script>
