import { webServerConnection } from '@/js/network/axios';

import {
	ENV_PRODUCTION,
	ENV_PROD,
	ENV_STAGING,
	ENV_PREPROD,
	ENV_TEST,
	ENV_DEVELOPMENT,
	ENV_DEV
} from '@/env';

const environmentList = [
	ENV_PRODUCTION,
	ENV_PROD,
	ENV_STAGING,
	ENV_PREPROD,
	ENV_TEST,
	ENV_DEVELOPMENT,
	ENV_DEV
];

export default {
	namespaced: true,
	state: () => ({
		environment: null,
		enableOrbitGuard: false,
		enableVisualizer: false,
		aiConnectionString: null
	}),
	mutations: {
		setEnvironment: (state, payload) => state.environment = payload,
		setEnableOrbitGuard: (state, payload) => state.enableOrbitGuard = payload,
		setEnableOrbitGuardCommitmentAssurance: (state, payload) => state.enableOrbitGuardCommitmentAssurance = payload,
		setEnableVisualizer: (state, payload) => state.enableVisualizer = payload,
		setaiConnectionString: (state, payload) => state.aiConnectionString = payload
	},
	getters: {
		environment: state => state.environment,
		isProductionEnvironment: state => state.environment === ENV_PRODUCTION || state.environment === ENV_PROD,
		isStagingEnvironment: state => state.environment === ENV_STAGING || state.environment === ENV_PREPROD,
		isTestEnvironment: state => state.environment === ENV_TEST,
		isDevelopmentEnvironment: state => state.environment === ENV_DEVELOPMENT || state.environment === ENV_DEV,
		enableOrbitGuard: state => state.enableOrbitGuard,
		enableOrbitGuardCommitmentAssurance: state => state.enableOrbitGuardCommitmentAssurance,
		enableVisualizer: state => state.enableVisualizer,
		aiConnectionString: state => state.aiConnectionString
	},
	actions: {
		// Function that is called _after_ authenticating the user, so this should load all the data needed for the user to use the app
		load: ({ commit, getters, dispatch }) =>
			webServerConnection.get('/environment').then(async ({ data }) => {
				const activeEnvironment = environmentList.find(env => env === data.ACTIVE_ENVIRONMENT.toUpperCase().trim());
				const enableOrbitGuard = !!parseInt(data.ENABLE_ORBIT_GUARD);
				const enableOrbitGuardCommitmentAssurance = !!parseInt(data.ENABLE_ORBIT_GUARD_COMMITMENT_ASSURANCE);
				const enableVisualizer = !!parseInt(data.ENABLE_VISUALIZER);
				const aiConnectionString = data.APPLICATIONINSIGHTS_CONNECTION_STRING;
				if (activeEnvironment) {
					console.log(`Connected to a ${activeEnvironment} server`);
					commit('setEnvironment', activeEnvironment);
					commit('setEnableOrbitGuard', enableOrbitGuard);
					commit('setEnableOrbitGuardCommitmentAssurance', enableOrbitGuardCommitmentAssurance);
					commit('setEnableVisualizer', enableVisualizer);
					commit('setaiConnectionString', aiConnectionString);
					if (getters.isProductionEnvironment || getters.isStagingEnvironment) {
						await dispatch('migratedAssets/fetchAssets', null, { root: true });
					}
					if (!getters.isProductionEnvironment) {
						document.title = `KOGNI (${activeEnvironment})`;
					}
					return activeEnvironment;
				}
				throw new Error(`Server did not give back a valid environment.\nGot: ${data.ACTIVE_ENVIRONMENT}\nValid: ${environmentList.join(',')}`);
			})
	}
};
