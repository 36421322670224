<template lang="pug">
label.toggle-switch(:for="fieldId")
	span.title(v-if="hasTitle")
		slot
	.switch(:class="classes")
		input(
			@input="toggle($event)"
			type="checkbox"
			:checked="checked"
			:id="fieldId"
			:disabled="isDisabled"
		)
		.track
		.indicator
			.indicator-icon-off
			VIcon.indicator-icon-on(name="20/form/checkbox.checkmarkOnly")
</template>

<script>
const nextId = (() => {
	let next = 0;

	return () => {
		next += 1;
		return `toggle-switch-${next}`;
	};
})();

export default {
	name: 'ToggleSwitch',
	props: {
		fieldId: {
			type: String,
			required: false,
			default() {
				return nextId();
			}
		},
		checked: {
			type: Boolean,
			required: false,
			default: false
		},
		size: {
			type: String,
			required: false,
			default: '' // Supports 'small'
		},
		enabled: {
			type: Boolean,
			required: false,
			default: null
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['update:checked'],
	data() {
		return {
			isChecked: this.checked
		};
	},
	computed: {
		hasTitle() {
			return typeof this.$slots.default === 'function';
		},
		isDisabled() {
			if (this.enabled === false) {
				return;
			}

			return this.disabled;
		},
		classes() {
			return {
				'small': this.size ? 'small' : '',
				'disabled': this.isDisabled
			};
		}
	},
	methods: {
		toggle(event) {
			if (this.isDisabled) {
				return;
			}

			this.isChecked = event.target.checked;
			this.$emit('update:checked', event.target.checked);
		}
	}
};
</script>

<style lang="scss" scoped>
.toggle-switch {
	user-select: none;
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;

	.title {
		flex-grow: 1;
		min-width: 0;
		font-weight: var(--font-bolder);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	&.has-change {
		.indicator {
			border-color: hsl(var(--orange)) !important;
		}
		.indicator-icon-on,
		.indicator-icon-off,
		input:checked ~ .indicator {
			background-color: hsl(var(--orange));
			border-radius: rem-calc(1000);
		}
	}
}

.switch {
	--width: #{rem-calc(42)};
	--height: #{rem-calc(22)};
	--track-inset: #{rem-calc(6)};
	--track-height: calc(var(--height) - var(--track-inset));
	--indicator-size: var(--height);
	--transition-duration: 0.22s;

	position: relative;
	display: block;
	margin: 0;
	width: var(--width);
	height: var(--height);

	&:not(:first-child) {
		margin-left: rem-calc(5);
	}

	input[type='checkbox'] {
		position: absolute;
		pointer-events: none;
		height: 0;
		width: 0;
		opacity: 0;
	}

	.track {
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 100%;
		height: var(--track-height);
		border-radius: calc(var(--track-height) / 2);
		border: 0 solid hsl(var(--white30));
		background-color: hsl(var(--white30));
		transition-property: background-color border-width height box-shadow;
		transition-duration: var(--transition-duration);
		transition-timing-function: ease-in-out;
	}

	.indicator {
		position: absolute;
		box-sizing: border-box;
		z-index: 1;
		top: 0;
		left: 0;
		width: var(--indicator-size);
		height: var(--indicator-size);
		background: hsl(var(--dark-purple));
		border: rem-calc(1.5) solid hsl(var(--white80));
		border-radius: calc(var(--indicator-size) / 2);
		transition-property: left background border;
		transition-duration: var(--transition-duration);
		transition-timing-function: ease-in-out;
	}

	.indicator-icon-off,
	.indicator-icon-on {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}

	.indicator-icon-off {
		width: rem-calc(8.5);
		height: rem-calc(1.5);
		border-radius: rem-calc(1000);
		background-color: hsl(var(--white80));
		opacity: 1;
		transition-property: background-color opacity;
		transition-duration: var(--transition-duration);
		transition-timing-function: ease-in-out;
	}

	.indicator-icon-on {
		--icon-size: #{rem-calc(20)};
		--icon-color-primary: hsl(var(--dark-purple));

		opacity: 0;
		transition: opacity var(--transition-duration) ease-in-out;
	}

	#{v-deep('.indicator-icon-on')} > svg {
		transform: scaleY(0);
		transition: transform var(--transition-duration) ease-in-out;
	}

	input:checked {
		& ~ .indicator {
			background-color: hsl(var(--white));
			border-color: hsl(var(--white));
			left: calc(100% - var(--indicator-size));

			.indicator-icon-off {
				opacity: 0;
			}

			.indicator-icon-on {
				opacity: 1;
			}

			#{v-deep('.indicator-icon-on')} > svg {
				transform: scaleY(1);
			}
		}
	}

	&:not(.disabled) {
		input:hover,
		input:focus {
			+ .track {
				background-color: hsl(var(--white10));
				border-width: 2px;
				height: var(--height);
				border-radius: calc(var(--height) / 2);
			}

			~ .indicator {
				border-color: hsl(var(--white));

				.indicator-icon-off {
					background-color: hsl(var(--white));
				}
			}
		}

		input:focus + .track {
			box-shadow: 0 0 0 3px hsl(var(--white) / 10%);
		}
	}

	&.small {
		--width: #{rem-calc(32)};
		--height: #{rem-calc(18)};
	}

	&.disabled {
		cursor: default;

		.indicator-icon-off {
			background-color: hsl(var(--white30));
		}

		.indicator {
			border-color: hsl(var(--white30));
		}

		input:checked ~ .indicator {
			background-color: hsl(var(--white50));
			border-color: hsl(var(--white50));
		}
	}
}
</style>
