import log from '@/assets/data/release-log.json';
import { DateTime } from 'luxon';

export default {
	namespaced: true,
	state: () => ({
		releaseLog: log
	}),
	getters: {
		all(state) {
			return state.releaseLog.map(log => ({
				...log,
				created: DateTime.fromISO(log.created, { zone: 'utc' })
			}));
		}
	}
};
