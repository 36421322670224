import mitt from 'mitt';

const bus = mitt();

export default {
	install: (app: any) => {
		app.config.globalProperties.$bus = bus;
	}
};

export { bus };
