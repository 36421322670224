<template lang="pug">
Popper(
	ref="popper"
	placement="bottom-start"
	arrow
)
	VButton.secondary(:disabled="isDisabled")
		.opener History
		VIcon(name="arrow/down.simple")

	template(#content)
		ol.evaluations
			li(
				v-for="(evaluation, i) in evaluations"
				:key="evaluation.id"
				:class="listItemClass(i, evaluation)"
				@click="setEvaluationIndex(i)"
			)
				Evaluation(:evaluation="evaluation")
</template>

<script>

import Popper from 'vue3-popper';

import Evaluation from './Evaluation.vue';

export default {
	components: {
		Evaluation,
		Popper
	},
	props: {
		evaluations: {
			type: Array,
			required: true
		},
		evaluationIndex: {
			type: Number,
			required: false,
			default: 0
		}
	},
	emits: [
		'update:evaluationIndex'
	],
	computed: {
		isDisabled() {
			return this.evaluations.length <= 1;
		}
	},
	methods: {
		setEvaluationIndex(i) {
			this.$emit('update:evaluationIndex', i);
		},
		listItemClass(i, evaluation) {
			return [
				this.$text.toKebabCase(evaluation.outcome),
				{
					'is-selected': i === this.evaluationIndex
				}
			];
		}
	}
};

</script>

<style lang="scss" scoped>
#{v-deep('.popper')} {
	width: 320px;
	padding: 0;
}

#{v-deep('.popper #arrow')} {
	clip-path:
		polygon(
			#{rem-calc(-5)} #{rem-calc(-5)},
			calc(100% + #{rem-calc(5)}) #{rem-calc(-5)},
			calc(100% + #{rem-calc(5)}) calc(50% + #{rem-calc(1)}),
			#{rem-calc(-5)} calc(50% + #{rem-calc(1)})
		);
}

.evaluations {

	max-height: 400px;
	overflow: scroll;

	@include scrollbars(0.5rem, hsl(var(--white20)));

	li {
		padding: 20px;
		cursor: pointer;
		border-left: rem-calc(3) solid transparent;

		&:not(:last-child) {
			border-bottom: rem-calc(1) solid hsl(var(--white20));
		}

		&.is-selected,
		&:hover:not(.is-selected) {
			background: hsl(var(--white) / 5%);
		}

		&.anomaly {
			border-left-color: hsl(var(--red80));
		}

		&.witness-required {
			border-left-color: hsl(var(--orange));
		}

		&.success {
			border-left-color: hsl(var(--green));
		}

		&.remark {
			border-left-color: hsl(var(--green));
		}
	}
}
</style>
