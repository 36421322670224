<template lang="pug">
li.component-group
	.content
		.title {{title}}
		template(v-if="hasSubmissions")
			time.subtitle {{submittedTime}}
		template(v-else)
			.subtitle Waiting
	.outcome
		OutcomeIcon(
			:outcome="outcome"
			:isManual="isManualOutcome"
		)
</template>

<script>

import delegate from '@/js/utils/delegate';
import submissionPropsMixin from '@/components/feature/ContactsSignModal/lib/mixins/submission-props';

import OutcomeIcon from '@/components/feature/ContactsSignModal/OutcomeIcon.vue';

export default {
	components: {
		OutcomeIcon
	},
	mixins: [
		submissionPropsMixin()
	],
	props: {
		group: {
			type: Object,
			required: true
		},
		sig: {
			type: Object,
			required: true
		}
	},
	computed: {
		...delegate('submissions', { to: 'group' }),
		signature() {
			return this.sig;
		},
		hasSubmissions() {
			return this.submissions.length > 0;
		},
		submission() {
			if (!this.hasSubmissions) {
				return null;
			}

			return this.submissions[0];
		},
		humanOverrides() {
			if (!this.hasSubmissions) {
				return [];
			}

			return this.submission.humanOverrides;
		},
		humanOverride() {
			if (this.humanOverrides.length === 0) {
				return null;
			}

			return this.humanOverrides[0];
		},
		evaluationIndex() {
			return 0;
		},
		title() {
			let title = '';

			// Add component name, formatted
			title += this.$text
				.capitalizeWords(this.group.componentName)
				// Ks3 -> KS3
				.replace(/([A-Z][a-z][0-9]+)([^\w]|$)/g, (_, word, separator) => {
					return `${word.toUpperCase()}${separator}`;
				});

			// Submission name goes after component separated by ,
			if (typeof this.group.submissionName === 'string' && this.group.submissionName.length > 0) {
				title += `, ${this.$text.toSentenceCase(this.group.submissionName)}`;
			}

			// The cherry on top is the disambiguationId(when present) surrounded by ()
			if (typeof this.group.disambiguationId === 'string' && this.group.disambiguationId.length > 0) {
				title += ` (${this.$text.toSentenceCase(this.group.disambiguationId)})`;
			}

			return title;
		}
	}
};

</script>

<style lang="scss" scoped>
.component-group {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		border-bottom: rem-calc(1) solid hsl(var(--white30));
	}

	.content {
		flex: 0 1 100%;
		position: relative;
		height: 30px;
		margin: rem-calc(12) 0;

		.title,
		.subtitle {
			position: absolute;
			left: 0;
			right: 0;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			word-break: break-word;
			line-height: 1.5;
		}

		.title {
			top: rem-calc(-3);
			font-size: rem-calc(14);
			font-weight: 600;
		}

		.subtitle {
			bottom: rem-calc(-3);
			font-size: rem-calc(12);
			color: hsl(var(--white60));
		}
	}

	.outcome {
		padding: rem-calc(10) 0;
		margin-left: rem-calc(10);
	}
}
</style>
