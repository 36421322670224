import { axiosUser } from '@/js/network/axios';
import { event } from '@/js/plugins/notify/notify';

import _ from 'lodash';

export default {
	namespaced: true,
	state: () => ({
		favorites: {},
		initiated: false
	}),
	mutations: {
		set: (state, { assetType, list }) => Object.assign(state.favorites, { [assetType]: list }),
		setAll: (state, favorites) => state.favorites = favorites,
		setAsset: (state, assetObject) => {
			if (!state.initiated) {
				return;
			}

			const { assetType, assetId } = assetObject;

			const assetItem = state.favorites[assetType].find(item => item.assetId === assetId);

			if (assetItem) {
				Object.assign(assetItem, assetObject);
				return;
			}

			state.favorites[assetType].push(assetObject);
		},
		initiate: state => state.initiated = true
	},
	getters: {
		find: (state, getters, rootState, rootGetters) => assetType => {
			if (!state.favorites[assetType]) {
				return [];
			}

			return _.orderBy(
				state.favorites[assetType].filter(({ assetId }) => rootGetters[`${assetType}/find`](assetId, { notify: false })),
				['count'],
				['desc']
			);
		}
	},
	actions: {
		fetchAll({ commit }) {
			return axiosUser.get(`/me/favorites`)
				.then(favorites => commit('setAll', favorites.data));
		},
		ensure({ dispatch, commit, state }) {
			if (!state.initiated) {
				return dispatch('fetchAll')
					.then(() => commit('initiate'))
					.catch(error => event(`Failed to load favorite assets.\n${error.message}`, 'warning'));
			}
			return Promise.resolve();
		},
		incrementAsset({ rootGetters, commit }, { assetType, id }) {
			if (rootGetters['settings/find']('logActivity')) {
				axiosUser.put(`/me/favorites/${assetType}/${id}/increment`)
					.then(({ data }) => commit('setAsset', data))
					.catch(() => null);
			}
		},
		clearLogs({ commit }) {
			return axiosUser.delete('/me/favorites')
				.then(() => commit('setAll', {}))
				.then(() => event('Successfully deleted activity logs.', 'success'))
				.catch(() => event('Failed to deleted activity logs.', 'error'));
		}
	}
};
