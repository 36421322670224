import { manageError } from '@/store/helpers/store-error';
import { EMIT_EDIT_START, EMIT_EDIT_STOP, EMIT_EDITOR_USER_LIST, UPDATE_RESOURCE } from '@/env';

const INITIATING_RECHECK_INTERVAL = 50;

export default api => {
	const base = {
		ensure({ dispatch, commit, state }, { filter } = {}) {
			if (state.initiating) {
				// avoid race conditions if the module is being ensured multiple times at once
				return setTimeout(() => dispatch('ensure', { filter }), INITIATING_RECHECK_INTERVAL);
			}

			if (state.initiated) {
				return;
			}

			commit('startInitiation');


			return dispatch('fetch', { filter })
				.then(() => dispatch('initiateMore'))
				.then(() => commit('initiate'))
				.then(() => {
					api.connection.socket.on(UPDATE_RESOURCE, data => dispatch('fetchOne', data.id));
					api.connection.socket.on(EMIT_EDITOR_USER_LIST, userList => dispatch('setEditorUserList', userList));
				})
				.catch(manageError)
				.finally(() => commit('endInitiation'));
		},
		fetch({ commit }, { filter }) {
			return api
				.list(filter)
				.then(response => commit('set', response))
				.catch(manageError);
		},
		fetchOne({ commit, dispatch, getters }, id) {
			if (!api.find) {
				return dispatch('fetch', { filter: {} });
			}
			return api
				.find(id)
				.then(resource => commit('update', resource))
				.then(() => getters.find(id))
				.catch(manageError);
		},
		create({ commit, getters }, record) {
			return api
				.add(record)
				.then(newRecord => {
					commit('update', newRecord);
					return getters.find(newRecord.id);
				})
				.catch(manageError);
		},
		update({ dispatch }, record) {
			return api
				.update(record.id, record)
				.then(() => dispatch('fetchOne', record.id))
				.catch(manageError);
		},
		remove({ dispatch }, id) {
			return api
				.remove(id)
				.then(() => dispatch('fetchOne', id))
				.catch(manageError);
		},
		initiateMore() {
			return Promise.resolve();
		},
		setEditorUserList({ commit, rootGetters }, userList) {
			const userId = rootGetters['user/user']?.id;

			// remove first id of the user since that represent this browser window
			const userIndex = userList.findIndex(id => id === userId);
			if (userIndex >= 0) {
				userList.splice(userIndex, 1);
			}

			// only set unique ids
			commit('setEditorUserList', [...new Set(userList)]);
		},
		startEditing({ dispatch }, id) {
			api.connection.socket.emit(EMIT_EDIT_START, id, userList => dispatch('setEditorUserList', userList));
		},
		stopEditing({ dispatch }, id) {
			api.connection.socket.emit(EMIT_EDIT_STOP, id, userList => dispatch('setEditorUserList', userList));
		}
	};

	// Determine which actions to create based on available API methods
	const actions = {
		startEditing: base.startEditing,
		stopEditing: base.stopEditing,
		setEditorUserList: base.setEditorUserList
	};

	if (api.list) {
		actions.ensure = base.ensure;
		actions.initiateMore = base.initiateMore;
		actions.fetch = base.fetch;
		actions.fetchOne = base.fetchOne;
	}
	if (api.add) {
		actions.create = base.create;
	}
	if (api.update) {
		actions.update = base.update;
	}
	if (api.remove) {
		actions.remove = base.remove;
	}

	// No actions were added
	if (!Object.keys(actions)) {
		console.log('API provided no methods compatible with any of the generic actions.' +
			'These API methods are compatible:\n' +
			'- list\n' +
			'- update\n' +
			'- add\n' +
			'- remove\n'
		);
	}

	return actions;
};
