import { DateTime } from 'luxon';
import _ from 'lodash';

import { UPDATE_RESOURCE } from '@/env';

import Connection from '@/js/network/helpers';
import { getScope } from '@/js/auth/auth-config';

import icons from '@/assets/icons.json';
import store from '@/store/store';
import { manageAPIError } from '@/js/api/builder/api-error';
import { listStoreModulesWithMethod } from '@/store/helpers/store-helpers';
import { customMapping, routerPillProps } from '@/js/utils/changelog';

import { icon as lifecycleIcon } from '@/js/plugins/lifecycle';

const idFieldRegex = /(?<fieldName>.*)(?<idAppend>Ids|Id)$|systems/;

const enforceArray = value => {
	return Array.isArray(value) ? value : [value];
};

const parseChangelog = unparsedEntry => {
	const entry = _.keysToCamel(unparsedEntry);

	const { timestamp, commentRevisions, comment } = entry;

	commentRevisions.map(comment => comment.timestamp = DateTime.fromISO(comment.timestamp, { zone: 'utc' }));
	entry.timestamp = DateTime.fromISO(timestamp, { zone: 'utc' });
	entry.comment.timestamp = DateTime.fromISO(comment.timestamp, { zone: 'utc' });
	entry.commentRevisions = commentRevisions;

	return entry;
};

const normalizeArraysAndObjects = objectType => entry => {
	if (objectType.includes('specification')) {
		return entry;
	}

	entry.changes = entry.changes.map(change => {
		const { oldValue, newValue } = change;

		if (Array.isArray(newValue) || Array.isArray(oldValue)) {
			// if change is an array make sure values that haven't changed is not listed
			const oldValueArray = oldValue ?? [];
			const newValueArray = newValue ?? [];
			change.isArrayChange = true;

			const disableChangeEqualMerging = customMapping[objectType]?.disableChangeEqualMerging?.[change.field];
			if (!disableChangeEqualMerging) {
				change.oldValue = _.differenceWith(oldValueArray, newValueArray, _.isEqual);
				change.newValue = _.differenceWith(newValueArray, oldValueArray, _.isEqual);
			}

			return change;
		}

		if (typeof oldValue === 'object' || typeof newValue === 'object') {
			// if change is an object make sure values that haven't changed is not listed
			change.oldValue = oldValue ?? {};
			change.newValue = newValue ?? {};
			change.isObjectChange = true;

			Object.keys(change.oldValue).forEach(key => {
				if (change.oldValue[key] === change.newValue[key]) {
					delete change.oldValue[key];
					delete change.newValue[key];
				}
			});
		}

		return change;
	})
		.filter(({ oldValue, newValue }) => {
			// If mapping has made it apparent no changes has been made, remove entry
			if (Array.isArray(newValue) && Array.isArray(oldValue)) {
				return !!(newValue.length || oldValue.length);
			}

			return (oldValue ?? newValue) != null;
		});

	return entry;
};

const findChangeIdRepresentation = moduleName => change => {
	if (!change) {
		return null;
	}

	const changeObject = store.getters[`${moduleName}/find`](change);

	if (!changeObject) {
		return change;
	}

	const props = routerPillProps(moduleName.slice(0, -1), changeObject);

	if (props) {
		return { representationComponent: 'RouterPill', props };
	}

	return changeObject.name;
};


const mapIdToName = objectType => entry => {
	const idToNameModules = listStoreModulesWithMethod('getters', 'find');
	if (objectType.toLowerCase() === 'reservationwindows') { //  Todo: escape to not map systems in reservatiowindows. Can be removed when KOGS(#417) changelog for dedicated systems return systemIds instead of systems.
		return entry;
	}
	entry.changes = entry.changes
		.map(change => {
			const { oldValue, newValue } = change;
			const idFieldMatch = change.field.match(idFieldRegex);

			// if fields ends with Id/Ids it is a candidate to lookup in the store
			if (idFieldMatch) {
				const { fieldName } = idFieldMatch.groups;

				// The fallback here is a workaround for KOGS not giving same format on dedicated systems see KOGS#689
				// This also applies to the last section of the regex pattern |systems
				// TODO: go back to moduleName = `${fieldName}s` and remove last section in regex
				const moduleName = fieldName ? `${fieldName}s` : idFieldMatch.input;

				// Try to lookup and apply apropirate representation (component, name, ...)
				if (idToNameModules.includes(moduleName)) {
					const representationChange = change => {
						if (Array.isArray(change)) {
							return change.map(findChangeIdRepresentation(moduleName));
						}

						return [findChangeIdRepresentation(moduleName)(change)];
					};

					if (oldValue != null) {
						change.oldValue = representationChange(change.oldValue);
					}

					if (newValue != null) {
						change.newValue = representationChange(change.newValue);
					}
				}
			}

			return change;
		});

	return entry;
};

const extractEntryInformation = entry => {
	entry.lifecycleChange = entry.changes.find(({ field }) => field === 'lifecycleState');

	return entry;
};

const applyCustomRepresentation = objectType => entry => {
	entry.changes = entry.changes.map(change => {
		const { field, oldValue, newValue } = change;
		const representationFunctions = customMapping[objectType]?.representationFunctions?.[field];

		if (representationFunctions) {
			if (oldValue != null) {
				change.oldValue = representationFunctions(oldValue, { newValue });
			}

			if (newValue != null) {
				change.newValue = representationFunctions(newValue, { oldValue });
			}

			const isComponentChange = [...enforceArray(change.oldValue), ...enforceArray(change.newValue)]
				.some(value => value != null && typeof value === 'object' && value.representationComponent);

			if (isComponentChange) {
				delete change.isObjectChange;
			}
		}

		return change;
	});

	return entry;
};

const idFieldName = field => {
	const match = field.match(idFieldRegex);

	if (!match) {
		return field;
	}

	const { fieldName, idAppend } = match.groups;
	return `${fieldName}${idAppend === 'Ids' ? 's' : ''}`;
};

const setCustomFieldNames = objectType => entry => {
	entry.changes = entry.changes.map(change => ({
		...change,
		field: _.startCase(
			customMapping[objectType]?.fieldNames?.[change.field] ||
			idFieldName(change.field) ||
			change.field
		),
		originalField: change.field
	}));

	return entry;
};

const transformCustomFields = objectType => entry => {
	/*
	The extractFields allows us to split up one change into several fields, given that the original change is structured like this:
	{ field: oldField, newValue : { newField1: someValue, newField2: someValue }, oldValue: { newField1: someValue, newField2: someValue }}
	*/
	const extractFields = customMapping[objectType]?.extractFields;

	if (extractFields) {
		Object.entries(extractFields).forEach(([originalField, mapping]) => {
			// Extract the field we want to split up and the new fields. Find index of matching change or return if nothing found
			const index = entry.changes.findIndex(change => change.field === originalField);
			if (index < 0) {
				return;
			}

			const oldEntry = entry.changes[index];
			// Create our new fields in changes
			mapping.newFields.forEach(newField => {
				entry.changes.push({
					field: newField,
					oldValue: oldEntry.oldValue?.[newField] || mapping.fallback,
					newValue: oldEntry.newValue?.[newField] || mapping.fallback
				});
			});

			// Remove the original change
			entry.changes.splice(index, 1);
		});
	}

	return entry;
};
const getSimpleChange = changes => {
	const maxTitleLength = 23; // 23 is length of formatDatetime string from $timeplugin
	if (changes.length !== 1) {
		return false;
	}
	const change = changes[0];

	const newValue = Array.isArray(change.newValue) && change.newValue.length === 1 ? change.newValue[0] : change.newValue;
	const oldValue = Array.isArray(change.oldValue) && change.oldValue.length === 1 ? change.oldValue[0] : change.oldValue;

	if (typeof newValue === 'object' || typeof oldValue === 'object') {
		if (newValue.representationValue != null || oldValue.representationValue != null) {
			return {
				newValue: newValue.representationValue,
				oldValue: oldValue.representationValue
			};
		}

		return false;
	}
	if (Array.isArray(newValue) && newValue.length > maxTitleLength) {
		return false;
	}

	return {
		newValue,
		oldValue
	};
};

const generateTitle = ({ changes, lifecycleChange }, objectType) => {
	// lifecycle change
	if (lifecycleChange) {
		if (lifecycleChange.oldValue == null) {
			return {
				...lifecycleChange,
				type: 'created',
				icon: 'plus'
			};
		}

		return {
			...lifecycleChange,
			type: 'lifecycle',
			icon: lifecycleIcon(lifecycleChange.newValue)
		};
	}

	if (objectType?.includes('specification')) {
		return {
			type: 'specification'
		};
	}

	// Simple update
	const simpleChange = getSimpleChange(changes);
	if (simpleChange) {
		const change = changes[0];

		return {
			...change,
			...simpleChange,
			type: 'simple'
		};
	}

	if (changes.length === 1) {
		const change = changes[0];

		const removed = !change.newValue?.length && change.oldValue?.length;
		const added = change.newValue?.length && !change.oldValue?.length;
		const action = removed ? 'Removed' : added ? 'Added' : 'Updated';

		const match = change.originalField.match(idFieldRegex);
		const icon = typeof icons[match?.groups?.fieldName] === 'string' ? match.groups.fieldName : null;

		return {
			...change,
			type: 'single',
			action,
			icon
		};
	}

	return { type: 'default' };
};

const generateEntryTitle = objectType => entry => {
	entry.title = generateTitle(entry, objectType);

	return entry;
};

const arrayFromChangeObject = change => {
	const combinedKeys = Object.keys(Object.assign({}, change.oldValue, change.newValue));

	return combinedKeys.map(key => ({ field: key, oldValue: change.oldValue[key], newValue: change.newValue[key] }));
};

// breaks a change object up into a seperate entries for each key
const handleObjectChanges = entry => {
	entry.changes = entry.changes.reduce((carried, change) => {
		const changeList = change.isObjectChange ? arrayFromChangeObject(change) : [change];
		return carried.concat(changeList);
	}, []);

	return entry;
};

const sortChanges = entry => {
	entry.changes.sort((a, b) => a.field.localeCompare(b.field));
	return entry;
};

const mapChangeToArray = entry => {
	entry.changes = entry.changes.map(change => {
		const { oldValue, newValue } = change;

		change.oldValue = oldValue == null ? [] : enforceArray(oldValue);
		change.newValue = newValue == null ? [] : enforceArray(newValue);

		return change;
	});

	return entry;
};

export const sortEntries = (a, b) => {
	// Sort entries by timestamp
	const diff = b.timestamp - a.timestamp;
	if (diff) {
		return diff;
	}

	// if equal place lifecycle changes before none-lifecycle changes
	return !!a.lifecycleChange - !!b.lifecycleChange;
};

const mapChangelogEntries = (entries, objectType) => {
	return entries
		.map(parseChangelog)
		.map(transformCustomFields(objectType))
		.map(normalizeArraysAndObjects(objectType))
		.map(mapIdToName(objectType))
		.map(extractEntryInformation)
		.map(applyCustomRepresentation(objectType))
		.map(setCustomFieldNames(objectType))
		.map(generateEntryTitle(objectType))
		.map(handleObjectChanges)
		.map(mapChangeToArray)
		.filter(entry => entry.changes.length)
		.map(sortChanges);
};

const fetchConnection = new Connection('/v1.0', await getScope('kogs')).axios;

export default connections => {
	connections = Array.isArray(connections) ? connections : [connections];
	const defaultChangelogPath = id => `/${id}/changelogs`;

	return {
		fetchChangelogs(context, id) {
			const responsePromises = connections.map(({ connection, objectType, changelogPath }) => {
				const path = changelogPath?.(id) || defaultChangelogPath(id);
				return connection.axios
					.get(path)
					.then(response => ({ entries: response.data.data, objectType }));
			});

			return Promise.all(responsePromises)
				.then(responseList => responseList.map(({ entries, objectType }) => {
					return mapChangelogEntries(entries, objectType);
				})
					.reduce((carried, entries) => carried.concat(entries), [])
					.sort(sortEntries)
				)
				.catch(error => { // Untill KOGS takes care of empty changelog issue. KOGS #554
					console.error(error);
					if (error.response.status === 404) {
						return [];
					}
					manageAPIError(error);
				});
		},
		watchChangelogs(context, callback) {
			connections.forEach(({ connection }) => connection.socket.on(UPDATE_RESOURCE, callback));
		},
		unwatchChangelogs(context, callback) {
			connections.forEach(({ connection }) => connection.socket.off(UPDATE_RESOURCE, callback));
		}
	};
};

export const manuallyFetchChangelogs = (objectType, id) =>
	fetchConnection.get(`${objectType}/${id}/changelogs`)
		.then(response => {
			return mapChangelogEntries(response.data.data, objectType)
				.reduce((carried, entries) => carried.concat(entries), [])
				.sort(sortEntries);
		})
		.catch(error => { // Untill KOGS takes care of empty changelog issue. KOGS #554
			console.error(error);
			if (error.response.status === 404) {
				return [];
			}
			manageAPIError(error);
		});

export const fetchSingleChangelog = (id, objectType) =>
	fetchConnection.get(`/changelogs/${id}`)
		.then(response => {
			const mappedEntry = mapChangelogEntries([response.data], objectType);
			return mappedEntry[0];
		})
		.catch(manageAPIError);


export const fetchSpecificationChangelogs = (objectType, objectId, specificationId) => {
	const pathPrefix = objectType === 'systems' ? 'systems/antennas' : objectType;
	return fetchConnection.get(`${pathPrefix}/${objectId}/specs/${specificationId}/changelogs`)
		.then(response => {
			return mapChangelogEntries(response.data.data, objectType + '-specification')
				.reduce((carried, entries) => carried.concat(entries), [])
				.map(entry => {
					entry.specification = true;
					return entry;
				})
				.sort(sortEntries);
		})
		.catch(error => { // Untill KOGS takes care of empty changelog issue. KOGS #554
			console.error(error);
			if (error.response.status === 404) {
				return [];
			}
			manageAPIError(error);
		});
};
