import Connection from '@/js/network/helpers';
import { getScope } from '@/js/auth/auth-config';
import { manageAPIError } from '@/js/api/builder/api-error';

const connection = new Connection(
	'/v1.0/reservation_windows',
	await getScope('kogs'),
	'reservationWindows'
);

const api = {
	updateDefault: (tenant_id, window) => connection.axios
		.put('/tenants', { window }, { params: { tenant_id }, refetchId: tenant_id })
		.then(response => response.data.data)
		.catch(manageAPIError),

	updateStation: (tenant_id, station_id, window) => connection.axios
		.put('/stations', { window }, { params: { tenant_id, station_id }, refetchId: tenant_id })
		.then(response => response.data.data)
		.catch(manageAPIError),

	removeStation: (tenant_id, station_id) => connection.axios
		.delete('/stations', { params: { tenant_id, station_id }, refetchId: tenant_id })
		.catch(manageAPIError),

	setOverride: (tenant_id, spacecraft_id, system_id, window) => connection.axios
		.put('/overrides', { window }, { params: { tenant_id, spacecraft_id, system_id }, refetchId: tenant_id })
		.then(response => response.data.data)
		.catch(manageAPIError),

	deleteOverride: (tenant_id, spacecraft_id, system_id) => connection.axios
		.delete('/overrides', { params: { tenant_id, spacecraft_id, system_id }, refetchId: tenant_id })
		.catch(manageAPIError),

	setSystemOverride: (tenant_id, system_id, window) => connection.axios
		.put('/systems', { window }, { params: { tenant_id, system_id }, refetchId: tenant_id })
		.then(response => response.data.data)
		.catch(manageAPIError),

	deleteSystemOverride: (tenant_id, system_id) => connection.axios
		.delete('/systems', { params: { tenant_id, system_id }, refetchId: tenant_id })
		.catch(manageAPIError),

	setSpacecraftOverride: (tenant_id, spacecraft_id, window) => connection.axios
		.put('/spacecrafts', { window }, { params: { tenant_id, spacecraft_id }, refetchId: tenant_id })
		.then(response => response.data.data)
		.catch(manageAPIError),

	deleteSpacecraftOverride: (tenant_id, spacecraft_id) => connection.axios
		.delete('/spacecrafts', { params: { tenant_id, spacecraft_id }, refetchId: tenant_id })
		.catch(manageAPIError),

	find: (tenantId) => connection.axios
		.get('/', { params: { tenant_id: tenantId } })
		.then(response => ({ ...response.data.data, tenantId }))
		.catch(manageAPIError)
};

export {
	connection,
	api
};
