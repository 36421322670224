<template lang="pug">
Pill(label="Blank")
</template>

<script>

import Pill from '../SchemaViewPill.vue';

export default {
	name: 'BlankValue',
	components: {
		Pill
	}
};

</script>

<style lang="scss" scoped>
.pill {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}
</style>
