
import { DateTime } from 'luxon';
import { bandsDefinitions, lifecycleStates, spacecraftDefinitions } from '@/js/utils/data-definitions';
import icons from '@/assets/icons.json';
import ContactState from '@/js/classes/contact-state';

// Base schemas used throughout the project
const baseSchemas = [
	joi => ({
		type: 'nameAndId',
		base: joi.object().keys({
			name: joi.string().required(),
			id: joi.string().guid()
				.required()
		})
	}),
	joi => ({
		type: 'position',
		base: joi.object().keys({
			latitude: joi.number().min(-90)
				.max(90)
				.required(),
			longitude: joi.number().min(-180)
				.max(180)
				.required(),
			altitude: joi.number().required()
		})
	}),
	joi => ({
		type: 'lifecycleState',
		base: joi.string(),
		validate(value) {
			if (!Object.values(lifecycleStates).map(({ id }) => id)
				.includes(value)) {
				return { value, error: `${value} not a valid Lifecycle state` };
			}
		}
	}),
	joi => ({
		type: 'band',
		base: joi.object().keys({
			polarization: joi.string().valid(...bandsDefinitions.polarizations.map(({ id }) => id), ''),
			direction: joi.string().valid(...bandsDefinitions.directions.map(({ id }) => id), '')
				.required(),
			type: joi.string().valid(...bandsDefinitions.types.map(({ id }) => id), ''),
			band: joi.string().valid(...bandsDefinitions.types.map(({ id }) => id), '')
		})
	}),
	joi => ({
		type: 'catalogNumberAssignment',
		base: joi.string(),
		validate(value) {
			if (!Object.values(spacecraftDefinitions.catalogNumberAssignments).map(({ id }) => id)
				.includes(value)) {
				return { value, error: `${value} not a valid catalog number assignment` };
			}
		}
	}),
	joi => ({
		type: 'kind',
		base: joi.string(),
		validate(value) {
			if (!Object.values(spacecraftDefinitions.kinds).map(({ id }) => id)
				.includes(value)) {
				return { value, error: `${value} not a valid spacecraft kind` };
			}
		}
	}),
	joi => ({
		type: 'orbit',
		base: joi.string(),
		validate(value) {
			if (!Object.values(spacecraftDefinitions.orbits).map(({ id }) => id)
				.includes(value)) {
				return { value, error: `${value} not a valid orbit` };
			}
		}
	}),
	joi => ({
		type: 'icon',
		base: joi.string(),
		messages: {
			'icon.inFile': '{{#label}} must be at key in the icon json file "@/assets/icons.json"'
		},
		validate(value, helpers) {
			if (!Object.keys(icons).includes(value)) {
				return { value, error: helpers.error('icon.inFile') };
			}
		}
	})
];

// schemas of the objects we get from the api (post mapping)
const apiSchemas = [
	joi => ({
		type: 'spacecraft',
		base: joi.object().keys({
			id: joi.string().guid()
				.required(),
			lifecycleState: joi.lifecycleState().required(),
			name: joi.string().required(),
			satelliteCatalogNumber: joi.number().required(),
			catalogNumberAssignment: joi.catalogNumberAssignment().required(),
			kind: joi.kind().required(),
			orbit: joi.orbit().required()
		})
	}),
	joi => ({
		type: 'tenant',
		base: joi.object().keys({
			description: joi.string().required(),
			id: joi.string().guid()
				.required(),
			lifecycleState: joi.lifecycleState().required(),
			missionProfiles: joi.array().items(joi.string().guid())
				.required(),
			name: joi.string().required(),
			organizationId: joi.string().guid()
				.required(),
			spacecrafts: joi.array().items(joi.string().guid())
				.required(),
			systems: joi.array().items(joi.string().guid())
				.required()
		})
	}),
	joi => ({
		type: 'organization',
		base: joi.object().keys({
			id: joi.string().guid()
				.required(),
			name: joi.string().required(),
			shortName: joi.string().required()
		})
	}),
	joi => ({
		type: 'missionProfile',
		base: joi.object().keys({
			bandServices: joi.array().items(joi.band())
				.required(),
			configUrl: joi.string().uri(),
			description: joi.string().required(),
			id: joi.string().guid()
				.required(),
			lifecycleState: joi.lifecycleState().required(),
			name: joi.string().required(),
			setupDuration: joi.number().required(),
			spacecrafts: joi.array().items(joi.string().guid())
				.required(),
			systems: joi.array().items(joi.string().guid())
				.required(),
			tags: joi.array().items(joi.string()),
			teardownDuration: joi.number().required(),
			tenant: joi.string().guid()
				.required()
		})
	}),
	joi => ({
		type: 'system',
		base: joi.object().keys({
			bands: joi.array().items(joi.band()),
			diameter: joi.number().required(),
			id: joi.string().guid()
				.required(),
			lifecycleState: joi.lifecycleState().required(),
			location: joi.position().required(),
			name: joi.string().required(),
			opsUnit: joi.string().guid()
				.required(),
			partner: joi.boolean().required(),
			setupDuration: joi.number().required(),
			station: joi.string().guid()
				.required(),
			teardownDuration: joi.number().required()
		})
	}),
	joi => ({
		type: 'contact',
		base: joi.object().keys({
			createdAt: joi.object().instance(DateTime),
			endTime: joi.object().instance(DateTime)
				.required(),
			id: joi.string().guid(),
			missionProfile: joi.string().guid()
				.required(),
			spacecraft: joi.string().guid()
				.required(),
			startTime: joi.object().instance(DateTime)
				.required(),
			state: joi.string(), // TODO define valid states
			system: joi.string().guid()
				.required()
		})
	}),
	joi => ({
		type: 'station',
		base: joi.object().keys({
			id: joi.string().guid(),
			location: joi.position().required(),
			name: joi.string().required(),
			lifecycleState: joi.lifecycleState()
		})
	}),
	joi => ({
		type: 'allotment',
		base: joi.object().keys({
			endTime: joi.object().instance(DateTime)
				.required(),
			id: joi.string().guid(),
			lifecycleState: joi.string().required(),
			startTime: joi.object().instance(DateTime)
				.required(),
			systemId: joi.string().guid()
				.required()
		})
	}),
	joi => ({
		type: 'apiKey',
		base: joi.object().keys({
			label: joi.string().required(),
			owner: joi.object({
				kind: joi.string().required(),
				ownerId: joi.string().guid()
					.required()
			}).required(),
			prefix: joi.string().required(),
			principalId: joi.string().guid()
				.required(),
			revoked: joi.object({
				revokedBy: joi.string().guid()
					.required(),
				revokedAt: joi.object().instance(DateTime)
			}).allow(null)
		})
	}),
	joi => ({
		type: 'opsUnit',
		base: joi.object().keys({
			id: joi.string().guid()
				.required(),
			name: joi.string().required(),
			lifecycleState: joi.lifecycleState().required(),
			description: joi.string().required()
		})
	}),
	joi => ({
		type: 'operator',
		base: joi.object().keys({
			id: joi.string().guid()
				.required(),
			name: joi.string().required()
		})
	}),
	joi => ({
		type: 'reservationWindow',
		base: joi.object().keys({
			default: joi.number(),
			overrides: joi.array().items(joi.object({
				spacecraft: joi.string().guid()
					.required(),
				system: joi.string().guid()
					.required(),
				window: joi.number().required()
			})),
			stations: joi.array().items(joi.object({
				station: joi.string().guid()
					.required(),
				window: joi.number().required()
			})),
			tenant: joi.string().guid(),
			tenantId: joi.string().guid()
		})
	}),
	joi => ({
		type: 'changelogEntry',
		base: joi.object().keys({
			id: joi.string().guid()
				.required(),
			principalId: joi.string().guid()
				.required(),
			comment: joi.object({
				principalId: joi.string().guid()
					.required(),
				timestamp: joi.object().instance(DateTime)
					.required(),
				text: joi.string()
			}),
			commentRevisions: joi.array().items(joi.object({
				principalId: joi.string().guid()
					.required(),
				timestamp: joi.object().instance(DateTime)
					.required(),
				text: joi.string()
			})),
			timestamp: joi.object().instance(DateTime),
			changes: joi.array().items(joi.object({
				field: joi.string()
			}))
		})
	}),
	joi => ({
		type: 'ephemeris',
		base: joi.object().keys({
			id: joi.string().guid()
				.required(),
			submittedBy: joi.string().guid()
				.required(),
			origin: joi.string().valid('EXTERNAL', 'INTERNAL', 'SPACETRACK'),
			kind: joi.string().valid('TLE', 'OMM_TLE', 'OEM'),
			epoch: joi.object().instance(DateTime),
			submittedAt: joi.object().instance(DateTime),
			inline: joi.object().keys({
				tle: joi.string() // Can also be omm/oem objects, unimplemented and untested
			})
				.allow(null)
		})
	})
];

// More fleshed out schemas, depending on either baseSchemas or apiSchemas
const extendedSchemas = [
	joi => ({
		// TODO rename to contact when 'joi.contact' is not in use anymore
		type: 'extendedContact',
		base: joi.object().keys({
			createdAt: joi.object().instance(DateTime),
			duration: joi.number().required(),
			durationPadded: joi.number().required(),
			endTime: joi.object().instance(DateTime)
				.required(),
			endTimePadded: joi.object().instance(DateTime)
				.required(),
			id: joi.string().guid(),
			extendedState: joi.object().instance(ContactState)
				.allow(null),
			missionProfile: joi.object().required(), // TODO: use api-model
			setupDuration: joi.number().required(),
			spacecraft: joi.object().required(), // TODO: use api-model
			startTime: joi.object().instance(DateTime)
				.required(),
			startTimePadded: joi.object().instance(DateTime)
				.required(),
			state: joi.lifecycleState(),
			system: joi.system().required(),
			teardownDuration: joi.number().required(),
			tenant: joi.object().required() // TODO: use api-model
		})
	}),
	joi => ({
		type: 'extendedScheduleElement',
		base: joi.object().keys({
			duration: joi.number().required(),
			durationPadded: joi.number().required(),
			endTime: joi.object().instance(DateTime)
				.required(),
			endTimePadded: joi.object().instance(DateTime)
				.required(),
			id: joi.string().guid(),
			setupDuration: joi.number().required(),
			startTime: joi.object().instance(DateTime)
				.required(),
			startTimePadded: joi.object().instance(DateTime)
				.required(),
			system: joi.system().required(),
			teardownDuration: joi.number().required()
		})
	}),
	joi => ({
		type: 'timeWindow',
		base: joi.object().keys({
			duration: joi.number().required(),
			startTime: joi.object().instance(DateTime)
				.required(),
			endTime: joi.object().instance(DateTime)
				.required()
		})
	}),
	joi => ({
		type: 'systemSchedule',
		base: joi.object().keys({
			alternatives: joi.array().items(joi.extendedContact()),
			conflictLevel: joi.number(),
			conflicts: joi.array().items(joi.timeWindow()),
			contact: joi.extendedContact(),
			schedule: joi.array().items(joi.extendedScheduleElement()),
			system: joi.system()
		})
	}),
	joi => ({
		type: 'contactCart',
		base: joi.object().keys({
			createdContacts: joi.array().items(joi.extendedContact()),
			cancelledContacts: joi.array().items(joi.extendedContact()),
			trimmedContacts: joi.array().items(joi.extendedContact()),
			movedContacts: joi.array().items(joi.extendedContact())
		})
	})
];

export { baseSchemas, apiSchemas, extendedSchemas };
