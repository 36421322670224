<template lang="pug">
.group
	.name(v-if="hasName") {{name}}

	component(
		v-for="{ component, props } in children"
		:is="component"
		v-bind="props"
	)
</template>

<script>

import Item from './Item.vue';

export default {
	name: 'NavigationGroup',
	props: {
		group: {
			type: Object,
			required: true
		},
		parentPath: {
			type: String,
			required: false,
			default: '/styleguide'
		}
	},
	computed: {
		name() {
			return this.group.name;
		},
		hasName() {
			return typeof this.name === 'string' && this.name.length > 0;
		},
		path() {
			return [this.parentPath, this.group.path]
				.join('/')
				.replace(/\/+/g, '/');
		},
		children() {
			return this.group.pages.map((item) => {
				if (item.type === 'group') {
					return {
						component: 'NavigationGroup',
						props: {
							group: item,
							parentPath: this.path
						}
					};
				}

				return {
					component: Item,
					props: {
						item,
						parentPath: this.path
					}
				};
			});
		}
	}
};

</script>

<style lang="scss" scoped>
.group {
	&:not(:first-child) {
		margin-top: rem-calc(30);
	}

	.name {
		text-transform: uppercase;
		font-size: rem-calc(12);
		font-weight: 500;
		margin-bottom: rem-calc(4);
		color: hsl(var(--white40));
	}
}
</style>
