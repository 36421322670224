import { isNil } from 'lodash';

import dig from '@/js/utils/dig';
import { getAllProperties } from './properties';
import { defaultValue } from './value';
import { isOneOfConst } from './types';

export const createObject = (schema, otherObject = {}) => {
	if (isOneOfConst(schema)) {
		return '';
	}

	switch (schema.type) {
		case 'array':
			return [];
		case 'string':
			return '';
		case 'object':
			return getAllProperties(schema, otherObject)
				.reduce((object, property) => {
					const type = property.subschema.type;
					let value = dig(otherObject, [property.id]);

					if (type === 'object') {
						value = createObject(property.subschema, value ?? {});
					} else if (isNil(value)) {
						value = defaultValue(property.subschema);
					}

					return {
						...object,
						[property.id]: value
					};
				}, {});
		case 'integer':
			return null;
		default:
			throw `createObject: Type '${schema.type}' not handled`;
	}
};
