<template lang="pug">
.callout(:class="[type, { sticky: isSticky }]")
	.callout-icon
		VIcon(:name="iconName")
	span.message
		slot
</template>

<script>
export default {
	name: 'VCallout',
	props: {
		type: {
			type: String,
			required: false,
			default: 'info'
		},
		isSticky: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		iconName() {
			const info = 'info';

			switch (this.type) {
				case 'info': return info;
				case 'warning': return 'danger.circle';
				case 'alert': return 'danger.triangle';
				case 'success': return 'success';
				default: return info;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.callout {
	display: flex;
	align-items: center;
	background-color: hsl(var(--dark-purple110));
	border: 1px solid transparent;
	border-radius: rem-calc(3);
	margin-bottom: rem-calc(10);
	text-shadow: 0 1px 2px hsl(0 0% 0% / 50%);

	@mixin colors($color) {
		border-color: hsl($color / 75%);
		background-image: linear-gradient(90deg, hsl($color / 10%), hsl($color / 0%));
		filter: drop-shadow(0 0 8px hsl($color / 20%));

		&:before {
			border-bottom-color: hsl($color / 75%);
		}

		#{v-deep('.icon')} {
			.stroke-primary {
				stroke: hsl($color);
			}

			.fill-primary {
				fill: hsl($color);
			}
		}
	}

	&.info {
		@include colors(var(--info-color));
	}

	&.alert {
		@include colors(var(--alert-color));
	}

	&.warning {
		@include colors(var(--warn-color));
	}

	&.success {
		@include colors(var(--success-color));
	}

	.callout-icon {
		display: flex;
		align-items: center;
		padding: rem-calc(10);
	}

	#{v-deep('.icon')} {
		.stroke-primary {
			stroke: hsl(var(--info-color));
		}
	}

	.message {
		padding: rem-calc(10 10 10 0);
		line-height: 1.2;

		#{v-deep('p')} {
			margin-bottom: rem-calc(6);
		}

		#{v-deep('ul')} {
			padding-left: rem-calc(14);

			li {
				line-height: 1.4;
			}
		}
	}
}

.sticky {
	$arrow-position: rem-calc(20);
	$arrow-size: rem-calc(16);

	position: absolute;
	top: 100%;
	left: 0;
	margin-top: rem-calc(10);
	z-index: 1;

	&:before {
		content: '';
		display: block;
		position: absolute;
		bottom: 100%;
		right: $arrow-position;
		width: 0;
		height: 0;
		border-left: ($arrow-size * 0.5) solid transparent;
		border-right: ($arrow-size * 0.5) solid transparent;
		border-bottom: ($arrow-size * 0.5) solid transparent;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 100%;
		right: calc(#{$arrow-position} + #{rem-calc(2)});
		width: 0;
		height: 0;
		border-left: (($arrow-size - rem-calc(4)) * 0.5) solid transparent;
		border-right: (($arrow-size - rem-calc(4)) * 0.5) solid transparent;
		border-bottom: (($arrow-size - rem-calc(4)) * 0.5) solid hsl(var(--dark-purple110));
	}
}
</style>
