<template lang="pug">
.wrapper
	VCallout(
		v-if="numberOfEditors > 0"
		type="warning"
	)
		template(
			v-for="(principal, index) in editorUserList"
			:key="index"
		)
			span(v-if="numberOfEditors > 1 && index + 1 === numberOfEditors") {{ ' and ' }}
			span(v-else) {{ ' ' }}
			span(v-if="isYou(principal)") You (in another browser window)
			VUser(
				v-else
				:principal="principal"
				:showIcon="false"
			)
		| {{ numberOfEditors > 1 || isYou(editorUserList[0]) ? ' are ' : ' is '  }} also editing this resource at the moment. Conflicts may occur.
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'EditWarning',
	props: {
		editorUserList: {
			type: Array,
			required: false,
			default: () => []
		},
		resourceName: {
			type: String,
			required: false,
			default: 'this resource'
		}
	},
	computed: {
		numberOfEditors() {
			return this.editorUserList.length;
		},
		...mapGetters({
			isYou: 'principals/isYou'
		})
	}
};
</script>

<style lang="scss" scoped>
.wrapper {
	position: fixed;
	display: block;
	bottom: 0;
}
</style>
