import { cloneDeep } from 'lodash';
import { createFilter } from '@/js/utils/filter';
import { toDateTime } from '@/js/utils/filter/types';

import dig from '@/js/utils/dig';

export * from './signature/actions';

const notHumanOverride = createFilter({
	'input.componentName': { not: 'HUMAN_OVERRIDE' }
});

const isHumanOverride = createFilter({
	'input.componentName': 'HUMAN_OVERRIDE'
});

export const evaluationOutcomeForced = (signature, options = {}) => {
	const {
		evaluationIndex = 0
	} = options;

	if ((signature.evaluations.length - 1) < evaluationIndex) {
		return false;
	}

	const evaluation = signature.evaluations[evaluationIndex];
	const submissionIds = evaluation.submissionsUsed.map(({ submissionId }) => {
		return submissionId;
	});
	const submissions = signature.submissions.filter(createFilter({
		id: { in: submissionIds }
	}));

	return submissions.some((submission) => {
		return dig(submission, ['input', 'payload', 'kind']) === 'FORCE';
	});
};

export const groupSubmissionsByComponent = (signature, options = {}) => {
	const {
		profile,
		components,
		evaluationIndex = 0
	} = options;

	// Find ids for the submissions used by the evaluation at evaluationIndex
	const evaluation = signature?.evaluations[evaluationIndex];
	const evaluatedAt = toDateTime(evaluation?.evaluatedAt ?? new Date());
	const submissionIds = (evaluation?.submissionsUsed ?? []).map(({ submissionId }) => submissionId);

	// Prepare submissions of different types
	const allSubmissions = signature?.submissions || [];
	const humanOverrides = allSubmissions
		.filter(isHumanOverride)
		.filter(createFilter({
			submittedAt: { lte: evaluatedAt }
		}));
	const submissionsUsed = allSubmissions
		.filter(notHumanOverride)
		.filter(createFilter({ id: { in: submissionIds } }));

	// Create empty groups for required components
	const requiredComponentGroups = profileComponentGroups(profile, { components });

	// With the required components we iterate submissons used, and replace the
	// empty ones with groups populated with submissions and any overrides
	return submissionsUsed
		.reduce(
			(groups, submission) => {
				const profilePart = profile.parts.find((part) => {
					return components.find(createFilter({ id: part.componentId }))?.kind === submission.input.componentName &&
						part.submissionName === submission.input.submissionName;
				});

				const component = {
					componentName: submission.input.componentName,
					submissionName: submission.input.submissionName,
					disambiguationId: submission.input.disambiguationId,
					required: profilePart?.inputRequired === true
				};

				// Find submissions for the same component that is not the current one
				// OR more recent
				const pastSubmissions = allSubmissions.filter(createFilter({
					id: { not: submission.id },
					'input.componentName': submission.input.componentName,
					'input.submissionName': submission.input.submissionName,
					'input.disambiguationId': submission.input.disambiguationId,
					submittedAt: { lt: toDateTime(submission.submittedAt) }
				}));

				// For each submission: Create an entry for each evaluation of that
				// submission
				const submissions = [
					submission,
					...pastSubmissions
				]
					.flatMap((submission) => {
						return submission.evaluations.map((_, i) => {
							return submissionEntry(submission, {
								evaluationIndex: i,
								humanOverrides
							});
						});
					})
					.sort((a, b) => {
						return toDateTime(b.submittedAt).toMillis() - toDateTime(a.submittedAt).toMillis();
					});

				return [
					...groups.filter((other) => {
						if (other.componentName !== component.componentName) {
							return true;
						}

						// We treat empty groups like part of the profile, and ignpre disambiguationId
						if (other.submissions.length === 0) {
							return other.submissionName !== component.submissionName;
						}

						return other.submissionName !== component.submissionName ||
							other.disambiguationId !== component.disambiguationId;
					}),
					{
						...component,
						submissions
					}
				];
			},
			requiredComponentGroups
		)
		.sort((a, b) => {
			return a.componentName.localeCompare(b.componentName);
		});
};

const profileComponentGroups = (profile, { components }) => {
	return profile.parts
		.map(({ componentId, submissionName, inputRequired }) => {
			const component = components.find(createFilter({ id: componentId }));

			return {
				componentName: component.kind,
				submissionName,
				disambiguationId: '',
				required: inputRequired,
				submissions: []
			};
		})
		.filter(createFilter({
			componentName: { not: 'HUMAN_OVERRIDE' }
		}));
};

const submissionEntry = (submission, options) => {
	const {
		humanOverrides,
		evaluationIndex = 0
	} = options;


	const evaluation = submission.evaluations[evaluationIndex] ?? null;
	const attemptId = dig(submission, [`evaluations[${evaluationIndex}]`, 'attemptId']);

	const relevantOverrides = humanOverrides
		.map((humanOverride) => {
			let next = cloneDeep(humanOverride);
			const entries = dig(next, ['input','payload','acknowledgeList','acknowledge']);

			if (entries !== null) {
				next.input.payload.acknowledgeList.acknowledge = entries.filter(createFilter({
					'identityReference.attemptId': attemptId
				}));
			}

			return next;
		})
		.filter((humanOverride) => {
			const entries = dig(humanOverride, ['input','payload','acknowledgeList','acknowledge']) ?? [];
			return entries.length > 0;
		})
		.sort((a, b) => {
			return toDateTime(a.submittedAt).toMillis() - toDateTime(b.submittedAt).toMillis();
		})
		.filter((humanOverride, i, humanOverrides) => {
			const isSameEntry = createFilter(humanOverride.input.payload.acknowledgeList.acknowledge[0]);
			const firstIndex = humanOverrides.findIndex((humanOverride) => {
				return isSameEntry(humanOverride.input.payload.acknowledgeList.acknowledge[0]);
			});

			return i === firstIndex;
		})
		.reverse();

	return {
		id: submission.id,
		submittedBy: submission.submittedBy,
		submittedAt: submission.submittedAt,
		payload: submission.input.payload,
		evaluation,
		humanOverrides: relevantOverrides
	};
};
