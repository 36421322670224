<template lang="pug">
header
	h1
		.title Signature profile
		.profile-name {{displayName}}

.components
	h2 Components
	ul
		li(
			v-for="{ name, description, required } in components"
		)
			.name {{name}}
			.description
				VTooltip(:arrow="false")
					VIcon(name="help")

					template(#content)
						.name {{name}}
						p {{description}}
			.type
				template(v-if="required")
					VIcon(name="danger.circle")
					span Required
				template(v-else)
					span Optional
</template>

<script>

import delegate from '@/js/utils/delegate';

export default {
	name: 'PickerDetails',
	props: {
		signatureProfile: {
			type: Object,
			required: true
		}
	},
	computed: {
		...delegate('id', 'displayName', 'parts', { to: 'signatureProfile' }),
		components() {
			return this.parts.map(({ componentId, inputRequired }) => {
				const {
					kind,
					description
				} = this.$store.getters['signatureComponents/find'](componentId);

				return {
					name: this.$text.toSentenceCase(kind.replace(/KS3_/, '')),
					description,
					required: inputRequired
				};
			});
		}
	}
};

</script>

<style lang="scss" scoped>
header {
	margin-bottom: rem-calc(30);

	h1 {
		display: block;
		margin: 0;

		.title {
			font-size: rem-calc(23);
			font-weight: 600;
			margin-bottom: rem-calc(3);
			color: hsl(var(--white60));
		}

		.profile-name {
			font-size: rem-calc(30);
		}
	}
}

.components {
	h2,
	li {
		&:not(:last-child) {
			border-bottom: rem-calc(1) solid hsl(var(--white15));
		}
	}

	h2 {
		margin: 0;
		padding-bottom: rem-calc(3);
		font-size: rem-calc(14.5);
		font-weight: 400;
		color: hsl(var(--white60));
	}

	ul {
		list-style: none;
	}

	li {
		--icon-color-primary: hsl(var(--white60));

		display: flex;
		padding: rem-calc(12.5) 0;
		color: var(--icon-color-primary);

		.name {
			flex: 0 0 auto;
			font-size: rem-calc(17);
			font-weight: 600;
			color: hsl(var(--white));
		}

		.description {
			flex: 0 0 auto;
			margin-left: rem-calc(5);
			font-size: rem-calc(14.5);
			color: hsl(var(--white60));
			line-height: 1.25;
		}

		#{v-deep('.description .popper')} {
			width: rem-calc(280);
			display: block;
			padding: rem-calc(10);
		}

		#{v-deep('.description .tooltip')} {
			display: block;

			h3 {
				font-size: rem-calc(17);
				font-weight: 600;
				margin: 0 0 rem-calc(5);
			}

			p {
				margin: 0;
			}
		}

		.type {
			flex: 0 1 100%;
			display: flex;
			justify-content: flex-end;
			margin-left: rem-calc(5);

			span:not(:first-child) {
				margin-left: rem-calc(5);
			}
		}
	}
}
</style>
