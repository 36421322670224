import dig from '@/js/utils/dig';
import { isObject, isString } from 'lodash';

export const isRef = (definition) => {
	if (!isObject(definition)) {
		return false;
	}

	return Object.keys(definition).length === 1 &&
		Object.keys(definition).includes('$ref');
};

export const getRef = (definition) => {
	if (isString(definition)) {
		return definition.split('/').at(-1);
	} else if (isRef(definition)) {
		return getRef(definition.$ref);
	}

	return null;
};

export const expandRef = (schema, definition) => {
	if (!isRef(definition)) {
		return definition;
	}

	return expandRef(
		schema,
		dig(schema, ['definitions', getRef(definition)])
	);
};
