<template lang="pug">
Popper(
	ref="popper"
	arrow
	@click="onClick"
)
	button.opener(type="button")
		VIcon(v-if="label == null" name="meatballs")
		.label(v-else) {{ label }}

	template(#content)
		ul.list(@click="close")
			slot
</template>

<script>

import Popper from 'vue3-popper';

export default {
	name: 'DropdownList',
	components: {
		Popper
	},
	props: {
		label: {
			type: String,
			required: false,
			default: null
		}
	},
	methods: {
		close() {
			this.$refs.popper?.$refs?.triggerNode?.click();
		},
		onClick(e) {
			// Stop event propagation by default. If click handler is attached, leave it to the parent to decide.
			if (this.$attrs && this.$attrs.onClick !== undefined) {
				return;
			}
			e.stopPropagation();
		}
	}
};
</script>

<style lang="scss" scoped>
.opener {
	--opener-color: hsl(var(--white50));

	appearance: none;
	margin: 0;
	display: inline-block;
	line-height: 1;
	vertical-align: middle;
	outline: none;
	border: 0;
	background: transparent;
	padding: 5px;
	cursor: pointer;

	.icon {
		width: 20px;
		height: 20px;

		#{v-deep('.fill-primary')} {
			fill: var(--opener-color);
		}

		#{v-deep('.stroke-primary')} {
			stroke: var(--opener-color);
		}
	}

	&:focus,
	&:hover {
		--opener-color: hsl(var(--white));
	}
}

.label {
	color: hsl(var(--white));
	height: calc(var(--input-height));
	padding: 0.75rem;
	border: 1px solid hsl(var(--white20));
	border-radius: var(--global-radius);

	&:hover {
		border-color: hsl(var(--white40));
	}
}

ul.list {
	list-style: none;
}

#{v-deep('.popper')} {
	min-width: var(--dropdown-list-width, rem-calc(130));
	padding: rem-calc(6) 0;
	z-index: 1;
}

#{v-deep('.popper > #arrow')} {
	left: 50%;
	transform: translateX(-50%);
}
</style>
