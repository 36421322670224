const KOGNI_UNIQUE_TAG_ID_PREFIX = 'KOGNI_UNIQUE_TAG_ID_PREFIX';

function* generator() {
	let i = 1;

	while (true) {
		yield i;
		i++;
	}
}

const idGenerator = generator();

const generateId = () => idGenerator.next().value;
const generateTagId = () => `${KOGNI_UNIQUE_TAG_ID_PREFIX}_${generateId()}`;

export default generator;

export { generateTagId, generateId };
