import axios from 'axios';
import { manageError } from '@/store/helpers/store-error';

export default {
	namespaced: true,
	state: () => ({
		initiated: false,
		missionSetup: '',
		contact: '',
		signatures: ''
	}),
	getters: {
		missionSetup(state) {
			return state.missionSetup;
		},
		contact(state) {
			return state.contact;
		},
		signatures(state) {
			return state.signatures;
		}
	},
	mutations: {
		initiate(state) {
			state.initiated = true;
		},
		setmissionSetup(state, missionSetup) {
			state.missionSetup = missionSetup;
		},
		setContact(state, contact) {
			state.contact = contact;
		},
		setSignatures(state, signatures) {
			state.signatures = signatures;
		}
	},
	actions: {
		ensure({ dispatch, commit, state }) {
			return state.initiated
				? null
				: dispatch('fetch')
					.then(() => commit('initiate'))
					.catch(manageError);
		},
		fetch({ commit }) {
			const promises = [
				axios.get('/howto-missionsetup.md'),
				axios.get('/howto-contact.md'),
				axios.get('/howto-signatures.md')
			];

			return Promise
				.all(promises)
				.then(([missionSetup, contact, signatures]) => {
					commit('setmissionSetup', missionSetup.data);
					commit('setContact', contact.data);
					commit('setSignatures', signatures.data);
				})
				.catch(manageError);
		}
	}
};
