<template lang="pug">
.fraction
	sup {{nominator}}
	| {{"&frasl;"}}
	sub {{denominator}}
</template>

<script>

import delegate from '@/js/utils/delegate';

export default {
	name: 'FractionValue',
	props: {
		value: {
			type: Object,
			required: true
		}
	},
	computed: {
		...delegate('nominator', 'denominator', { to: 'value' })
	}
};

</script>
