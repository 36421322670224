<template lang="pug">
.json-schema-view
	Property(
		v-for="item in items"
		v-bind="item.props"
	)
</template>

<script>

import { computed } from 'vue';

import { getAllProperties } from '@/js/utils/json-schema';

import Property from './Property/SchemaViewProperty.vue';

export default {
	name: 'JsonSchemaView',
	components: {
		Property
	},
	inject: {
		parentLevel: {
			from: 'level',
			default: -1
		}
	},
	provide() {
		return {
			level: computed(() => {
				return this.level;
			})
		};
	},
	props: {
		object: {
			type: Object,
			required: true
		},
		schema: {
			type: Object,
			required: true
		}
	},
	computed: {
		level() {
			return this.parentLevel + 1;
		},
		items() {
			return getAllProperties(this.schema, this.object)
				.map(({ id, subschema }) => {
					return {
						props: {
							id,
							value: this.object[id],
							schema: subschema
						}
					};
				});
		}
	}
};

</script>
