
import _ from 'lodash';

import { manageError } from '@/store/helpers/store-error';
import { builder as systemBuilder } from './systems';

const systemApi = { ...systemBuilder.build(['list']) };

const filter = { migrated: true };
const updateInterval = 5 * 60;

export default {
	namespaced: true,
	state: () => ({
		migratedSystems: [],
		allSystems: []
	}),
	getters: {
		systems: state => _.sortByAlphaNum(state.migratedSystems),
		systemIsMigrated: state => system => {
			if (typeof system === 'object') {
				system = system.id;
			}
			return !!state.migratedSystems.find(migratedSystem => migratedSystem.id === system);
		},
		unmigratedSystems: state => _.sortByAlphaNum(state.allSystems
			.filter(system => !state.migratedSystems
				.some(migratedSys => migratedSys.id === system.id)))
	},
	mutations: {
		setSystems(state, systems) {
			state.migratedSystems = systems;
		},
		setAllSystems(state, systems) {
			state.allSystems = systems;
		}
	},
	actions: {
		async fetchAssets({ dispatch }) {
			await Promise.all([
				dispatch('fetchSystems'),
				dispatch('fetchAllSystems')
			]);
			setTimeout(() => dispatch('fetchAssets'), 1000 * updateInterval);
		},
		fetchSystems({ commit }) {
			return systemApi.list(filter)
				.then(response => commit('setSystems', response))
				.catch(manageError);
		},
		fetchAllSystems({ commit }) {
			return systemApi.list()
				.then(response => commit('setAllSystems', response))
				.catch(manageError);
		}
	}
};
