<template lang="pug">
header
	a.back(
		href="#"
		@click.prevent="didClickBack"
	)
		VIcon(name="arrow/left.simple")
		span {{back}}
	.title
		.name
			h2 {{title}}
		.details
			SubmissionDetails(
				v-if="details"
				:items="details"
			)
.object-wrapper
	pre.object {{formattedObject}}
</template>

<script>
import { underscoreKeys } from '@/js/utils/case-conversion';

import { SubmissionDetails, SubmissionDetailSignedBy }  from './SubmissionDetails';

export default {
	name: 'ObjectInspector',
	components: {
		SubmissionDetails,
		SubmissionDetailSignedBy
	},
	inject: [
		'$stack'
	],
	props: {
		title: {
			type: String,
			required: true
		},
		back: {
			type: String,
			required: true
		},
		object: {
			type: Object,
			required: true
		},
		details: {
			type: [Array, Object],
			required: false,
			default() {
				return [];
			}
		}
	},
	computed: {
		formattedObject() {
			return underscoreKeys(this.object);
		}
	},
	methods: {
		didClickBack() {
			this.$stack.pop();
		}
	}
};

</script>
<style lang="scss" scoped>
.title {
	display: flex;
	align-items: center;

	.name {
		padding-right: rem-calc(20);
		#{v-deep('h2')} {
			margin: 1rem 0;
		}
	}

	.details {
		margin: 0.35rem 0 0;
	}
}

.back {
	--back-button-color: hsl(var(--white70));
	--icon-color-primary: var(--back-button-color);
	--icon-size: #{rem-calc(24)};

	display: inline-flex;
	align-items: center;
	color: var(--back-button-color);
	text-decoration: none;

	.label {
		font-size: rem-calc(14.5);
	}
}

.object-wrapper {

	overflow: auto;
	max-height: 800px;
	padding: 20px;
	background-color: rgba(#000, 0.25);
	border: rem-calc(1) solid hsl(var(--white15));
	border-radius: rem-calc(8);

	@include scrollbars(0.5rem, hsl(var(--white20)));

	pre {
		font-family: var(--font-family-mono);
		font-size: rem-calc(13);
		font-weight: 600;
		line-height: 1.25;
	}
}
</style>
