<template lang="pug">
.evaluation
	OutcomeIcon(
		:outcome="outcome"
		:isManual="isForced"
	)

	.content
		SubmissionDetails(:items="details")
		ul.comments
			li.comment(
				v-for="comment in comments"
				:key="comment"
			) {{ comment.comment }}
		IssueCategories(:ids="issueCategories")

	.actions
		slot(name="actions")
</template>

<script>

import delegate from '@/js/utils/delegate';

import evaluationPropsMixin from '../../lib/mixins/evaluation-props';

import IssueCategories from '../../IssueCategories.vue';
import OutcomeIcon from '../../OutcomeIcon.vue';
import { SubmissionDetails, SubmissionDetailSignedBy }  from '../../SubmissionDetails/index';

export default {
	name: 'EvaluationDetails',
	components: {
		IssueCategories,
		OutcomeIcon,
		SubmissionDetails
	},
	mixins: [
		evaluationPropsMixin()
	],
	inject: [
		'$signContactModal'
	],
	props: {
		evaluation: {
			type: Object,
			required: true
		}
	},
	computed: {
		...delegate('issueCategories', { to: 'evaluation' }),
		...delegate('signature', { to: '$signContactModal' }),
		title() {
			if (!this.hasEvaluatedAt) {
				return this.$text.toSentenceCase(this.outcome);
			}

			return `${this.$text.toSentenceCase(this.outcome)} at ${this.evaluatedAt}`;
		},
		containerClasses() {
			return [
				this.$text.toKebabCase(this.outcome)
			];
		},
		details() {
			let items = [
				{
					props: {
						text: this.title
					}
				}
			];

			if (this.isForced || this.isElaborated) {
				items.push({
					component: SubmissionDetailSignedBy,
					props: {
						principalId: this.principalId,
						isElaborated: this.isElaborated
					}
				});
			}

			return items;
		}
	}
};

</script>

<style lang="scss" scoped>
.evaluation {
	display: flex;

	.state-icon,
	.content,
	.actions {
		flex: 0 0 auto;

		&:not(:last-child) {
			margin-right: rem-calc(13);
		}
	}

	.content {
		flex: 0 1 100%;

		#{v-deep('.detail')} {
			font-size: rem-calc(14.5);
		}

		.comments {
			list-style: disc inside;

			&:not(:last-child) {
				margin-bottom: rem-calc(15);
			}
		}

		.comment {
			font-size: rem-calc(14.5);
			line-height: 1.2;

			&:not(:last-child) {
				margin-bottom: rem-calc(8);
			}
		}
	}
}
</style>
