import { defineAsyncComponent } from 'vue';

import typeFor from './type-for';

import BlankPropertyValue from '../Value/Blank.vue';
import BooleanPropertyValue from '../Value/Boolean.vue';
import FractionPropertyValue from '../Value/Fraction.vue';
import IntegerPropertyValue from '../Value/Integer.vue';
import MeasurementPropertyValue from '../Value/Measurement.vue';
import NumberPropertyValue from '../Value/Number.vue';
import RawPropertyValue from '../Value/Raw.vue';
import StringPropertyValue from '../Value/String.vue';
import TravelRangePropertyValue from '../Value/TravelRange.vue';

const ArrayPropertyValue = defineAsyncComponent(() => {
	return import('../Value/Array.vue');
});

const ObjectPropertyValue = defineAsyncComponent(() => {
	return import('../Value/Object.vue');
});

export default (schema) => {
	const type = typeFor(schema);

	switch (type) {
		case 'array':
			return ArrayPropertyValue;
		case 'blank':
			return BlankPropertyValue;
		case 'boolean':
			return BooleanPropertyValue;
		case 'integer':
			return IntegerPropertyValue;
		case 'fraction':
			return FractionPropertyValue;
		case 'measurement':
			return MeasurementPropertyValue;
		case 'number':
			return NumberPropertyValue;
		case 'object':
			return ObjectPropertyValue;
		case 'string':
			return StringPropertyValue;
		case 'travel_range':
			return TravelRangePropertyValue;
		default:
			console.warn(`Unknown type '${type}' encountered.`);
			console.trace();
			return RawPropertyValue;
	}
};
