<template lang="pug">
component(
	:is="navigationLink ? 'router-link' : 'div'"
	:to="navigationLink"
	:class="['pill', { status }, status, { clickable }]"
)
	VTooltip(:text="tooltip", :position="tooltipPosition")
		template(v-if="type === 'list'")
			SimplePillItem(
				v-for="item in items"
				:key="item"
				:icon="item.icon"
			) {{ item.text }}

		SimplePillItem(
			v-else
			:icon="icon"
		)
			slot
</template>

<script>
import SimplePillItem from './SimplePillItem.vue';

export default {
	name: 'SimplePill',
	components: {
		SimplePillItem
	},
	props: {
		icon: {
			type: String,
			required: false,
			default: ''
		},
		status: {
			type: String,
			required: false,
			default: ''
		},
		items: {
			type: Array,
			required: false,
			default: null
		},
		tooltip: {
			type: String,
			required: false,
			default: null
		},
		tooltipPosition: {
			type: String,
			required: false,
			default: 'top'
		},
		navigationLink: {
			type: String,
			required: false,
			default: null
		}
	},
	computed: {
		type() {
			return this.$props.items ? 'list' : 'single';
		},
		clickable() {
			return !!this.$attrs.onClick;
		}
	}
};
</script>

<style lang="scss" scoped>
.pill {
	display: inline-flex;
	padding: rem-calc(7 14);
	position: relative;
	border: 1px solid hsl(var(--white20));
	border-radius: rem-calc(50);
	background-color: var(--body-background);
	margin: rem-calc(0 12 12 0);
	color: inherit;
	text-decoration: none;

	&.status,
	&.small {
		padding: rem-calc(5 10);
	}

	&.xsmall {
		padding: rem-calc(3 6);
		margin: rem-calc(0 3 3 0);
	}

	@mixin colors($color) {
		border-color: hsl($color / 75%);

		#{v-deep('.pill-icon')} {
			opacity: 1;

			.stroke-primary {
				stroke: hsl($color / 75%);
			}

			.fill-primary {
				fill: hsl($color / 75%);
			}
		}
	}

	&.error {
		@include colors(var(--red));
	}

	&.warning {
		@include colors(var(--orange120));
	}

	&.primary {
		@include colors(var(--orange));
	}

	&.info {
		@include colors(var(--info-color));
	}

	&.no-margin {
		margin: 0;
	}

	&.clickable {
		cursor: pointer;
		user-select: none;

		&:hover {
			border-color: hsl(var(--white40));

			&.error {
				@include colors(var(--red));
			}

			&.warning {
				@include colors(var(--orange120));
			}

			&.info {
				@include colors(var(--info-color));
			}
		}
	}
}
</style>
