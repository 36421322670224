<template lang="pug">
VModal(
	v-if="isOpen"
	size="small"
	confirmButton="Submit"
	:disableConfirmButton="disableConfirmButton"
	:confirmButtonTooltip="submitTooltip"
	@confirm="submit"
	@close="close"
	:closeOnConfirm="false"
)
	template(#header)
		h2 {{title}}

	.form-row(v-if="showOutcome")
		.form-column
			VDropdown(
				header="Outcome"
				:label="outcomeLabel"
				:list="this.outcomeOptions"
				@check="setOutcome"
			)

	.form-row(v-if="showCategory")
		.form-column
			VDropdown(
				header="Category"
				:label="categoriesLabel"
				:list="categories"
				:disabled="categoriesDisabled"
				multiSelect
				@toggleCheck="setCategories"
			)

	.form-row
		.form-column
			VTextArea(
				v-model="formData.comment"
				:required="showComment"
			) Comment
	div(v-if="this.isForceOutcome")
		VCheckbox(v-model:checked="submitServiceNow") Submit Incident to Service Now
</template>

<script>
import { mapActions } from 'vuex';

import { createFilter } from '@/js/utils/filter';
import { issueCategories } from '@/js/utils/data-definitions';
import { toDateTime } from '@/js/utils/filter/types';

import {
	findExtendedSignatures as findSignature
} from '@/js/api/contact-signatures';
import { camelizeKeys } from '@/js/utils/case-conversion';
import dig from '@/js/utils/dig';
import {
	elaborate, isValidElaborate,
	elaborateSignature, isValidElaborateSignature,
	forceOutcome, isValidForceOutcome,
	override, isValidOverride, getCommentValidationErrors
} from './lib/signature';

import { getComponentName } from './lib/signature/submission';

export default {
	props: {
		signature: {
			type: Object,
			required: true
		},
		contact: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isOpen: false,
			contactId: null,
			submissionId: null,
			mode: null,
			identityReference: null,
			formData: null,
			isSubmitting: false,
			submitServiceNow: false
		};
	},
	computed: {
		evaluations() {
			return this.signature?.evaluations ?? [];
		},
		title() {
			if (this.isElaborate || this.isElaborateSignature) {
				return 'Elaborate';
			} else if (this.isAttest) {
				return 'Attest';
			} else if (this.isOverride) {
				return 'Override';
			}

			return 'Force outcome';
		},
		outcomeLabel() {
			const selected = this.outcomeOptions.find(createFilter({
				id: this.formData.outcome ?? ''
			}));

			return selected?.name ?? 'Select an outcome';
		},
		outcomeOptions() {
			return [
				'SUCCESS',
				'ANOMALY',
				'REMARK'
			].map((id) => {
				return {
					id,
					name: this.$text.toSentenceCase(id)
				};
			});
		},
		isSuccess() {
			return this.formData?.outcome === 'SUCCESS';
		},
		isAnomaly() {
			return this.formData?.outcome === 'ANOMALY';
		},
		isRemark() {
			return this.formData?.outcome === 'REMARK';
		},
		categoriesLabel() {
			if (this.formData.categories.length > 0) {
				return this.$text.pluralize(
					this.formData.categories.length,
					'issue category',
					'issue categories'
				);
			}

			return 'Issue categories';
		},
		categories() {
			return issueCategories.map((category) => {
				return {
					...category,
					isChecked: this.formData.categories.includes(category.id)
				};
			});
		},
		showComment() {
			return !this.categoriesDisabled || this.isForceOutcome;
		},
		categoriesDisabled() {
			return this.isSuccess;
		},
		isForceOutcome() {
			return !this.isElaborate &&
				!this.isElaborateSignature &&
				!this.isAttest &&
				!this.isOverride;
		},
		forceOutcomeOptions() {
			return this.overrideOptions;
		},
		isElaborate() {
			return this.mode === 'elaborate';
		},
		elaborateOptions() {
			return {
				identityReference: this.identityReference,
				comment: this.formData.comment
			};
		},
		isElaborateSignature() {
			return this.mode === 'elaborateSignature';
		},
		elaborateSignatureOptions() {
			return {
				comment: this.formData.comment
			};
		},
		isAttest() {
			return this.mode === 'attest';
		},
		isOverride() {
			return this.mode === 'override';
		},
		overrideOptions() {
			return {
				identityReference: this.identityReference,
				issueCategories: this.formData.categories,
				comment: this.formData.comment,
				outcome: this.formData.outcome
			};
		},
		showSignatureComment() {
			return this.isForceOutcome || this.isElaborateSignature;
		},
		showOutcome() {
			return this.isForceOutcome || this.isOverride;
		},
		showCategory() {
			return this.isForceOutcome || this.isOverride;
		},
		isValid() {
			if (this.isAttest) {
				return true;
			} else if (this.isElaborate) {
				return isValidElaborate(this.signature, this.elaborateOptions) &&
					this.elaborateOptions.comment !== this.getInitialComment();
			} else if (this.isElaborateSignature) {
				return isValidElaborateSignature(this.signature, this.elaborateSignatureOptions);
			} else if (this.isForceOutcome) {
				return isValidForceOutcome(this.signature, this.forceOutcomeOptions);
			} else if (this.isOverride) {
				return isValidOverride(this.signature, this.overrideOptions);
			}

			return false;
		},
		submitTooltip () {
			// Only show one error at at time since the VTooltip does support line breaks easily.
			const messageList = [];
			if (this.showOutcome && !this.formData.outcome) {
				messageList.push('Outcome required');
			}
			if (this.showCategory && !this.categoriesDisabled) {
				if (this.formData.categories.length === 0) {
					messageList.push('Issue category is missing');
				}
			}
			if (this.showComment) {
				messageList.push(...getCommentValidationErrors(this.formData.comment));
			}
			return messageList.slice(0, 1).join('\n');
		},
		canSubmit() {
			return this.isValid && !this.isSubmitting;
		},
		disableConfirmButton() {
			return !this.canSubmit;
		},
		componentName() {
			if (this.identityReference?.componentName) {
				return this.identityReference.componentName;
			} else if (this.submissionId) {
				return getComponentName(this.signature, this.submissionId);
			}

			return 'UNKNOWN';
		},
		formattedComponentName() {
			return this.$text.toSentenceCase(this.componentName);
		}
	},
	watch: {
		isSuccess(isSuccess) {
			if (!isSuccess) {
				return;
			}

			this.formData.categories = [];
		}
	},
	methods: {
		open(options) {
			const {
				contactId,
				submissionId,
				mode = 'force',
				identityReference = null
			} = options;

			this.contactId = contactId;
			this.submissionId = submissionId;
			this.mode = mode;
			this.identityReference = identityReference;

			return new Promise((resolve) => {
				this.resolver = resolve;
				this.formData = {
					outcome: null,
					categories: [],
					comment: this.getInitialComment()
				};

				if (this.isAttest) {
					this.$dialog.confirm({
						message: `Do you want to attest ${this.formattedComponentName}?`
					}).then((result) => {
						if (result) {
							this.submit();
						} else {
							this.close();
						}
					});
				} else {
					this.isOpen = true;
				}
			});
		},
		close() {
			if (!this.isOpen) {
				return;
			}

			this.isOpen = false;
			this.contactId = null;
			this.submissionId = null;
			this.mode = null;
			this.identityReference = null;
			this.formData = null;
			this.resolver = null;
			this.isSubmitting = false;
		},
		getInitialComment() {
			if (this.isElaborateSignature) {
				if (this.evaluations.length === 0) {
					return '';
				}
				return this.evaluations[0].comments.find(createFilter({
					'submissionMetadata.componentName': 'HUMAN_OVERRIDE'
				}))?.comment || '';
			}

			if (this.isElaborate) {
				if (this.signature.submissions.length === 0) {
					return '';
				}
				return this.signature.submissions
					.filter(createFilter({ 'input.componentName': 'HUMAN_OVERRIDE' }))
					.sort((a, b) => {
						return toDateTime(b.submittedAt).toMillis() - toDateTime(a.submittedAt).toMillis();
					})
					.flatMap((submission) => {
						return dig(submission, ['input', 'payload', 'acknowledgeList', 'acknowledge']);
					})
					.find(createFilter({
						'identityReference.attemptId': this.identityReference.attemptId
					}))?.elaborate?.comment || '';
			}

			return '';
		},
		setOutcome({ id }) {
			this.formData.outcome = id;
		},
		setCategories({ item, isChecked }) {
			let next = this.formData.categories.filter((other) => {
				return other !== item.id;
			});
			if (isChecked) {
				next.push(item.id);
			}

			this.formData.categories = next.sort((a, b) => {
				return a.localeCompare(b);
			});
		},
		async submit() {
			this.isSubmitting = true;

			try {
				let response;
				if (this.isElaborate) {
					response = await elaborate(this.contactId, this.signature, this.elaborateOptions);
				} else if (this.isElaborateSignature) {
					response = await elaborateSignature(this.contactId, this.signature, this.elaborateSignatureOptions);
				} else if (this.isForceOutcome) {
					let tasks = [forceOutcome(this.contactId, this.signature, this.forceOutcomeOptions)];
					if (this.submitServiceNow) {
						const serviceNowPromise = this.createTicket({
							contact: this.contact,
							shortDesc: this.forceOutcomeOptions.comment,
							desc: '',
							tenantName: this.contact.tenant.name
						});
						tasks.push(serviceNowPromise);
					}
					response = (await Promise.all(tasks))[0];
					if (this.submitServiceNow && response[1]) {
						this.$notify.event('Successfully created incident(s)', 'success'); // Test this
					}
				} else if (this.isOverride) {
					response = await override(this.contactId, this.signature, this.overrideOptions);
				} else {
					throw new Error('Submit not handled');
				}

				if (typeof this.resolver === 'function') {
					this.resolver(response);
				}
				await this.fetchSignature();
				await this.fetchContact(this.contactId);
				this.close();
			} catch (e) {
				throw new Error(e);
			} finally {
				this.isSubmitting = false;
			}
		},
		fetchSignature() {
			if (!this.hasContact) {
				return Promise.resolve();
			}

			return findSignature(this.contact.id)
				.then((signature) => {
					this.loadedSignature = camelizeKeys(signature);

					return this.loadedSignature;
				});
		},
		...mapActions({
			fetchContact: 'contacts/fetchOne',
			createTicket: 'serviceNow/createTicket'
		})
	}
};

</script>

<style lang="scss" scoped>
.form-row,
.form-actions {
	display: flex;
}

.form-column,
.form-actions .button {
	flex: 0 1 100%;

	&:not(:first-child) {
		margin-left: rem-calc(15);
	}
}
</style>
