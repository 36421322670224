import { DefineComponent, defineComponent, getCurrentInstance, h } from 'vue';
import store from '@/store/store';
import VLoadingView from '@/components/global/VLoadingView/VLoadingView.vue';
import RouteWrapperComponentError from './RouteWrapperComponentError.vue';
import EditWarning from '@/components/HoC/EditWarning.vue';
import { errorMessage } from '@/js/api/builder/api-error';
import { validateDependencies } from './route-wrapper-validate';
import { inlineThrow } from '@/js/utils/safe';

export type Dependency = { action: string, params?: { id: string | string[] } }

/**
 * Wraps a component to conditionally validate and load its store dependencies, if present.
 * @param ViewComponent - A component potentially with a "dependencies" property.
 * @returns A wrapped component with pre-loaded dependencies, if any exist.
 */
export default async function (ViewComponent: () => Promise<DefineComponent> | DefineComponent) {
	if (typeof ViewComponent !== 'function') {
		return ViewComponent;
	}

	const viewComponent = (await ViewComponent()).default;

	if (viewComponent.dependencies === undefined) {
		return viewComponent;
	}

	validateDependencies(viewComponent);

	return defineComponent({
		name: 'RouteWrapperComponent',
		inheritAttrs: false,
		data() {
			return {
				internalInstance: getCurrentInstance() ?? inlineThrow("Failed to get instance"),
				isLoading: true,
				errorMessage: null
			};
		},
		computed: {
			dependencies() {
				const dependencies = viewComponent.dependencies as Dependency[];
				return Array.isArray(dependencies) ? dependencies : [dependencies];
			},
			$progress() {
				return this.internalInstance.appContext.config.globalProperties.$Progress;
			},
			progressPercent() {
				const length = this.dependencies.length;
				return Math.round(1 / length * 100);
			},
			editorModule() {
				if (!viewComponent.countEditorsIn) {
					return null;
				}
				return viewComponent.countEditorsIn;
			},
			id() {
				return this.$route.params.id;
			},
			editorUserList() {
				if (!viewComponent.countEditorsIn) {
					return;
				}
				return this.$store.getters[`${this.editorModule}/editorUserList`];
			}
		},
		created() {
			this.$progress.start();

			const dependencies = this.dependencies.map((dependency) => {
				const action = dependency.action;
				const params: { id: string | string[] } | object = dependency.params ?? {};

				if ('id' in params) {
					params.id = this.id;
				}

				return store
					.dispatch(action, params)
					.finally(() => this.$progress.increase(this.progressPercent));
			});

			Promise.all(dependencies)
				.catch(error => this.errorMessage = errorMessage(error))
				.finally(() => {
					this.isLoading = false;
					this.$progress.finish();
				});

			if (this.editorModule) {
				this.$store.dispatch(`${this.editorModule}/startEditing`, this.id);
			}
		},
		beforeUnmount() {
			if (this.editorModule) {
				this.$store.dispatch(`${this.editorModule}/stopEditing`, this.id);
			}
		},
		render() {
			if (this.isLoading) {
				return h(VLoadingView);
			}
			if (this.errorMessage) {
				return h(RouteWrapperComponentError, { errorMessage: this.errorMessage });
			}
			if (viewComponent.countEditorsIn) {
				return [
					h(viewComponent, { ...this.$route.params, style: 'margin-bottom: 24px;' }),
					h(EditWarning, { editorUserList: this.editorUserList })
				];
			}
			return h(viewComponent, { ...this.$route.params });
		}
	}
	);
}
