import { event } from '@/js/plugins/notify/notify';

export default {
	namespaced: true,
	state: () => ({
		list: []
	}),
	mutations: {
		add: (state, brokenObject) => state.list = [...state.list, brokenObject]
	},
	getters: {
		exist: state => ({ id, module }) => state.list.some(brokenObject => brokenObject.id === id && brokenObject.module === module)
	},
	actions: {
		set({ commit, getters, rootState }, brokenObject) {
			if (!getters.exist(brokenObject)) {
				commit('add', brokenObject);

				const { id, module } = brokenObject;
				const environment = rootState.environment.environment;
				const message = `Resource '${module}' with id '${id}' could not be found.\nThis is a sign of inconsistency in the state of the data in the '${environment}' environment.`;
				event(message, 'warning', { notifyDevops: true });
			}
		}
	}
};
