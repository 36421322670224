<template lang="pug">
.message
	h3 Internal error!
	p {{ message }}
	button.button.secondary.small.invert(@click="copyMessage")
		VIcon(name="copy")
		.label Copy message
</template>

<script>
import { copyToClipboard } from '@/js/utils/copy.js';

export default {
	name: 'NotifyInternalError',
	props: {
		message: {
			type: String,
			required: true
		}
	},
	methods: {
		copyMessage() {
			copyToClipboard(this.message);
		}
	}
};
</script>

<style lang="scss" scoped>
h3 {
	margin: 0;
}

p {
	font-size: var(--font-small-size);
	line-height: 1.4;
	margin: rem-calc(15 0);
	white-space: pre-line;
}

.button {
	margin: 0;
}
</style>
