<template lang="pug">
.expanding-panel(:class="containerClasses")
	.expanding-panel-inner(ref="inner")
		slot
</template>

<script>

export default {
	name: 'ExpandingPanel',
	props: {
		expanded: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isOpen: null
		};
	},
	computed: {
		containerClasses() {
			return {
				'is-open': this.isOpen === true
			};
		}
	},
	watch: {
		expanded: {
			handler() {
				if (this.$el === null || this.isOpen === null) {
					this.setIsOpen(this.expanded)();
					return;
				}

				this.transitionIsOpen(this.expanded);
			},
			immediate: true
		}
	},
	methods: {
		transitionIsOpen(isOpen) {
			if (isOpen) {
				this.addTransitionListeners()
					.then(this.setHeight(0))
					.then(this.setOpacity(0))
					.then(this.enableTransitions)
					.then(this.setHeight(this.getInnerHeight()))
					.then(this.setOpacity(1))
					.then(this.waitForTransitions)
					.then(this.disableTransitions)
					.then(this.setIsOpen(true))
					.then(this.removeTransitionListeners)
					.then(this.setHeight(null))
					.then(this.setOpacity(null));
			} else {
				this.addTransitionListeners()
					.then(this.setHeight(this.getInnerHeight()))
					.then(this.setOpacity(1))
					.then(this.setIsOpen(false))
					.then(this.enableTransitions)
					.then(this.setHeight(0))
					.then(this.setOpacity(0))
					.then(this.waitForTransitions)
					.then(this.disableTransitions)
					.then(this.removeTransitionListeners)
					.then(this.setHeight(null))
					.then(this.setOpacity(null));
			}
		},
		setIsOpen(isOpen) {
			return () => {
				return new Promise((resolve) => {
					this.isOpen = isOpen;
					this.$nextTick(resolve);
				});
			};
		},
		setHeight(height) {
			return () => {
				return new Promise((resolve) => {
					if (height !== null) {
						height = `${height}px`;
					}

					this.$el.style.height = height;
					window.requestAnimationFrame(resolve);
				});
			};
		},
		setOpacity(opacity) {
			return () => {
				return new Promise((resolve) => {
					this.$refs.inner.style.opacity = opacity;
					window.requestAnimationFrame(resolve);
				});
			};
		},
		waitForTransitions() {
			return new Promise((resolve) => {
				this.transitionCallback = resolve;
			});
		},
		addTransitionListeners() {
			return new Promise((resolve) => {
				this.runningTransitions = 0;
				this.$el.addEventListener('transitionstart', this.onTransitionStart);
				this.$el.addEventListener('transitionend', this.onTransitionEnd);

				window.requestAnimationFrame(resolve);
			});
		},
		removeTransitionListeners() {
			return new Promise((resolve) => {
				this.runningTransitions = 0;
				this.$el.removeEventListener('transitionstart', this.onTransitionStart);
				this.$el.removeEventListener('transitionend', this.onTransitionEnd);

				window.requestAnimationFrame(resolve);
			});
		},
		onTransitionStart(e) {
			if (!this.$el.isSameNode(e.target)) {
				return;
			}

			this.runningTransitions += 1;
		},
		onTransitionEnd(e) {
			if (!this.$el.isSameNode(e.target)) {
				return;
			}

			this.runningTransitions -= 1;
			if (
				this.runningTransitions === 0 &&
				typeof this.transitionCallback === 'function'
			) {
				window.requestAnimationFrame(() => {
					this.transitionCallback();
					this.transitionCallback = null;
				});
			}
		},
		enableTransitions() {
			return this.toggleTransitions(true);
		},
		disableTransitions() {
			return this.toggleTransitions(false);
		},
		toggleTransitions(enabled) {
			return new Promise((resolve) => {
				this.$el.classList.toggle('transitions-enabled', enabled);

				window.requestAnimationFrame(resolve);
			});
		},
		getInnerHeight() {
			const el = this.$refs.inner;
			const style = window.getComputedStyle(el);

			return parseFloat(style.height) +
				parseFloat(style.paddingTop) +
				parseFloat(style.paddingBottom) +
				parseFloat(style.marginTop) +
				parseFloat(style.marginBottom) +
				parseFloat(style.borderTopWidth) +
				parseFloat(style.borderBottomWidth);
		}
	}
};

</script>

<style lang="scss">
:root {
	--expanding-panel-transition-duration: 0.5s;
	--expanding-panel-transition-timing-function: ease-in-out;
	--expanding-panel-top: 0;
	--expanding-panel-bottom: auto;
}
</style>

<style lang="scss" scoped>
.expanding-panel {
	position: relative;
	height: 0;
	overflow: hidden;

	&.transitions-enabled {
		transition:
			height
			var(--expanding-panel-transition-duration)
			var(--expanding-panel-transition-timing-function);
	}

	&.transitions-enabled &-inner {
		transition:
			opacity
			var(--expanding-panel-transition-duration)
			var(--expanding-panel-transition-timing-function);
	}

	&.is-open {
		overflow: visible;
		height: auto;
	}

	&-inner {
		position: absolute;
		box-sizing: content-box;
		top: var(--expanding-panel-top);
		bottom: var(--expanding-panel-bottom);
		left: 0;
		right: 0;

		.is-open & {
			position: static;
		}
	}
}
</style>
