<template lang="pug">
.state-icon(:class="modifierClasses")
	VIcon(:name="iconName")
	.signed-manually(v-if="isManual")
		VIcon(name="pencil.filled")
</template>

<script>
import { contactSignatureOutcomes } from '@/js/utils/data-definitions';

export default {
	props: {
		outcome: {
			type: String,
			required: true
		},
		isManual: {
			type: Boolean,
			required: false,
			default: false
		},
		isFaded: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		modifierClasses() {
			return [
				this.$text.toKebabCase(this.outcome),
				{
					'is-faded': this.isFaded
				}
			];
		},
		iconName() {
			switch (this.outcome.toUpperCase()) {
				case 'REMARK':
					return contactSignatureOutcomes.remark.icon;
				case 'SUCCESS':
					return contactSignatureOutcomes.success.icon;
				case 'ANOMALY':
					return contactSignatureOutcomes.anomaly.icon;
				case 'EVALUATION_ERROR':
					return 'danger.triangle';
				case 'WITNESS_REQUIRED':
					return contactSignatureOutcomes.witness_required.icon;
				case 'WAITING':
					return contactSignatureOutcomes.waiting.icon;
				default:
					console.warn(`[OutcomeIcon] Unknown outcome: ${this.outcome}`);
					return 'deprecated';
			}
		}
	}
};

</script>

<style lang="scss" scoped>
.state-icon {
	&.success,
	&.remark {
		background-color: hsl(var(--green120));

		.icon {
			--icon-color-primary: hsl(var(--white));

			background-color: hsl(var(--green80));
		}
	}

	&.waiting {
		background-color: hsl(var(--white40));
		opacity: 0.6;

		.icon {
			background-color: hsl(var(--dark-purple));

			#{v-deep('svg')} {
				width: rem-calc(20);
				height: rem-calc(20);
			}
		}
	}

	&.anomaly,
	&.evaluation-error {
		background-color: hsl(var(--red70));

		.icon {
			background-color: hsl(var(--red40));

			#{v-deep('svg')} {
				transform: translateY(rem-calc(-1));
			}
		}
	}

	&.witness-required {
		background-color: hsl(var(--orange));

		.icon {
			--icon-color-primary: hsl(var(--orange));

			background-color: hsl(var(--orange10));
		}
	}

	&.internal {
		background-color: hsl(var(--white20));

		.icon {
			background-color: hsl(var(--white10));
		}
	}

	.signed-manually {
		--size: #{rem-calc(14)};
		--icon-size: #{rem-calc(9)};

		background: hsl(var(--white));
		border-radius: 50%;
		width: var(--size);
		height: var(--size);
		position: absolute;
		bottom: 0;
		right: 0;
		transform: translate(25%, 25%);

		.icon {
			--icon-color-primary: hsl(var(--dark-purple));

			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: transparent;
			width: var(--icon-size);
			height: var(--icon-size);

			#{v-deep('.stroke-primary')} {
				stroke: var(--icon-color-primary);
			}

			#{v-deep('.fill-primary')} {
				fill: var(--icon-color-primary);
			}
		}
	}

	&.is-faded {
		&.anomaly,
		&.evaluation-error,
		&.internal,
		&.remark,
		&.success,
		&.waiting,
		&.witness-required {
			background: hsl(var(--white15));

			> .icon {
				--icon-color-primary: hsl(var(--white70));

				background-color: var(--body-background);
			}

			.signed-manually {
				background-color: hsl(var(--white70));
			}
		}
	}
}
</style>
