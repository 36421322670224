<template lang="pug">
.col
	.sidebar
		Navigation

	.content
		header
			h1 {{title}}

		main
			router-view
</template>

<script>

import Navigation from './components/Navigation/Navigation.vue';

export default {
	name: 'StyleguidePage',
	components: {
		Navigation
	},
	computed: {
		title() {
			return this.$route.meta.name;
		}
	}
};

</script>

<style lang="scss" scoped>
.col {
	display: flex;

	.row > & {
		max-width: rem-calc(1000);
	}
}

.sidebar {
	flex: 0 0 rem-calc(260);
}

.content {
	flex: 0 1 100%;
	margin-left: rem-calc(40);
}

header {
	margin-bottom: rem-calc(30);

	h1 {
		line-height: 1;
		margin: 0 0 rem-calc(12);
	}
}
</style>
