<template lang="pug">
VJSON.raw-value(:data="value")
</template>

<script>

export default {
	name: 'RawValue',
	props: {
		value: {
			required: false,
			default: null,
			validator() {
				return true;
			}
		}
	}
};

</script>

<style lang="scss" scoped>
.container.raw-value {
	margin: 0;
}
</style>
