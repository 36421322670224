<template lang="pug">
ul
	NavigationtItem(
		v-for="signatureProfile in signatureProfiles"
		:key="signatureProfile"
		:signatureProfile="signatureProfile"
		:selectedSignatureProfileId="modelValue"
		@pick="setProfileId"
	)
</template>

<script>

import NavigationtItem from './NavigationItem.vue';

export default {
	name: 'PickerNavigation',
	components: {
		NavigationtItem
	},
	props: {
		modelValue: {
			type: String,
			required: true
		}
	},
	emits: [
		'update:modelValue'
	],
	data() {
		return {
			nextSignatureProfileId: null
		};
	},
	computed: {
		signatureProfiles() {
			return this.$store.getters['signatureProfiles/all'];
		}
	},
	methods: {
		setProfileId(id) {
			this.$emit('update:modelValue', id);
		}
	}
};

</script>

<style lang="scss" scoped>
ul {
	list-style: none;
}
</style>
