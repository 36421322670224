<template lang="pug">
header
	h2
		.name {{ contact.spacecraft.name }}
		.contact-details
			.system
				VIcon(name="system")
				| {{ contact.system.name }}
			.time
				VIcon(name="time")
				| {{ $time.formatTimeLabel(contact.startTime, contact.endTime) }}
	VIcon.header-icon(name="contact")

IdentifierCopy(type="Contact") {{ contact.id }}
Evaluations(
	:evaluations="signature.evaluations"
	v-model:evaluationIndex="evaluationIndex"
)

VCallout.evaluation-forced-callout(
	v-if="evaluationForced"
	type="info"
) Component signatures does not have effect since signature evaluation is forced

ComponentSubmissions(
	:signature="signature"
	:evaluationIndex="evaluationIndex"
)

</template>

<script>

import { evaluationOutcomeForced } from './lib/signature';

import ComponentSubmissions from './Details/ComponentSubmissions.vue';
import IdentifierCopy from '@/components/base/IdentifierCopy.vue';
import Evaluations from './Details/Evaluations.vue';

export default {
	components: {
		ComponentSubmissions,
		IdentifierCopy,
		Evaluations
	},
	props: {
		contact: {
			type: Object,
			required: true
		},
		signature: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			evaluationIndex: 0
		};
	},
	computed: {
		hasEvaluations() {
			return this.signature.evaluations.length > 0;
		},
		evaluationForced() {
			return evaluationOutcomeForced(this.signature, {
				evaluationIndex: this.evaluationIndex
			});
		}
	}
};

</script>

<style lang="scss" scoped>
header {
	position: relative;
	padding: 0;
	margin-bottom: var(--box-padding);
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: rem-calc(24);
	flex-shrink: 0;
	font-weight: var(--font-bolder);

	h2 {
		margin: 0;
		flex-flow: column;
		align-items: flex-start;

		.name {
			margin-bottom: rem-calc(10);
		}
	}

	.contact-details {
		color: hsl(var(--white60));
		font-weight: var(--font-normal);
		font-size: var(--font-body-size);
		display: flex;
		align-items: center;

		.icon:first-child {
			opacity: 0.6;
			margin-right: rem-calc(5);
		}

		.system,
		.time {
			margin-right: rem-calc(15);
			display: inline-flex;
			align-items: center;
		}
	}
}

.identifier {
	margin-bottom: var(--box-padding);
}

.evaluation-forced-callout.callout {
	margin-top: rem-calc(20);
	margin-bottom: rem-calc(25);
}
</style>
