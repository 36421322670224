import { h } from 'vue';
import icons from '@/assets/icons';

const isString = (string, minLength = 1) => {
	return typeof string === 'string' && string.length >= minLength;
};

const getName = (component) => {
	if (isString(component.$options.name)) {
		return component.$options.name;
	} else if (isString(component.$options.__file)) {
		return component.$options.__file.replace(/^(?:.*\/)*(.+)\.vue$/, '$1');
	}

	return '__NoName__';
};

const getNames = (component) => {
	if (component === null) {
		return [];
	}

	return [
		...getNames(component.$parent),
		getName(component)
	].filter((name) => {
		return name !== null;
	});
};

export default {
	name: 'VIcon',
	render() {
		return h('div', {
			class: ['icon', this.iconClass],
			innerHTML: this.svg
		});
	},
	props: {
		name: {
			type: String,
			required: true
		}
	},
	computed: {
		svg() {
			if (!icons[this.name]) {
				console.warn(`Unknown icon '${this.name}' in ${getNames(this.$parent).join('/')}`);
				return '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" fill="#FF0000"/></svg>';
			}
			return icons[this.name];
		},
		iconClass() {
			const base = this.name
				.replace(/([a-z0-9][A-Z])/g, (characters) => {
					return characters
						.split('')
						.map(c => c.toLowerCase())
						.join('-');
				}).replace(/[./]/g, '-');

			return `icon-${base}`;
		}
	}
};
