export default [
	{
		name: 'Components',
		path: 'components'
	},
	{
		type: 'group',
		name: 'Layout',
		path: 'layout',
		pages: [
			{
				name: 'Button Group',
				path: 'button-group'
			}
		]
	},
	{
		type: 'group',
		name: 'Forms',
		path: 'forms',
		pages: [
			{
				name: 'Dropdown Select',
				path: 'dropdown-select'
			}
		]
	},
	{
		type: 'group',
		name: 'JSON Schema',
		path: 'json-schema',
		pages: [
			{
				name: 'View',
				path: 'view'
			},
			{
				name: 'Form',
				path: 'form'
			}
		]
	},
];
