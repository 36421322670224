<template lang="pug">
Popper(
	:arrow="false"
	:hover="true"
	offsetDistance="0"
)
	.more(:class="modifierClasses") More
	template(#content)
		slot
</template>

<script>
import Popper from 'vue3-popper';

export default {
	name: "NavbarDropdown",
	components: {
		Popper
	},
	props: {
		activeUrls: {
			type: Array,
			required: true
		}
	},
	computed: {
		modifierClasses() {
			return {
				'is-active': this.isActive
			};
		},
		isActive() {
			return this.activeUrls.some(url => {
				if (typeof url === 'string') {
					return url === this.$route.path;
				}
				return url.test(this.$route.path);
			});
		}
	}
};
</script>
