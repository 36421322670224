<template lang="pug">
Popper.tooltip(
	v-if="isTooltip"
	:content="text"
	:placement="placement"
	:hover="true"
	:interactive="false"
	:arrow="true"
)
	slot
	template(#content="props")
		slot(
			name="content"
			v-bind="props"
		)
slot(v-else)
</template>

<script>
import Popper from 'vue3-popper';

export default {
	name: 'VTooltip',
	components: {
		Popper
	},
	inject: {
		injectPosition: {
			from: 'tooltipPlacement',
			default: null
		}
	},
	props: {
		text: {
			type: String,
			required: false,
			default: null
		},
		position: {
			type: String,
			required: false,
			default: 'top'
		}
	},
	computed: {
		isTooltip() {
			return this.hasText || this.hasContent;
		},
		hasText() {
			return !!this.text;
		},
		hasContent() {
			return !!this.$slots.content;
		},
		placement() {
			if (this.injectPosition) {
				return this.injectPosition;
			}
			return this.position;
		}
	}
};
</script>
