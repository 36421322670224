import { state, mutations, actions, getters } from '@/store/build-module/common';
import changelogActions from '@/store/build-module/changelog-actions';
import { updateAssociation } from '@/store/helpers/store-common';
import { manageAPIError } from '@/js/api/builder/api-error';
import { manageError } from '@/store/helpers/store-error';
import baseAPIBuilder from '@/js/api/builder/api-builder';
import grafanaLinks from '@/assets/data/grafana-system-links.json';
import { getScope } from '@/js/auth/auth-config';
import { UPDATE_RESOURCE } from '@/env';
import Connection from '@/js/network/helpers';
import { dedicatedSystemsConnection } from '../dedicated-systems';

import specifications from './specifications';

const module = 'systems';

const meta = {
	module,
	basePath: '/v1.0/systems/antennas',
	mapping: {
		setup_duration: 'setupDuration',
		teardown_duration: 'teardownDuration',
		ops_unit: 'opsUnit',
		lifecycle_state: 'lifecycleState'
	},
	parser: data => data.antenna || data.data || data,
	inputWrapper: input => ({ data: input }),
	scope: await getScope('kogs')
};

const horizonConnection = new Connection('/v1.0/systems', await getScope('kogs'), 'horizon');
const operatorConnection = new Connection('/v1.0/systems/operators', await getScope('kogs'), 'operators');

export const builder = new baseAPIBuilder(meta);

const api = {
	...builder.build(['list', 'add', 'update', 'find']),

	findHorizonProfile: id => horizonConnection.axios
		.get(`/${id}/masks`)
		.then(response => response.data)
		.catch(manageAPIError),
	updateHorizonProfile: (id, profile) => horizonConnection.axios
		.patch(`/${id}/masks`, profile, { refetchId: id })
		.then(response => response.data)
		.catch(manageAPIError),
	fetchDedicatedSystems: () => dedicatedSystemsConnection.axios
		.get()
		.then(response => response.data.data)
		.catch(manageAPIError),
	fetchSystemOperators: () => operatorConnection.axios
		.get()
		.then(response => response.data.operators)
		.catch(manageAPIError),
	fetchDedicatedSystem: (id) => dedicatedSystemsConnection.axios
		.get(`?tenant_id=${id}`)
		.then(response => response.data.data[0])
		.catch(manageAPIError)
};

const baseActions = actions(api);

export default {
	namespaced: true,
	modules: {
		specifications
	},
	state: () => ({
		...state(module),

		horizonProfiles: {},
		trackingLinks: {},
		systemOperators: [],
		dedicatedSystems: []
	}),
	getters: {
		...getters(module),

		findTrackingLink(state) {
			return (systemName, from = null, to = null) => {
				let link = state.trackingLinks[systemName];

				if (!link) {
					return null;
				}

				link += from ? '&from=' + from.toMillis() : '';
				link += to ? '&to=' + to.toMillis() : '';

				return link;
			};
		},
		findHorizonProfile(state) {
			return systemId => state.horizonProfiles[systemId];
		},
		findSystemOperator(state) {
			return operatorId => state.systemOperators.find(operator => operator.id === operatorId);
		},
		operators(state) {
			return state.systemOperators;
		},
		dedicatedSystems(state) {
			return state.dedicatedSystems;
		}
	},
	mutations: {
		...mutations(module),

		setHorizonProfile(state, { id, horizonProfile }) {
			state.horizonProfiles[id] = horizonProfile;
		},
		setTrackingLinks(state, trackingLinks) {
			state.trackingLinks = trackingLinks;
		},
		setDedicatedSystems(state, dedicatedSystems) {
			state.dedicatedSystems = dedicatedSystems;
		},
		setSystemOperators(state, systemOperators) {
			state.systemOperators = systemOperators;
		},
		setDedicatedSystem(state, dedicatedSystem) {
			const existing = state.dedicatedSystems.find(dedicated => dedicated.tenant === dedicatedSystem.tenant);
			if (existing) {
				existing.systems = dedicatedSystem.systems;
			} else {
				state.dedicatedSystems.push(dedicatedSystem);
			}
		},
		dedicatedSystemsUpdated(state) {
			state.systems.forEach(system => {
				const dedicatedSystem = state.dedicatedSystems.find(dedicated => dedicated.systems.includes(system.id));
				if (dedicatedSystem) {
					system.dedicatedTo = dedicatedSystem.tenant;
				} else {
					delete system.dedicatedTo;
				}
			});
		}
	},
	actions: {
		...baseActions,
		...changelogActions([
			{ connection: api.connection, objectType: module },
			{ connection: horizonConnection, objectType: 'horizonProfile', changelogPath: id => `/${id}/masks/changelogs` }
		]),

		initiateMore({ dispatch }) {
			horizonConnection.socket.on(UPDATE_RESOURCE, ({ id }) => dispatch('fetchHorizonProfile', id));
			dedicatedSystemsConnection.socket.on(UPDATE_RESOURCE, ({ id }) => dispatch('fetchDedicatedSystem', id));

			dispatch('fetchDedicatedSystems');
		},

		ensureTrackingLinks({ commit }) {
			commit('setTrackingLinks', grafanaLinks);
		},
		ensureHorizonProfile({ getters, dispatch }, { id }) {
			if (getters.findHorizonProfile(id)) {
				return;
			}
			return dispatch('fetchHorizonProfile', id);
		},
		ensureSystemOperators({ getters, dispatch }) {
			if (getters.operators.length) {
				return;
			}
			return dispatch('fetchSystemOperators');
		},
		fetchHorizonProfile({ commit }, id) {
			return api
				.findHorizonProfile(id)
				.then(horizonProfile => commit('setHorizonProfile', { id, horizonProfile }))
				.catch(manageError);
		},
		fetchDedicatedSystems({ commit }) {
			api.fetchDedicatedSystems()
				.then(dedicatedSystems => {
					commit('setDedicatedSystems', dedicatedSystems);
					commit('dedicatedSystemsUpdated');
				})
				.catch(manageError);
		},
		fetchDedicatedSystem({ commit }, tenantId) {
			api.fetchDedicatedSystem(tenantId)
				.then(dedicatedSystem => {
					commit('setDedicatedSystem', dedicatedSystem);
					commit('dedicatedSystemsUpdated');
				})
				.catch(manageError);
		},
		fetchSystemOperators({ commit }) {
			api.fetchSystemOperators()
				.then(systemOperators => {
					commit('setSystemOperators', systemOperators);
				})
				.catch(manageError);
		},
		updateHorizonProfile({ dispatch }, { id, profile }) {
			return api
				.updateHorizonProfile(id, profile)
				.then(() => Promise.all([
					dispatch('fetchHorizonProfile', id),
					dispatch('fetchOne', id)
				]))
				.catch(manageError);
		},
		updateAssociatedTenants(context, { newTenants, id }) {
			return updateAssociation(context, 'tenants', 'systems', newTenants, id);
		},
		updateAssociatedMissionProfiles(context, { newMissionProfiles, id }) {
			return updateAssociation(context, 'missionProfiles', 'systems', newMissionProfiles, id);
		}
	}
};
