const progressBarConfig = {
	color: 'hsl(0, 0%, 100%)',
	failedColor: 'hsl(360, 90%, 60%)',
	thickness: '4px',
	transition: {
		opacity: '0.6s',
		termination: 400
	}
};

const showdownConfig = {
	flavor: 'github',
	options: {
		emoji: true,
		tables: true,
		tasklists: true,
		simpleLineBreaks: true,
		underline: true,
		strikethrough: true,
		simplifiedAutoLink: true,
		parseImgDimensions: true,
		disableForced4SpacesIndentedSublists: true,
		completeHTMLDocument: false // Default is false, but added to remind developers this is an option
	}
};

export { progressBarConfig, showdownConfig };
