// Vite building
// Dynamically import the JsonSchemaForm component to leverage code-splitting and lazy loading.
// The dynamically imported module is then statically exported to maintain a consistent export interface.
let Form: any;

(async () => {
	const module = await import('./Form/JsonSchemaForm.vue');
	Form = module.default;
})();

export { Form };
export { default as View } from './View/JsonSchemaView.vue';
