// These constants should be the same as the one found in ~webserver/.env
export const NAMESPACE_PREPEND_PROXY = '/PROXY:';
export const EMIT_EDITOR_USER_LIST = 'EMIT_EDITOR_USER_LIST';
export const EMIT_EDIT_START = 'EMIT_EDIT_START';
export const EMIT_EDIT_STOP = 'EMIT_EDIT_STOP';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const HEADER_SOCKET_ID = 'x-kogni-socket-id';
export const HEADER_REFETCH_ID = 'x-kogni-refetch-id';
export const HEADER_REFETCH_ID_KEY = 'x-kogni-refetch-id-key';
export const HEADER_REFETCH_NAMESPACE = 'x-kogni-refetch-namespace';
export const HEADER_HEIMDALL_TOKEN = 'x-kogni-heimdall-token';
export const HEADER_KOGS_TOKEN = 'x-kogni-kogs-token';
export const PROXY_PATH = '/proxy';
export const USER_PATH = '/user';
export const ENV_PRODUCTION = 'PRODUCTION';
export const ENV_PROD = 'PROD'; // Equal to production
export const ENV_PREPROD = 'PREPROD'; // Equal to staging
export const ENV_STAGING = 'STAGING';
export const ENV_TEST = 'TEST';
export const ENV_DEVELOPMENT = 'DEVELOPMENT';
export const ENV_DEV = 'DEV'; // Equal to development
export const ACTIVITY_NAMESPACE = '/ACTIVITY';
export const EMIT_ACTIVITY_IS_ACTIVE = 'EMIT_ACTIVITY_IS_ACTIVE';
export const EMIT_ACTIVITY_DISCONNECT = 'EMIT_ACTIVITY_DISCONNECT';
export const EMIT_ACTIVITY_ACTIVE_USERS = 'EMIT_ACTIVITY_ACTIVE_USERS';
export const PROXY_KOGS = 'kogs';
export const PROXY_HEIMDALL = 'heimdall';
export const PROXY_TYR = 'tyr';
export const PROXY_ORBITGUARD = 'orbitguard';
export const PROXY_TO_USE = 'x-kogni-proxy';
// urls
export const WEBSERVER_URL = import.meta.env.VITE_APP_SERVER_URL ||
    `${window.location.protocol}//${window.location.hostname}:${import.meta.env.VITE_APP_WEBSERVER_PORT ? `${import.meta.env.VITE_APP_WEBSERVER_PORT}` : `${window.location.port}`
    }`;
export const PROXY_URL = WEBSERVER_URL + PROXY_PATH;

// frontend only
export const ACTIVITY_UPDATE_INTERVAL = 60; // seconds
export const CONNECT_SOCKETS = 'CONNECT_SOCKETS';
