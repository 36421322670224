/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable curly */

import { TODO } from "../api/types";
import { entries } from "./safe";

export type UnwrapAsync<T> = T extends (...args: any[]) => Promise<infer U> ? U : T;
export type UnwrapArray<T> = T extends (infer U)[] ? U : T;

type Length<T extends any[]> = T extends { length: infer L } ? L : never;
type BuildTuple<L extends number, T extends any[] = []> = T extends { length: L } ? T : BuildTuple<L, [...T, any]>;

type Subtract<A extends number, B extends number> =
    BuildTuple<A> extends [...(infer U), ...BuildTuple<B>]
        ? Length<U>
        : never;

type MapString<Mapping extends Record<keyof any, string>, Key extends keyof any> = Key extends keyof Mapping ? Mapping[Key] : Key;

type MapKeys<Mapping extends Record<string, string>, T extends Record<string, unknown>, Dummy extends number = 0> =
{
	[K in keyof T as MapString<Mapping, K>]:
		T[K] extends Record<string, unknown>
			? MapKeys<Mapping, T[K], Subtract<Dummy, 1>>
			: T[K] extends (infer U)[]
				? U extends Record<string, unknown>
					? MapKeys<Mapping, U, Subtract<Dummy, 1>>[]
					: 1
				: T[K]
};

function mapString<const Mapping extends Record<keyof any, string>, const Key extends keyof any>(mapping: Mapping, key: Key): MapString<Mapping, Key> {
	if (key in mapping) {
		return mapping[key] as MapString<Mapping, Key>;
	}
	return key as MapString<Mapping, Key>;
}

export function mapKeys<const M extends Record<string, string>, O extends Record<string, unknown>>(mapping: M, object: O) {
	const obj: any = {};
	for (const [key, value] of entries(object)) {
		const newKey = mapString(mapping, key);
		if (value instanceof Object) {
			if (Array.isArray(value)) {
				obj[newKey] = value.map((v) => mapKeys(mapping, v));
			} else if (value instanceof Object) {
				obj[newKey] = mapKeys(mapping, value as Record<string, unknown>);
			}
		} else {
			obj[newKey] = value;
		}
	}
	return obj as MapKeys<M, O>;
}

function map<T extends unknown[], R>(arr: [...T], callback: (args: T) => R): R[] {
	const result: R[] = [];
	for (const elem of arr) {
		result.push(callback(elem as TODO));
	}
	return result;
}
