<template lang="pug">
.loading-wrap(:class="{ inline, transparent }")
	.solar
</template>

<script>
export default {
	name: 'VLoadingView',
	props: {
		inline: {
			type: Boolean,
			required: false,
			default: false
		},
		transparent: {
			type: Boolean,
			required: false,
			default: false
		}
	}
};
</script>

<style lang="scss">
.loading-wrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--body-background);

	&.inline {
		position: relative;
		width: 100%;
		height: auto;
		min-height: rem-calc(200);
	}

	&.transparent {
		position: relative;
		width: rem-calc(100);
		height: rem-calc(100);
		background-color: transparent;
	}
}

.solar {
	position: relative;
	background-color: hsl(55 94% 54%);
	width: 10px;
	height: 10px;
	margin: 0 auto;
	border-radius: 50%;

	&:after,
	&:before {
		content: '';
		position: absolute;
		width: 5px;
		height: 5px;
		border-radius: 50%;
	}

	&:after {
		left: -10px;
		top: -5px;
		background-color: hsl(51 23% 72%);
		transform-origin: 15px 10px;
		animation: axis 1s linear infinite;
	}

	&:before {
		left: -25px;
		top: -15px;
		background-color: hsl(204 100% 50%);
		transform-origin: 30px 20px;
		animation: axis 2s linear infinite;
	}
}

@keyframes axis {
	0% {
		transform: rotateZ(0deg) translate3d(0, 0, 0);
	}

	100% {
		transform: rotateZ(360deg) translate3d(0, 0, 0);
	}
}
</style>

