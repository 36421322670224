import { state, mutations, actions, getters } from '@/store/build-module/common';
import changelogActions from '@/store/build-module/changelog-actions';
import baseAPIBuilder from '@/js/api/builder/api-builder';
import { getScope } from '@/js/auth/auth-config';

import { connection as reservationWindowsConnection } from '@/js/api/reservation-windows';
import { dedicatedSystemsConnection } from './dedicated-systems';

const module = 'tenants';

const meta = {
	module,
	basePath: '/v1.0/tenants',
	mapping: {
		lifecycle_state: 'lifecycleState',
		mission_profiles: 'missionProfiles',
		organization_id: 'organizationId'
	},
	parser: data => data.tenant || data.data || data,
	inputWrapper: input => ({ data: input }),
	scope: await getScope('kogs')
};
const builder = new baseAPIBuilder(meta);
const api = {
	...builder.build(['list', 'add', 'update', 'find'])
};

export default {
	namespaced: true,
	state: () => ({
		...state(module)
	}),
	getters: {
		...getters(module)
	},
	mutations: {
		...mutations(module)
	},
	actions: {
		...actions(api),
		...changelogActions([
			{ connection: api.connection, objectType: module },
			{ connection: reservationWindowsConnection, objectType: 'reservationWindows' },
			{ connection: dedicatedSystemsConnection, objectType: 'dedicatedSystems' }
		]),
		initiateMore ({ dispatch }) {
			dispatch('reservationWindows/initiateMore', null, { root: true });
		}
	}
};
