<template lang="pug">
template(v-if="principal")
	span(v-if="principal.isLoading") Loading user...
	template(v-else)
		VTooltip(
			v-if="showIcon && icon"
			:text="kindName"
		)
			VIcon.principal-icon(:name="icon")
		VTooltip(
			v-if="isApiKey"
			:text="`Principal id: ${user.id}`"
		)
			| {{ userText }}
		VTooltip(
			v-else-if="user.isYou"
			:text="`Logged in as: ${user.name}`"
		)
			| You
		VTooltip(
			v-else-if="user.isUnknown"
			:text="`Principal id: ${user.id}`"
		)
			| [unknown]
		VTooltip(
			v-else
			:text="user.lastActive ? `Last active: ${user.lastActive ? $time.formatDateTime(user.lastActive) : 'unknown'}` : ''"
		)
			| {{ userText }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { ACTIVITY_UPDATE_INTERVAL } from '@/env';

import { principalKinds } from '@/js/utils/data-definitions';
import { formattedPrincipalName } from '@/js/utils/principal-name-formatted';

// ACTIVITY_UPDATE_INTERVAL with small padding or 3 minutes
const secondsOfNoActivityBeforeAway = Math.max(ACTIVITY_UPDATE_INTERVAL + 10, 3 * 60);

export default {
	name: 'VUser',
	props: {
		principal: {
			type: String,
			required: false,
			default: null
		},
		showIcon: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	data() {
		return {
			loading: false
		};
	},
	computed: {
		user() {
			return this.findPrincipal(this.principal);
		},
		userText() {
			return formattedPrincipalName(this.user);
		},
		owner() {
			return this.user.owner;
		},
		state() {
			if (this.user.isUnknown) {
				return null;
			}

			if (this.user.isOnline) {
				const diffNow = this.now.diff(this.user.lastActive, 'seconds').toObject();
				if (diffNow.seconds > secondsOfNoActivityBeforeAway) {
					return 'away';
				}

				return 'online';
			}

			return 'offline';
		},

		icon() {
			return principalKinds[this.user.kind]?.icon;
		},

		kindName() {
			return principalKinds[this.user.kind]?.name;
		},

		isIndividual() {
			return principalKinds.individual.id === this.user.kind;
		},

		isApiKey() {
			return principalKinds.key.id === this.user.kind;
		},

		...mapGetters({
			findPrincipal: 'principals/find',
			now: 'time/now'
		})
	},
	watch: {
		principal() {
			this.ensurePrincipal(this.principal);
		}
	},
	created() {
		this.ensurePrincipal(this.principal);
	},
	methods: {
		...mapActions({
			ensurePrincipal: 'principals/ensure'
		})
	}
};
</script>

<style lang="scss" scoped>
.principal-icon {
	margin-right: rem-calc(4);
}
</style>
