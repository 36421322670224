<template lang="pug">
li.contact(
	:class="modifierClasses"
	@click="didClickToggle"
)
	.inner
		.selection
			VIcon(
				:class="selectionIconModifierClasses"
				:name="selectionIcon"
			)
		.spacecraft-name
			VTooltip(:text="spacecraft.name")
				.text {{spacecraft.name}}
		.system-name
			VIcon(name="system")
			VTooltip(:text="system.name")
				.text {{system.name}}
		.principal
			template(v-if="hasPrincipal")
				VIcon(:name="principalIcon")
				VTooltip(:text="principalName")
					.text {{principalName}}
		.time
			VIcon(name="time")
			.text
				time(:datetime="isoStartTime") {{formattedStartTime}}
				| {{' - '}}
				time(:datetime="isoEndTime") {{formattedEndTime}}
		.comment
			template(v-if="hasComment")
				VIcon(name="speechBubble")
				VTooltip(:text="comment")
					.text {{comment}}
		OutcomeColumn(
			:outcome="outcome"
			:signature="signature"
			:signatureProfileId="signatureProfileId"
		)
</template>

<script>

import delegate from '@/js/utils/delegate';
import { toDateTime } from '@/js/utils/filter/types';

import evaluationPropsMixin from '@/components/feature/ContactsSignModal/lib/mixins/evaluation-props';

import OutcomeColumn from './OutcomeColumn/OutcomeColumn.vue';

export default {
	name: 'TimelineContact',
	components: {
		OutcomeColumn
	},
	mixins: [
		evaluationPropsMixin()
	],
	inject: [
		'selectContact'
	],
	props: {
		contact: {
			type: Object,
			required: true
		},
		signature: {
			type: Object,
			required: true
		},
		signatureProfileId: {
			type: String,
			required: true
		},
		isSelected: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		...delegate('id', 'spacecraft', 'system', 'startTime', 'endTime', { to: 'contact' }),
		evaluation() {
			return this.signature?.evaluations[0] ?? null;
		},
		contactPath() {
			return `/contacts/${this.id}`;
		},
		modifierClasses() {
			return {
				'is-selectable': this.isSelectable
			};
		},
		selectionIconModifierClasses() {
			return {
				'is-disabled': !this.isSelectable
			};
		},
		selectionIcon() {
			if (this.isSelectable && this.isSelected) {
				return '20/form/checkbox.checked';
			}

			return '20/form/checkbox';
		},
		isoStartTime() {
			return toDateTime(this.startTime).toISO();
		},
		formattedStartTime() {
			return this.$time.formatTime(this.startTime);
		},
		isoEndTime() {
			return toDateTime(this.endTime).toISO();
		},
		formattedEndTime() {
			return this.$time.formatTime(this.endTime);
		},
		principalIcon() {
			switch (this.principal?.kind) {
				case 'system':
					return 'component';
				case 'individual':
					return 'profile';
				default:
					return 'deprecated';
			}
		},
		hasPrincipal() {
			return this.principalId !== null;
		},
		principalName() {
			return this.principal?.name ?? '…';
		},
		hasComment() {
			return this.comments.length > 0;
		},
		comment() {
			return this.comments[0]?.comment;
		},
		isSelectable() {
			return true;
		}
	},
	methods: {
		didClickToggle() {
			this.selectContact(this.id, !this.isSelected);
		}
	}
};

</script>

<style lang="scss" scoped>
.contact {
	--offset: var(--box-padding);

	margin: 0 calc(var(--offset) * -1);
	padding: 0 var(--offset);
	position: relative;

	&:not(:last-child) .inner {
		border-bottom: rem-calc(1) solid hsl(var(--white20));
	}

	.inner {
		align-items: center;
		display: flex;

		> * {
			padding: rem-calc(15) 0;

			&:not(:first-child) {
				margin-left: rem-calc(14);
			}
		}
	}

	&.is-selectable {
		cursor: pointer;
	}

	&.is-selectable:hover {
		&:before {
			opacity: 1;
			left: 0;
			right: 0;
		}
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		left: var(--offset);
		right: var(--offset);
		background: hsl(var(--black) / 20%);
		top: rem-calc(-1);
		bottom: 0;
		border-top: rem-calc(1) solid hsl(var(--white20));
		border-bottom: rem-calc(1) solid hsl(var(--white20));
		opacity: 0;
		transition-property: opacity left right;
		transition-duration: 0.25s;
		transition-timing-function: ease-in-out;
	}

	&:last-child {
		&:before {
			border-bottom: 0;
		}
	}
}

.selection {
	.icon {
		&.is-disabled {
			opacity: 0.25;
		}
	}
}

.spacecraft-name {
	position: relative;
	flex: 0 0 rem-calc(100);
	min-height: rem-calc(16);

	.tooltip {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

		.text {
			left: 0;
		}
	}

	.text {
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		line-height: 2;
		font-size: rem-calc(16);
		font-weight: 600;
	}
}

.system-name,
.principal,
.time,
.comment {
	--icon-color-primary: hsl(var(--white60));
	--icon-size: #{rem-calc(16)};

	display: flex;
	position: relative;
	color: var(--icon-color-primary);

	.icon {
		flex: 0 0 auto;
	}

	.tooltip {
		position: absolute;
		left: calc(var(--icon-size) + #{rem-calc(4)});
		right: 0;
		top: 0;
		bottom: 0;

		.text {
			left: 0;
		}
	}

	.text {
		position: absolute;
		left: calc(var(--icon-size) + #{rem-calc(4)});
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		line-height: 2;
	}
}

.system-name {
	flex: 0 0 rem-calc(75);
}

.principal {
	flex: 0 0 rem-calc(110);
}

.time {
	flex: 0 0 rem-calc(180);
}

.comment {
	--icon-color-primary: hsl(var(--white));

	flex: 0 1 100%;
}

.selection,
.outcome,
.open {
	flex: 0 0 auto;
}

.open {
	a {
		--color: hsl(var(--white60));
		--icon-color-primary: var(--color);
		--icon-size: #{rem-calc(16)};

		display: flex;
		align-items: center;
		text-decoration: none;
		color: var(--color);

		&:hover {
			--color: hsl(var(--white));

			opacity: 1 !important;
		}

		.text {
			margin-left: rem-calc(4);
		}

		#{v-deep('.icon .fill-primary')},
		#{v-deep('.icon .stroke-primary')} {
			transition: none;
		}
	}
}
</style>
