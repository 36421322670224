<template lang="pug">
.identifier(@click="copyID")
	VTooltip(:text="`${typeName} ID (click to copy)`")
		.id
			slot
	.copy
		| {{actionText}}
		VIcon(name="copy")
</template>

<script>
import { copyToClipboard } from '@/js/utils/copy.js';

export default {
	name: 'IdentifierCopy',
	props: {
		type: {
			type: String,
			required: false,
			default: ''
		},
		toast: {
			type: String,
			required: false,
			default: 'Copied ID!'
		},
		actionText: {
			type: String,
			required: false,
			default: 'Copy ID'
		}
	},
	computed: {
		identifier() {
			return this.$slots.default()[0].children;
		},
		typeName() {
			return this.type || this.$route.meta.type || 'Unique';
		}
	},
	methods: {
		copyID() {
			copyToClipboard(this.identifier);
			this.$notify.event(this.toast, 'success');
		}
	}
};
</script>

<style lang="scss" scoped>
.identifier {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: var(--font-small-size);
	padding: rem-calc(12);
	border: 1px solid hsl(var(--white20));
	border-radius: var(--global-radius);
	cursor: pointer;
	min-width: 0;
	user-select: none;
	background-color: hsl(var(--dark-purple));

	&:hover {
		border-color: hsl(var(--white40));
	}

	&:active {
		border-color: hsl(var(--white10));
	}

	.id {
		font-family: var(--font-family-mono);
		font-weight: var(--font-normal);
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.copy {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		margin-left: rem-calc(12);

		.icon {
			opacity: 0.4;
			margin-left: rem-calc(7);
		}
	}
}
</style>
