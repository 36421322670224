<template lang="pug">
Popper(
	:arrow="true"
	:z-index="1"
	:placement="placement"
)
	slot
	template(#content="{ close }")
		header(v-if="title || showCloseButton")
			h4(v-if="title") {{ title }}
			button.close(@click="close")
				VIcon(name="cross")
		slot(name="content")
</template>

<script>
import Popper from 'vue3-popper';

export default {
	name: 'PopupWindow',
	components: {
		Popper
	},
	props: {
		title: {
			type: String,
			required: false,
			default: null
		},
		showCloseButton: {
			type: Boolean,
			required: false,
			default: false
		},
		placement: {
			type: String,
			required: false,
			default: 'bottom'
		}
	}
};
</script>

<style lang="scss" scoped>
#{v-deep('.popper')} {
	--popper-theme-padding: #{rem-calc(10)};

	min-width: rem-calc(250);
}

header {
	margin-bottom: rem-calc(15);

	h4 {
		font-weight: var(--font-boldest);
		font-size: rem-calc(18);
		margin: rem-calc(0 20 0 0);
		white-space: nowrap;
	}
}

.close {
	position: absolute;
	top: rem-calc(5);
	right: rem-calc(5);
	appearance: none;
	border: 0;
	background-color: transparent;
	padding: 0;
	margin: 0;
	cursor: pointer;
	opacity: 0.4;

	&:hover {
		opacity: 1;
	}

	.icon {
		margin: 0 !important;
		width: rem-calc(24);
		height: rem-calc(24);
	}
}

.list {
	list-style: none;
	margin: rem-calc(15 0);
	padding: 0;
	max-height: calc(var(--manage-list-item-height) * 7);
	overflow: auto;

	@include scrollbars(0.5rem, hsl(var(--white20)));

	.item {
		height: var(--manage-list-item-height);
	}
}

.button {
	width: 100%;
	justify-content: center;
	margin: 0;
}
</style>
