<template lang="pug">
.schema-view-property(
	:class="containerClasses"
	:data-level="level"
	:style="containerStyle"
)
	.schema-view-property-name
		| {{name}}
	.schema-view-property-value
		template(v-if="hasValueComponent")
			component(
				:is="valueComponent"
				v-bind="valueProps"
			)
		template(v-else)
			p Unknown

</template>

<script>

import { camelize, dasherize } from '@/js/utils/case-conversion';
import { expandRef, prettyPropertyName } from '@/js/utils/json-schema';

import componentFor from '../lib/component-for';
import propsFor from '../lib/props-for';
import typeFor from '../lib/type-for';

export default {
	name: 'SchemaViewProperty',
	inject: [
		'level'
	],
	props: {
		id: {
			type: String,
			required: true
		},
		value: {
			required: false,
			default: null,
			validator() {
				return true;
			}
		},
		schema: {
			type: Object,
			required: true
		}
	},
	computed: {
		type() {
			if (typeof this.value === 'undefined' || this.value === null) {
				return 'blank';
			}

			return typeFor(this.schema);
		},
		name() {
			return prettyPropertyName(this.schema, this.id);
		},
		hasValueComponent() {
			return this.valueComponent !== null;
		},
		valueComponent() {
			return componentFor(this.type);
		},
		valueProps() {
			return propsFor(this.schema, this.value);
		},
		containerClasses() {
			return [
				`is-${dasherize(this.type)}`,
				{
					'is-inline': this.isInline
				}
			];
		},
		containerStyle() {
			return {
				'--level': this.level
			};
		},
		isInline() {
			if (this.isArray) {
				return this.value.length === 0 ||
					expandRef(this.schema, this.schema.items).type !== 'object';
			} else if (this.isObject) {
				return false;
			}

			return true;
		},
		...[
			'array',
			'object'
		].reduce((computed, type) => {
			return {
				...computed,
				[camelize(`is-${type}`)]() {
					return this.type === type;
				}
			};
		}, {})
	}

};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/json-schema';

.schema-view-property {
	--level: 0;
	--is-indented: min(1, var(--level));
	--property-inset: var(--indentation);
	--property-inset-right: var(--property-inset);
	--property-inset-left:
		max(
			0rem,
			calc(
				var(--property-inset) - (var(--indentation-width) * var(--is-indented))
			)
		);
	--property-padding-top: #{rem-calc(12)};
	--property-padding-bottom: var(--property-padding-top);
	--property-background: transparent;
	--property-background-hightlight: hsl(var(--white05));
	--name-width: #{rem-calc(300)};
	--indentation: #{rem-calc(20)};
	--indentation-color: #{list.nth($json-schema-indentation-colors, 1)};
	--indentation-width: #{rem-calc(3)};

	display: flex;
	align-items: flex-start;
	padding:
		var(--property-padding-top)
		var(--property-inset-right)
		var(--property-padding-bottom)
		var(--property-inset-left);
	background: var(--property-background);
	margin: 0 calc(var(--property-inset-right) * -1) 0 calc(var(--property-inset-left) * -1);

	@for $level from 2 through length($json-schema-indentation-colors) {
		&[data-level='#{$level - 2}'] {
			--indentation-color: #{list.nth($json-schema-indentation-colors, $level)};
		}
	}

	&:nth-child(2n-1) {
		--property-background: var(--property-background-hightlight);
	}

	&-name {
		flex: 0 0 calc(var(--name-width) - (var(--indentation) * var(--level)));
		line-height: 1;
		color: hsl(var(--white60));
	}

	&-value {
		position: relative;
		flex: 0 1 100%;
		align-self: stretch;
		margin-left: var(--indentation);

		.schema-view-property {
			line-height: 1;
		}

		#{v-deep('p')} {
			margin-bottom: 0;
			line-height: 1;
		}
	}

	&:not(.is-inline) {
		display: block;
		background: transparent;
		padding-left: 0;
		padding-right: 0;
		margin: 0;

		&:not(:first-child) {
			padding-top: rem-calc(10);
		}

		&:not(:last-child) {
			padding-bottom: rem-calc(20);
		}

		> .schema-view-property-name {
			padding-bottom: rem-calc(12);
		}

		> .schema-view-property-value {
			position: relative;
			margin-top: rem-calc(15);
			margin-bottom: 0;
		}
	}

	&.is-array:not(.is-inline),
	&.is-object {
		padding-top: 0;

		> .schema-view-property-name {
			// Match zebra styling of regular properties
			padding:
				var(--property-padding-top)
				var(--property-inset-right)
				var(--property-padding-bottom)
				var(--property-inset-left);
			background: var(--property-background);
			margin: 0 calc(var(--property-inset-right) * -1) 0 calc(var(--property-inset-left) * -1);

			// Labels are brighter than regular objects
			color: hsl(var(--white));
		}

		&:last-child {
			padding-bottom: 0;
		}

		// Reduce distance between objects/arrays and objects/arrays that are not
		// zebra striped
		& + & {
			&:not(:nth-child(2n-1)) > .schema-view-property-name {
				padding-top: 0;
			}
		}

		&[data-level='0'],
		&[data-level='1'] {
			> .schema-view-property-name {
				padding-top: 0;
				margin-bottom: rem-calc(20);
				font-size: rem-calc(24);
				font-weight: 500;
				color: hsl(var(--white));
				background: transparent;
			}
		}

		&[data-level='0'] {
			// Increase distance between inline and non-inline properties at level 0
			.is-inline + & {
				margin-top: var(--property-padding-top);
			}
		}

		&[data-level='1'] {
			padding-top: var(--property-padding-top);

			> .schema-view-property-name {
				margin-bottom: rem-calc(12);
				font-size: rem-calc(18);
			}
		}

		// Reduce distance of adjacent objects/arrays at levels greater than 0
		&:not([data-level='0']) + &:not([data-level='0']) {
			padding-top: 0;
		}
	}

	&.is-object {
		> .schema-view-property-value {
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: calc(var(--indentation) / -1);
				width: var(--indentation-width);
				background: var(--indentation-color);
			}
		}
	}
}
</style>
