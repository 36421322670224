<template lang="pug">
ul.details
	component(
		v-for="{ component, props } in renderableItems"
		:is="component"
		v-bind="props"
	)
	slot
</template>

<script>

import Detail from './Detail.vue';

export default {
	name: 'SubmissionDetails',
	props: {
		items: {
			type: Array,
			required: false,
			default() {
				return [];
			}
		}
	},
	computed: {
		renderableItems() {
			return this.items.map((item) => {
				return {
					...item,
					component: item.component ?? Detail
				};
			});
		}
	}
};

</script>

<style lang="scss" scoped>
.details {
	list-style: none;
	display: flex;
	margin: 0;
	padding: 0;
	flex-wrap: wrap;
}
</style>
