<template lang="pug">
tr.comp-expandable-list-row.view(
	:class="[{ expanded: expanded }]"
	@click="toggleDetails"
)
    slot

tr.expanded-content(v-if="expanded")
    slot(name='expandedContent')
</template>

<script>
export default {
	name: 'VExpandableListRow',
	data() {
		return {
			expanded: false
		};
	},
	methods: {
		toggleDetails() {
			this.expanded = !this.expanded;
		}
	}
};
</script>

<style lang="scss">
.comp-expandable-list-row {
	box-shadow: inset 0 1px 0 0 hsl(var(--white20));
	cursor: pointer;

	&:hover .column.dimmed {
		color: hsl(var(--white));
	}

	td {
		padding: rem-calc(9 9);
		white-space: nowrap;
		line-height: 1;
	}

	.column {
		display: inline-flex;
		align-items: center;
		font-size: var(--font-table-size);

		&.dimmed {
			color: hsl(var(--white70));
		}

		&.notice {
			color: hsl(var(--orange));
		}

		.icon {
			margin-right: rem-calc(5);
			opacity: 0.7;
		}
	}

	&.expanded {
		.toggle {
			.icon {
				transform: rotate(90deg);
			}
		}
	}

	.name {
		font-weight: var(--font-bold);
	}
}

</style>
