<template lang="pug">
.pill(:class="modifierClasses")
	VIcon(
		v-if="hasIcon"
		:name="icon"
	)
	| {{label}}
</template>

<script>

export default {
	name: 'SchemaViewPill',
	props: {
		icon: {
			type: String,
			required: false,
			default: null
		},
		label: {
			type: String,
			required: true
		}
	},
	computed: {
		modifierClasses() {
			return {
				'has-icon': this.hasIcon
			};
		},
		hasIcon() {
			return this.icon !== null;
		}
	}
};

</script>

<style lang="scss" scoped>
.pill {
	--icon-size: #{rem-calc(16)};
	--icon-color-primary: hsl(var(--white60));

	position: relative;
	display: inline-block;
	padding: rem-calc(6) rem-calc(12) rem-calc(6.5);
	border-radius: 100px;
	background: hsl(var(--white15));
	color: hsl(var(--white80));
	text-transform: uppercase;
	font-size: rem-calc(13);
	font-weight: 500;
	letter-spacing: rem-calc(0.7);
	line-height: 1;

	&.has-icon {
		padding-left: rem-calc(26);
	}

	.icon {
		position: absolute;
		top: 50%;
		left: rem-calc(7);
		transform: translateY(-50%);
	}
}
</style>
