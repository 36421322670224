import {
	set as setPath
} from 'lodash';

import Connection from '@/js/network/helpers';
import { $text } from '@/js/plugins/text';
import { getScope } from '@/js/auth/auth-config';
import { underscoreKeys } from '@/js/utils/case-conversion';

import { camelizeJsonSchemaResponse } from './json-schemas';

const kogs = new Connection('/v1.0', await getScope('kogs')).axios;

const systemSpecificationsPath = (systemId) => {
	return `/systems/antennas/${systemId}/specs`;
};

const systemSpecificationPath = (systemId, id) => {
	return `${systemSpecificationsPath(systemId)}/${id}`;
};

const parseSchemaError = (error) => {
	if (error?.response?.data?.code !== 'INVALID_BODY') {
		throw error;
	}

	error.response.data.errors = error.response.data.message
		.split("\n")
		.slice(1, -1)
		.reduce((errors, line) => {
			const [path, message] = line
				.replace(/^\$\./, '')
				.split(':')
				.map((string) => {
					return string.trim();
				});

			const fullPath = [
				...path
					.split('.')
					.slice(0, -1)
					.map((segment) => {
						return `${segment}.errors`;
					}),
				path.split('.').slice(-1),
				'message'
			].join('.');

			setPath(errors, fullPath, $text.toSentenceCase(message, { preserveCase: false }));
			return errors;
		}, {});

	throw error;
};

export const getAllSystemSpecifications = (systemsIds) => {
	return kogs.post('/systems/antennas/specs', { systems_ids: systemsIds })
		.then((response) => {
			return {
				...response,
				data: camelizeJsonSchemaResponse(response.data)
			};
		})
		.catch(parseSchemaError);
};

export const getSystemSpecifications = (systemId) => {
	return kogs.get(systemSpecificationsPath(systemId))
		.then((response) => {
			return {
				...response,
				data: camelizeJsonSchemaResponse(response.data)
			};
		});
};

export const getSystemSpecification = (systemId, id) => {
	return kogs.get(systemSpecificationPath(systemId, id))
		.then((response) => {
			return {
				...response,
				data: camelizeJsonSchemaResponse(response.data)
			};
		});
};

export const createSystemSpecification = (systemId, { schemaId, object }) => {
	const path = systemSpecificationsPath(systemId);
	const payload = underscoreKeys({
		data: {
			jsonSchemaId: schemaId,
			jsonObject: object
		}
	});

	return kogs.post(path, payload, { refetchId: systemId }) // refetchId added to silence console error. TODO: Add listener functionality
		.then((response) => {
			return {
				...response,
				data: camelizeJsonSchemaResponse(response.data)
			};
		})
		.catch(parseSchemaError);
};

export const updateSystemSpecification = (systemId, id, { patches }) => {
	const path = systemSpecificationPath(systemId, id);
	const payload = underscoreKeys({
		data: patches
	});

	return kogs.patch(path, payload, { refetchId: systemId }) // refetchId added to silence console error. TODO: Add listener functionality
		.then((response) => {
			return {
				...response,
				data: camelizeJsonSchemaResponse(response.data)
			};
		})
		.catch(parseSchemaError);
};
