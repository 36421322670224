import { principalKinds } from '@/js/utils/data-definitions';

export const formattedPrincipalName = (principal) => {
	const name = principal.name;
	const owner = principal.owner;
	const principalTags = principal?.tags?.length ? ` - ${principal.tags}` : '';

	if (principalKinds.key.id === principal.kind) {
		const ownerName = owner?.name ? ` - ${owner.name}` : '';
		const ownerTags = owner?.tags?.length ? ` - ${owner.tags}` : '';
		return `${name} ${ownerName} ${ownerTags}`;
	} else if (principal.isYou) {
		return 'You';
	} else if (principal.isUnknown) {
		return '[unknown]';
	}
	return `${name} ${principalTags}`;
};
