const settings = {
	hideDeprecated: {
		defaultValue: false,
		title: 'Hide deprecated items',
		helperText: 'Hide from main lists and searches.',
		type: 'boolean'
	},
	logActivity: {
		defaultValue: true,
		title: 'Log activity',
		helperText: 'Log activity to get suggestions.',
		type: 'boolean'
	}
};

export default settings;
