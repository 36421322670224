import store from '@/store/store';

const mapSettings = settings => {
	if (typeof settings !== 'object') {
		console.error('Map settings should be called with an array or an object');
	}

	let obj = settings;

	if (Array.isArray(settings)) {
		obj = {};
		settings.forEach(key => obj[key] = key);
	}

	Object.keys(obj).forEach(key => {
		const setting = obj[key];
		obj[key] = {
			get() {
				return store.getters['settings/find'](setting);
			},
			set(value) {
				return store.dispatch('settings/save', { setting, value });
			}
		};
	});

	return obj;
};

export { mapSettings };
