import { extendContacts } from "@/store/modules/contact";
import ContactState from "@/js/classes/contact-state";
import { frontendStates } from '@/js/utils/data-definitions';
import { DateTime } from 'luxon';

// only use frontend states that has either a startTimeKey or an endTimeKey
const timeStates = {};
Object.keys(frontendStates).forEach(key => {
	const { startTimeKey, endTimeKey } = frontendStates[key];
	if (startTimeKey || endTimeKey) {
		timeStates[key] = frontendStates[key];
	}
});

export const PAST_STATE = frontendStates.insigning.id;

export default {
	namespaced: true,
	state() {
		return {
			timeouts: []
		};
	},
	mutations: {
		setTimeouts: (state, timeoutList) => {
			timeoutList.forEach(({ id }) => {
				const timeouts = state.timeouts.find(contact => contact.id === id);
				if (timeouts) {
					// clear timeouts
					timeouts.timeouts.forEach(timeout => clearTimeout(timeout));
				}
			});

			state.timeouts = [
				...timeoutList,
				...state.timeouts.filter(contact => !timeoutList.find(({ id }) => contact.id === id))
			];
		}
	},
	actions: {
		scheduleContacts: ({ commit }, contacts) => {
			if (!contacts.length) {
				return [];
			}
			const relevantContacts = contacts.filter(contact => ContactState.committed.is(contact.state)); // Using string comparison here to not extend irrelevant contacts.
			const extendedContacts = extendContacts(relevantContacts);

			const now = DateTime.utc().toMillis();
			const contactTimeouts = [];
			const activeStates = [];

			extendedContacts.forEach(extendedContact => {
				const timeouts = [];
				const setTimeoutFunction = (delay, frontendState = null) => timeouts.push(setTimeout(() =>
					commit('contacts/updateState', { id: extendedContact.id, newState: frontendState }, { root: true }), delay)
				);

				Object.values(timeStates).forEach(({ id, startTimeKey, endTimeKey }) => {
					const frontendState = id;

					const delay = extendedContact[startTimeKey].toMillis() - now;
					if (delay > 0) {
						setTimeoutFunction(delay, frontendState);
						return;
					}

					const stateIsActiveNow = extendedContact[endTimeKey].toMillis() > now && extendedContact[startTimeKey].toMillis() <= now;
					if (stateIsActiveNow) {
						activeStates.push({ id: extendedContact.id, newState: frontendState });
					}
				});

				const endTimeDelay = extendedContact.endTimePadded.toMillis() - now;
				if (endTimeDelay > 0) {
					setTimeoutFunction(endTimeDelay, PAST_STATE);
				} else {
					activeStates.push({ id: extendedContact.id, newState: PAST_STATE });
				}

				contactTimeouts.push({ id: extendedContact.id, timeouts });
			});

			commit('setTimeouts', contactTimeouts);
			return activeStates;
		}
	}
};
