import Vuex from 'vuex';

import contacts from './modules/contacts';
import organizations from './modules/organizations';
import missionProfiles from './modules/mission-profiles';
import reservationWindows from './modules/reservation-windows';
import dedicatedSystems from './modules/dedicated-systems';
import opsUnits from './modules/ops-units';
import spacecrafts from './modules/spacecrafts';
import stations from './modules/stations';
import systems from './modules/systems';
import tenants from './modules/tenants';
import downtimes from './modules/downtimes';
import partnerAllocations from './modules/partnerAllocations';
import problematics from './modules/problematics';
import filters from './modules/filters';
import principals from './modules/principals';
import brokenIds from './modules/broken-ids';
import reservations from './modules/reservations';
import frontendStates from './modules/frontend-states';
import signatureComponents from './modules/signature-components';
import signatureProfiles from './modules/signature-profiles';
import preferences from './modules/preferences';
import jsonSchemas from './modules/json-schemas';
import orbitGuard from './modules/orbit-guard';
import serviceNow from './modules/service-now';

import migratedAssets from './modules/migrated-assets';
import releaseLog from './modules/release-log';
import documentation from './modules/documentation';

import settings from './modules/settings';
import user from './modules/user';
import environment from './modules/environment';
import time from './modules/time';
import favorites from './modules/favorites';

export default new Vuex.Store({
	modules: {
		contacts,
		organizations,
		missionProfiles,
		spacecrafts,
		stations,
		systems,
		tenants,
		environment,
		reservationWindows,
		dedicatedSystems,
		opsUnits,
		downtimes,
		partnerAllocations,
		problematics,
		filters,
		principals,
		releaseLog,
		documentation,
		settings,
		user,
		time,
		favorites,
		brokenIds,
		migratedAssets,
		reservations,
		frontendStates,
		signatureComponents,
		signatureProfiles,
		preferences: preferences as any,
		jsonSchemas,
		orbitGuard,
		serviceNow
	},
	strict: import.meta.env.MODE !== 'production'
});
