import { state, mutations, actions, getters } from '@/store/build-module/common';
import changelogActions from '@/store/build-module/changelog-actions';
import { getScope } from '@/js/auth/auth-config';
import { manageError } from '@/store/helpers/store-error';
import baseAPIBuilder from '@/js/api/builder/api-builder';
import { extendContact, toApiContact } from '@/store/modules/contact';

import {
	UPDATE_RESOURCE,
	EMIT_EDITOR_USER_LIST
} from '@/env';

const basePath = '/v1.0/contacts';
const module = 'contacts';

const meta = {
	module,
	basePath,
	mapping: {
		end_time: 'endTime',
		start_time: 'startTime',
		created_at: 'createdAt',
		mission_profile_id: 'missionProfile',
		system_id: 'system',
		spacecraft_id: 'spacecraft',
		reservation_id: 'reservationId',
		signed_at: 'signedAt',
		entity_system: 'entitySystem',
		entity_individual: 'entityIndividual',
		updated_at: 'updatedAt',
		updated_after: 'updatedAfter',
		external_ref: 'externalRef'
	},
	dateFields: ['start_time', 'end_time', 'created_at', 'signed_at', 'updated_at', 'updated_after'],
	parser: data => data.contact || data.data || data,
	inputWrapper: input => ({ data: input }),
	scope: await getScope('kogs')
};
const builder = new baseAPIBuilder(meta);
const api = builder.build(['list', 'add', 'find', 'update', 'remove']);

const baseActions = actions(api);

export default {
	namespaced: true,
	state: {
		...state(module)
	},
	getters: {
		...getters(module)
	},
	mutations: {
		...mutations(module),
		update: () => { /* empty placeholder for distributing updates to contact-schedule and contact */ },
		updateState: () => { /* empty placeholder for distributing updates to contact-schedule and contact */ },
		updateTicket: () => { /* empty placeholder for distributing updates to contact-schedule and contact */ }
	},
	actions: {
		...baseActions,
		...changelogActions({ connection: api.connection, objectType: module }),

		ensure: async ({ dispatch }) => {
			api.connection.socket.on(UPDATE_RESOURCE, ({ id }) => dispatch('fetchOne', id));
			api.connection.socket.on(EMIT_EDITOR_USER_LIST, userList => dispatch('setEditorUserList', userList));

			// ensure all dependencies
			await dispatch('reservations/initiate', null, { root: true });

			await Promise.all(
				['spacecrafts', 'systems', 'missionProfiles', 'stations', 'tenants', 'organizations']
					.map(moduleName => dispatch(`${moduleName}/ensure`, {}, { root: true }))
			);
		},

		fetch: async ({ dispatch }, filter) => {
			const contacts = await dispatch('fetchRaw', filter);
			// Schedule timeouts for active contacts and set the new state if they are active.
			const activeStates = await dispatch('frontendStates/scheduleContacts', contacts, { root: true });
			activeStates.forEach(({ id, newState }) => contacts.find(c => c.id === id).newState = newState);
			return contacts;
		},

		fetchRaw(scope, filter) {
			if (filter.contacts && filter.contacts.length > 35) { // Cutoff is arbitrarilty set at 35 to not overload the url filter towards kvasir. Max url lenght is around 2000 chars
				let contactIDs = [...filter.contacts];
				const promises = new Array(Math.ceil(contactIDs.length / 35))
					.fill(null)
					.map(() => api.list({ ...filter, contacts: contactIDs.splice(0, 35) }));
				return Promise.all(promises)
					.then((resp) => {
						return resp.flat();
					});
			}
			return api.list(filter);
		},

		fetchOne: async ({ dispatch, commit }, id) => {
			const contact = await api.find(id);
			const activeStates = await dispatch('frontendStates/scheduleContacts', [contact], { root: true });
			if (activeStates.length) {
				contact.newState = activeStates[0].newState;
			}
			commit('update', contact);
			return contact;
		},

		create({ commit, dispatch }, { contact, isInternal }) {
			return api
				.add(toApiContact(contact))
				.then(async newContact => {
					if (isInternal) {
						return newContact;
					}

					await dispatch('reservations/add', extendContact(newContact), { root: true });
					return await api.find(newContact.id);
				})
				.then(newContact => {
					commit('update', newContact);
					return newContact;
				})
				.catch(manageError);
		},

		update(context, contact) {
			return baseActions.update(context, toApiContact(contact));
		}
	}
};

const connection = builder.connection;
export { connection, api };
