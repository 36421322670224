import { state, mutations, actions, getters } from '@/store/build-module/common';
import changelogActions from '@/store/build-module/changelog-actions';
import baseAPIBuilder from '@/js/api/builder/api-builder';
import { getScope } from '@/js/auth/auth-config';

const module = 'missionProfiles';

const meta = {
	module,
	basePath: '/v1.0/mission_profiles',
	mapping: {
		config_url: 'configUrl',
		teardown_duration: 'teardownDuration',
		setup_duration: 'setupDuration',
		lifecycle_state: 'lifecycleState',
		band_services: 'bandServices',
		parameter_schema_id: 'parameterSchemaId'
	},
	parser: data => data.mission_profile || data.data || data,
	inputWrapper: input => ({ data: input }),
	scope: await getScope('kogs')
};
const builder = new baseAPIBuilder(meta);
const api = {
	...builder.build(['list', 'add', 'update', 'find'])
};

export default {
	namespaced: true,
	state: () => ({
		...state(module)
	}),
	getters: {
		...getters(module)
	},
	mutations: {
		...mutations(module)
	},
	actions: {
		...actions(api),
		...changelogActions({ connection: api.connection, objectType: module })
	}
};
