import _ from 'lodash';

const updateAssociation = ({ rootGetters, dispatch }, module, association, newItems, id) => {
	const currentItems = rootGetters[module + '/all'].filter(item => item[association].includes(id));

	const exists = item => currentItems.find(entry => entry.id === item.id);
	const isAdded = item => newItems.find(entry => entry.id === item.id);
	const isEdited = item => (isAdded(item) && !exists(item)) || (exists(item) && !isAdded(item));

	const promises = _.chain([...newItems, ...currentItems])
		.uniqBy('id')
		.filter(isEdited)
		.cloneDeep()
		.map(item => {
			isAdded(item) ?
				item[association].push(id) :
				item[association] = item[association].filter(i => i !== id);

			return dispatch(module + '/update', item, { root: true });
		})
		.value();

	return Promise.all(promises);
};

export {
	updateAssociation
};
