import store from '@/store/store';
// Heavily borrowed from: https://github.com/latelierco/vue-application-insights/blob/master/src/index.js
// First used in portal, but modified here to work with the vue3 implementation of the router.

import { ApplicationInsights, Util, SeverityLevel } from '@microsoft/applicationinsights-web';

let insightsInstance = null;
const baseName = 'KOGNI';
function install(Vue, options) {

	const cs = getAIConnectionString();
	if (!cs) {
		console.log('No AI instance. Set APPLICATIONINSIGHTS_CONNECTION_STRING to activate.');
		return;
	}

	const config = options.appInsightsConfig || {};
	config.connectionString = cs;

	if (options.appInsights) {
		Vue.appInsights = options.appInsights;
	} else {
		Vue.appInsights = new ApplicationInsights({ config });
		Vue.appInsights.loadAppInsights();
		if (typeof options.onAfterScriptLoaded === 'function') {
			options.onAfterScriptLoaded(Vue.appInsights);
		}
	}
	insightsInstance = Vue.appInsights;

	const user = store.getters['user/user'];
	if (user) {
		insightsInstance.setAuthenticatedUserContext(user.id, undefined, true);
	}
	const router = options.router;

	// Watch route event if router option is defined.
	if (router) {
		if (options.trackInitialPageView === false) {
			setupPageTracking(options, Vue);
		} else {
			router.isReady(() => setupPageTracking(options, Vue));
		}
	}
	Vue.config.globalProperties.$monitoring = {
		monitorError
	};
}

/**
 * Track route changes as page views with AppInsights
 * @param options
 */

function setupPageTracking(options, Vue) {
	const router = options.router;

	router.afterEach(route => {
		const name = baseName + ' / ' + route.name;
		const url = location.protocol + '//' + location.host + route.fullPath;
		Vue.appInsights.stopTrackPage(name, url);
		Vue.appInsights.flush();
	});
}

export function monitorBefore(route) {
	if (!insightsInstance) {
		return;
	}
	const name = baseName + ' / ' + route.name;
	insightsInstance.context.telemetryTrace.traceID = Util.generateW3CId();
	insightsInstance.context.telemetryTrace.name = route.name;
	insightsInstance.startTrackPage(name);
}

export function monitorError(error, { properties = {}, severityLevel = SeverityLevel.Error }) {
	if (!insightsInstance) {
		return;
	}
	insightsInstance.trackException({
		exception: error,
		properties: properties,
		severityLevel: severityLevel
	});
}

function getAIConnectionString() {
	return store.getters['environment/aiConnectionString'];
}

export default install;
