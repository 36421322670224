<template lang="pug">
ol.component-groups
	ComponentGroup(
		v-for="group in groups"
		:group="group"
		:sig="signature"
	)
</template>

<script>

import ComponentGroup from './ComponentGroup.vue';

export default {
	components: {
		ComponentGroup
	},
	props: {
		groups: {
			type: Array,
			required: true
		},
		signature: {
			type: Object,
			required: true
		}
	}
};

</script>

<style lang="scss" scoped>
.component-groups {
	list-style: none;
	width: rem-calc(180);
}
</style>
