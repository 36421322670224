import { postTicket, getAllTickets, fetchUpdatedAfter } from '@/js/api/service-now-tickets';
import { DateTime } from 'luxon';

export default {
	namespaced: true,
	state: () => ({
		tickets: [],
		initiated: false,
		lastUpdatedAt: DateTime.utc()
	}),
	getters: {
		findTicket: (state) => id => {
			return state.tickets.find(ticket => ticket.id == id);
		},
		findTicketByContactId: (state) => contactId => {
			return state.tickets.find(ticket => ticket.contactId == contactId);
		},
		all: (state) => {
			return state.tickets;
		}
	},
	mutations: {
		setTickets: (state, tickets) => {
			state.tickets = tickets;
		},
		addTicket: (state, ticket) => {
			state.tickets.push(ticket);
		},
		update: (state, newTickets) => {
			if (!Array.isArray(newTickets)) {
				newTickets = [newTickets];
			}

			if (newTickets.length > 0) {
				newTickets.forEach(record => {
					let existingRecordIndex = state.tickets.findIndex(found => found.id === record.id);
					if (existingRecordIndex >= 0) {
						state.tickets[existingRecordIndex] = record;
					} else {
						state.tickets.push(record);
					}
				});
			}
		},
		setLastUpdatedAt: (state, UpdatedAfter) => {
			state.lastUpdatedAt = UpdatedAfter;
		},
		setInitiated: (state, initiated) => {
			state.initiated = initiated;
		}
	},
	actions: {
		ensureTickets: async ({ dispatch, commit, state }) => {
			if (state.initiated) {
				return;
			}
			await dispatch('fetchTickets');
			dispatch('initiateRefetchLoop');
			commit('setInitiated', true);
		},
		fetchTickets: async ({ commit }) => {
			const tickets = await getAllTickets();
			commit('setTickets', tickets);
		},
		fetchUpdatedAfter: async ({ commit, state }) => {
			const updatedTickets = await fetchUpdatedAfter(state.lastUpdatedAt.toISO());
			if (updatedTickets.length > 0) {
				commit('update', updatedTickets);
				updatedTickets.forEach(ticket => {
					commit('contacts/updateTicket', { ticket }, { root: true });
				});
			}
		},
		createTicket: async ({ commit }, { contact, shortDesc, desc, tenantName, parentId }) => {
			const ticket = await postTicket(contact, shortDesc, desc, tenantName, parentId);
			if (ticket) {
				commit('addTicket', ticket);
				commit('contacts/update', { ...contact, ticket: ticket }, { root: true });
				return ticket;
			}
		},
		initiateRefetchLoop: ({ dispatch, commit }) => {
			setInterval(() => {
				dispatch('fetchUpdatedAfter');
				commit('setLastUpdatedAt', DateTime.utc());
			}, 60 * 1000 * 1); // 60000 milliseconds = 1 minute
		}
	}
};
