<template lang="pug">
template(v-if="isBlank")
	BlankPropertyValue
template(v-else)
	.measurement {{measurement}}
</template>

<script>

import BlankPropertyValue from './Blank.vue';

export default {
	name: 'MeasurementValue',
	components: {
		BlankPropertyValue
	},
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		measurement() {
			return `${this.value.value} ${this.value.unit}`;
		},
		isBlank() {
			return !this.hasValue || !this.hasUnit;
		},
		hasValue() {
			return this.value.value !== null;
		},
		hasUnit() {
			return typeof this.value.unit === 'string' && this.value.unit.length > 0;
		}
	}
};

</script>
