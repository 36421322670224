<template lang="pug">
button.button(
	:disabled="isDisabled"
	:type="type"
)
	VTooltip(:text="tooltip")
		VIcon(
			v-if="icon"
			:name="icon"
		)
		slot
</template>

<script>
export default {
	name: 'VButton',
	props: {
		icon: {
			type: String,
			required: false,
			default: ''
		},
		tooltip: {
			type: String,
			required: false,
			default: ''
		},
		type: {
			type: String,
			required: false,
			default: 'button'
		},
		disabled: {
			type: Boolean,
			required: false,
			default: null
		},
		enabled: {
			type: Boolean,
			required: false,
			default: null
		}
	},
	computed: {
		isDisabled() {
			if (this.disabled !== null) {
				return this.disabled !== false;
			} else if (this.enabled !== null) {
				return this.enabled !== true;
			}

			return false;
		}
	}
};
</script>
