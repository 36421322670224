import { createFilter } from '@/js/utils/filter';
import { getSignatureProfiles } from '@/js/api/signature-profiles';

const state = {
	signatureProfiles: null
};

const mutations = {
	set(state, signatureProfiles) {
		state.signatureProfiles = signatureProfiles;
	}
};

const actions = {
	ensure({ getters, commit }) {
		if (getters.hasSignatureProfiles) {
			return Promise.resolve(getters.all);
		}

		return getSignatureProfiles()
			.then(({ data }) => {
				commit('set', data.data);
				return getters.all;
			});
	}
};

const getters = {
	all({ signatureProfiles }) {
		return signatureProfiles;
	},
	find(_, { all }) {
		return (id) => {
			return all.find(createFilter({ id }));
		};
	},
	hasSignatureProfiles(_, { all }) {
		return Array.isArray(all);
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
