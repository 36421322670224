<template lang="pug">
p Use this to group different types of components, ensuring they work well togeether by ensuring margins, height, etc. is the same.

StyleguideExample
	ButtonGroup
		a.button.secondary.contact-button(
			href="#"
		) View details
		CopyContactsAsDropdown(
			:contacts="[]"
		)
		VTrackingLinkButton.tracking-button(
			text="Grafana"
			:contacts="[]"
		)
</template>

<script>

import styleguideComponents from '../components';

import ButtonGroup from '@/components/base/ButtonGroup.vue';
import ContactActionsDropdown from '@/components/feature/ContactActionsDropdown.vue';
import CopyContactsAsDropdown from '@/components/feature/CopyContactsAsDropdown.vue';

export default {
	components: {
		...styleguideComponents,
		ButtonGroup,
		ContactActionsDropdown,
		CopyContactsAsDropdown
	}
};

</script>
