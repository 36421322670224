<template lang="pug">
nav
	Group(
		:group="group"
		parentPath="/styleguide"
	)
</template>

<script>

import pages from '../../config/pages';

import Group from './Group.vue';

export default {
	name: 'StyleguideNavigation',
	components: {
		Group
	},
	computed: {
		group() {
			return {
				pages
			};
		}
	}
};

</script>
