<template lang="pug">
li(
	:class="modifierClasses"
	@click="onClick"
)
	.name {{displayName}}
	.indicator
</template>

<script>

import delegate from '@/js/utils/delegate';

export default {
	name: 'PickerNavigationItem',
	props: {
		signatureProfile: {
			type: Object,
			required: true
		},
		selectedSignatureProfileId: {
			type: String,
			required: false,
			default: null
		}
	},
	emits: [
		'pick'
	],
	computed: {
		...delegate('id', 'displayName', { to: 'signatureProfile' }),
		modifierClasses() {
			return {
				'is-selected': this.isSelected
			};
		},
		isSelected() {
			return this.selectedSignatureProfileId === this.id;
		}
	},
	methods: {
		onClick() {
			this.$emit('pick', this.id);
		}
	}
};

</script>

<style lang="scss" scoped>
li {
	--color: hsl(var(--white60));

	padding: rem-calc(10) 0;
	cursor: pointer;
	color: var(--color);
	display: flex;

	&:not(:last-child) {
		border-bottom: rem-calc(1) solid hsl(var(--white15));
	}

	.name,
	.badges,
	.indicator {
		flex: 0 0 auto;

		&:not(:first-child) {
			margin-left: rem-calc(15);
		}
	}

	.name {
		flex: 0 1 100%;
	}

	.indicator {
		position: relative;
		box-sizing: border-box;
		flex: 0 0 rem-calc(20);
		height: rem-calc(20);
		border-radius: 50%;
		border: rem-calc(1.5) solid var(--color);

		&:after {
			--inset: #{rem-calc(5)};

			content: '';
			display: block;
			position: absolute;
			left: var(--inset);
			right: var(--inset);
			top: var(--inset);
			bottom: var(--inset);
			background: transparent;
			border-radius: 50%;
		}
	}

	&:hover,
	&.is-selected {
		--color: hsl(var(--white));
	}

	&.is-selected {
		.indicator:after {
			background: var(--color);
		}
	}
}
</style>
