import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import state from './state';

const stateBuilder = stateName => state(stateName);
const gettersBuilder = stateName => getters(stateName);
const mutationsBuilder = stateName => mutations(stateName);
const actionsBuilder = api => actions(api);

export {
	stateBuilder as state,
	mutationsBuilder as mutations,
	actionsBuilder as actions,
	gettersBuilder as getters
};
