import typeFor from './type-for';

const compact = (object) => {
	return Object
		.entries(object)
		.reduce((object, [key, value]) => {
			if (typeof value === 'undefined' || value === null) {
				return object;
			}

			return {
				...object,
				[key]: value
			};
		}, {});
};

export default (schema, value) => {
	const type = typeFor(schema);

	switch (type) {
		case 'array':
			return {
				items: value,
				schema: schema
			};
		case 'object':
			return {
				subschema: schema,
				value
			};
		case 'string':
			return compact({
				format: schema.format,
				value
			});
		default:
			return {
				value
			};
	}
};
