import { state, mutations, actions, getters } from '@/store/build-module/common';
import changelogActions from '@/store/build-module/changelog-actions';
import { updateAssociation } from '@/store/helpers/store-common';
import baseAPIBuilder from '@/js/api/builder/api-builder';
import { getScope } from '@/js/auth/auth-config';

const module = 'spacecrafts';

const meta = {
	module,
	basePath: '/v1.0/spacecrafts',
	mapping: {
		lifecycle_state: 'lifecycleState',
		satellite_catalog_number: 'satelliteCatalogNumber',
		catalog_number_assignment: 'catalogNumberAssignment'
	},
	inputWrapper: input => ({ data: input }),
	scope: await getScope('kogs')
};
const builder = new baseAPIBuilder(meta);
const api = {
	...builder.build(['list', 'add', 'update', 'find'])
};

export default {
	namespaced: true,
	state: () => ({
		...state(module)
	}),
	getters: {
		...getters(module)
	},
	mutations: {
		...mutations(module)
	},
	actions: {
		...actions(api),
		...changelogActions({ connection: api.connection, objectType: module }),

		updateAssociatedTenants(context, { newTenants, id }) {
			return updateAssociation(context, 'tenants', 'spacecrafts', newTenants, id);
		},
		updateAssociatedMissionProfiles(context, { newMissionProfiles, id }) {
			return updateAssociation(context, 'missionProfiles', 'spacecrafts', newMissionProfiles, id);
		}
	}
};
