<template lang="pug">
VDropdown.action-dropdown(
	:label="label"
	:isActive="isOpen"
	:icon="icon"
	:list="list"
	:searchable="searchable"
	:disabled="isDisabled"
	:lengthUntilSearchable="0"
	@toggle="toggle"
	@hide="close"
	@check="check"
)
	ul.list(v-if="!searchable")
		template(v-for="{ title, options } in groups")
			li.list-group-title(v-if="title") {{title}}
			li.list-item(
				v-for="option in options"
				:key="option.value"
				:class="optionClasses(option)"
				@click="pickOption(option)"
			) {{ option.label }}
</template>

<script>

import { default as predicates } from '@/js/utils/filter/predicates';

const isGroup = (option) => {
	return option.group === true &&
		typeof option.title === 'string' &&
		option.title.length > 0;
};

export default {
	props: {
		modelValue: {
			type: [String, Number, Object],
			default: null
		},
		options: {
			type: Array,
			required: true
		},
		icon: {
			type: String,
			default: null
		},
		placeholder: {
			type: String,
			default: 'Select'
		},
		searchable: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: null
		},
		enabled: {
			type: Boolean,
			required: false,
			default: null
		}
	},
	emits: [
		'update:modelValue'
	],
	data() {
		return {
			isOpen: false
		};
	},
	computed: {
		label() {
			if (!this.modelValue) {
				return this.placeholder;
			}

			const isModelValue = predicates.eq(this.modelValue);
			const selected = this.allOptions.find((option) => {
				return isModelValue(this.optionValue(option));
			});

			if (selected) {
				return selected.label;
			}

			return this.placeholder;
		},
		list() {
			if (!this.searchable) {
				return null;
			}

			return this.options.map((option) => {
				return {
					id: this.optionValue(option),
					name: option.label,
					isDisabled: option.isDisabled
				};
			});
		},
		groups() {
			return [
				this.ungroupedOptions,
				...this.options.filter(isGroup)
			];
		},
		allOptions() {
			return this.groups.flatMap((group) => {
				return group.options;
			});
		},
		ungroupedOptions() {
			return this.options.reduce(
				(group, option) => {
					if (isGroup(option)) {
						return group;
					}

					const options = option.group === true ? option.options : [option];

					return {
						...group,
						options: [
							...group.options,
							...options
						]
					};
				},
				{
					options: []
				}
			);
		},
		isDisabled() {
			if (this.disabled !== null) {
				return this.disabled !== false;
			} else if (this.enabled !== null) {
				return this.enabled !== true;
			}

			return false;
		}
	},
	methods: {
		pickOption(option) {
			if (this.isOptionDisabled(option)) {
				return;
			}

			this.$emit('update:modelValue', this.optionValue(option));
			this.close();
		},
		optionValue(option) {
			return option.id ?? option.value;
		},
		optionClasses(option) {
			return {
				'is-disabled': this.isOptionDisabled(option)
			};
		},
		isOptionDisabled({ isDisabled }) {
			return isDisabled === true;
		},
		toggle(force = null) {
			if (force) {
				this.isOpen = force;
				return;
			}

			this.isOpen = !this.isOpen;
		},
		close() {
			this.toggle(false);
		},
		check(option) {
			this.pickOption(option);
		}
	}
};

</script>

<style lang="scss" scoped>
.list {
	max-height: 250px;
	overflow: auto;

	@include scrollbars(0.5rem, hsl(var(--white20)));
}

.list-group-title {
	font-size: rem-calc(11);
	font-weight: 600;
	color: hsl(var(--white50));
	text-transform: uppercase;
	border-bottom: rem-calc(1) solid hsl(var(--white15));
	padding-bottom: rem-calc(4);
	margin-top: rem-calc(20);
}

.list-item {
	&.is-disabled,
	&.is-disabled:hover {
		cursor: default;
		color: hsl(var(--white40));
	}
}
</style>
