export const compareAlphaNum = (field = 'name') => {
	return (a, b) => {
		return a[field].localeCompare(b[field], undefined, { numeric: true });
	};
};

/**
 * Compares two strings taking a version number at the end of the string into
 * account. Newer versions will come before older ones.
 *
 * Example:
 *   const things = [
 *     'TransmiTron V1.0',
 *     'ReceivoTron V1337',
 *     'TransmiTron V1.1'
 *   ].sort(compareVersionedStrings);
 *
 *   console.log(things);
 *   // Array(3) [
 *   //   'ReceivoTron V1337',
 *   //   'TransmiTron V1.1',
 *   //   'TransmiTron V1.0'
 *   // ]
 *
 * @return {integer}
 */
export const compareVersionedStrings = (() => {
	const versionRegex = /([Vv][0-9]+[.0-9]*)$/;
	const hasVersion = (string) => {
		return versionRegex.test(string);
	};

	return (a, b) => {
		a = a.trim();
		b = b.trim();

		if (!hasVersion(a) || !hasVersion(b)) {
			return a.localeCompare(b, undefined, { numeric: true });
		}
		if (a.replace(versionRegex, '').trim() !== b.replace(versionRegex, '').trim()) {
			return a.localeCompare(b, undefined, { numeric: true });
		}

		return a.localeCompare(b, undefined, { numeric: true }) * -1;
	};
})();
