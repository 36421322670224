<template lang="pug">
ol.component-stack
	li.component-stack-item(v-if="hasRoot")
		slot
	li.component-stack-item(v-for="{ component, props } in children")
		component(
			:is="component"
			v-bind="props"
		)
</template>

<script>

import { shallowRef } from 'vue';

export default {
	provide() {
		return {
			$stack: {
				push: this.push.bind(this),
				pop: this.pop.bind(this)
			}
		};
	},
	data() {
		return {
			children: []
		};
	},
	computed: {
		hasRoot() {
			return Object.keys(this.$slots).length > 0;
		}
	},
	methods: {
		push({ component, props = {} }) {
			component = shallowRef(component);

			this.children.push({
				component,
				props
			});
		},
		pop(children = 1) {
			children = Math.min(this.children.length - 1, children);

			if (children > 0) {
				this.children.splice(this.children.length - children, children);
			} else if (this.hasRoot && children === 0) {
				this.children = [];
			}
		},
		popToRoot() {
			return this.pop(this.children.length - 1);
		}
	}
};

</script>

<style lang="scss" scoped>
.component-stack {
	list-style: none;

	.modal-body > &:first-child {
		margin-top: calc(var(--box-padding) * -1);
	}
}

.component-stack-item {
	&:not(:last-child) {
		display: none;
	}
}
</style>
