import { createFilter } from '@/js/utils/filter';
import { toDateTime } from '@/js/utils/filter/types';

import dig from '@/js/utils/dig';

export default () => {
	return {
		computed: {
			outcome() {
				return this.evaluation?.outcome ?? 'waiting';
			},
			submissionsUsed() {
				return this.evaluation?.submissionsUsed ?? [];
			},
			comments() {
				return (this.evaluation?.comments ?? []).filter(({ comment }) => {
					return typeof comment === 'string' && comment.length > 0;
				});
			},
			principalId() {
				if (this.isForced) {
					return this.evaluation.submissionsUsed.map(({ submissionId }) => {
						return this.signature.submissions.find(createFilter({
							id: submissionId
						}));
					}).find(createFilter({
						'input.componentName': 'HUMAN_OVERRIDE'
					}))?.submittedBy ?? this.submissionPrincipalId;
				} else if (this.isElaborated) {
					return this.signature.submissions
						.filter(createFilter({
							id: { in: this.submissionIds }
						}))
						.filter(createFilter({
							'input.componentName': 'HUMAN_OVERRIDE'
						}))
						.sort((a, b) => {
							return toDateTime(b.submittedAt).toMillis() - toDateTime(a.submittedAt).toMillis();
						})
						.find((entry) => {
							return typeof entry.comment === 'string';
						})?.submittedBy ?? this.submissionPrincipalId;
				}

				return this.submissionPrincipalId;
			},
			principal() {
				return this.$store.getters['principals/find'](this.principalId);
			},
			submissionPrincipalId() {
				return this.submission?.submittedBy ?? null;
			},
			evaluatedAt() {
				if (!this.evaluation.evaluatedAt) {
					return null;
				}
				const evaluatedAt = toDateTime(this.evaluation.evaluatedAt);
				return this.$time.formatDateTime(evaluatedAt);
			},
			hasEvaluatedAt() {
				return this.evaluatedAt !== null;
			},
			isForced() {
				if (this.evaluation?.lastImpactingPrincipalKind !== 'INDIVIDUAL') {
					return false;
				}

				return dig(this.submission, ['input', 'payload', 'kind']) === 'FORCE';
			},
			isElaborated() {
				return typeof this.acknowledgeList?.comment === 'string' &&
					this.acknowledgeList.comment.length > 0;
			},
			submission() {
				return this.submissions.reduce((submission, nextSubmission) => {
					if (submission === null) {
						return nextSubmission;
					}
					if (toDateTime(nextSubmission.submittedAt).toMillis() < toDateTime(submission.submittedAt).toMillis()) {
						return submission;
					}

					return nextSubmission;
				}, null);
			},
			acknowledgeList() {
				return dig(this.submission, ['input', 'payload', 'acknowledgeList']);
			},
			acknowledgeListEntries() {
				return this.acknowledgeList?.acknowledge ?? [];
			},
			submissions() {
				return this.signature.submissions.filter(createFilter({
					id: { in: this.submissionIds }
				}));
			},
			submissionIds() {
				return this.submissionsUsed.map(({ submissionId }) => {
					return submissionId;
				});
			}
		},
		watch: {
			principalId: {
				handler(principalId) {
					if (principalId !== null) {
						this.$store.dispatch('principals/ensure', principalId);
					}
				},
				immediate: true
			}
		}
	};
};
