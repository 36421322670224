<template lang="pug">
ExamplePicker(v-model="example")

VButton(@click="toggleMode") {{buttonTitle}}

template(v-if="isEdit")
	SchemaForm(
		:schema="schema"
		:errors="errors"
		v-model="object"
	)
		template(#actions)
			| #actions content

template(v-else-if="isView")
	SchemaView(
		:schema="schema"
		:object="object"
	)

VJSON(
	title="object"
	:data="object"
)

VJSON(
	title="prunedObject"
	:data="prunedObject"
)

VJSON(
	title="schema"
	:data="schema"
)

VJSON(
	title="errors"
	:data="errors"
)
</template>

<script>

import { createObject, pruneOptionalProperties } from '@/js/utils/json-schema';

import ExamplePicker from './components/ExamplePicker.vue';
import {
	Form as SchemaForm,
	View as SchemaView
} from '@/components/base/JsonSchema/index';

import examples from './examples/index';

export default {
	name: 'StyleguideJsonSchemaForm',
	components: {
		ExamplePicker,
		SchemaForm,
		SchemaView
	},
	data() {
		return {
			mode: 'edit',
			object: null
		};
	},
	computed: {
		example: {
			get() {
				return this.$route.query.example ?? 'basic';
			},
			set(value) {
				this.$router.replace({
					query: {
						example: value
					}
				});
			}
		},
		errors() {
			return null;
		},
		buttonTitle() {
			if (this.isView) {
				return 'Edit';
			}

			return 'View';
		},
		schema() {
			return examples[this.example].schema;
		},
		exampleObject() {
			const blank = false;
			if (blank) {
				return {};
			}

			return examples[this.example].object;
		},
		prunedObject() {
			return pruneOptionalProperties(this.schema, this.object);
		},
		isEdit() {
			return this.mode === 'edit';
		},
		isView() {
			return this.mode === 'view';
		}
	},
	watch: {
		schema: {
			handler() {
				this.object = createObject(this.schema, this.exampleObject);
			},
			immediate: true
		}
	},
	methods: {
		toggleMode() {
			if (this.isView) {
				this.mode = 'edit';
			} else {
				this.mode = 'view';
			}
		}
	}
};

</script>
