<template lang="pug">
.col.error-page
	h1 Error
	p(v-html="errorMessage")
	router-link.button.invert(to="/") Go to homepage
</template>

<script>
export default {
	name: 'RouteWrapperComponentError',
	props: {
		errorMessage: {
			type: String,
			required: false,
			default: 'Unknown error'
		}
	}
};
</script>

<style lang="scss" scoped>
.error-page {
	display: flex;
	flex-flow: column;
	align-items: center;
}

h1 {
	color: hsl(var(--red));
	font-size: rem-calc(108);
	margin: 0;
	line-height: 1.1;
}

h2 {
	margin: 0;
}

p {
	margin: rem-calc(20 0 30);
	vertical-align: middle;

	&:first-letter {
		text-transform: capitalize;
	}

	#{v-deep('code')} {
		font-size: var(--font-small-size);
		margin: rem-calc(0 5);
		padding: rem-calc(8 12);
		border: 1px solid hsl(var(--white20));
		border-radius: var(--global-radius);
		font-family: var(--font-family-mono);
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
</style>
