import Joi from 'joi';

import { baseSchemas, apiSchemas, extendedSchemas } from './custom-schemas';


let customJoi = Joi.defaults((schema) => schema.options({
	allowUnknown: true
})); // To let us get away from having to define every single object property in validation

baseSchemas.forEach(baseSchema => customJoi = customJoi.extend(baseSchema));
apiSchemas.forEach(apiSchema => customJoi = customJoi.extend(apiSchema));
extendedSchemas.forEach(extendedSchema => customJoi = customJoi.extend(extendedSchema));

export default customJoi;
