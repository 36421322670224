import { manageAPIError } from '@/js/api/builder/api-error';
import { DateTime } from 'luxon';
import Connection from '@/js/network/helpers';

import _ from 'lodash';

export default class {
	constructor ({ basePath, mapping, dateFields, parser, scope, module, inputWrapper }) {
		this.basePath = basePath;
		this.mapping = mapping;
		this.dateFields = dateFields || [];
		this.parser = parser;
		this.inputWrapper = inputWrapper || ((/** @type {any} */ input) => input);

		const useConnection = !!(basePath && scope);
		this.connection = useConnection ? new Connection(basePath, scope, module) : null;
	}

	build(methods) {
		let api = {
			output: (/** @type {any} */ data) => this.output(data),
			connection: this.connection
		};

		methods.forEach((/** @type {string | number} */ method) => {
			try {
				api[method] = this[method]();
			} catch {
				console.warn(`You tried to add an API method which doesn't exist as a generic method: ${method}.` +
					'These are accepted as generic methods:\n' +
					'- list\n' +
					'- update\n' +
					'- add\n' +
					'- find\n' +
					'- remove\n' +
					'- changelog\n'
				);
			}
		});

		return api;
	}

	#defaultParser(data) {
		return data.data || data;
	}

	#parse(data) {
		try {
			const parsed = this.parser ? this.parser(data) : this.#defaultParser(data);
			if (parsed) {
				return parsed;
			}

			throw { error: 'Unable to parse response. Result from parsing: ' + parsed };
		} catch (error) {
			this.#parseError(error, data);
		}
	}

	#parseError(error, data) {
		console.log('Response-data before parsing:\n', data);

		let feedback = this.parser ? 'You tried to use a custom parser' : 'You used the default parser';
		feedback += '. The parser-method provided by the store-module could not parse the response. ';
		feedback += `This is related to the store-module using '${this.basePath}' as base path for API requests.`;
		console.log(feedback + '\n\nError:', error);

		throw feedback;
	}

	#fetchResources(filter = {}) {
		return this.connection.axios.get('/', { params: filter });
	}

	list() {
		const list = async (filter = {}) => {
			let resources = [];
			let cursor = 'page[cursor]';
			let result;
			let params = this.#generateParameters(filter);

			// Try at least one time to fetch resources.
			// If we receive a continuation token (cursor) we should keep querying
			// to fetch all remaining resources. filter[cursor] will be null when
			// there is nothing more to fetch and the loop will exit.
			do {
				try {
					result = await this.#fetchResources(params);
					resources = this.#mergeResources(resources, result);

					const cursorValue = result.data?.page?.cursor;
					cursorValue ?
						params.set(cursor, cursorValue) :
						params.delete(cursor);
				} catch (error) {
					manageAPIError(error);
				}
			} while (params.has(cursor));

			return this.output(resources);
		};
		return list;
	}

	#mergeResources(resources, result) {
		const latestResources = this.#parse(result.data);

		if (!Array.isArray(latestResources)) {
			const error = 'Parsed response needs to be an array. It is a requirement for the list()-method to work.';
			this.#parseError({ error }, result.data);
		}

		return [...resources, ...latestResources];
	}

	#generateParameters(newFilter) {
		const filter = this.input(_.cloneDeep(newFilter));
		let params = new URLSearchParams();

		for (const key in filter) {
			const value = filter[key];
			if (!value) {
				delete filter[key];
				continue;
			}

			if (!Array.isArray(value)) {
				params.set(`include[${key}]`, value);
				continue;
			}

			if (value.length) {
				value.forEach(entry => params.append(`include[${key}]`, entry));
				continue;
			}

		}

		return params;
	}

	add() {
		const add = (data = {}) => this.connection.axios
			.post('/', this.inputWrapper(this.input(data)), { refetchIdKey: 'id' })
			.then(response => this.output(response.data))
			.catch(manageAPIError);
		return add;
	}

	update() {
		const update = (/** @type {string} */ id, /** @type {any} */ data) => {
			return this.connection.axios
				.put('/' + id, this.inputWrapper(this.input(data)), { refetchId: id })
				.then(response => this.output(response.data))
				.catch(manageAPIError);
		};
		return update;
	}

	find() {
		const find = (/** @type {string} */ id) => {
			return this.connection.axios
				.get('/' + id)
				.then(response => this.output(response.data))
				.catch(manageAPIError);
		};
		return find;
	}

	remove() {
		const remove = (/** @type {string} */ id) => {
			return this.connection.axios
				.delete('/' + id, { refetchId: id })
				.catch(manageAPIError);
		};
		return remove;
	}

	output(response) {
		response = this.#parse(response);

		return Array.isArray(response) ?
			response.map(resource => this.#output(resource)) : // Return a mapped array
			this.#output(response); // Return a mapped object
	}

	// Recursively map response to;
	// 1. Ensure that dates/times become DateTime-objects from Luxon
	// 2. Ensure all keys follow the camelCase-format
	#output(response) {
		_.forIn(response, (value, key) => {
			if (_.isPlainObject(value)) {
				response[key] = this.#output(value);
			} else if (Array.isArray(value) && value.every(item => typeof item === 'object')) {
				response[key] = value.map(entry => this.#output(entry));
			} else {
				response[key] = this.#convertToDateTime(key, value);
			}
			response = this.#convertToCamelCase(response, key);
		});
		return response;
	}

	#convertToDateTime(key, value) {
		return this.dateFields.includes(key)
			? DateTime.fromISO(value, { zone: 'utc' })
			: value;
	}

	// Ensures that selected properties in responses like some_key becomes someKey,
	// given that they are defined in 'mapping'
	#convertToCamelCase(response, key) {
		if (!this.mapping || !this.mapping[key]) {
			return response;
		}

		response[this.mapping[key]] = response[key];
		delete response[key];

		return response;
	}

	// Ensures that selected properties in both requests and filters like someKey becomes some_key,
	// given that they are defined in 'mapping'
	input(data) {
		const input = _.cloneDeep(data);

		const fromDateTime = (/** @type {string | number} */ field) => input[field] = input[field] ? input[field].toUTC().toString() : null;
		this.dateFields.forEach(fromDateTime);

		if (!this.mapping) {
			return input;
		}

		Object.keys(this.mapping).forEach(key => {
			input[key] = input[this.mapping[key]];
			delete input[this.mapping[key]];
		});

		return input;
	}
}
