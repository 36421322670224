import { createFilter } from '@/js/utils/filter';

export const groupByDate = (items) => {
	return items.reduce((groups, item) => {
		const date = item.contact.startTimePadded.startOf('day');
		let index = groups.findIndex(createFilter({ date }));

		if (index === -1) {
			groups.push({
				date,
				items: []
			});
			index = groups.length - 1;
		}

		groups[index].items.push(item);
		return groups;
	}, []);
};
