import { bus } from '@/js/plugins/bus';
import { monitorError } from '@/js/plugins/monitoring';


const apiError = (response, message) => {
	let feedback = '';

	const method = response.config.method.toUpperCase();
	switch (method) {
		case 'GET': feedback = 'Unable to fetch resource(s)'; break;
		case 'POST': feedback = 'Unable to create resource'; break;
		case 'PUT': feedback = 'Unable to update resource'; break;
		case 'PATCH': feedback = 'Unable to update resource'; break;
		case 'DELETE': feedback = 'Unable to remove resource'; break;
		default: console.log('Unknown HTTP method: ' + method);
	}

	if (message) {
		feedback = message;
	}

	feedback += '. Endpoint: ' + method + ' ' + response.config.url;

	const data = response.data;
	const error = data.message || data.error_message || 'Not available';
	return feedback + '. API feedback:\n\n ' + error;
};

const manageAPIError = (error, message = '') => {
	// All error responses from axios have the response-field,
	// meaning this error cannot be an API error.
	if (!error.response) {
		throw error;
	}

	const response = error.response || error;
	bus.emit('exception', apiError(response, message));
	console.error(response);
	monitorError(response, { properties: { 'errorSource': 'manageAPIError' } });

	throw { isAPIError: true, error: response };
};

const errorMessage = error => {
	return error.error?.data?.message || // KOGS error
		error.error?.data?.error_message || // Heimdall error
		error.error || // Internal error
		error.toString(); // Fallback
};

export { manageAPIError, errorMessage };
