<template lang="pug">
.button-group(:class="modifierClasses")
	slot
</template>

<script>

export default {
	name: 'ButtonGroup',
	props: {
		stretch: {
			type: Boolean,
			required: false,
			default: false
		},
		vertical: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		modifierClasses() {
			return {
				'stretch': this.stretch,
				'vertical': this.vertical
			};
		}
	}
};

</script>

<style lang="scss">
:root {
	--button-group-font-size: #{rem-calc(14)};
	--button-group-item-height: var(--input-height);
	--button-group-spacing: #{rem-calc(10)};
}

.button-group {
	display: flex;
	gap: var(--button-group-spacing);
	align-items: center;

	> .tooltip.inline-block {
		display: block;
	}

	> .button,
	> .tooltip.inline-block div .button,
	.dropdown {
		height: var(--button-group-item-height);
	}

	> .button,
	> .tooltip.inline-block .button,
	> .tooltip.inline-block .button.activity-monitor,
	> .tooltip.inline-block div .button,
	.dropdown > .label {
		font-size: var(--button-group-font-size);
		margin-bottom: 0;
	}

	&.stretch {
		flex-wrap: nowrap;

		> * {
			flex: 1 1 auto;
		}

		> .tooltip.inline-block .button {
			width: 100%;
		}
	}

	&.vertical {
		display: block;

		> :not(:last-child) {
			margin-bottom: var(--button-group-spacing);
		}
	}
}
</style>
