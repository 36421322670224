import API from '@/js/api/orbit-guard';

export default {
	namespaced: true,
	state: () => ({
		configurations: [],
		visibilityRunResults: [],
		dedicatedRunResults: [],
		dedicatedAllocationSystems: []
	}),
	getters: {
		findConfiguration: (state) => id => {
			return state.configurations.find(configuration => configuration.id == id);
		},
		findDedicatedAllocationSystems: (state) => id => {
			return state.dedicatedAllocationSystems.find(system => system.id == id);
		},
		allConfigurations: (state) => {
			return state.configurations;
		},
		allVisibilityRunResults: (state) => {
			return state.visibilityRunResults;
		},
		allDedicatedRunResults: (state) => {
			return state.dedicatedRunResults;
		},
		allDedicatedAllocationSystems: (state) => {
			return state.dedicatedAllocationSystems;
		}
	},
	mutations: {
		setConfigurations: (state, configurations) => {
			state.configurations = configurations;
		},
		setConfiguration: (state, configuration) => {
			const existingIndex = state.configurations.findIndex(config => config.id === configuration.id);
			if (existingIndex === -1) {
				state.configurations.push(configuration);
			} else {
				state.configurations[existingIndex] = configuration;
			}
		},
		removeConfiguration: (state, id) => {
			const index = state.configurations.findIndex(config => config.id === id);
			if (index !== -1) {
				state.configurations.splice(index, 1);
			}
		},
		setDedicatedAllocationSystems: (state, dedicatedAllocationSystems) => {
			state.dedicatedAllocationSystems = dedicatedAllocationSystems;
		},
		addDedicatedAllocationSystem: (state, dedicatedAllocationSystem) => {
			state.dedicatedAllocationSystems.push(dedicatedAllocationSystem);
		},
		removeDedicatedAllocationSystem: (state, id) => {
			const index = state.dedicatedAllocationSystems.findIndex(config => config.id === id);
			if (index !== -1) {
				state.dedicatedAllocationSystems.splice(index, 1);
			}
		},
		setRunResults: (state, runResults) => {
			state.visibilityRunResults = runResults.visibility;
			state.dedicatedRunResults = runResults.dedicated;
		}
	},
	actions: {
		ensureConfigurations: async ({ dispatch }) => {
			await dispatch('fetchConfigurations');
		},
		fetchConfigurations: async ({ commit }) => {
			const configurations = await API.getAllConfigurations();
			commit('setConfigurations', configurations);
		},
		fetchRunResults: async ({ commit }, filter) => {
			const runResults = await API.getRunResults(filter);
			commit('setRunResults', runResults);
		},
		createConfiguration: async ({ commit }, configuration) => {
			const createdConfiguration = await API.createConfiguration(configuration);
			commit('setConfiguration', createdConfiguration);
			return createdConfiguration;
		},
		updateConfiguration: async ({ commit }, configuration) => {
			const updatedConfiguration = await API.updateConfiguration(configuration);
			commit('setConfiguration', updatedConfiguration);
			return updatedConfiguration;
		},
		deleteConfiguration: async ({ commit }, id) => {
			await API.deleteConfiguration(id);
			commit('removeConfiguration', id);
		},
		ensureDedicatedAllocationSystems: async ({ dispatch }) => {
			await dispatch('fetchDedicatedAllocationSystems');
		},
		fetchDedicatedAllocationSystems: async ({ commit }) => {
			const dedicatedAllocationSystems = await API.getDedicatedAllocationSystems();
			commit('setDedicatedAllocationSystems', dedicatedAllocationSystems);
		},
		addDedicatedAllocationSystem: async ({ commit }, systemId) => {
			const addedDedicatedAllocationSystem = await API.addDedicatedAllocationSystem(systemId);
			commit('addDedicatedAllocationSystem', addedDedicatedAllocationSystem);
			return addedDedicatedAllocationSystem;
		},
		deleteDedicatedAllocationSystem: async ({ commit }, id) => {
			await API.deleteDedicatedAllocationSystem(id);
			commit('removeDedicatedAllocationSystem', id);
		}
	}
};
