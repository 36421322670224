import applyLodashMixins from '@/js/utils/lodash-mixins';
applyLodashMixins();

import '@/js/utils/filter/lodash-mixin';

import { createApp } from 'vue';
import router from '@/router';
import store from '@/store/store';

import Toast, { POSITION } from 'vue-toastification';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import VueProgressBar from '@aacassandra/vue3-progressbar';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { dragscrollNext } from 'vue-dragscroll';
import { VueShowdownPlugin } from 'vue-showdown';

import bus from '@/js/plugins/bus';
import time from '@/js/plugins/time';
import text from '@/js/plugins/text';
import notify from '@/js/plugins/notify/notify';
import lifecycle from '@/js/plugins/lifecycle';
import validate from '@/js/plugins/validate';
import contact from '@/js/plugins/contact';
import search from '@/js/plugins/search';
import dialog from '@/js/plugins/dialog';
import Monitoring from '@/js/plugins/monitoring';

// import VComponents
import VTheHeader from '@/components/global/VTheHeader/VTheHeader.vue';
import VButton from '@/components/global/VButton/VButton.vue';
import VModal from '@/components/global/VModal/VModal.vue';
import VCallout from '@/components/global/VCallout/VCallout.vue';
import VIcon from '@/components/global/VIcon/VIcon';
import VInput from '@/components/global/VInput/VInput.vue';
import VTextArea from '@/components/global/VTextArea/VTextArea.vue';
import VSelect from '@/components/global/VSelect/VSelect.vue';
import VTooltip from '@/components/global/VTooltip/VTooltip.vue';
import VCheckbox from '@/components/global/VCheckbox/VCheckbox.vue';
import VDropdown from '@/components/global/VDropdown/VDropdown.vue';
import VDropdownSelect from '@/components/global/VDropdownSelect.vue';
import VUser from '@/components/global/VUser/VUser.vue';
import VJSON from '@/components/global/VJSON.vue';
import VLoadingView from '@/components/global/VLoadingView/VLoadingView.vue';
import VTrackingLinkButton from '@/components/global/VTrackingLinkButton/VTrackingLinkButton.vue';
import VExpandableListRow from '@/components/global/VExpandableListRow/VExpandableListRow.vue';
import VSelectAll from '@/components/global/VSelectAll/VSelectAll.vue';

import clickOutside from '@/js/directives/clickOutside';

import { progressBarConfig, showdownConfig } from '@/js/utils/application-config';

// Since App.vue is dependent on some of the other imports it is imported last
import App from '@/App.vue';

(async () => {
	try {
		await store.dispatch('user/authenticate');

		await Promise.all([
			store.dispatch('time/start'),
			store.dispatch('environment/load')
		]);

		if (store.getters['environment/isProductionEnvironment'] && 'serviceWorker' in navigator) {
			navigator.serviceWorker.register(window.origin + '/service-worker.js');
		}

		await store.dispatch('settings/initiate');

		const app = createApp(App)
			.component('VButton', VButton)
			.component('VInput', VInput)
			.component('VTextArea', VTextArea)
			.component('VSelect', VSelect)
			.component('VModal', VModal)
			.component('VCallout', VCallout)
			.component('VIcon', VIcon)
			.component('VTheHeader', VTheHeader)
			.component('VTooltip', VTooltip)
			.component('VCheckbox', VCheckbox)
			.component('VDropdown', VDropdown)
			.component('VDropdownSelect', VDropdownSelect)
			.component('VUser', VUser)
			.component('VLoadingView', VLoadingView)
			.component('VTrackingLinkButton', VTrackingLinkButton)
			.component('VJSON', VJSON)
			.component('VExpandableListRow', VExpandableListRow)
			.component('VSelectAll', VSelectAll)
			.use(Toast, { position: POSITION.BOTTOM_CENTER })
			.use(VueProgressBar, progressBarConfig)
			.use(VueShowdownPlugin, showdownConfig)
			.use(store)
			.use(router)
			.use(bus)
			.use(time)
			.use(text)
			.use(notify)
			.use(lifecycle)
			.use(validate)
			.use(contact)
			.use(search)
			.use(dialog)
			.use(Monitoring, {
				trackInitialPageView: false,
				router
			})
			.directive('click-outside', clickOutside)
			.directive('dragscroll', dragscrollNext);

		app.mount('#app');

	} catch (error) {
		console.error(error);
		window.alert(`Failed to start KOGNI`);
	}
})();
