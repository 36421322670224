import { state, mutations, actions, getters } from '@/store/build-module/common';
import { manageError } from '@/store/helpers/store-error';
import { manageAPIError } from '@/js/api/builder/api-error';
import { PROXY_URL, UPDATE_RESOURCE } from '@/env';

import { bus } from '@/js/plugins/bus';
import baseAPIBuilder from '@/js/api/builder/api-builder';
import { getScope } from '@/js/auth/auth-config';

import Connection from '@/js/network/helpers';

const basePath = '/v1/organizations';
const module = 'organizations';

const meta = {
	module,
	basePath,
	mapping: {
		organization_uuid: 'id',
		short_name: 'shortName',
		display_name: 'name',
		principal_uuid: 'principalId',
		revoked_by: 'revokedBy',
		revoked_at: 'revokedAt',
		owner_uuid: 'ownerId'
	},
	dateFields: ['revoked_at'],
	parser: data => data.organization || data.organizations || data,
	scope: await getScope('heimdall')
};

const apiKeyConnection = new Connection('/v1', await getScope('heimdall'), 'apiKeys');

const builder = new baseAPIBuilder(meta);
const api = {
	...builder.build(['list', 'find']),

	add: organization => builder.connection.axios
		.post('/create_managed', builder.input(organization), { refetchIdKey: 'organization.organization_uuid' })
		.then(response => builder.output(response.data.organization))
		.catch(manageAPIError),

	findApiKeys: owner_uuid => {
		const findConnectedTenant = async key => {
			const permissions = await builder.connection.axios
				.get(`${PROXY_URL}/v1/permissions/query/principals/${key.principalId}?type_privilege=tenant:admin`)
				.then(response => response.data.resource_permissions)
				.catch(manageAPIError);

			if (permissions.length > 1) {
				// Should be "impossible", but Heimdall supports this state for now, even though it would break the APIs
				bus.emit('exception',
					`Permissions for key ${key.prefix}  exceeds one resource. \n` +
					'Sign of misconfiguration of current API keys. \n' +
					'Please reassign the key to desired tenant!');
			}
			return { principalId: key.principalId, tenant: permissions[0]?.resource_uuid };
		};

		return builder.connection.axios
			.get(`${PROXY_URL}/v1/api_keys`, { params: { owner_uuid } })
			.then(response => response.data.keys.map(key => builder.output(key)))
			.then(async keys => {
				const tenantPromises = keys.map(key => findConnectedTenant(key));
				const tenants = await Promise.all(tenantPromises);

				keys.forEach(key => {
					const principal = tenants.find(tenant => key.principalId === tenant.principalId);
					key.tenant = principal.tenant;
				});
				return keys;
			})
			.catch(manageAPIError);
	},

	addApiKey: (organizationId, { credentials, label }) => apiKeyConnection.axios
		.post(
			`/api_keys/manage/organizations/${organizationId}/basic_auth`,
			{ legacy_credentials: credentials, label },
			{ refetchId: organizationId }
		)
		.then(response => builder.output(response.data.new_key))
		.catch(manageAPIError),

	reassignApiKey: (principalId, tenantId, isNew, organizationId) => apiKeyConnection.axios
		.put(
			`/permissions/manage/principals/${principalId}/resources/${tenantId}`,
			{ permissions: isNew ? ['admin'] : [] },
			{ refetchId: organizationId }
		)
		.catch(manageAPIError),

	revokeApiKey: (principalId, organizationId) => apiKeyConnection.axios
		.delete(
			`/api_keys/${principalId}/revoke`,
			{ refetchId: organizationId }
		)
		.then(response => builder.output(response.data.key))
		.catch(manageAPIError)
};

export default {
	namespaced: true,
	state: () => ({
		...state(module),
		apiKeys: {}
	}),
	getters: {
		...getters(module),

		findApiKeys(state) {
			return organizationId => {
				return state.apiKeys[organizationId];
			};
		}
	},
	mutations: {
		...mutations(module),

		setApiKeys(state, { organizationId, apiKeys }) {
			state.apiKeys = {
				...state.apiKeys,
				[organizationId]: apiKeys
			};
		}
	},
	actions: {
		...actions(api),

		initiateMore({ dispatch }) {
			apiKeyConnection.socket.on(UPDATE_RESOURCE, ({ id }) => dispatch('fetchApiKeys', id));
		},

		ensureApiKeys({ getters, dispatch }, { id }) {
			if (getters.findApiKeys(id)) {
				return;
			}
			return dispatch('fetchApiKeys', id);
		},
		fetchApiKeys({ commit, getters }, organizationId) {
			return api
				.findApiKeys(organizationId)
				.then(apiKeys => {
					commit('setApiKeys', { organizationId, apiKeys });
					return getters.findApiKeys(organizationId);
				})
				.catch(manageError);
		},
		newApiKey({ dispatch }, { organizationId, key }) {
			return api
				.addApiKey(organizationId, key)
				.then(apiKey => dispatch('fetchApiKeys', organizationId).then(() => apiKey))
				.catch(manageError);
		},
		revokeApiKey({ dispatch }, { organizationId, principalId }) {
			return api
				.revokeApiKey(principalId, organizationId)
				.then(apiKey => dispatch('fetchApiKeys', organizationId).then(() => apiKey))
				.catch(manageError);
		},
		async assignKey({ dispatch }, { key, tenantId, organizationId }) {
			if (key.tenant) { // Remove existing assignment before attempting to add new
				await api.reassignApiKey(key.principalId, key.tenant, false, organizationId);
			}

			return api
				.reassignApiKey(key.principalId, tenantId, true, organizationId)
				.then(() => dispatch('fetchApiKeys', organizationId).then(() => key));
		}
	}
};
