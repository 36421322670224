<template lang="pug">
li.list-item(:class="modifierClasses")
	component.content(
		:is="contentComponent"
		v-bind="contentProps"
		v-on="eventHandlers"
	)
		.addon(v-if="hasIcon")
			VIcon(:name="icon")
		.label
			slot

	.loading-indicator(v-if="isLoading")
		VIcon(name="inSetupStatus")
</template>

<script>

export default {
	name: 'DropdownListItem',
	props: {
		icon: {
			type: String,
			required: false,
			default: null
		},
		disabled: {
			type: Boolean,
			required: false,
			default: null
		},
		enabled: {
			type: Boolean,
			required: false,
			default: null
		},
		loading: {
			type: Boolean,
			required: false,
			default: false
		},
		href: {
			type: String,
			required: false,
			default: null
		}
	},
	emits: [
		'click'
	],
	computed: {
		modifierClasses() {
			return {
				'is-disabled': this.isDisabled,
				'is-loading': this.isLoading
			};
		},
		contentComponent() {
			if (this.isLink) {
				return 'router-link';
			}

			return 'div';
		},
		contentProps() {
			if (this.isLink) {
				return {
					to: this.href
				};
			}

			return {

			};
		},
		eventHandlers() {
			if (this.isLink) {
				return {};
			}

			return {
				click: this.onClick
			};
		},
		hasIcon() {
			return this.icon && this.icon.length > 0;
		},
		isLink() {
			return typeof this.href === 'string';
		},
		isDisabled() {
			if (this.disabled !== null) {
				return this.disabled;
			}
			if (this.enabled !== null) {
				return !this.enabled;
			}
			return false;
		},
		isEnabled() {
			return !this.isDisabled;
		},
		isLoading() {
			return this.loading;
		}
	},
	methods: {
		onClick(e) {
			if (this.isDisabled || this.isLoading) {
				e.preventDefault();
				e.stopPropagation();
				return;
			}

			e.preventDefault();
			this.$emit('click', e);
		}
	}
};
</script>

<style lang="scss" scoped>
.list-item {
	--list-item-icon-color: hsl(var(--white40));
	--list-item-text-color: hsl(var(--white80));

	cursor: pointer;
	position: relative;

	.content {
		display: flex;
		padding: rem-calc(9) rem-calc(8);
		text-decoration: none;
		color: var(--list-item-text-color);
		font-size: rem-calc(14.5);
		letter-spacing: 0.005em;
	}

	.addon,
	.label {
		&:not(:first-child) {
			margin-left: rem-calc(8);
		}
	}

	.addon {
		flex: 0 0 auto;

		.icon {
			#{v-deep('.fill-primary')} {
				fill: var(--list-item-icon-color);
			}

			#{v-deep('.stroke-primary')} {
				stroke: var(--list-item-icon-color);
			}
		}
	}

	.label {
		flex: 0 1 100%;
	}

	.loading-indicator {
		--icon-size: #{rem-calc(16)};

		position: absolute;
		top: calc(50% - (var(--icon-size) / 2));
		left: calc(50% - (var(--icon-size) / 2));
		width: var(--icon-size);
		height: var(--icon-size);
		animation:
			loading-indicator-spin-animation
			1.25s
			linear
			infinite;

		@keyframes loading-indicator-spin-animation {
			0% {
				transform: rotateZ(0);
			}

			50% {
				transform: rotateZ(180deg);
			}

			100% {
				transform: rotateZ(360deg);
			}
		}
	}

	&:hover {
		--list-item-text-color: hsl(var(--white80));
		--list-item-icon-color: hsl(var(--white));
	}

	&.is-disabled,
	&.is-disabled:hover {
		--list-item-text-color: hsl(var(--white20));
		--list-item-icon-color: hsl(var(--white20));

		cursor: default;

		#{v-deep('.label')} > a {
			cursor: default;
			pointer-events: none;
		}
	}

	#{v-deep('.label')} > a {
		color: var(--list-item-text-color);
		text-decoration: none;

		&:not(.button):not(.collapsible-title):not(.content):not(.pill):hover {
			opacity: 1;
		}
	}

	&.is-loading {
		pointer-events: none;

		.content {
			opacity: 0;
		}
	}
}

</style>
