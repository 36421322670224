import jiff from 'jiff';
import { has as hasProperty } from 'lodash';

import { createFilter } from '@/js/utils/filter';
import {
	createSystemSpecification,
	getSystemSpecifications,
	updateSystemSpecification
} from '@/js/api/system-specifications';

const normalize = (specification, data = {}) => {
	if (Array.isArray(specification)) {
		return specification.map((specification) => {
			return normalize(specification, data);
		});
	}

	return {
		...specification,
		...data
	};
};

const state = {
	specifications: []
};

const mutations = {
	set(state, specification) {
		let index = state.specifications.findIndex(createFilter({
			id: specification.id
		}));
		if (index === -1) {
			index = state.specifications.length;
		}

		state.specifications.splice(index, 1, specification);
	}
};

const actions = {
	save({ dispatch }, options) {
		if (typeof options.id !== 'undefined' && options.id !== null) {
			return dispatch('update', options);
		}
		return dispatch('create', options);

	},
	create({ commit }, { systemId, schemaId, object }) {
		return createSystemSpecification(systemId, { schemaId, object })
			.then((response) => {
				commit('set', normalize(response.data, { systemId }));

				return getters.find(response.data.id);
			});
	},
	update({ commit, getters }, { systemId, id, object }) {
		const patches = jiff.diff(getters.find(id).object, object);

		if (patches.length === 0) {
			return Promise.resolve(getters.find(id));
		}

		return updateSystemSpecification(systemId, id, { patches })
			.then((response) => {
				commit('set', normalize(response.data, { systemId }));

				return getters.find(response.data.id);
			});
	},
	getForSystem({ commit, dispatch, getters }, { id: systemId }) {
		return getSystemSpecifications(systemId)
			.then((response) => {
				const specifications = normalize(response.data.data, { systemId });
				return Promise.all(
					specifications.map((specification) => {
						return dispatch(
							'jsonSchemas/ensure',
							specification.jsonSchemaId,
							{ root: true }
						).then(() => {
							commit('set', specification);

							return getters.find(specification.id);
						});
					})
				).then((...specifications) => {
					return specifications.flat();
				});
			});
	}
};

const getters = {
	find(_, getters) {
		return (id) => {
			return getters.findBy({ id });
		};
	},
	findBy(_, getters) {
		return (filter) => {
			return getters.findAllBy(filter).at(0) ?? null;
		};
	},
	all({ specifications }) {
		return specifications;
	},
	findAllBy({ specifications }, getters, rootState, rootGetters) {
		return (filter) => {
			if (hasProperty(filter, 'schemaId')) {
				filter.jsonSchemaId = filter.schemaId;
				delete filter.schemaId;
			}

			return specifications
				.filter(createFilter(filter))
				.map((specification) => {
					return {
						systemId: specification.systemId,
						id: specification.id,
						object: specification.jsonObject,
						schemaId: specification.jsonSchemaId,
						schema: rootGetters['jsonSchemas/findSchema'](specification.jsonSchemaId)
					};
				});
		};
	},
	has(_, getters) {
		return (id) => {
			return getters.find(id) !== null;
		};
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};

