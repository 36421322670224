<template lang="pug">
VDropdown.action-dropdown(
	label="Copy as.."
	icon="copy"
	:isActive="isActive"
	@hide="toggleDropdown"
	@toggle="toggleDropdown"
)
	ul.list
		li.list-item(@click="copyContactID") Copy ID
		li.list-item(@click="copyScheduleReply") Copy schedule reply
</template>

<script>
import { copyToClipboard } from '@/js/utils/copy.js';
import validate, { joi } from '@pv';

export default {
	name: 'CopyContactsAsDropdown',
	props: {
		contacts: {
			type: Array,
			required: true,
			validator: validate(joi.array().items(joi.extendedContact()))
		},
		isActive: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['toggle'],
	data() {
		return {
			showCopyAs: false
		};
	},
	methods: {
		toggleDropdown(showCopyAs) {
			this.$emit('toggle', showCopyAs);
			if (!showCopyAs) {
				this.showCopyAs = false;
				this.$nextTick(() => this.showCopyAs = true);
			}
		},
		copyContactID() {
			this.toggleDropdown();
			const contactIds = this.contacts
				.map(contact => contact.id)
				.join(',');
			copyToClipboard(contactIds);
			this.$notify.event('Contact ID has been copied to your clipboard!', 'success');
		},
		copyScheduleReply() {
			this.toggleDropdown();
			const scheduleReply = this.contacts
				.map(contact => this.$contact.formatScheduleReply(contact))
				.join('\n');
			copyToClipboard(scheduleReply.trim());
			this.$notify.event('Schedule reply has been copied to your clipboard!', 'success');
		}
	}
};
</script>
