import { PROXY_ORBITGUARD } from '@/env';

import baseAPIBuilder from '@/js/api/builder/api-builder';
import Connection from '@/js/network/helpers';
import { manageAPIError } from '@/js/api/builder/api-error';
import { getScope } from '@/js/auth/auth-config';

const meta = { dateFields: ['startTime', 'endTime', 'triggered'] };
const builder = new baseAPIBuilder(meta);
const connection = new Connection('/api', await getScope('kogs'), null, PROXY_ORBITGUARD).axios;

const getAllConfigurations = () =>
	connection.get(`/configuration/configurations`)
		.then(response => builder.output(response.data))
		.catch(manageAPIError);

const getConfiguration = (id) =>
	connection.get(`/configuration/configurations/${id}`)
		.then(response => builder.output(response.data))
		.catch(manageAPIError);

const createConfiguration = (configuration) =>
	connection.post(`/configuration/configurations`, configuration)
		.then(response => builder.output(response.data))
		.catch(manageAPIError);

const updateConfiguration = (configuration) =>
	connection.put(`/configuration/configurations/${configuration.id}`, configuration)
		.then(response => builder.output(response.data))
		.catch(manageAPIError);

const deleteConfiguration = (id) =>
	connection.delete(`/configuration/configurations/${id}`)
		.catch(manageAPIError);

const getDedicatedAllocationSystems = () =>
	connection.get(`/configuration/dedicated-allocation-systems`)
		.then(response => builder.output(response.data))
		.catch(manageAPIError);

const addDedicatedAllocationSystem = (systemId) =>
	connection.post(`/configuration/dedicated-allocation-systems`, { systemId })
		.then(response => builder.output(response.data))
		.catch(manageAPIError);

const deleteDedicatedAllocationSystem = (id) =>
	connection.delete(`/configuration/dedicated-allocation-systems/${id}`)
		.catch(manageAPIError);

const getRunResults = (filter) =>
	connection.get(`/allocator/run-results?StartTime=${filter.startTime}&EndTime=${filter.endTime}&Types=Scheduled&Types=Manual`)
		.then(response => builder.output(response.data))
		.catch(manageAPIError);

export default {
	getConfiguration,
	getAllConfigurations,
	createConfiguration,
	updateConfiguration,
	deleteConfiguration,
	getDedicatedAllocationSystems,
	addDedicatedAllocationSystem,
	deleteDedicatedAllocationSystem,
	getRunResults
};
