<template lang="pug">
Pill(
	:icon="icon"
	:label="formattedValue"
)
</template>

<script>

import Pill from '../SchemaViewPill.vue';

export default {
	name: 'BooleanValue',
	components: {
		Pill
	},
	props: {
		value: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		formattedValue() {
			if (this.value !== true) {
				return 'False';
			}

			return 'True';
		},
		icon() {
			if (this.value !== true) {
				return 'cross';
			}

			return 'checkmark';
		}
	}
};

</script>

<style lang="scss" scoped>
.pill {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}
</style>
