import Connection from '@/js/network/helpers';
import { camelizeKeys } from '@/js/utils/case-conversion';
import { getScope } from '@/js/auth/auth-config';

const kogs = new Connection('/v1.0', await getScope('kogs')).axios;

const signatureComponentsPath = '/signature_components';

export const getSignatureComponents = () => {
	return kogs.get(signatureComponentsPath)
		.then((response) => {
			return {
				...response,
				data: camelizeKeys(response.data)
			};
		});
};
