<template lang="pug">
li.submission-group(:class="modifierClasses")
	.current
		OutcomeIcon(
			:outcome="outcome"
			:isManual="isManualOutcome"
			:isFaded="evaluationForced"
		)
		.state
			h3.title {{title}}
			.outcome {{prettyOutcome}}
		.content
			template(v-if="hasSubmissions")
				SubmissionDetails(:items="details")
			.comment(v-if="hasComment")
				p {{comment}}
			IssueCategories(:ids="issueCategories")
		.actions
			DropdownList(v-if="hasActions")
				DropdownListItem(
					:enabled="canAttest"
					icon="pencil"
					@click="didClickAttest"
				) Attest
				DropdownListItem(
					:enabled="canElaborate"
					icon="elaborate"
					@click="didClickElaborate"
				) Elaborate
				DropdownListItem(
					:enabled="canOverride"
					icon="arrow/up.square"
					@click="didClickOverride"
				) Override
				DropdownListItem(
					v-if="hasPayload"
					icon="textBlock"
					@click.prevent="didClickShowPayload"
				)
					| Show payload
	ExpandingPanel(:expanded="showSubmissionTimeline")
		SubmissionsTimeline(
			:submissions="submissions"
		)
</template>

<script>
import { computed } from 'vue';

import delegate from '@/js/utils/delegate';
import { createFilter } from '@/js/utils/filter';

import dig from '@/js/utils/dig';
import submissionPropsMixin from '../lib/mixins/submission-props';

import ExpandingPanel from '@/components/base/ExpandingPanel.vue';
import Inspector from '../Inspector.vue';
import IssueCategories from '../IssueCategories.vue';
import OutcomeIcon from '../OutcomeIcon.vue';
import SubmissionsTimeline from '../SubmissionsTimeline.vue';
import { DropdownList, DropdownListItem } from '@/components/base/DropdownList/index';
import { SubmissionDetails } from '../SubmissionDetails/index';

export default {
	components: {
		DropdownList,
		DropdownListItem,
		ExpandingPanel,
		IssueCategories,
		OutcomeIcon,
		SubmissionDetails,
		SubmissionsTimeline
	},
	mixins: [
		submissionPropsMixin()
	],
	provide() {
		return {
			title: computed(() => this.title),
			evaluationIndex: computed(() => {
				return this.evaluationIndex;
			})
		};
	},
	inject: [
		'$signContactModal',
		'$stack'
	],
	props: {
		group: {
			type: Object,
			required: true
		},
		evaluationIndex: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			showSubmissionTimeline: false
		};
	},
	computed: {
		...delegate('submissions', { to: 'group' }),
		hasSubmissions() {
			return this.submissions.length > 0;
		},
		isLatestEvaluation() {
			return this.evaluationIndex === 0;
		},
		submission() {
			if (!this.hasSubmissions) {
				return null;
			}

			return this.submissions[0];
		},
		title() {
			let title = '';

			// Add component name, formatted
			title += this.$text
				.capitalizeWords(this.group.componentName)
				// Ks3 -> KS3
				.replace(/([A-Z][a-z][0-9]+)([^\w]|$)/g, (_, word, separator) => {
					return `${word.toUpperCase()}${separator}`;
				});

			// Submission name goes after component separated by ,
			if (typeof this.group.submissionName === 'string' && this.group.submissionName.length > 0) {
				title += `, ${this.$text.toSentenceCase(this.group.submissionName)}`;
			}

			// The cherry on top is the disambiguationId(when present) surrounded by ()
			if (typeof this.group.disambiguationId === 'string' && this.group.disambiguationId.length > 0) {
				title += ` (${this.$text.toSentenceCase(this.group.disambiguationId)})`;
			}

			return title;
		},
		details() {
			return [
				...this.baseDetails.slice(0, -1),
				{
					props: {
						icon: 'burger',
						text: this.$text.pluralize(this.numberOfEntries, 'entry', 'entries'),
						onClick: this.didClickToggleSubmissions
					}
				},
				...this.baseDetails.slice(-1)
			];
		},
		numberOfEntries() {
			return this.submissions.reduce((entries, { humanOverrides }) => {
				return entries + humanOverrides.length;
			}, this.submissions.length);
		},
		humanOverrides() {
			if (!this.hasSubmissions) {
				return [];
			}

			return this.submission.humanOverrides;
		},
		humanOverride() {
			if (this.humanOverrides.length === 0) {
				return null;
			}

			return this.humanOverrides[0];
		},
		identityReference() {
			if (this.hasAttemptId) {
				return {
					attemptId: this.attemptId
				};
			}
			return {
				componentName: this.group.componentName,
				submissionName: this.group.submissionName
			};
		},
		modifierClasses() {
			return {
				'show-submission-timeline': this.showSubmissionTimeline,
				'is-faded': this.evaluationForced
			};
		},
		canAttest() {
			return false;
		},
		isAttested() {
			return this.humanOverrides.some((humanOverride) => {
				return dig(humanOverride, ['input', 'payload', 'acknowledgeList', 'acknowledge[0]', 'kind']) === 'ATTEST';
			});
		},
		canElaborate() {
			if (this.signatureActionsDisabled) {
				return false;
			}
			if (this.isAttested) {
				return false;
			}

			return this.humanOverrides.every(createFilter({
				'input.payload.kind': { not: 'FORCE' }
			}));
		},
		canOverride() {
			if (this.signatureActionsDisabled) {
				return false;
			}

			return true;
		},
		hasPayload() {
			return this.hasSubmissions;
		},
		hasActions() {
			return [
				this.canAttest,
				this.canElaborate,
				this.canOverride,
				this.hasPayload
			].some(option => option === true);
		},
		signatureActionsDisabled() {
			if (!this.isLatestEvaluation) {
				return true;
			}
			if (this.signature.evaluations.length === 0) {
				return true;
			}
			if (!this.hasSubmissions) { // temporary disable until we handle human overrides on waiting components
				return true;
			}

			return this.signature.evaluations[0].submissionsUsed
				.map(({ submissionId }) => {
					return this.signature.submissions.find(createFilter({
						id: submissionId
					}));
				})
				.some((submission) => {
					return dig(submission, ['input', 'payload', 'kind']) === 'FORCE';
				});
		}
	},
	methods: {
		didClickAttest() {
			this.openOverrideSubmissionModal('attest');
		},
		didClickOverride() {
			this.openOverrideSubmissionModal('override');
		},
		didClickElaborate() {
			this.openOverrideSubmissionModal('elaborate');
		},
		openOverrideSubmissionModal(mode) {
			this.$signContactModal.openOverrideSubmissionModal({
				mode,
				submissionId: this.submission?.id,
				identityReference: this.identityReference
			});
		},
		didClickShowPayload() {
			this.$stack.push({
				component: Inspector,
				props: {
					back: 'Back to signatures',
					title: this.title,
					object: this.submissions[0]?.payload,
					details: this.details.filter(entry => entry.props.icon != 'burger')
				}
			});
		},
		didClickToggleSubmissions() {
			this.showSubmissionTimeline = !this.showSubmissionTimeline;
		}
	}
};

</script>

<style lang="scss" scoped>
.submission-group {
	--expanding-panel-transition-duration: 0.25s;
	--expanding-panel-top: auto;
	--expanding-panel-bottom: 0;
	--component-submission-text-color: hsl(var(--white));
	--component-submission-text-color-dim: hsl(var(--white65));
	--issue-categories-text-color: var(--component-submission-text-color);
	--submission-details-text-color: var(--component-submission-text-color-dim);

	transition:
		border-color
		var(--expanding-panel-transition-duration)
		var(--expanding-panel-transition-timing-function);

	&:not(:last-child) {
		border-bottom: rem-calc(1) solid hsl(var(--white10));
	}

	&.show-submission-timeline {
		border-color: transparent;
	}

	&.is-faded {
		--component-submission-text-color: hsl(var(--white70));
		--component-submission-text-color-dim: hsl(var(--white40));
	}
}

.current {
	padding: rem-calc(23) 0;
	display: flex;
}

.state-icon,
.state,
.content,
.actions {
	flex: 0 0 auto;

	&:not(:first-child) {
		margin-left: rem-calc(20);
	}
}

.state {
	flex-basis: rem-calc(175);

	.title {
		margin: 0 0 rem-calc(7);
		color: var(--component-submission-text-color);
	}

	.outcome {
		color: var(--component-submission-text-color-dim);
	}
}

.content {
	flex: 0 1 100%;
}

.comment {
	margin-bottom: rem-calc(15);
	color: var(--component-submission-text-color);

	p {
		margin-bottom: rem-calc(10);
	}
}

#{v-deep('.details')} {
	&:not(:last-child) {
		margin-bottom: rem-calc(5);
	}
}

.actions {
	#{v-deep('.list-item')} {
		list-style: none;
	}
}

</style>
