<template lang="pug">
.checkbox
	input(
		@input="toggle($event.target.checked)"
		type="checkbox"
		:checked="checked"
		:id="fieldId"
		:disabled="!!disabled"
	)
	label(
		:for="fieldId"
		:class="!!disabled ? 'disabled' : null"
	)
		VIcon(:name="iconName")
		slot
		span.disabled-reason(v-if="typeof disabled === 'string'") ({{ disabled }})
</template>

<script>
import { generateTagId } from '@/js/utils/unique-id-generator';

export default {
	name: 'VCheckbox',
	props: {
		fieldId: {
			type: String,
			required: false,
			default: () => generateTagId()
		},
		checked: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: [String, Boolean],
			required: false,
			default: false
		}
	},
	emits: ['update:checked'],
	computed: {
		iconName() {
			return this.checked ? '20/form/checkbox.checked' : '20/form/checkbox';
		}
	},
	methods: {
		toggle(checked) {
			this.$emit('update:checked', checked);
		}
	}
};
</script>

<style lang="scss" scoped>
input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	pointer-events: none;
}

.icon {
	margin-right: rem-calc(12);

	&:not([class*='checked']) {
		opacity: 0.4;
	}

	#{v-deep('.stroke-white-100')} {
		stroke: var(--body-background);
	}
}

.checkbox {
	user-select: none;
	position: relative;

	label {
		display: flex;
		align-items: center;
		flex-grow: 1;
		cursor: pointer;
		font-size: var(--font-body-size);
		font-weight: var(--font-bolder);
		line-height: 1.2;

		&.disabled {
			color: hsl(var(--white40));
			cursor: not-allowed;
		}

		.disabled-reason {
			margin-right: rem-calc(8);
		}
	}
}
</style>
