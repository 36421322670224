<template lang="pug">
Popper(
	ref="popper"
	placement="bottom-end"
	arrow
	locked
	@close:popper="onClose"
)
	VButton.secondary
		.label {{openerLabel}}
		VIcon(name="arrow/down.simple")

	template(
		#content="{ close }"
	)
		.inner
			.sidebar
				Navigation(v-model="signatureProfileId")
				.actions
					VButton(
						@click="didClickApply(close)"
						:disabled="applyDisabled"
					) Apply
					VButton.secondary.invert(
						@click="didClickClose(close)"
					) Close
			.content
				SignatureDetails(:signatureProfile="signatureProfile")

</template>

<script>

import Popper from 'vue3-popper';

import { createFilter } from '@/js/utils/filter';

import SignatureDetails from './SignatureDetails.vue';
import Navigation from './Navigation.vue';

export default {
	name: 'SignatureProfilePicker',
	components: {
		SignatureDetails,
		Navigation,
		Popper
	},
	inject: [
		'$signContactModal'
	],
	data() {
		return {
			nextSignatureProfileId: null,
			isApplying: false
		};
	},
	computed: {
		signatureProfiles() {
			return this.$store.getters['signatureProfiles/all'];
		},
		signatureProfileId: {
			get() {
				return this.nextSignatureProfileId ?? this.activeSignatureProfileId;
			},
			set(profileId) {
				if (profileId === this.activeSignatureProfileId) {
					this.nextSignatureProfileId = null;
				} else {
					this.nextSignatureProfileId = profileId;
				}
			}
		},
		defaultSignatureProfileId() {
			return this.signatureProfiles[Math.floor(Math.random() * this.signatureProfiles.length)].id;
		},
		activeSignatureProfileId() {
			return this.$signContactModal.signatureProfileId;
		},
		...[
			'signatureProfile',
			'activeSignatureProfile',
			'defaultSignatureProfile'
		].reduce((properties, property) => {
			return {
				...properties,
				[property]() {
					return this.signatureProfiles.find(createFilter({
						id: this[`${property}Id`]
					}));
				}
			};
		}, {}),
		openerLabel() {
			return this.activeSignatureProfile.displayName;
		},
		applyDisabled() {
			return this.isApplying ||
				!this.canApply;
		},
		canApply() {
			return this.nextSignatureProfileId !== null;
		},
		closeDisabled() {
			return this.isApplying;
		}
	},
	methods: {
		onClose() {
			this.reset();
		},
		reset() {
			this.signatureProfileId = null;
			this.isApplying = false;
		},
		didClickApply(closePopper) {
			this.isApplying = true;
			this.$signContactModal.setSignatureProfileId(this.nextSignatureProfileId)
				.then(() => {
					closePopper();
				})
				.finally(() => {
					this.isApplying = false;
				});
		},
		didClickClose(closePopper) {
			if (this.isApplying) {
				return;
			}

			closePopper();
		}
	}
};

</script>

<style lang="scss" scoped>
#{v-deep('.popper')} {
	width: rem-calc(570);
}

.inner {
	margin: -0.25rem -0.5rem;
	display: flex;
}

.sidebar,
.content {
	min-height: rem-calc(300);
	max-height: rem-calc(430);
	overflow-y: scroll;

	@include scrollbars(0.5rem, hsl(var(--white20)));
}

.sidebar {
	flex: 0 0 rem-calc(200);
	padding: rem-calc(15);
	border-right: var(--popper-theme-border-width) solid var(--popper-theme-border-color);
	display: flex;
	flex-direction: column;

	> * {
		flex: 0 1 100%;
	}

	.actions {
		flex: 0 0 auto;
		display: flex;

		> * {
			display: block;
			flex: 0 1 100%;

			&:not(:first-child) {
				margin-left: rem-calc(10);
			}
		}
	}
}

.content {
	flex: 0 1 100%;
	padding: rem-calc(15);
}
</style>
