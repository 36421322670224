<template lang="pug">
li.detail(
	:class="modifierClasses"
	@click="handleClick"
)
	VIcon(
		v-if="hasIcon"
		:name="icon"
	)
	.text {{text}}
</template>

<script>

export default {
	name: 'SubmissionDetail',
	props: {
		icon: {
			type: String,
			required: false,
			default: null
		},
		text: {
			type: String,
			required: true
		},
		onClick: {
			type: Function,
			required: false,
			default: null
		}
	},
	computed: {
		modifierClasses() {
			return {
				'is-clickable': this.isClickable
			};
		},
		isClickable() {
			return typeof this.onClick === 'function';
		},
		hasIcon() {
			return typeof this.icon === 'string' && this.icon.length > 0;
		}
	},
	methods: {
		handleClick() {
			if (!this.isClickable) {
				return;
			}

			this.onClick();
		}
	}
};

</script>

<style lang="scss" scoped>
.detail {
	--icon-color-primary: var(--submission-details-text-color, hsl(var(--white60)));
	--icon-size: #{rem-calc(16)};

	display: flex;
	align-items: center;
	color: var(--icon-color-primary);
	margin: 0 0 rem-calc(10);

	&:not(:last-child) {
		margin-right: rem-calc(15);
	}

	.text {
		&:not(:first-child) {
			margin-left: rem-calc(5);
		}
	}

	&.is-clickable {
		cursor: pointer;

		.text {
			text-decoration: underline;
		}
	}

	&.is-clickable:hover {
		.text {
			text-decoration: none;
		}
	}
}
</style>
