<template lang="pug">
.execution-environment(:class="environmentClass")
	.environment-border
		TheNavbar
		#content
			.row
				router-view
				vue-progress-bar#progress-bar
		TheWindowDialog
		TheEnvironmentIndicator
</template>

<script>
import { mapGetters } from 'vuex';

import { ACTIVITY_UPDATE_INTERVAL } from '@/env';

import { isActive } from '@/js/network/socket';

import TheNavbar from '@/components/single/TheNavbar/TheNavbar.vue';
import TheWindowDialog from '@/components/single/TheWindowDialog.vue';
import TheEnvironmentIndicator from '@/components/single/TheEnvironmentIndicator.vue';

const activityListeners = ['mousemove', 'keydown'];

export default {
	name: 'App',
	components: {
		TheNavbar,
		TheWindowDialog,
		TheEnvironmentIndicator
	},
	computed: {
		environmentClass() {
			return this.environment.toLowerCase();
		},
		...mapGetters({
			environment: 'environment/environment',
			unmigratedSystems: 'migratedAssets/unmigratedSystems'
		})
	},
	created() {
		this.$bus.on('exception', this.onException);
		this.registerActivityListeners();
	},
	beforeUnmount() {
		this.$bus.off('exception', this.onException);
	},
	methods: {
		onException(message) {
			this.$notify.internalError(message);
		},
		registerActivityListeners() {
			activityListeners.forEach(listener => window.addEventListener(listener, this.handleActivity));
		},
		handleActivity() {
			activityListeners.forEach(listener => window.removeEventListener(listener, this.handleActivity));
			isActive();
			setTimeout(this.registerActivityListeners, 1000 * ACTIVITY_UPDATE_INTERVAL);
		}
	}
};
</script>

<style lang="scss">
@import '@/assets/scss/variables';
@import '@/assets/scss/box';
@import '@/assets/scss/grid';
@import '@/assets/scss/typography';
@import '@/assets/scss/forms';
@import '@/assets/scss/buttons';
@import '@/assets/scss/tables';
@import '@/assets/scss/notify';
@import '@/assets/scss/datetime';
@import '@/assets/scss/chart';
@import '@/assets/scss/icons';
@import '@/assets/scss/components';

html,
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	font-family: var(--font-family);
	font-weight: var(--font-normal);
	color: var(--body-color);
	font-size: 100%;
	background: var(--body-background);
	margin: 0;
	overflow-anchor: none;

	@include scrollbars(0.5rem, hsl(var(--white20)));
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

#content {
	padding: var(--navbar-height) rem-calc(20 20);

	@media (min-width: $mobile-breakpoint) {
		padding: calc(var(--navbar-height) + #{rem-calc(60)}) rem-calc(40 40);
	}
}

.environment-border {
	min-height: 100vh;
	box-sizing: border-box;

	.development &,
	.dev & {
		border: 4px solid hsl(var(--dev-color));
	}

	.test & {
		border: 4px solid hsl(var(--test-color));
	}

	.staging &,
	.preprod & {
		border: 4px solid hsl(var(--staging-color));
	}
}
</style>
