<template lang="pug">
.example-picker
	.label Select example

	VDropdownSelect(
		placeholder="Select example"
		:options="examples"
		v-model="example"
	)
</template>

<script>

import examples from '../examples';

export default {
	props: {
		modelValue: {
			type: String,
			required: true
		}
	},
	emits: [
		'update:modelValue'
	],
	computed: {
		example: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		},
		examples() {
			return Object.keys(examples).map((example) => {
				return {
					value: example,
					label: example
				};
			});
		}
	}
};

</script>

<style lang="scss">
.example-picker {
	display: flex;
	margin-bottom: 30px;
	align-items: center;

	.label {
		flex: 0 0 200px;
	}

	.dropdown-container {
		flex: 0 1 100%;
		margin-left: 20px;
	}
}
</style>
