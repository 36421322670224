<template lang="pug">
.search-section
	.search-box(:class="[{ results: modelValue && autocompleteSource }, { focus: inputFocused }, { 'search-has-input': searchisActive }]")
		VIcon(name="search")
		input(
			type="search"
			ref="searchBox"
			:modelValue="modelValue"
			@update:modelValue="modelValue = $event"
			@input="delayUpdateEvent($event)"
			@focus="inputFocused = true"
			@blur="inputFocused = false"
			:onkeydown="navigateList"
			:placeholder="placeholder"
		)
	ul.list.search-result(v-if="showAutocomplete")
		SimpleListItem(
			v-for="item in results"
			:key="item.id"
			:item="item"
			:class="{ selected: isSelected(item) }"
			:ref="'ref' + item.id"
			:icon="item.icon"
			tooltip-position="left"
			@click="itemClick(item)"
		) {{ item.name }}
		SimpleListItem(v-if="!hasResults") No matches
</template>

<script>
import _ from 'lodash';

import validate, { joi } from '@pv';

import SimpleListItem from '@/components/base/SimpleList/SimpleListItem.vue';

export default {
	name: 'SearchBox',
	components: {
		SimpleListItem
	},
	props: {
		autocompleteSource: {
			type: Array,
			required: false,
			default: null,
			validator: validate(joi.array().items(joi.nameAndId()))
		},
		modelValue: {
			type: String,
			required: false,
			default: ''
		},
		placeholder: {
			type: String,
			required: false,
			default: 'Search'
		},
		navigateOnClick: {
			type: Boolean,
			required: false,
			default: true
		},
		focusOnLoad: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['itemClicked', 'update:modelValue'],
	data() {
		return {
			itemClicked: false,
			inputFocused: false,
			selectedIndex: 0,
			delayTimer: 0,
			searchisActive: false
		};
	},
	computed: {
		showAutocomplete() {
			return this.autocompleteSource && !this.itemClicked;
		},
		hasResults() {
			return this.autocompleteSource && this.autocompleteSource.length;
		},
		results() {
			if (!this.autocompleteSource) {
				return [];
			}

			return _.chain(this.autocompleteSource)
				.sortByAlphaNum()
				.sortByDeprecated()
				.value();
		},
		selected() {
			return this.results[this.selectedIndex];
		}
	},
	watch: {
		autocompleteSource: function() {
			this.itemClicked = false;
		}
	},
	created() {
		if (this.focusOnLoad) {
			this.$nextTick(() => {
				this.focusOnSearch();
			});
		}
	},
	methods: {
		// Delay emitting of updated search string to avoid intensive recursive calls
		delayUpdateEvent(event) {
			this.searchisActive = !!this.modelValue;
			clearTimeout(this.delayTimer);
			this.delayTimer = setTimeout(() => {
				this.$emit('update:modelValue', event.target.value);
			}, 300);
		},
		isSelected(item) {
			return item.id === this.selected.id;
		},
		navigateList(event) {
			if (!this.selected) {
				return;
			}

			this.adjustScroll();

			if (event.key === 'Enter') {
				this.itemClick(this.selected);
			} else if (event.key === 'ArrowUp' && this.selectedIndex) {
				--this.selectedIndex;
			} else if (event.key === 'ArrowDown' && this.selectedIndex < this.results.length - 1) {
				++this.selectedIndex;
			} else {
				this.selectedIndex = 0; // Resets selected item whenever user changes their search
			}
		},
		adjustScroll() {
			this.$nextTick(() => {
				const id = 'ref' + this.selected.id;
				if (this.$refs[id]?.length) {
					this.$refs[id][0].$el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
				}
			});
		},
		itemClick(item) {
			if (this.navigateOnClick) {
				this.$router.push(item.url);
			} else {
				this.itemClicked = true;
				this.$emit('itemClicked', item);
			}
		},
		focusOnSearch() {
			this.$nextTick(() => {
				this.$refs["searchBox"].focus();
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.search-section {
	position: relative;
}

.search-box {
	margin: rem-calc(0 0 10);
	border: 1px solid hsl(var(--white20));
	border-radius: var(--global-radius);
	background-color: transparent;
	color: var(--body-color);
	height: var(--input-height);
	padding: rem-calc(0 10);
	font-family: inherit;
	font-size: 1rem;
	line-height: 1.5;
	display: flex;
	align-items: center;

	&:hover {
		border-color: hsl(var(--white40));
	}

	&.focus {
		border-color: hsl(var(--white40));
		box-shadow: 0 0 0 3px hsl(var(--white) / 10%);
	}

	&.disabled {
		border-color: hsl(var(--white20));
		color: hsl(var(--white40));
		pointer-events: none;
	}

	&.results {
		border-radius: var(--global-radius) var(--global-radius) 0 0;
	}

	&.search-has-input {
		border-color: hsl(var(--white));
	}

	.icon {
		margin-right: rem-calc(10);

		&#{v-deep('.stroke-primary')} {
			stroke: hsl(var(--white40));
		}

		&#{v-deep('.fill-primary')} {
			fill: hsl(var(--white40));
		}
	}

	input {
		margin: 0;
		padding: 0;
		border: 0;

		&:focus {
			box-shadow: none;
		}
	}
}

.search-result {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	border-style: solid;
	border-width: 0 1px 1px;
	border-color: hsl(var(--white));
	background-color: var(--body-background);
	padding: rem-calc(0 10);
	border-radius: 0 0 var(--global-radius) var(--global-radius);
	z-index: 1;
	max-height: calc(var(--search-list-item-height) * 7);
	overflow: hidden auto;

	@include scrollbars(0.5rem, hsl(var(--white20)));

	.list-item {
		padding: rem-calc(5 0);
		height: var(--search-list-item-height);

		&.selected {
			margin: rem-calc(0 -5);

			#{v-deep('.content')} {
				padding-left: rem-calc(5);
				padding-right: rem-calc(5);
				background-color: hsl(var(--white15));
				border-radius: var(--global-radius);
			}
		}
	}
}
</style>
