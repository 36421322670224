<template lang="pug">
label(:class="{ required }")
	slot
	span.optional(v-if="!required && $slots.default") (optional)
	.input(:class="[type, { focus: inputFocused }, { disabled: disabled }, { 'has-input': hasInput }, { 'has-addon': addon } ]")
		VIcon(:name="icon")
		input(
			:type="type"
			:value="modelValue"
			:placeholder="placeholder"
			:disabled="disabled"
			:required="required"
			:name="name"
			:ref="inputRef"
			:title="title"
			:min="min"
			:max="max"
			@focus="onFocus"
			@blur="inputFocused = false"
			@input="updateInput"
		)
		.addon(v-if="addon") {{ addon }}
		VIcon.disable-icon(
			v-if="disabled"
			name="lock"
		)

	VCallout(
		v-if="validation"
		type="warning"
	) {{ validation }}
</template>

<script>
export default {
	name: 'VInput',
	props: {
		modelValue: {
			type: [String, Number],
			required: true
		},
		name: {
			type: String,
			required: false,
			default: ''
		},
		type: {
			type: String,
			required: false,
			default: 'text'
		},
		placeholder: {
			type: String,
			required: false,
			default: ''
		},
		min: {
			type: [Number, String],
			required: false,
			default: null
		},
		max: {
			type: [Number, String],
			required: false,
			default: null
		},
		required: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		validation: {
			type: String,
			required: false,
			default: null
		},
		icon: {
			type: String,
			required: false,
			default: 'reportingAlt'
		},
		inputRef: {
			type: String,
			required: false,
			default: ''
		},
		title: {
			type: String,
			required: false,
			default: null
		},
		hasInput: {
			type: Boolean,
			required: false,
			default: false
		},
		addon: {
			type: String,
			required: false,
			default: null
		}
	},
	emits: ['update:modelValue', 'focus'],
	data() {
		return {
			inputFocused: false
		};
	},
	methods: {
		updateInput(event) {
			this.$emit('update:modelValue', event.target.value);
		},
		onFocus() {
			this.inputFocused = true;
			this.$emit('focus');
		}
	}
};
</script>

<style lang="scss" scoped>
.text,
.password,
.date,
.datetime,
.datetime-local,
.month,
.week,
.email,
.number,
.search,
.tel,
.time,
.url,
.color {
	display: flex;
	align-items: center;
	border: 1px solid hsl(var(--white20));
	border-radius: var(--global-radius);
	background-color: var(--body-background);
	color: var(--body-color);
	height: var(--input-height);
	padding: rem-calc(0 10);
	font-family: inherit;
	font-size: 1rem;
	line-height: 1.5;

	.icon {
		flex-shrink: 0;
		margin-right: rem-calc(10);

		&.disable-icon {
			margin: rem-calc(0 0 0 10);
		}

		&#{v-deep('.stroke-primary')} {
			stroke: hsl(var(--white40));
		}

		&#{v-deep('.fill-primary')} {
			fill: hsl(var(--white40));
		}
	}

	&:hover {
		border-color: hsl(var(--white40));
	}

	&.focus {
		border-color: hsl(var(--white40));
		box-shadow: 0 0 0 3px hsl(var(--white) / 10%);
	}

	&.has-input {
		border-color: hsl(var(--white));

		&:hover {
			border-color: hsl(var(--white80));
		}
	}

	&.disabled {
		border-color: hsl(var(--white20));
		color: hsl(var(--white40));
		cursor: not-allowed;
		user-select: none;

		input {
			pointer-events: none;
		}
	}

	input {
		margin: 0;
		padding: 0;
		border: 0;

		@include input-placeholder {
			color: hsl(var(--white40));
		}

		&:focus {
			box-shadow: none;
		}
	}

	.addon {
		display: flex;
		align-items: center;
		height: var(--input-height);
		border-left: 1px solid hsl(var(--white20));
		padding-left: rem-calc(10);
		font-weight: var(--font-boldest);
	}
}

label {
	margin: rem-calc(0 0 10);
	position: relative;

	> .callout {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 1;
		margin-top: rem-calc(10);
	}
}
</style>
