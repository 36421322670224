import { isDeprecated } from '@/js/plugins/lifecycle';

import store from '@/store/store';
import { createFilter } from '@/js/utils/filter';

export default stateName => {
	return {
		all(state, getters, rootState) {
			const list = state[stateName];
			const hideDeprecated = rootState.settings.settings.hideDeprecated;
			return hideDeprecated ? list.filter(item => !isDeprecated(item)) : list;
		},
		allForce(state) {
			return state[stateName];
		},
		find(state) {
			return (id, options) => {
				const { notify } = { notify: true, ...options };

				const match = state[stateName].find(record => record.id === id);
				if (match || !notify) {
					return match;
				}

				store.dispatch('brokenIds/set', { module: stateName, id });

				return null;
			};
		},
		filter(state) {
			return (filter) => {
				return state[stateName].filter(createFilter(filter));
			};
		},
		some(state) {
			return (filter) => {
				return state[stateName].some(createFilter(filter));
			};
		},
		every(state) {
			return (filter) => {
				return state[stateName].every(createFilter(filter));
			};
		},
		none(_, getters) {
			return (filter) => {
				return !getters.some(filter);
			};
		},
		editorUserList(state) {
			return state.editorUserList;
		}
	};
};
