<template lang="pug">
.submissions-timeline
	h1 Submission timeline
	ol.submissions-timeline-entries
		Entry(
			v-for="{ key, submission, humanOverride } in entries"
			:key="key"
			:submission="submission"
			:humanOverride="humanOverride"
		)
</template>

<script>

import { toDateTime } from '@/js/utils/filter/types';

import Entry from './SubmissionsTimeline/Entry.vue';

export default {
	components: {
		Entry
	},
	props: {
		submissions: {
			type: Array,
			required: true
		}
	},
	computed: {
		entries() {
			return this.submissions
				.flatMap((submission) => {
					const baseSubmission = Object.keys(submission)
						.reduce((object, property) => {
							if (property === 'humanOverrides') {
								return object;
							}

							return {
								...object,
								[property]: submission[property]
							};
						}, {});

					return [
						{
							key: baseSubmission.id,
							submission: baseSubmission,
							humanOverride: null
						},
						...submission.humanOverrides.map((humanOverride) => {
							return {
								key: humanOverride.id,
								submission: baseSubmission,
								humanOverride
							};
						})
					];
				})
				.sort((a, b) => {
					const aSubmittedAt = a.humanOverride === null ? a.submission.submittedAt : a.humanOverride.submittedAt;
					const bSubmittedAt = b.humanOverride === null ? b.submission.submittedAt : b.humanOverride.submittedAt;

					return toDateTime(bSubmittedAt).toMillis() - toDateTime(aSubmittedAt).toMillis();
				});
		}
	}
};

</script>

<style lang="scss" scoped>
.submissions-timeline {
	padding: rem-calc(20);
	background-color: hsl(var(--dark-purple110));
	border: rem-calc(1) solid hsl(var(--white15));
	border-radius: rem-calc(8);

	h1 {
		margin: 0 0 rem-calc(20);
		font-size: rem-calc(17);
		font-weight: 600;
	}
}

.submissions-timeline-entries {
	list-style: none;
}
</style>
