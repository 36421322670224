export function storeValue(key: string, value: object) {
	sessionStorage.setItem(key, JSON.stringify(value));
}

export function getValue<T>(key: string, remove: boolean = true): T | undefined {
	const value = sessionStorage.getItem(key) ?? undefined;
	if (remove) {
		sessionStorage.removeItem(key);
	}
	if (value === undefined) {
		return undefined;
	}
	return JSON.parse(value) as T;
}
