import { contactStates, frontendStates, contactSignatureOutcomes } from "@/js/utils/data-definitions";
import { $text } from "@/js/plugins/text";
const allStates = { ...contactStates, ...frontendStates, ...contactSignatureOutcomes };

class ContactState {
	constructor(state) {
		if (state instanceof ContactState) {
			this.name = state.name;
		} else {
			const name = String(state).toLowerCase();
			this.name = name;
		}
	}
	get class() {
		return allStates[this.name].class ?? this.name;
	}

	get icon() {
		return allStates[this.name].icon;
	}

	get text() {
		return allStates[this.name].text ?? $text.capitalize(this.name);
	}

	is(...states) {
		return states
			.map((state) => {
				return new ContactState(state);
			})
			.some((state) => {
				return this.name === state.name;
			});
	}

	not(...states) {
		return !this.is(...states);
	}
}

// For all our states, add an isX function, e.g isCancelled, etc.
Object.values(allStates).forEach(state => {
	ContactState[state.id] = new ContactState(state.id);

	ContactState.prototype[`is${$text.capitalizeWords(state.id, '')}`] = function () {
		return this.is(ContactState[state.id]);
	};
});
export default ContactState;

