<template lang="pug">
.styleguide-example
	h2(v-if="hasTitle") {{title}}
	.container
		.canvas
			slot

		.value(v-if="hasValueSlot")
			slot(name="value")
</template>

<script>

export default {
	name: 'StyleguideExample',
	props: {
		title: {
			type: String,
			required: false,
			default: null
		}
	},
	computed: {
		hasTitle() {
			return typeof this.title === 'string' && this.title.length > 0;
		},
		hasValueSlot() {
			return !!this.$slots.value;
		}
	}
};

</script>

<style lang="scss" scoped>
.styleguide-example {
	&:not(:last-child) {
		margin-bottom: rem-calc(50);
	}

	.container {
		border: rem-calc(1) solid hsl(var(--white20));
		border-radius: rem-calc(8);

		> * {
			padding: rem-calc(20);
		}

		> :not(:last-child) {
			border-bottom: rem-calc(1) solid hsl(var(--white20));
		}
	}

	.value {
		font-size: rem-calc(14);
		font-family: var(--font-family-mono);
	}
}
</style>
