<template lang="pug">
Evaluation(:evaluation="blankEvaluation")
	template(#actions)
		slot(name="actions")
</template>

<script>

import Evaluation from './Evaluation.vue';

export default {
	components: {
		Evaluation
	},
	props: {
		evaluation: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		blankEvaluation() {
			return {
				outcome: 'waiting',
				comments: [],
				issueCategories: [],
				submissionsUsed: []
			};
		}
	}
};

</script>
