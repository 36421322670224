import {
	isFraction,
	isMeasurement,
	isOneOfConst,
	isOneOfConstArray
} from '@/js/utils/json-schema/types';


export default (schema) => {
	if (typeof schema === 'string') {
		return schema;
	}

	if (isOneOfConst(schema)) {
		return 'string';
	} else if (isOneOfConstArray(schema)) {
		return 'array';
	} else if (isFraction(schema)) {
		return 'fraction';
	} else if (isMeasurement(schema)) {
		return 'measurement';
	}

	return schema.type;
};
