import joi from './custom-joi';

export default joiSchema => val => {
	const { value, error } = joiSchema.validate(val);
	if (error) {
		console.warn(error, value);
		return false;
	}
	return true;
};

export { joi };
