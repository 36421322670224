import Connection from '@/js/network/helpers';
import { manageAPIError } from '@/js/api/builder/api-error';
import { getScope } from '@/js/auth/auth-config';

import { UPDATE_RESOURCE } from '@/env';

const reservationConnection = new Connection('/v1.0/reservations', await getScope('kogs'), 'reservations');

const api = {
	add: ({ id, tenant }) => {
		return reservationConnection.axios
			.post('/expose', { contact_ids: [id], tenant_id: tenant.id }, { refetchId: id })
			.catch(manageAPIError);
	},

	remove: contact => reservationConnection.axios
		.delete(`/${contact.reservationId}`, { refetchId: contact.id })
		.catch(manageAPIError)
};

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		initiate({ dispatch }) {
			reservationConnection.socket.on(UPDATE_RESOURCE, ({ id }) => dispatch('contacts/fetchOne', id, { root: true }));
		},
		add({ dispatch }, contact) {
			return api.add(contact).then(() => dispatch('contacts/fetchOne', contact.id, { root: true }));
		},

		remove({ dispatch }, contact) {
			return api.remove(contact).then(() => dispatch('contacts/fetchOne', contact.id, { root: true }));
		}
	}
};
