import { camelize } from '@/js/utils/case-conversion';

const toKey = (...segments) => {
	return segments
		.map((segment, i) => {
			const camelizeFirst = i > 0;
			if (typeof segment === 'object') {
				return camelize(segment.key ?? segment.name, camelizeFirst);
			}

			return camelize(segment, camelizeFirst);
		})
		.filter((segment) => {
			return segment.length > 0;
		})
		.join('');
};

export const mapPaths = (entries, options = {}) => {
	const {
		path = ''
	} = options;

	return entries.reduce((paths, entry) => {
		if (entry.type === 'group') {
			return {
				...paths,
				[toKey(entry)]: mapPaths(
					entry.pages,
					{
						...options,
						path: `${path}/${entry.path}`.replace(/^\//, '')
					}
				)
			};
		}

		return {
			...paths,
			[toKey(entry)]: `${path}/${entry.path}`.replace(/^\//, '')
		};
	}, {});
};

export const mapNames = (entries, options = {}) => {
	return entries.reduce((names, entry) => {
		if (entry.type === 'group') {
			return {
				...names,
				[toKey(entry)]: mapNames(
					entry.pages,
					options
				)
			};
		}

		return {
			...names,
			[toKey(entry)]: entry.name
		};
	}, {});
};
