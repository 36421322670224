import { type DateInput, DateTime, Interval } from "luxon";

import { getScope } from "../auth/auth-config";
import { PROXY_TYR } from '@/env';
import Connection from '@/js/network/helpers';
import { manageAPIError } from "@/js/api/builder/api-error";
import { Datetime, Uuid } from "./types";

const connection = new Connection('/internal/v1', await getScope('kogs'), null, PROXY_TYR).axios;

type WirePrediction = {
	ephemeris_uuid: Uuid,
	visibility_start: Datetime,
	visibility_end: Datetime,
	station_uuid: Uuid,
	maximum_elevation_angle: number,
}

type WireEphemeris = {
	ephemeris_uuid: Uuid,
	prediction_processing_state: "UNAVAILABLE" | "PROCESSING" | "COMPUTED"
	computed_at: Datetime
}

// type MappedPrediction = MapAndCamel<WirePrediction, {"station_uuid": "station"}>;
// type MappedEphemeris = MapAndCamel<WireEphemeris, Record<string, never>>;

const output = (prediction: any) => {

	prediction.ephemerisUuid = prediction.ephemeris_uuid;
	delete prediction.ephemeris_uuid;

	prediction.visibilityStart = DateTime.fromISO(prediction.visibility_start, { zone: 'utc' });
	delete prediction.visibility_start;

	prediction.visibilityEnd = DateTime.fromISO(prediction.visibility_end, { zone: 'utc' });
	delete prediction.visibility_end;

	prediction.station = prediction.station_uuid;
	delete prediction.station_uuid;

	prediction.maximumElevation = prediction.maximum_elevation_angle;
	delete prediction.maximum_elevation_angle;
	return prediction;
};

const outputEphemeris = (ephemeris: any) => {
	ephemeris.ephemerisUuid = ephemeris.ephemeris_uuid;
	delete ephemeris.ephemeris_uuid;

	ephemeris.predictionProcessingState = ephemeris.prediction_processing_state;
	delete ephemeris.prediction_processing_state;

	// TYR returns null for computed_at if the predictions are on demand, setting it to current time
	ephemeris.computedAt = DateTime.fromISO(ephemeris.computed_at ?? DateTime.utc().toISO(), { zone: 'utc' });
	delete ephemeris.computed_at;

	return ephemeris;
};

const findMatchingPredictions = (predictions: WirePrediction[], startTime: DateInput, endTime: DateInput) => {
	const contactInterval = Interval.fromDateTimes(
		startTime,
		endTime
	);
	const relevantPrediction = predictions.filter(pred => {
		const predictionInterval = Interval.fromDateTimes(
			DateTime.fromISO(pred.visibility_start, { zone: 'utc' }),
			DateTime.fromISO(pred.visibility_end, { zone: 'utc' })
		);
		return predictionInterval.overlaps(contactInterval);
	}).map(output);
	return relevantPrediction;
};

type FetchPredictions = {
	ephemeris: WireEphemeris[];
	predictions: WirePrediction[];
}

const fetchPredictions = (tenantId: Uuid, spacecraftId: Uuid, stationIds = [], startTime: DateInput | null = null, endTime: DateInput | null = null) => connection
	.post('predictions/station/timeline', {
		spacecraft_uuid: spacecraftId,
		tenant_uuid: tenantId,
		station_uuids: stationIds,
		start_time: startTime?.toString(),
		end_time: endTime?.toString()
	})
	.then(async ({ data }: { data: FetchPredictions }) => {
		if (data.predictions.length > 0) {
			return {
				ephemeris: data.ephemeris.map(outputEphemeris),
				predictions: data.predictions.map(output)
			};
		}
		return {
			ephemeris: data.ephemeris.map(outputEphemeris),
			predictions: (await fetchOnDemandPredictions(tenantId, spacecraftId, stationIds, startTime, endTime)).map(output)
		};
	})
	.catch(manageAPIError);


const fetchOnDemandPredictions = (tenantId: Uuid, spacecraftId: Uuid, stationIds = [], startTime: DateInput | null = null, endTime: DateInput | null = null) => connection
	.post('predictions/station/on-demand', {
		spacecraft_uuid: spacecraftId,
		tenant_uuid: tenantId,
		station_uuids: stationIds,
		start_time: startTime?.toString(),
		end_time: endTime?.toString()
	})
	.then(({ data }: { data: { predictions: WirePrediction[] } }) => data.predictions);

const fetchContactPrediction = (ephemerisIds: Uuid[], stationIds = Array<Uuid>(), startTime = null as DateInput | null, endTime = null as DateInput | null) => connection
	.post('predictions/station/ephemeris', {
		ephemeris_uuids: ephemerisIds,
		station_uuids: stationIds
	})
	.then(({ data }) => ({
		ephemeris: data.ephemeris.map(outputEphemeris),
		predictions: findMatchingPredictions(data.predictions, startTime!, endTime!)
	}))
	.catch(manageAPIError);

const fetchTrackingPrediction = (ephemerisId: Uuid, from: DateInput, until: DateInput, systemId: Uuid, format = 'JSON', interval = 1000) => connection
	.post('tracking/calculate', {
		ephemeris_uuid: ephemerisId,
		calculate_from: from?.toString(),
		calculate_until: until?.toString(),
		observer_kind: "SYSTEM",
		observer: {
			id: systemId
		},
		format: format,
		interval: interval
	})
	.catch(manageAPIError);

export {
	fetchPredictions,
	fetchContactPrediction,
	fetchTrackingPrediction
};
