
import { manageError } from '@/store/helpers/store-error';
import { UPDATE_RESOURCE } from '@/env';

import { api, connection } from '@/js/api/reservation-windows';

export default {
	namespaced: true,
	state: () => ({
		reservationWindows: []
	}),
	getters: {
		find(state) {
			return tenantId => {
				return state['reservationWindows'].find(record => record.tenantId === tenantId);
			};
		}
	},
	mutations: {
		update(state, { window, tenantId }) {
			state.reservationWindows = [
				...state.reservationWindows.filter(window => window.tenantId !== tenantId),
				window
			];
		}
	},
	actions: {
		ensureOne({ dispatch, getters }, { id }) {
			if (getters.find(id)) {
				return;
			}
			return dispatch('fetchOne', id);
		},
		initiateMore({ dispatch }) {
			connection.socket.on(UPDATE_RESOURCE, ({ id }) => dispatch('fetchOne', id));
		},
		fetchOne({ commit }, tenantId) {
			return api.find(tenantId)
				.then(window => commit('update', { tenantId, window }))
				.catch(manageError);
		},
		fetchOneAndTenant({ dispatch }, tenantId) {
			// need to update tenant when reservation window is updated to enforce fetch of changelog
			return Promise.all([
				dispatch('fetchOne', tenantId),
				dispatch('tenants/fetchOne', tenantId, { root: true })
			]);
		},
		updateDefaultWindow({ dispatch }, { tenantId, window }) {
			return api.updateDefault(tenantId, window)
				.then(() => dispatch('fetchOneAndTenant', tenantId))
				.catch(manageError);
		},
		updateStationWindow({ dispatch }, { tenantId, stationId, window }) {
			return api.updateStation(tenantId, stationId, window)
				.then(() => dispatch('fetchOneAndTenant', tenantId))
				.catch(manageError);
		},
		removeStationWindow({ dispatch }, { tenantId, stationId }) {
			return api.removeStation(tenantId, stationId)
				.then(() => dispatch('fetchOneAndTenant', tenantId))
				.catch(manageError);
		},
		setOverride({ dispatch }, { tenantId, spacecraft, system, window }) {
			const spacecraftId = spacecraft;
			const systemId = system;

			return api.setOverride(tenantId, spacecraftId, systemId, window)
				.then(() => dispatch('fetchOneAndTenant', tenantId))
				.catch(manageError);
		},
		removeOverride({ dispatch }, { tenantId, spacecraft, system }) {
			const spacecraftId = spacecraft;
			const systemId = system;

			return api.deleteOverride(tenantId, spacecraftId, systemId)
				.then(() => dispatch('fetchOneAndTenant', tenantId))
				.catch(manageError);
		},
		updateSingleResourceOverrideWindows({ getters }, { tenantId, overrides, resourceType }) {
			const resource = resourceType.toLowerCase();
			const windows = getters.find(tenantId) || { overrides: [] };
			const oldOverrides = windows[`${resourceType.toLowerCase()}s`];

			let update = null;
			let remove = null;
			if (resource === 'spacecraft') {
				update = override => api.setSpacecraftOverride(tenantId, override[`${resource}`], override.window);
				remove = override => api.deleteSpacecraftOverride(tenantId, override[`${resource}`]);
			} else {
				update = override => api.setSystemOverride(tenantId, override.system, override.window);
				remove = override => api.deleteSystemOverride(tenantId, override.system);
			}

			const wasAdded = override => !oldOverrides.find((rule) => rule[`${resource}`] === override[`${resource}`]);
			const wasRemoved = override => !overrides.find((rule) => rule[`${resource}`] === override[`${resource}`]);
			const wasUpdated = override => oldOverrides.find((rule) =>
				rule[`${resource}`] === override[`${resource}`] &&
				rule.window !== override.window);

			const added = overrides
				.filter(wasAdded)
				.map(update);
			const removed = oldOverrides
				.filter(wasRemoved)
				.map(remove);
			const updated = overrides
				.filter(wasUpdated)
				.map(update);

			return Promise
				.allSettled([...removed, ...updated, ...added])
				.then(list => {
					const failed = list.filter(response => response.status === 'rejected');

					if (failed.length) {
						failed.forEach(response => console.log(response));
						throw new Error('Not all overrides were updated successfully.');
					}
				});
		},
		updateOverrideWindows({ getters, dispatch }, { tenantId, overrides }) {
			const windows = getters.find(tenantId) || { overrides: [] };
			const oldOverrides = windows.overrides;

			const update = override => dispatch('setOverride', { ...override, tenantId });
			const remove = override => dispatch('removeOverride', { ...override, tenantId });

			const wasAdded = override => !oldOverrides.find(({ spacecraft, system }) => spacecraft === override.spacecraft && system === override.system);
			const wasRemoved = override => !overrides.find(({ spacecraft, system }) => spacecraft === override.spacecraft && system === override.system);
			const wasUpdated = override => oldOverrides.find(({ spacecraft, system, window }) =>
				spacecraft === override.spacecraft &&
				system === override.system &&
				window !== override.window
			);

			const added = overrides
				.filter(wasAdded)
				.map(update);
			const removed = oldOverrides
				.filter(wasRemoved)
				.map(remove);
			const updated = overrides
				.filter(wasUpdated)
				.map(update);

			return Promise
				.allSettled([...removed, ...updated, ...added])
				.then(list => {
					const failed = list.filter(response => response.status === 'rejected');

					if (failed.length) {
						failed.forEach(response => console.log(response.reason));
						throw new Error('Not all overrides were updated successfully.');
					}
				});
		}
	}
};
