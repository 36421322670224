<template lang="pug">
teleport(to="body")
	transition(name="fade")
		.dialog-overlay(v-show="showDialog")
			.dialog-box
				p.message {{ appliedMessage }}
				.buttons(v-if="appliedType === 'confirm'")
					VButton.confirm(@click.stop="confirm") Confirm
					VButton.secondary(v-if="alternateConfirmOption" @click.stop="alternateConfirm") {{ alternateConfirmOption }}
					VButton.secondary.invert.cancel(@click.stop="cancel") Cancel
				.buttons(v-else-if="appliedType === 'alert'")
					VButton.confirm(@click.stop="confirm") Ok
				template(v-else)
					VInput(
						v-model="value"
						@keyup.enter="confirmPrompt"
					)
					VCallout(v-if="requireNumber && this.notNumberFormat", type='warning') {{this.notNumberFormat}}
					.buttons
						VButton.confirm(
							@click.stop="confirmPrompt"
							:disabled="!value.length || (requireNumber && $validate.isNumeric(value))"
						) Ok
						VButton.secondary.invert.cancel(@click.stop="cancel") Cancel
</template>

<script>
export default {
	name: 'TheWindowDialog',
	data() {
		return {
			message: null,
			type: '',
			showDialog: false,
			value: '',
			requireNumber: false,
			alternateConfirmOption: false
		};
	},
	computed: {
		appliedMessage() {
			return this.message ?? 'Are you sure you want to do this?';
		},
		appliedType() {
			return this.type ?? 'confirm';
		},
		notNumberFormat() {
			return this.requireNumber && this.$validate.isNumeric(this.value) && this.value != '' ? "Input is of wrong type, expecting a Number" : null;
		}
	},
	created() {
		this.$bus.on('$dialogShow', this.show);
	},
	methods: {
		show({ message, type, requireNumber, alternateConfirmOption }) {
			this.value = '';
			this.message = message;
			this.type = type;
			this.requireNumber = requireNumber;
			this.showDialog = true;
			this.alternateConfirmOption = alternateConfirmOption ?? false;
			document.body.addEventListener('keyup', this.closeOnEscape);
			document.body.style.overflow = 'hidden';
		},
		close() {
			document.body.removeEventListener('keyup', this.closeOnEscape);
			document.body.style.overflow = 'auto';
			this.showDialog = false;
		},
		confirm() {
			this.$bus.emit('$dialogResolve', true);
			this.close();
		},
		alternateConfirm() {
			this.$bus.emit('$dialogResolve', 'alternateConfirm');
			this.close();
		},
		cancel() {
			this.$bus.emit('$dialogResolve', false);
			this.close();
		},
		confirmPrompt() {
			if (!this.value.length) {
				return;
			}
			this.$bus.emit('$dialogResolve', this.value);
			this.close();
		},
		closeOnEscape(event) {
			if (event.key == 'Escape') {
				this.close();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.dialog-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-y: auto;
	background: hsl(0 0% 0% / 40%);
	z-index: 1001;
	display: flex;
	align-items: center;
	padding: rem-calc(40 20);

	@include scrollbars(0.5rem, hsl(var(--white20)));

	.dialog-box {
		margin: 0 auto;
		padding: rem-calc(20);
		background: hsl(var(--dark-purple110));
		border: 1px solid hsl(var(--white20));
		box-shadow: inset 0 0 9px 0 hsl(0 0% 100% / 10%);
		border-radius: var(--global-radius);
		min-width: rem-calc(350);
		max-width: rem-calc(700);
	}

	.message {
		margin: rem-calc(0 0 20);
		white-space: pre-wrap;
	}

	#{v-deep('.input')} {
		margin-bottom: rem-calc(20);
	}
}

.buttons {
	display: flex;
	justify-content: flex-end;

	.button {
		margin-bottom: 0;
	}
}
</style>
