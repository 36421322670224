<template lang="pug">
.component-submissions
	header
		h3.title Components
		.signature-profile
			.label Signature profile
			SignatureProfilePicker
	ol.submission-groups(v-if="hasGroups")
		ComponentSubmission(
			v-for="group in groups"
			:group="group"
			:evaluationIndex="evaluationIndex"
		)
	.blank-slate(v-else)
		VIcon(name="deprecated")
		p No required components for current signature profile
</template>

<script>


import { groupSubmissionsByComponent } from '../lib/signature';

import ComponentSubmission from './ComponentSubmission.vue';
import SignatureProfilePicker from '../SignatureProfilePicker/SignatureProfilePicker.vue';

export default {
	components: {
		ComponentSubmission,
		SignatureProfilePicker
	},
	inject: [
		'$signContactModal'
	],
	props: {
		signature: {
			type: Object,
			required: true
		},
		evaluationIndex: {
			type: Number,
			required: false,
			default: 0
		}
	},
	computed: {
		groups() {
			return groupSubmissionsByComponent(this.signature, {
				profile: this.signatureProfile,
				components: this.signatureComponents,
				evaluationIndex: this.evaluationIndex
			});
		},
		signatureComponents() {
			return this.$store.getters['signatureComponents/all'];
		},
		signatureProfile() {
			return this.$store.getters['signatureProfiles/find'](this.signatureProfileId);
		},
		signatureProfileId() {
			if (this.signature.evaluations.length === 0) {
				return this.$signContactModal.signatureProfileId;
			}
			return this.signature.evaluations[this.evaluationIndex].signatureProfileId;
		},
		hasGroups() {
			return this.groups.length > 0;
		}
	}
};

</script>

<style lang="scss" scoped>
header {
	display: flex;
	border-bottom: rem-calc(1) solid hsl(var(--white10));
	padding-bottom: rem-calc(6);
}

.title {
	flex: 0 1 100%;
	font-size: rem-calc(14.5);
	font-weight: 400;
	color: hsl(var(--white70));
	margin: 0;
}

.signature-profile {
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	margin-left: rem-calc(20);

	.label {
		margin-right: rem-calc(10);
		font-size: rem-calc(13);
	}

	#{v-deep('.button')} {
		margin: 0;
	}
}

.submission-groups {
	list-style: none;
}

.blank-slate {
	--icon-color-primary: hsl(var(--white60));

	background: hsl(var(--white05));
	border-radius: rem-calc(9);
	margin: rem-calc(20) 0 0;
	padding: rem-calc(40);
	color: var(--icon-color-primary);

	.icon {
		--icon-size: #{rem-calc(52)};

		display: block;
		margin: 0 auto 10px;
	}

	p {
		max-width: rem-calc(220);
		font-size: rem-calc(17);
		margin: 0 auto;
		text-align: center;
	}
}
</style>
