<template lang="pug">
Detail(
	icon="signing"
	:text="text"
)
</template>

<script>

import Detail from './Detail.vue';

export default {
	components: {
		Detail
	},
	props: {
		principalId: {
			type: String,
			required: true
		},
		isElaborated: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isLoaded: false
		};
	},
	computed: {
		text() {
			return `${this.action} ${this.principal?.name ?? this.fallbackName}`;
		},
		action() {
			if (this.isElaborated) {
				return 'Elaborated by';
			}

			return 'Signed by';
		},
		fallbackName() {
			if (this.isLoaded) {
				return 'unknown';
			}
			return '…';
		},
		principal() {
			return this.$store.getters['principals/find'](this.principalId);
		}
	},
	watch: {
		principalId: {
			handler(value) {
				this.isLoaded = false;
				this.$store.dispatch('principals/ensure', value).then(() => {
					this.isLoaded = true;
				});
			},
			immediate: true
		}
	}
};

</script>
