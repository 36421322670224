<template lang="pug">
VTooltip(:text="buttonTooltip")
	a.button.secondary(
		:class="classList"
		:disabled="validLinks.length ? null : true"
		@click="openLinks"
	)
		VIcon(name="globe")
		.label(v-if='text') {{ text }}
</template>

<script>
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import validate, { joi } from '@pv';

export default {
	props: {
		text: {
			type: String,
			required: false,
			default: null
		},
		extraClass: {
			type: String,
			required: false,
			default: null
		},
		contacts: {
			type: Array,
			required: false,
			validator: validate(joi.array().items(joi.extendedContact())),
			default: () => []
		},
		systemName: {
			type: String,
			required: false,
			default: null
		},
		startTime: {
			type: DateTime,
			required: false,
			default: null
		},
		endTime: {
			type: DateTime,
			required: false,
			default: null
		}
	},
	computed: {
		validLinks() {
			if (this.contacts?.length) {
				return this.contacts.map(contact => this.findTrackingLink(contact.system.name, contact.startTime, contact.endTime)).filter(Boolean);
			}
			const link = this.findTrackingLink(this.systemName, this.startTime, this.endTime);
			return link ? [link] : [];
		},
		invalidLinks() {
			if (this.contacts?.length) {
				return this.contacts.map(contact => {
					const link = this.findTrackingLink(contact.system.name, contact.startTime, contact.endTime);
					if (!link) {
						return contact.system.name;
					}
					return null;

				}).filter(Boolean);
			}
			const link = this.findTrackingLink(this.systemName, this.startTime, this.endTime);
			return link ? [] : [this.systemName];
		},
		buttonTooltip() {
			if (!this.contacts?.length && !this.systemName) {
				return 'No contacts selected';
			} else if (!this.validLinks.length) {
				return 'No Grafana link is configured';
			}
			return (this.validLinks.length + this.invalidLinks.length) > 1 ? `Open Grafana dashboards` : 'Open Grafana dashboard';
		},
		classList () {
			return [
				this.extraClass,
				this.text ? null : 'icon-only'
			];
		},
		...mapGetters({
			findTrackingLink: 'systems/findTrackingLink'
		})
	},
	methods: {
		openLinks() {
			this.validLinks.forEach(link => window.open(link, "_blank"));
			this.invalidLinks.forEach(systemName => this.$notify.event(`No grafana link is configured for ${systemName}`, 'info'));
		}
	}
};
</script>

<style lang="scss" scoped>
a.button.secondary {
	&.contacts-entry {
		margin-bottom: 0;
		height: 100%;
	}

	&.contacts-entry[disabled] {
		height: 100%;
	}

	&.activity-monitor {
		margin-bottom: rem-calc(10);
	}
}
</style>

