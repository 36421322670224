<template lang="pug">
PopupWindow(placement="bottom-end").settings
	VButton.secondary.invert.settings-button(
		icon="arrow/down.simple"
	) {{ getAcronyms(user.name) }}
	template(#content)
		header
			VIcon(name="profile")
			| {{ user.name }}
		h5 SETTINGS
		ul.list
			li.settings-item
				ToggleSwitch(
					fieldId="settings_hideDeprecated"
					:checked="hideDeprecated"
					@update:checked="value => hideDeprecated = value"
					size="small"
				) {{ settings.hideDeprecated.title }}
				.helper-text {{ settings.hideDeprecated.helperText }}
			li.settings-item
				ToggleSwitch(
					fieldId="settings_logActivity"
					:checked="logActivity"
					@update:checked="value => logActivity = value"
					size="small"
				) {{ settings.logActivity.title }}
				.helper-text {{ settings.logActivity.helperText }}
				VButton.secondary.invert.clear-logs(@click="clearLogs") Clear activity
		footer
			a(@click="logout") Log out
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import settings from '@/store/helpers/store-settings';
import { mapSettings } from '@/store/helpers/store-settings-utilities';
import PopupWindow from '@/components/base/PopupWindow.vue';
import ToggleSwitch from '@/components/base/ToggleSwitch.vue';

export default {
	name: 'NavbarSettings',
	components: {
		PopupWindow,
		ToggleSwitch
	},
	props: {
		show: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			settings: {
				hideDeprecated: settings.hideDeprecated,
				logActivity: settings.logActivity
			}
		};
	},
	computed: {
		...mapSettings([
			'hideDeprecated',
			'logActivity'
		]),
		...mapGetters({
			user: 'user/user'
		})
	},
	methods: {
		getAcronyms(name) {
			let fullName = name.split(/ |-/);
			let acronyms = fullName.map(name => name[0]);
			return acronyms.join('');
		},
		async clearLogs() {
			const confirm = await this.$dialog.confirm({ message: 'Are you sure you want to clear all activities?' });

			if (confirm) {
				this.clearActivityLogs();
			}
		},
		...mapActions({
			logout: 'user/logout',
			clearActivityLogs: 'favorites/clearLogs'
		})
	}
};
</script>

<style lang="scss" scoped>
.settings {
	align-self: center;
}

#{v-deep('.settings-button')} {
	margin: rem-calc(0 0 0 8);
	padding: rem-calc(0 8 0 6);
	height: $navbar-labels-size;
	min-height: 0;

	.icon {
		width: rem-calc(14);
		height: rem-calc(14);
		opacity: 0.4;
	}

	&:hover {
		.icon {
			opacity: 0.65;
		}
	}
}

header {
	border-bottom: 1px solid hsl(var(--white20));
	margin: rem-calc(0 0 15);
	padding: rem-calc(5 0 15);
	display: flex;
	align-items: center;
	font-weight: var(--font-bold);

	.icon {
		margin-right: rem-calc(10);
		opacity: 0.6;
	}
}

footer {
	border-top: 1px solid hsl(var(--white20));
	margin: rem-calc(10 0 0);

	> a {
		display: block;
		cursor: pointer;
		padding: rem-calc(15 0 5);
		color: var(--body-color);
		font-weight: var(--font-bold);
	}
}

h5 {
	font-weight: var(--font-bolder);
	color: hsl(var(--white60));
	font-size: var(--font-small-size);
	margin: rem-calc(0 0 10);
}

.settings-item {
	display: flex;
	flex-flow: column;
	padding: rem-calc(0 0 10);
}

.helper-text {
	margin-top: rem-calc(5);
}

.clear-logs {
	margin: rem-calc(10 0 0) auto;
}
</style>
