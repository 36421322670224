import { manageAPIError } from '@/js/api/builder/api-error';
import { manageError } from '@/store/helpers/store-error';
import { getScope } from '@/js/auth/auth-config';
import Connection from '@/js/network/helpers';
import { UPDATE_RESOURCE } from '@/env';
import { event } from '@/js/plugins/notify/notify';

const module = 'dedicatedSystems';
const basePath = '/v1.0/dedicated_systems';

const dedicatedSystemsConnection = new Connection(basePath, await getScope('kogs'), module);

const api = {
	set: (tenant_id, system_id) => dedicatedSystemsConnection.axios
		.put('/', {}, { params: { tenant_id, system_id }, refetchId: tenant_id })
		.catch(manageAPIError),

	delete: (tenant_id, system_id) => dedicatedSystemsConnection.axios
		.delete('/', { params: { tenant_id, system_id }, refetchId: tenant_id })
		.catch(manageAPIError),

	find: (tenant_id) => dedicatedSystemsConnection.axios
		.get('/', { params: { tenant_id } })
		.then(response => response.data.data[0])
		.catch(manageAPIError)
};


export default {
	namespaced: true,
	state: () => ({
		dedicatedSystems: []
	}),
	getters: {
		find(state) {
			return tenantId => {
				const dedicatedForTenant = state.dedicatedSystems.find(record => record.tenant === tenantId);
				if (!dedicatedForTenant) {
					return null;
				}
				return dedicatedForTenant.systems;
			};
		}
	},
	mutations: {
		update(state, response) {
			state.dedicatedSystems = [
				...state.dedicatedSystems.filter(record => record.tenant !== response.tenant),
				response
			];
		}
	},
	actions: {
		ensureOne({ dispatch, getters }, { id }) {
			if (getters.find(id)) {
				return;
			}
			dispatch('fetchOne', id);
			dispatch('initiateMore');
		},
		initiateMore({ dispatch }) {
			dedicatedSystemsConnection.socket.on(UPDATE_RESOURCE, ({ id }) => dispatch('fetchOneAndTenant', id));
		},
		fetchOne({ commit }, tenantId) {
			return api.find(tenantId)
				.then(dedicatedForTenant => commit('update', dedicatedForTenant))
				.catch(manageError);
		},
		fetchOneAndTenant({ dispatch }, tenantId) {
			// need to update tenant when dedicated system is updated to enforce fetch of changelog
			return Promise.all([
				dispatch('fetchOne', tenantId),
				dispatch('tenants/fetchOne', tenantId, { root: true }),
				dispatch('systems/fetchDedicatedSystem', tenantId, { root: true })
			]);
		},
		update({ getters, dispatch }, { tenantId, systems }) {
			const currentSystems = getters.find(tenantId);

			const removedSystems = currentSystems.filter(id => !systems.includes(id));

			const addedSystems = systems.filter(id => !currentSystems.includes(id));

			return Promise.all([
				...removedSystems.map(system => api.delete(tenantId, system)),
				...addedSystems.map(system => api.set(tenantId, system))
			])
				.catch(() => event('Failed to update dedicated systems.', 'error'))
				.finally(() => dispatch('fetchOneAndTenant', tenantId));
		}
	}
};

export { dedicatedSystemsConnection };
