import { createFilter } from '@/js/utils/filter';
import { getSignatureComponents } from '@/js/api/signature-components';

const state = {
	signatureComponents: null
};

const mutations = {
	set(state, signatureComponents) {
		state.signatureComponents = signatureComponents;
	}
};

const actions = {
	ensure({ getters, commit }) {
		if (getters.hasSignatureComponents) {
			return Promise.resolve(getters.all);
		}

		return getSignatureComponents()
			.then(({ data }) => {
				commit('set', data.data);
				return getters.all;
			});
	}
};

const getters = {
	all({ signatureComponents }) {
		return signatureComponents;
	},
	find(_, { all }) {
		return (id) => {
			return all.find(createFilter({ id }));
		};
	},
	hasSignatureComponents(_, { all }) {
		return Array.isArray(all);
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
