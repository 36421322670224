import { state, mutations, actions, getters } from '@/store/build-module/common';
import baseAPIBuilder from '@/js/api/builder/api-builder';
import { getScope } from '@/js/auth/auth-config';

const module = 'opsUnits';

const meta = {
	module,
	basePath: '/v1.0/ops_units',
	mapping: { lifecycle_state: 'lifecycleState' },
	inputWrapper: input => ({ data: input }),
	scope: await getScope('kogs')
};
const builder = new baseAPIBuilder(meta);
const api = {
	...builder.build(['list'])
};

export default {
	namespaced: true,
	state: () => ({
		...state(module)
	}),
	getters: {
		...getters(module)
	},
	mutations: {
		...mutations(module)
	},
	actions: {
		...actions(api)
	}
};
