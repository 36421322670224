import { markRaw } from 'vue';

import delegate from '@/js/utils/delegate';
import { createFilter } from '@/js/utils/filter';
import { toDateTime } from '@/js/utils/filter/types';

import dig from '@/js/utils/dig';
import { evaluationOutcomeForced } from '../signature';

import { SubmissionDetailSignedBy }  from '../../SubmissionDetails';

export default () => {
	return {
		inject: {
			$signContactModal: {
				default: null
			}
		},
		computed: {
			...delegate('evaluation', { to: 'submission' }),
			...delegate('signature', { to: '$signContactModal' }),
			hasSubmission() {
				return this.submission !== null && typeof this.submission === 'object';
			},
			outcome() {
				if (!this.hasSubmission) {
					return 'waiting';
				}

				// Collect the outcome for all human overrides and the submission. The
				// resulting array consists of strings or null. The first string is the
				// current outcome
				return [
					...this.humanOverrides.flatMap((humanOverride) => {
						const entries = dig(humanOverride, ['input', 'payload', 'acknowledgeList', 'acknowledge']);

						return entries.map((entry) => {
							return dig(entry, ['override', 'outcome']);
						});
					}),
					dig(this.submission, ['evaluation', 'evaluation', 'outcome'])
				].find((outcome) => {
					return typeof outcome === 'string' && outcome.length > 0;
				});
			},
			prettyOutcome() {
				switch (this.outcome) {
					default:
						return this.$text.toSentenceCase(this.outcome);
				}
			},
			comment() {
				if (!this.hasSubmission) {
					return 'Waiting for submission';
				}

				return [
					...this.humanOverrides.flatMap((humanOverride) => {
						const entries = dig(humanOverride, ['input', 'payload', 'acknowledgeList', 'acknowledge']);

						return [
							...entries.flatMap((entry) => {
								return [
									dig(entry, ['comment']),
									dig(entry, ['elaborate', 'comment']),
									dig(entry, ['override', 'comment'])
								];
							})
						];
					}),
					dig(this.submission, ['evaluation', 'evaluation', 'comment'])
				].find((comment) => {
					return typeof comment === 'string';
				});
			},
			hasComment() {
				return typeof this.comment === 'string' &&
					this.comment.length > 0;
			},
			attemptId() {
				return dig(this, ['submission', 'evaluation', 'attemptId']);
			},
			hasAttemptId() {
				return typeof this.attemptId === 'string' && this.attemptId.length > 0;
			},
			baseDetails() {
				return [
					{
						props: {
							icon: 'calendar',
							text: this.submittedDate
						}
					},
					{
						props: {
							icon: 'time',
							text: this.submittedTime
						}
					},
					{
						component: markRaw(SubmissionDetailSignedBy),
						props: {
							principalId: this.submittedBy,
							isElaborated: this.isElaborated
						}
					}
				];
			},
			details() {
				return this.baseDetails;
			},
			issueCategories() {
				if (!this.hasSubmission) {
					return [];
				}
				if (this.isManualOutcome) {
					return [
						...this.humanOverrides.flatMap((humanOverride) => {
							const entries = dig(humanOverride, ['input', 'payload', 'acknowledgeList', 'acknowledge']);

							return entries.map((entry) => {
								return dig(entry, ['override', 'issueCategories']);
							});
						}),
						dig(this.submission, ['evaluation', 'evaluation', 'issueCategories'])
					].find((categories) => {
						return Array.isArray(categories);
					});
				}
				return this.evaluation?.evaluation?.issueCategories;
			},
			submittedBy() {
				if (this.isManualOutcome || this.isElaborated) {
					return this.humanOverride.submittedBy;
				}

				return this.submission.submittedBy;
			},
			submittedAt() {
				const date = [
					this.humanOverride?.submittedAt,
					this.submission.submittedAt
				].find((date) => {
					return typeof date === 'string';
				});

				return toDateTime(date);
			},
			submittedDate() {
				return this.$time.formatDate(this.submittedAt);
			},
			submittedTime() {
				return this.$time.formatTime(this.submittedAt);
			},
			hasHumanOverride() {
				return this.humanOverride !== null && typeof this.humanOverride === 'object';
			},
			isManualOutcome() {
				if (!this.hasHumanOverride) {
					return false;
				}

				return (dig(this.humanOverride, ['input', 'payload', 'acknowledgeList', 'acknowledge']) ?? [])
					.filter(createFilter({
						'identityReference.attemptId': this.attemptId
					}))
					.some((entry) => {
						const kind = entry.kind;
						const outcome = dig(entry, [kind?.toLowerCase(), 'outcome']);

						return typeof outcome === 'string';
					});
			},
			isElaborated() {
				if (!this.hasHumanOverride) {
					return false;
				}

				return (dig(this.humanOverride, ['input', 'payload', 'acknowledgeList', 'acknowledge']) ?? [])
					.filter(createFilter({
						'identityReference.attemptId': this.attemptId
					}))
					.some(createFilter({
						'kind': 'ELABORATE'
					}));
			},
			evaluationForced() {
				return evaluationOutcomeForced(this.signature, {
					evaluationIndex: this.evaluationIndex
				});
			}
		}
	};
};
