<template lang="pug">
.outcome
	VTooltip
		template(#content)
			ComponentGroups(
				:groups="componentGroups"
				:signature="signature"
			)

		OutcomeIcon(:outcome="outcome")
</template>

<script>

import { groupSubmissionsByComponent } from '@/components/feature/ContactsSignModal/lib/signature';

import ComponentGroups from './ComponentGroups.vue';
import OutcomeIcon from '@/components/feature/ContactsSignModal/OutcomeIcon.vue';

export default {
	name: 'OutcomeColumn',
	components: {
		ComponentGroups,
		OutcomeIcon
	},
	props: {
		outcome: {
			type: String,
			required: true
		},
		signature: {
			type: Object,
			required: true
		},
		signatureProfileId: {
			type: String,
			required: true
		}
	},
	computed: {
		componentGroups() {
			return groupSubmissionsByComponent(this.signature, {
				profile: this.signatureProfile,
				components: this.signatureComponents
			});
		},
		signatureComponents() {
			return this.$store.getters['signatureComponents/all'];
		},
		signatureProfile() {
			return this.$store.getters['signatureProfiles/find'](this.signatureProfileId);
		}
	}
};

</script>
