import { createFilter } from '@/js/utils/filter';

import dig from '@/js/utils/dig';

const HUMAN_OVERRIDE = 'HUMAN_OVERRIDE';

const findSubmissionIdByEvaluation = (signature, attemptId) => {
	return signature.submissions.find((submission) => {
		return submission.evaluations.some(createFilter({
			attemptId
		}))?.id;
	});
};

export const getComponentName = (signature, submissionId) => {
	const submission = signature.submissions.find(createFilter({
		id: submissionId
	}));

	if (submission.input.componentName === HUMAN_OVERRIDE) {
		const identityReference = dig(submission, [
			'input',
			'payload',
			'acknowledgeList',
			'acknowledge[0]',
			'identityReference'
		]);

		if (!identityReference) {
			return null;
		} else if (identityReference.componentName === HUMAN_OVERRIDE) {
			return getComponentName(
				signature,
				findSubmissionIdByEvaluation(signature, identityReference.attemptId)
			);
		}

		return identityReference.componentName;
	}

	return submission.input.componentName;
};
