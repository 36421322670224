import _ from 'lodash';
import { DateTime } from 'luxon';

const requiredFields = requiredFields => {
	let error = 'Required fields are missing input.';
	return requiredFields.some(field => field === '') ? error : null;
};

const name = (value, allow = {}) => {
	let error = '';

	if (allow.strict) {
		error = 'Invalid name. Only lowercase english characters and numbers allowed.';
		return /^[a-z0-9]+$/.test(value) || value == '' ? null : error;
	}

	if (allow.lowercase && allow.hyphen) {
		error = 'Invalid name. Only english characters, numbers, underscores and hyphens allowed.';
		return /^[a-zA-Z0-9_-]+$/.test(value) || value == '' ? null : error;
	}

	if (allow.lowercase) {
		error = 'Invalid name. Only english characters, numbers and underscores allowed.';
		return /^[a-zA-Z0-9_]+$/.test(value) || value == '' ? null : error;
	}

	error = 'Invalid name. Only upper case english characters, numbers and underscores allowed.';
	return /^[A-Z0-9_]+$/.test(value) || value == '' ? null : error;
};

const latitude = value => {
	let error = 'Latitude must be between -90.000000 and 90.000000';
	return _.inRange(value, -90.000000, 90.000000) ? null : error;
};

const longitude = value => {
	let error = 'Longitude must be between -180.000000 and 180.000000';
	return _.inRange(value, -180.000000, 180.000000) ? null : error;
};

const altitude = value => {
	let error = 'Altitude must be a number between -100 and 10000';
	return _.inRange(value, -100, 10000) ? null : error;
};

const integerRange = (value, min, max) => {
	if (!_.isInteger(+value)) {
		return 'Input must be an integer';
	}

	let error = `Input must be an integer between ${min} and ${max}`;
	return _.inRange(value, min, max) ? null : error;
};

const decimalRange = (value, min, max) => {
	let error = `Number must be between ${min} and ${max}`;
	return _.inRange(value, min, max) ? null : error;
};

const url = (value, fieldName) => {
	// Valid http(s) url
	let check = /^(https?):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]/.test(value) || value == '';
	let error = `${fieldName} must be a valid URL (prefixed with http(s))`;
	return check ? null : error;
};

const isoDate = value => {
	let check = DateTime.fromISO(value).isValid;
	let error = 'Not a valid ISO date';
	return check ? null : error;
};

const isGreaterThan = (lowValue, highValue, lowValueName, highValueName) => {
	let check = highValue > lowValue;
	let error = `${highValueName} must be greater than ${lowValueName}`;
	return check ? null : error;
};

const isNumeric = value => {
	return !isNaN(parseFloat(value)) && isFinite(value) ? null : 'Input must be an integer';
};

export default {
	install: app => {
		app.config.globalProperties.$validate = {
			requiredFields,
			name,
			latitude,
			longitude,
			altitude,
			integerRange,
			decimalRange,
			url,
			isoDate,
			isGreaterThan,
			isNumeric
		};
	}
};
