<template lang="pug">
StyleguideExample(title="Basic")
		VDropdownSelect(
			placeholder="Select county"
			:options="counties"
			v-model="countyId"
		)

		template(#value)
			| countyId: {{countyId ?? 'null'}}

StyleguideExample(title="Grouped options")
		VDropdownSelect(
			placeholder="PokemonPicker™"
			:options="pokemon"
			v-model="pokemonId"
		)

		template(#value)
			| pokemonId: {{pokemonId ?? 'null'}}
</template>

<script>

import styleguideComponents from '../components';

export default {
	components: {
		...styleguideComponents
	},
	data() {
		return {
			countyId: null,
			pokemonId: null
		};
	},
	computed: {
		counties() {
			return [
				{ id: 3, label: 'Oslo' },
				{ id: 11, label: 'Rogaland' },
				{ id: 15, label: 'Møre og Romsdal' },
				{ id: 18, label: 'Nordland' },
				{ id: 30, label: 'Viken' },
				{ id: 34, label: 'Innlandet' },
				{ id: 38, label: 'Vestfold og Telemark' },
				{ id: 42, label: 'Agder' },
				{ id: 46, label: 'Vestland' },
				{ id: 50, label: 'Trøndelag' },
				{ id: 54, label: 'Troms og Finnmark' }
			];
		},
		pokemon() {
			return [
				{ id: 25, label: 'Pikachu' },
				{
					group: true,
					options: [
						{ id: 52, label: 'Meowth' }
					]
				},
				{
					group: true,
					title: 'Grass',
					options: [
						{ id: 1, label: 'Bulbasaur' },
						{ id: 2, label: 'Ivysaur' },
						{ id: 3, label: 'Venusaur' }
					]
				},
				{
					group: true,
					title: 'Fire',
					options: [
						{ id: 4, label: 'Charmander' },
						{ id: 5, label: 'Charmeleon' },
						{ id: 6, label: 'Charizard' }
					]
				},
				{
					group: true,
					title: 'Water',
					options: [
						{ id: 7, label: 'Squirtle' },
						{ id: 8, label: 'Wartortle' },
						{ id: 9, label: 'Blastoise' }
					]
				}
			];
		}
	}
};

</script>
