import Connection from '@/js/network/helpers';
import { camelize } from '@/js/utils/case-conversion';
import { getScope } from '@/js/auth/auth-config';
import { manageAPIError } from '@/js/api/builder/api-error';

const kogs = new Connection('/v1.0', await getScope('kogs')).axios;

const jsonSchemasPath = () => {
	return `/json/schemas`;
};

const jsonSchemaPath = (id) => {
	return `${jsonSchemasPath()}/${id}`;
};

const resources = ['ANTENNA', 'SPACECRAFTS', 'MISSION_PROFILE_PARAMETERIZATION'];

export const camelizeJsonSchemaResponse = (data) => {
	if (Array.isArray(data)) {
		return data.map(camelizeJsonSchemaResponse);
	} else if (data === null || typeof data !== 'object') {
		return data;
	}

	const preservedProperties = [
		'jsonObject',
		'jsonSchema'
	];

	return Object.entries(data)
		.reduce((data, [key, value]) => {
			key = camelize(key);
			return {
				...data,
				[key]: preservedProperties.includes(key) ? value : camelizeJsonSchemaResponse(value)
			};
		}, {});
};

export const getJsonSchemas = (resource = '') => {
	let path = jsonSchemasPath();
	if (resources.includes(resource)) {
		path += `?include[resources]=${resource}`;
	}

	return kogs.get(path)
		.then((response) => {
			return {
				...response,
				data: {
					...response.data,
					data: camelizeJsonSchemaResponse(response.data.data)
				}
			};
		});
};

export const getJsonSchema = (id) => {
	return kogs.get(jsonSchemaPath(id))
		.then((response) => {
			return {
				...response,
				data: camelizeJsonSchemaResponse(response.data)
			};
		});
};

export const createJsonSchema = (resourceType, jsonSchema) => {
	return kogs.post(jsonSchemasPath(), {
		data: {
			resource_type: resourceType,
			json_schema: jsonSchema
		}
	}, { refetchId: '00000000-0000-0000-0000-000000000000' }).catch(manageAPIError);
};

export const replaceJsonSchema = (id, resourceType, jsonSchema) => {
	return kogs.put(jsonSchemaPath(id), {
		data: {
			resource_type: resourceType,
			json_schema: jsonSchema
		}
	}, { refetchId: id }).catch(manageAPIError);
};
