const resolveDialog = ({ $bus }, type) => async input => {
	$bus.emit('$dialogShow', { ...input, type });

	let resolveFunction = null;

	const result = await new Promise(resolve => {
		resolveFunction = resolve;

		$bus.on('$dialogResolve', resolveFunction);
	});

	$bus.off('$dialogResolve', resolveFunction);

	return result;
};

export default {
	install: app => {
		app.config.globalProperties.$dialog = {
			confirm: resolveDialog(app.config.globalProperties, 'confirm'),
			alert: resolveDialog(app.config.globalProperties, 'alert'),
			prompt: resolveDialog(app.config.globalProperties, 'prompt')
		};
	}
};
